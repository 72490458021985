import React from "react";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";

const COLORS = ["#1ab5fa", "#ebf2f5"];

export default ({ score, icon }) => {
  const data = [
    { name: "", value: score },
    { name: "", value: 100 - score },
  ];

  return (
    <div style={{ width: "100%", height: 180 }}>
      <div
        style={{
          position: "absolute",
          textAlign: "center",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        {icon}
        <div className="mt-2">{score}&nbsp;/&nbsp;100</div>
      </div>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            dataKey="value"
            data={data}
            fill="#8884d8"
            innerRadius={65}
            outerRadius={80}
            startAngle={90}
            endAngle={-270}
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    </div>
  );
};
