import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const DELETE_PROPERTY = gql`
  mutation DeleteProperty($listing_id: String!) {
    deleteProperty(id: $listing_id) {
      message
    }
  }
`;

export default (({
  refetchQueries = [],
  onCompleted, onError }) =>
{
  const [deleteProperty, { loading, error }] = useMutation(
  DELETE_PROPERTY, {
    refetchQueries: () => refetchQueries,
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err) });



  return [listing_id => deleteProperty({
    variables: { listing_id } }),
  loading, error];
});