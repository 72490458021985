import _ from "lodash";

import {
  AirConditioning,
  Dishwasher,
  Fireplace,
  WashingMachine,
  Pool,
  Elevator,
  Wheelchair,
  Furnished,
} from "../components/general/Icons";

const AllAmenities = [
  // {
  //   label: "Wheelchair Accessible",
  //   search: "Wheelchair Accessible",
  //   question: "Is your property wheelchair accessible",
  //   value: "wheel-chair",
  //   group: "accessibility",
  //   icon: Wheelchair,
  // },
  {
    label: "Elevator",
    search: "Must have elevator",
    question: "Does your property have an elevator?",
    value: "elevator",
    group: "accessibility",
    icon: Elevator,
  },

  // {
  //   label: "Swimming Pool",
  //   search: "Swimming pool",
  //   question: "Does your property have a swimming pool?",
  //   group: "details",
  //   value: "pool",
  //   icon: Pool,
  // },

  // {
  //   label: "Basement",
  //   search: "Basement",
  //   question: "Does your property have a basement?",
  //   group: "details",
  //   value: "basement",
  //   icon: Pool,
  // },

  // {
  //   label: "AC/Heating",
  //   search: "Central AC/Heating",
  //   qustion: "Does your property have central AC/Heating?",
  //   group: "details",
  //   value: "air-conditioning",
  //   icon: AirConditioning,
  // },

  {
    label: "Furnished",
    search: "Furnished homes only",
    question:
      "Is your property furnished (e.g. couches, beds, tables, chairs, etc.)?",
    group: "details",
    value: "furniture",
    icon: Furnished,
  },

  // {
  //   label: "Washer/Dryer",
  //   search: "Washer/dryer included",
  //   question: "Does your property include a washer and dryer?",
  //   group: "details",
  //   value: "washer",
  //   icon: WashingMachine,
  // },

  {
    label: "Dishwasher",
    search: "Dishwasher included",
    question: "Does your property include a dishwasher?",
    group: "details",
    value: "dishwasher",
    icon: Dishwasher,
  },

  {
    label: "Fireplace",
    search: "Fireplace",
    question: "Does your property have a fireplace?",
    group: "details",
    value: "fireplace",
    icon: Fireplace,
  },
];

const getGroup = (group) => _.filter(AllAmenities, { group });

const accessibility = getGroup("accessibility");
const details = getGroup("details");

const matchAmenities = (group, selectedAmenities) =>
  getGroup(group).filter((am) => _.includes(selectedAmenities, am.value));

const diffAmenities = (group, selectedAmenities) =>
  _.difference(selectedAmenities, _.map(getGroup(group), "value"));

export { matchAmenities, diffAmenities };

export default {
  accessibility,
  details,
};
