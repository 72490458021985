import CommentImage1 from "../img/comment2.jpg";
import CommentImage2 from "../img/comment3.jpg";

export default [
  {
    text: "I liked the idea of renting my property to generate income but hated the idea of being a landlord. Then my realtor recommended Dojo. I could tell right away that their approach attracts buyers that care, not tenants, and that changed everything.",
    name: "Liam K.",
    image: CommentImage1
  },
  {
    text: "I hadn’t even considered a “gradual sale” until our realtor told us about Dojo. But it makes sense. The platform kept all of the transactions in one place, so staying organized and moving from one step to the next was seamless.",
    name: "Olivia W.",
    image: CommentImage2
  }
];