import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const QUERY = gql `

  query getMlsImportLog($groupBy: DateGrouping) {
    mlsImportLog(groupBy: $groupBy) {
      id
      dt
      added
      updated
      deleted
    }
  }
`;

export default ((onCompleted, onError) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY, {
    variables: {groupBy: "day"},
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err)
  });


  return [data && data.mlsImportLog, loading, error];
});
