import React, { useContext, useEffect } from "react";

import OnBoardingContext from "../context/OnBoardingContext";
import useCurrentUser from "../hooks/queries/useCurrentUser";
//import useEmailValidation from "../hooks/mutations/useEmailValidation";
import Onboarding from "../onboarding";
import useLogout from "../hooks/mutations/useLogout";

export default ({ anonymousUser, onHide }) => {
  const [user] = useCurrentUser();
  // const [validateEmail, validating] = useEmailValidation((data) => {
  //   setMessage(data.requestEmailValidation.message);
  // });
  const { open, setOpen, isSeller } = useContext(OnBoardingContext);
  const [logout] = useLogout();

  useEffect(() => {
    open && !anonymousUser && logout();
  }, [open, anonymousUser, logout]);

  return anonymousUser ? (
    <Onboarding
      show={open}
      user={user}
      anonymousUser={anonymousUser}
      onHide={() => {
        setOpen(false);
        onHide && onHide();
      }}
      isSeller={isSeller}
    />
  ) : null;
};
