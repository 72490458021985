import React from "react";
import { Link } from "react-router-dom";
import { Nav, Dropdown } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import Avatar from "../../general/Avatar";
import useLogout from "../../hooks/mutations/useLogout";
import useUserInfo from "../../hooks/helpers/useUserInfo";

export default ({ user, setLoggedOut }) => {
  const { t } = useTranslation();

  const [logout] = useLogout(() => {
    setLoggedOut(true);
  });

  const { hasCompleteProfile } = useUserInfo(user);

  return (
    <>
      <Dropdown alignRight={true}>
        <Dropdown.Toggle
          as={Nav.Link}
          className="px-2 no-dropdown-toggle"
          id="dropdown-basic"
        >
          <Avatar user={user} size={40} warning={!hasCompleteProfile}></Avatar>
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {!hasCompleteProfile && (
            <>
              <Dropdown.Item as={Link} to="?complete">
                <strong>{t("member.completeProfile")}</strong>
              </Dropdown.Item>

              <Dropdown.Divider />
            </>
          )}

          <Dropdown.Item as={Link} to="/profile/settings">
            Account Settings
          </Dropdown.Item>
          {false && (
            <Dropdown.Item as={Link} to={`/buyer/signup?email=${user.email}`}>
              {_.includes(user.modes, "member")
                ? "Switch to Member"
                : "Create Member Account"}
            </Dropdown.Item>
          )}

          {user.mode === "member" && _.includes(user.modes, "seller") ? (
            <Dropdown.Item as={Link} to={`/seller?login`}>
              Switch to Seller
            </Dropdown.Item>
          ) : (
            <Dropdown.Item as={Link} to={`/seller`}>
              List A Property
            </Dropdown.Item>
          )}

          <Dropdown.Divider />

          <Dropdown.Item onClick={(e) => logout()}>Logout</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};
