import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import ListingPropertyValue from "./ListingPropertyValue";
import ListingTaxDetails from "./ListingTaxDetails";
import ListingPropertyDetails from "./ListingPropertyDetails";
import usePropertyDetails from "../../../components/hooks/queries/usePropertyDetails";
import Loading from "../../../components/general/Loading";

export default React.forwardRef(({ listing }, ref) => {
  const { t } = useTranslation();
  const [details, loading] = usePropertyDetails(listing._id);

  return (
    <Card id="info" ref={ref} className="ListingInfo">
      <Card.Body>
        <h4 className="mb-3">{t("property.listingInfo")}</h4>
        {loading ? (
          <Loading />
        ) : (
          <React.Fragment>
            <ListingPropertyValue listing={listing} details={details} />
            <ListingTaxDetails listing={listing} details={details} />
            <ListingPropertyDetails listing={listing} details={details} />
          </React.Fragment>
        )}
      </Card.Body>
    </Card>
  );
});
