import React, { useState } from "react";
import _ from "lodash";

import {
  Row,
  Col,
  Badge,
  Button,
  Modal,
  ListGroup,
  Form,
} from "react-bootstrap";

import { makeImageUrl } from "../../../tools";
import useGetUsers from "../../../components/hooks/queries/useGetUsers";
import useUpdateUser from "../../../components/hooks/mutations/useUpdateUser";
import useCreateUser from "../../../components/hooks/mutations/useCreateUser";

export default ({ user }) => {
  const [newUser, setNewUser] = useState(undefined);

  const [refetch, users, loading, error] = useGetUsers({ type: "admin" });
  const [createAdminUser] = useCreateUser(
    {
      ...newUser,
      mode: "admin",
    },
    [],
    () => setNewUser(undefined)
  );
  const [updateUser] = useUpdateUser();

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2>Administrative Accounts</h2>
      </Col>

      <Modal show={newUser} onHide={() => setNewUser(undefined)}>
        <Modal.Header>
          <h5>Add New Support Account</h5>
        </Modal.Header>
        {newUser && (
          <Modal.Body>
            <Form.Group>
              <Form.Label>Firtname</Form.Label>
              <Form.Control
                value={newUser.firstname}
                onChange={(e) =>
                  setNewUser({ ...newUser, firstname: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Lastname</Form.Label>
              <Form.Control
                value={newUser.lastname}
                onChange={(e) =>
                  setNewUser({ ...newUser, lastname: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>E-Mail</Form.Label>
              <Form.Control
                type="email"
                value={newUser.email}
                onChange={(e) =>
                  setNewUser({ ...newUser, email: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Password</Form.Label>
              <Form.Control
                type="password"
                value={newUser.password}
                onChange={(e) =>
                  setNewUser({ ...newUser, password: e.target.value })
                }
              ></Form.Control>
            </Form.Group>

            <Form.Group>
              <Form.Label>Role</Form.Label>
              <Form.Control
                as="select"
                value={newUser.role}
                onChange={(e) =>
                  setNewUser({ ...newUser, role: e.target.value })
                }
              >
                <option value={undefined}>Select Role</option>
                <option value="data-entry">Data Entry Specialist</option>
                <option value="success-manager">Success Manager</option>
                <option value="admin">Super Admin</option>
              </Form.Control>
            </Form.Group>
          </Modal.Body>
        )}
        <Modal.Footer>
          <Button variant="light" onClick={() => setNewUser(undefined)}>
            Cancel
          </Button>
          <Button
            disabled={
              newUser &&
              _.difference(
                ["email", "firstname", "lastname", "role", "password"],
                _.keys(newUser)
              ).length > 0
            }
            onClick={() => createAdminUser()}
          >
            Create Account
          </Button>
        </Modal.Footer>
      </Modal>

      <Col xs={12}>
        {loading || !users ? (
          "loading..."
        ) : (
          <ListGroup className="mt-2">
            {users.map((u) => (
              <ListGroup.Item className="px-3" key={u._id}>
                <div className="d-flex">
                  <div className="mr-3">
                    {u.profile && u.profile.photo ? (
                      <div
                        style={{
                          backgroundImage: `url(${makeImageUrl(
                            u.profile.photo
                          )}`,
                          backgroundSize: "cover",
                          backgroundPosition: "center",
                          width: 42,
                          height: 42,
                          borderRadius: "50%",
                          boxShadow: "0px 5px 20px rgba(58, 152, 238, 0.2)",
                          border: "solid 1px #CCE6FF",
                        }}
                      ></div>
                    ) : (
                      <svg
                        width="42"
                        height="42"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM7.1 18.3C7.5 17.4 10.1 16.5 12 16.5C13.9 16.5 16.5 17.4 16.9 18.3C15.6 19.4 13.9 20 12 20C10.1 20 8.4 19.4 7.1 18.3ZM18.4 16.8C17 15.1 13.5 14.5 12 14.5C10.5 14.5 7.1 15.1 5.6 16.8C4.6 15.5 4 13.8 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 13.8 19.4 15.5 18.4 16.8ZM12 6C10.1 6 8.5 7.6 8.5 9.5C8.5 11.4 10.1 13 12 13C13.9 13 15.5 11.4 15.5 9.5C15.5 7.6 13.9 6 12 6ZM12 11C11.2 11 10.5 10.3 10.5 9.5C10.5 8.7 11.2 8 12 8C12.8 8 13.5 8.7 13.5 9.5C13.5 10.3 12.8 11 12 11Z"
                          fill="#243B5D"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="flex-grow-1">
                    <div className="d-flex">
                      <div>
                        {u.email}
                        <br />
                        {u.firstname} {u.lastname}
                      </div>
                      <div>
                        {u.banned ? (
                          <Badge variant="danger" className="ml-2">
                            DISABLED
                          </Badge>
                        ) : null}
                        {u.roles.map((role) => (
                          <Badge variant="info" className="ml-2">
                            {role.replace("-", " ").toUpperCase()}
                          </Badge>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div className="flex-grow-0">
                    {!u.banned && (
                      <Button
                        size="sm"
                        variant="warning"
                        disabled={user._id === u._id}
                        onClick={() => updateUser(u._id, { banned: true })}
                      >
                        Disable
                      </Button>
                    )}
                    {u.banned && (
                      <Button
                        size="sm"
                        variant="success"
                        disabled={user._id === u._id}
                        onClick={() => updateUser(u._id, { banned: false })}
                      >
                        Enable
                      </Button>
                    )}
                  </div>
                </div>
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
      </Col>
      <Col xs={12} className="mt-3">
        <Button variant="outline-primary" onClick={() => setNewUser({})}>
          Add Administrative Account
        </Button>
      </Col>
    </Row>
  );
};
