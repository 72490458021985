import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const GET_CATEGORIES = gql `
  query GetPropertyCategories($status: PropertyCategoryStatus) {
    propertyCategories(status: $status) {
      _id
      header
      thumbnail
      status
      labelled
    }
  }

`;

export default ((status) => {
  const {
    loading,
    data,
    error
  } =
    useQuery(GET_CATEGORIES, {
    variables: {status}
  });


  return [data && data.propertyCategories, loading, error];
});
