import gql from "graphql-tag";
import _ from "lodash";
import { useMutation } from "@apollo/react-hooks";

const MUTATION = gql `
  mutation UpdateSellerInfo($anonymousId: ID, $sellerInfo: SellerInfoInput!) {
    updateSellerInfo(anonymousId: $anonymousId, sellerInfo: $sellerInfo) {
      message
    }
  }
`;

export default ((sellerInfo, onCompleted, onError) => {
  const [updateSellerInfo, { loading, error}] = useMutation(MUTATION, {
    variables: {
      anonymousId: localStorage.getItem("dojo_id"),
      sellerInfo: _.pick(sellerInfo, [
        "property.address",
        "property.city",
        "property.state",
        "property.zip",
        "sellerType",
        "describe",
        "propertyStatus",
        "homeDescription",
        "submittedFrom: String,",
      ]),
    },
    refetchQueries: () => ["CurrentUser", "AllUsers", "AnonymousUser"],
    onCompleted: data => onCompleted && onCompleted(data.sellerInfo),
    onError: err => onError && onError(err)
  });


  return [updateSellerInfo, loading, error];
});