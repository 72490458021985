import gql from "graphql-tag";
import {
useMutation } from
"@apollo/react-hooks";

const QUERY = gql`
  mutation RegisterMember($user: UserInput!) {
    registerMember(user: $user) {
      _id
    }
  }
`;

export default ((onCompleted, onError) => {
  const [registerMember, {
    loading,
    error,
    client }] =
  useMutation(QUERY, {
    onCompleted: data => {
      client.resetStore();
      onCompleted && onCompleted(data && data.registerMember);
    },
    onError: err => onError && onError(err) });


  return [user => registerMember({
    variables: {
      user } }),

  loading, error];
});