import React from "react";
import { Modal, Button } from "react-bootstrap";

export default ({ almostDoneModal, setAlmostDoneModal }) => {
  return (
    <Modal
      show={almostDoneModal}
      onHide={() => setAlmostDoneModal(false)}
      size="sm"
    >
      <Modal.Body className="text-center px-3 py-3">
        <div className="p-5">
          <svg
            width="96"
            height="96"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
              fill="#46C9F4"
            />
          </svg>
        </div>

        <h4 className="mb-4">Almost done!</h4>

        <p>
          Your documents are under review, all your properties will appear on
          our listing soon.
        </p>

        <p>It takes 3-5 days to complete the review.</p>

        <div className="d-flex w-100 text-uppercase">
          <Button
            className="flex-grow-1 mx-4"
            variant="primary"
            block
            onClick={(e) => {
              setAlmostDoneModal(false);
            }}
          >
            View Property
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
