import React from "react";
import Card from "react-bootstrap/Card";

import { makeImageUrl } from "../../tools";

export default ({ file, caption }) => {
  return (
    <Card className="PreviewContainer">
      <div
        style={{
          backgroundImage: `url(${makeImageUrl(file)})`,
          marginBottom: 40,
        }}
        className="mx-2 mt-2 ImageContainer no-border"
      ></div>
      <div>
        <div
          className="p-2 text-center"
          style={{ position: "absolute", bottom: 0, left: 0, right: 0 }}
        >
          {caption}
        </div>
      </div>
    </Card>
  );
};
