import React from "react";
import {
  Network,
  Tuning,
  HomeCheck,
} from "../../../../../components/landing/icons";

export default [
  {
    icon: <HomeCheck />,
    title: "Available cash out options",
    text:
      "Need money for moving costs or unexpected expenses elsewhere? As you retain ownership, you can cash out at any point. Our mortgage partners will be there to walk you through your options and help you find the best way to tap into your home equity.",
  },
  {
    icon: <Network />,
    title: "Connect with Buyers",
    text:
      "Dojo connects sellers with a pool of motivated buyers who are renting now to own your property later. Taking care of your home is their top priority, and our platform helps them get started with a library of homeownership resources.",
  },
  {
    icon: <Tuning />,
    title: "Frictionless Management",
    text:
      "From closing contracts, to managing all financial transactions and maintenance repairs, our fully digital platform provides seamless support. Meaning, the click of a button is the most you'll have to do to help us manage your property.",
  },
];
