import React from "react";
import classNames from "classnames";

export default ({ image, text, name, single }) => (
  <div
    className={classNames(
      "landing-card",
      {
        "single": single
      }
    )}>
    <div className="landing-card-image" style={{ backgroundImage :` url(${image})` }} />
    <div className="landing-card-label">
      <div className="landing-card-inner">
        <p>
          {`“${text}”`}
        </p>
        <h3>
          {name}
        </h3>
      </div>
    </div>
  </div>
);