import React, { useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";
import _ from "lodash";

import { getCategory } from "../data/placeTypes";
import SinglePropertyMap from "../../../components/general/SinglePropertyMap";
import WalkscoreCard from "../components/WalkscoreCard";
import useNearbyPlaces from "../../../components/hooks/queries/useNearbyPlaces";
import useWalkscore from "../../../components/hooks/queries/useWalkscore";
import { Pedestrian, Bus, Bike } from "../../../components/icons";

const WalkScoreRange = [
  {
    score: 24,
    alt: "very dependent on a car",
    text: "almost all errands require one",
  },
  { score: 49, alt: "dependent on a car", text: "most errands require one" },
  {
    score: 69,
    alt: "somewhat walkable",
    text: "some errands can be accomplished on foot",
  },
  {
    score: 89,
    alt: "very walkable",
    text: "most errands can be accomplished on foot",
  },
  {
    score: 100,
    alt: "a walker’s paradise",
    text: "your daily errands will not require a car",
  },
];

const TransitScoreRange = [
  {
    score: 24,
    alt: "minimal",
    text: ", with very few nearby public transportation options",
  },
  {
    score: 49,
    alt: "below average",
    text: ", with few nearby public transportation options",
  },
  {
    score: 69,
    alt: "good",
    text: ", with many nearby public transportation options",
  },
  {
    score: 89,
    alt: "excellent",
    text: " and convenient for most trips",
  },
  {
    score: 100,
    alt: "world-class",
    text: ", with countless options for public transportation",
  },
];

const BikeScoreRange = [
  {
    score: 49,
    alt: "somewhat bikeable",
    text: ", with minimal bike infrastructure",
  },
  {
    score: 69,
    alt: "bikeable",
    text: ", with some bike infrastructure",
  },
  {
    score: 89,
    alt: "very bikeable",
    text: ", and biking is convenient for most trips",
  },
  {
    score: 100,
    alt: "a biker’s paradise",
    text: ", with great bike infrastructure",
  },
];

export default React.forwardRef(({ listing }, ref) => {
  const { t } = useTranslation();
  const [selectedItems, setSelectedItems] = useState([]);
  const [walkscore] = useWalkscore(listing._id);
  const [places] = useNearbyPlaces(listing._id, (data) => {
    const selected = _.chain(data.nearbyPlaces.results)
      .map((place) => getCategory(place.types))
      .filter()
      .uniq()
      .value();
    setSelectedItems(selected);
  });

  const updateSelectedItems = (item, checked) => {
    const items = checked
      ? [...selectedItems, item]
      : _.without(selectedItems, item);
    setSelectedItems(items);
  };

  const availablePlaces =
    (places &&
      _.filter(places.results, (place) => !!getCategory(place.types))) ||
    [];

  const availableSelectors = _.chain(availablePlaces)
    .map((place) => getCategory(place.types))
    .uniq()
    .value();

  const displayPlaces = availablePlaces.filter((place) => {
    return _.includes(selectedItems, getCategory(place.types));
  });

  const getScoreText = (source, score) =>
    _.first(source, (el) => el.score > score);

  return (
    <Card id="neighbourhood" ref={ref} className="ListingNeighbourhood">
      <Card.Body>
        <h4 className="my-2">{t("property.aboutNeighbourhood")}</h4>
        <h6 className="my-3">{t("property.transportation")}</h6>
        {walkscore && (
          <React.Fragment>
            <Row className="justify-content-center">
              <Col xs={12} md={4}>
                <WalkscoreCard
                  score={walkscore.walkscore}
                  icon={<Pedestrian />}
                />
              </Col>
              {walkscore.transit && (
                <Col xs={12} md={4}>
                  <WalkscoreCard
                    score={walkscore.transit.score}
                    icon={<Bus />}
                  />
                </Col>
              )}
              {walkscore.bike && (
                <Col xs={12} md={4}>
                  <WalkscoreCard score={walkscore.bike.score} icon={<Bike />} />
                </Col>
              )}
            </Row>
            <Row>
              <Col xs={12}>
                <p>
                  <Trans
                    i18nKey="property.walkscoreText"
                    values={getScoreText(WalkScoreRange, walkscore.walkscore)}
                    components={{ bold: <strong /> }}
                  />

                  {walkscore.transit && (
                    <Trans
                      i18nKey="property.transitscoreText"
                      values={getScoreText(
                        TransitScoreRange,
                        walkscore.transit.score
                      )}
                      components={{ bold: <strong /> }}
                    />
                  )}
                  {walkscore.bike && (
                    <Trans
                      i18nKey="property.bikescoreText"
                      values={getScoreText(
                        BikeScoreRange,
                        walkscore.bike.score
                      )}
                      components={{ bold: <strong /> }}
                    />
                  )}
                </p>
              </Col>
            </Row>
          </React.Fragment>
        )}

        <h6>{t("property.whatNearby")}</h6>
        <Row className="my-2">
          {availableSelectors.map((item) => (
            <Col xs={12} md={6}>
              <Form.Check
                checked={_.includes(selectedItems, item)}
                onChange={(e) => updateSelectedItems(item, e.target.checked)}
                label={t(`property.${item}`)}
              />
            </Col>
          ))}
        </Row>

        {listing && places && (
          <SinglePropertyMap listing={listing} places={displayPlaces} />
        )}
      </Card.Body>
    </Card>
  );
});
