import React from "react";

import Button from "react-bootstrap/Button";

import { makeImageUrl } from "../../tools";
import { Check } from "../icons";

export default ({ file, removeImage }) => {
  console.log(file);
  return (
    <div className="PreviewContainer">
      <div className="UploadedCheck">
        <Check height={20} color="#2D596D" />
      </div>
      {file.mimetype && file.mimetype.startsWith("image/") ? (
        <div
          className="ImageContainer"
          style={{ backgroundImage: `url(${makeImageUrl(file)})` }}
        ></div>
      ) : (
        <div
          className="ImageContainer d-flex flex-column"
          title={file.filename}
        >
          <div className="pt-2 text-center flex-grow-1">
            <svg
              width="36"
              height="36"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2H6ZM13 9V3.5L18.5 9H13Z"
                fill="#243B5D"
              />
            </svg>
          </div>
          <div
            className="p-2 text-center flex-grow-0 small"
            style={{ textOverflow: "ellipsis", overflow: "hidden" }}
          >
            {file.filename}
          </div>
        </div>
      )}

      {removeImage ? (
        <Button
          small="sm"
          className="RemoveImage"
          onClick={(e) => removeImage(file._id)}
          style={{
            position: "absolute",
            top: -12,
            right: -12,
            background: "#000",
            width: 24,
            height: 24,
            borderRadius: 12,
            padding: "0 2px",
          }}
        >
          <span style={{ fontSize: 12, verticalAlign: "text-top" }}>✕</span>
        </Button>
      ) : null}
    </div>
  );
};
