import gql from "graphql-tag";
import { useQuery } from '@apollo/react-hooks';

const MY_OFFERS = gql`
query MyMemberOffers($active: Boolean) {
  getApplications(active: $active) {
    _id
    down_payment
    status

    listing {
      _id
      address
      price
      rent
      beds
      baths
      sqft

      archived
      under_contract

      images {
        _id
        extension
        fileid
        mimetype
        filename
        url
      }
    }
  }
}
`;

export default (active => {
  const {
    loading,
    data,
    error } =
  useQuery(MY_OFFERS, {
    variables: {
      active },

    fetchPolicy: "network-only" });


  return [data && data.getApplications, loading, error];
});