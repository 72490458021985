import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";

import DojohomeModal from "../modals/DojohomeModal";
import useRequestResetPassword from "../hooks/mutations/useRequestResetPassword";

export default ({ show, mode, onHide }) => {
  const [email, setEmail] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [
    requestResetPassword,
    data,
    loading,
    error,
  ] = useRequestResetPassword(email, mode, () => setEmailSent(true));

  const handleHide = () => {
    onHide();
    emailSent && setEmailSent(false);
  };

  return (
    <DojohomeModal
      show={show}
      onHide={handleHide}
      className="super-slim"
    >
      {emailSent ? (
        <>
          <h3>{data.requestResetPassword.message}</h3>
          <div className="modal-controls">
            <Button
              variant="dojobrand"
              onClick={handleHide}
              className="single-control"
            >
              OK
            </Button>
          </div>
        </>
      ) : (
        <Form
          onSubmit={e => {
            e.preventDefault();
            requestResetPassword();
          }}
        >
          <h2>
            Enter your e-mail
            <br />
            to reset password
          </h2>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="single-control"
            isInvalid={error}
          />
          <Form.Control.Feedback type="invalid">
            {error && error.graphQLErrors[0].message}
          </Form.Control.Feedback>
          <div className="modal-controls">
            <Button
              variant="dojobrand"
              disabled={!email || loading}
              className="single-control"
              type="submit"
            >
              Confirm
            </Button>
          </div>
        </Form>
      )}
    </DojohomeModal>
  );
};
