import React from "react";
import { Card, Button } from "react-bootstrap";

export default ({ icon: Icon, text, action }) => {
  return (
    <Card className="ListingSummaryInfo no-shadow">
      <Card.Body className="px-2 py-3 d-flex">
        <div className="px-3 ListingSummaryBlock">
          <Icon height={24} />
        </div>
        <div className="ListingSummaryBlock">{text}</div>
        {action && (
          <div style={{ margin: -4 }}>
            <Button
              className="mx-3 px-4"
              variant="default"
              onClick={action.func}
            >
              {action.label}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};
