import gql from "graphql-tag";
import _ from "lodash";
import {
  useMutation
} from "@apollo/react-hooks";

const MUTATION = gql `
  mutation SendPropertyReport($email: String!, $report: PropertyReportInput, $anonymousId: String) {
    sendPropertyReport(email: $email, report: $report, anonymousId: $anonymousId)
  }
`;

export default (email, report, refetchQueries = [], onCompleted, onError) => {
  const [sendReport, {
    loading,
    error
  }] = useMutation(MUTATION, {
    variables: {
      email,
      report: _.mapValues(report, (v) => _.isObject(v) ? v : _.toString(v)),
      anonymousId: localStorage.getItem("dojo_id")
    },    
    refetchQueries: () => ["AnonymousUser", ...refetchQueries],
    onCompleted,
    onError
  });


  return [sendReport, loading, error];
};
