import React, { useState } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import TransactionHistory from "../../components/payment/TransactionHistory";

export default ({ user, activeOffer, setPayoutMethod }) => {
  const { t } = useTranslation();
  const [showTransactions, setShowTransactions] = useState(false);

  return (
    <React.Fragment>
      {showTransactions ? (
        <Row>
          <Col xs={12}>
            <Card className="mt-1 mb-4">
              <Card.Body>
                <a href="#" onClick={(e) => setShowTransactions(false)}>
                  &lt; {t("contract.info.back")}
                </a>

                <TransactionHistory user={user} offer={activeOffer._id} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      ) : (
        <>
          <Row>
            <Col xs={12}>
              <Card className="mt-1 mb-4">
                <Card.Body>
                  <h3>{t("contract.payout.method")}</h3>

                  <p>{t("contract.payout.method_label")}</p>
                </Card.Body>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col xs={12}>
              <Card className="mt-1 mb-4">
                <Card.Body>
                  <h3>{t("contract.payout.transaction_history")}</h3>

                  <p>{t("contract.payout.transaction_label")}</p>

                  <Button
                    variant="outline-secondary"
                    className="text-uppercase px-5"
                    onClick={(e) => setShowTransactions(true)}
                  >
                    {t("contract.payout.view")}
                  </Button>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </React.Fragment>
  );
};
