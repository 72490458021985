import gql from "graphql-tag";
import {
useMutation } from
"@apollo/react-hooks";

const QUERY = gql`
  mutation RegisterSeller($user: UserInput!) {
    registerSeller(user: $user) {
      _id
    }
  }
`;

export default ((onCompleted, onError) => {
  const [registerSeller, {
    loading,
    error,
    client }] =
  useMutation(QUERY, {
    onCompleted: data => {
      client.resetStore();
      onCompleted && onCompleted(data && data.registerSeller);
    },
    onError: err => onError && onError(err) });


  return [user => registerSeller({
    variables: {
      user } }),

  loading, error];
});