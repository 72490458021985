import React from "react";

export default ({ items, titleAlign }) => {
  const icons = items.map(item => item.icon);
  const titles = items.map(item => item.title);
  const texts = items.map(item => item.text);

  return(
    <div className={`explanation-container grid-${items.length}`}>
      {icons.map((icon, i) => (
        <div className="explanation-image" key={`icon ${i}`}>
          {icon}
        </div>
      ))}
      {titles.map((title, i) => (
        <h4 className={`explanation-title${titleAlign ? ` align-items-${titleAlign}` : ""}`} key={`title ${i}`}>
          {title}
        </h4>
      ))}
      {texts.map((text, i) => (
        <p className="explanation-text" key={`text ${i}`}>
          {text}
        </p>
      ))}
    </div>
  )
};