import gql from "graphql-tag";
import _ from "lodash";
import { useMutation } from "@apollo/react-hooks";

const APPLY_TO_PROPERTY = gql`
  mutation ApplyToProperty($application: PropertyApplicationInput) {
    applyToProperty(application: $application) {
      _id
    }
  }
`;

export default ((listing, offer, onCompleted, onError) => {
  const [applyToProperty, { loading, error = {} }] = useMutation(APPLY_TO_PROPERTY, {
    variables: {
      application: {
        ...{
          ...offer,
          plan: _.pick(offer.plan, ["id", "title", "targetEquity", "targetMonths"])
        },
        listing } },

    onCompleted: data => onCompleted && onCompleted(data) });


  return [applyToProperty, loading, error];
});
