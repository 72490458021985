import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { Link } from "react-router-dom";
import { Row, Col, Card, Button } from "react-bootstrap";
import currencyFormatter from "currency-formatter";
import _ from "lodash";

import { makeImageUrl, protectedUserName } from "../../tools";
import ConfirmationModal from "../../components/general/ConfirmationModal";
import usePayDownPayment from "../hooks/mutations/offer/usePayDownPayment";

const UPDATE_OFFER_STATUS = loader("./UpdateOfferStatus.graphql");

const offerStatus = (offer) => {
  switch (offer.status) {
    case "new":
      return "Waiting on Seller";
    case "approved":
      return "Waiting on Buyer";
    case "approvedDocuments":
      return "Intent Fully Executed";
    case "tenantVerification":
      return "Pending Tenant Verification";
    case "documents":
      return "Tenant Verification OK";
    case "closed":
      return "Closed";
    case "rejected":
      return "Declined";
    case "withdrawn":
      return "Withdrawn by Buyer";
    default:
      return "N/A";
  }
};

export default ({ offer, user }) => {
  const [updateOffer, { loading: loadingStatus }] = useMutation(
    UPDATE_OFFER_STATUS,
    {
      variables: {
        id: offer._id,
      },

      refetchQueries: () => ["AllProperties", "AllOffers"],
    }
  );

  const [buyEquity, loadingPayment] = usePayDownPayment(offer._id);

  const [confirmation, setConfirmation] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState(null);
  const [confirmationAction, setConfirmationAction] = useState(null);

  const confirmOperation = (message, action) => {
    setConfirmationMessage(message);
    setConfirmationAction(() => action);
    setConfirmation(true);
  };

  const setOfferStatus = (status) =>
    updateOffer({
      variables: {
        status,
      },
    });

  const approveOffer = (status) => {
    if (status === "closed") {
      buyEquity();
    }
    setOfferStatus(status);
    setConfirmation(false);
  };

  const loading = loadingPayment || loadingStatus;

  return (
    <Card style={{ overflow: "hidden" }} className="mb-3">
      <ConfirmationModal
        show={confirmation}
        onHide={(e) => setConfirmation(false)}
        onApprove={(e) => confirmationAction()}
      >
        {confirmationMessage}
      </ConfirmationModal>
      <div className="d-flex">
        <div
          className="flex-grow-0"
          style={{ minWidth: 160, position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${makeImageUrl(offer.listing.images[0])})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
        </div>
        <div className="flex-grow-1 py-3 px-3">
          <Row>
            <Col xs={12} md={6}>
              <div>Owner</div>
              <h6>
                <Link to={`/profile/${offer.owner._id}`}>
                  {protectedUserName(offer.owner, user)}
                </Link>
              </h6>
            </Col>
            <Col xs={12} md={6}>
              <div>Applicant</div>
              <h6>
                <Link to={`/profile/${offer.applicant._id}`}>
                  {protectedUserName(offer.applicant, user)}
                </Link>
              </h6>
            </Col>
          </Row>
          <Row>
            <Col xs={12} md={6}>
              <div>Address</div>
              <h6>
                <Link to={`/property/${offer.listing._id}`}>
                  {offer.listing.address}
                </Link>
              </h6>
            </Col>
            {offer.paymentMethod ? (
              <Col xs={12} md={6}>
                <div>Payment Method</div>
                <h6>{offer.paymentMethod.name}</h6>
              </Col>
            ) : (
              offer.applicant.paymentMethod && (
                <Col xs={12} md={6}>
                  <div>Payment Method</div>
                  <h6>{offer.applicant.paymentMethod.name}</h6>
                </Col>
              )
            )}
          </Row>
        </div>
        <div
          className="py-3 px-3"
          style={{ borderLeft: "1px solid #CCE6FF", minWidth: 120 }}
        >
          <div>Price</div>
          <h6>
            {currencyFormatter.format(offer.listing.price, {
              code: "USD",
              precision: 0,
            })}
          </h6>
          <div>Rent</div>
          <h6>
            {currencyFormatter.format(offer.listing.rent, {
              code: "USD",
              precision: 0,
            })}
          </h6>
        </div>
        <div className="py-3 px-3" style={{ borderLeft: "1px solid #CCE6FF" }}>
          <div>Status</div>
          <h6>{offerStatus(offer)}</h6>
          {offer.status === "new" && (
            <React.Fragment>
              <Button
                size="sm"
                block
                variant="success"
                disabled={loading}
                onClick={(e) =>
                  confirmOperation(
                    "Do you wish to approve an offer on seller behalf?",
                    () => approveOffer("approved")
                  )
                }
              >
                Approve
              </Button>
              <Button
                size="sm"
                block
                variant="danger"
                disabled={loading}
                onClick={(e) =>
                  confirmOperation(
                    "Do you really wish to reject an offer?",
                    () => setOfferStatus("rejected")
                  )
                }
              >
                Reject
              </Button>
            </React.Fragment>
          )}

          {_.includes(
            [
              "approved",
              "approvedDocuments",
              "tenantVerification",
              "documents",
            ],
            offer.status
          ) && (
            <Button
              size="sm"
              block
              variant="primary"
              as={Link}
              to={`/seller/properties/${offer.listing._id}/applications/${offer._id}`}
            >
              View Offer
            </Button>
          )}

          {offer.status === "approvedDocuments" && (
            <React.Fragment>
              <Button
                size="sm"
                block
                variant="warning"
                onClick={(e) =>
                  confirmOperation(
                    "Do you confirm that that the buyer has provided valid documents and you proceed to contract signing.",
                    () => approveOffer("documents")
                  )
                }
              >
                Confirm Tenant
              </Button>
              <Button
                size="sm"
                block
                variant="success"
                onClick={(e) =>
                  confirmOperation(
                    "Do you want to indicate that buyer needs to go for tenant verification.",
                    () => approveOffer("tenantVerification")
                  )
                }
              >
                Verify Tenant
              </Button>
            </React.Fragment>
          )}

          {offer.status === "tenantVerification" && (
            <React.Fragment>
              <Button
                size="sm"
                block
                variant="success"
                onClick={(e) =>
                  confirmOperation(
                    "Do you confirm that that the buyer has provided valid documents and you proceed to contract signing.",
                    () => approveOffer("documents")
                  )
                }
              >
                Confirm Documents
              </Button>

              <Button
                size="sm"
                block
                variant="danger"
                onClick={(e) =>
                  confirmOperation("Do you want to reject this offer?", () =>
                    setOfferStatus("rejected")
                  )
                }
              >
                Reject Documents
              </Button>
            </React.Fragment>
          )}

          {offer.status === "documents" && (
            <React.Fragment>
              <Button
                size="sm"
                block
                variant="success"
                disabled={
                  loading ||
                  (!offer.paymentMethod && !offer.applicant.paymentMethod)
                }
                onClick={(e) =>
                  confirmOperation(
                    "Do you confirm that the contract is signed? The buyer will be charged the down payment fees immediately.",
                    () => approveOffer("closed")
                  )
                }
              >
                Contract Signed
              </Button>

              <Button
                size="sm"
                block
                variant="danger"
                disabled={loading}
                onClick={(e) =>
                  confirmOperation(
                    "Do you really wish to reject an offer?",
                    () => setOfferStatus("rejected")
                  )
                }
              >
                Reject
              </Button>
            </React.Fragment>
          )}
        </div>
      </div>
    </Card>
  );
};
