import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";

const QUERY = gql `
  query AnonymousUser($id: ID) {
    anonymous(id: $id) {
      _id
      email
      createdAt

      buyingPower {
        buyerType
        moveIn
        employment
        dreamHome
        savings
        annualIncome
        creditScore
        completed

        submittedAt
        submittedFrom
      }

      sellerInfo {
        property {
          address
          city
          state
          zip
        }
        sellerType
        describe
        propertyStatus
        homeDescription

        submittedFrom
        submittedAt
      }
    }
  }
`;

const MUTATION = gql `
  mutation UpdateAnonymousUser($id: ID!, $user: AnonymousUserInput!) {
    updateAnonymous(id: $id, user: $user) {
      _id
    }
  }
`

export default ((onCompleted, onQueryCompleted) => {
  const { loading, data, error } = useQuery(QUERY, {
    variables: {
      id: localStorage.getItem("dojo_id")
    },
    onCompleted: onQueryCompleted
  });

  const [
    updateUser,
    { error: updateError, loading: saving }
  ] = useMutation(MUTATION, {
    variables: {
      id: localStorage.getItem("dojo_id")
    },
    refetchQueries: () => ["AnonymousUser", "CurrentUser"],
    onCompleted,
  });

  return [data && data.anonymous, (user) => updateUser({
    variables: {
      user
    }
  }), loading || saving, error || updateError];
});
