import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";

import useAnonymous from "../hooks/queries/useAnonymous";
import AuthorizationModal from "../auth/AuthorizationModal";
import useAnonymousSettings from "../hooks/mutations/useAnonymousSettings";

export default () => {
  const [showWall, setShowWall] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [canClose, setCanClose] = useState(false);
  const [anonymousSettings] = useAnonymousSettings();
  const [anonymousUser, updateAnonymousUser] = useAnonymous(() => {
    setShowWall(false);
    setCanClose(false);
    setShowLogin(true);
  });
  const [user, setUser] = useState({});

  useEffect(() => {
    const id = setTimeout(() => {
      if (!anonymousSettings.enabled) return;

      if (anonymousUser && !anonymousUser.email) {
        setShowWall(true);
      }
    }, anonymousSettings.openAfter);
    return () => clearTimeout(id);
  }, [anonymousSettings.enabled, anonymousSettings.openAfter, anonymousUser]);

  useEffect(() => {
    const id = setTimeout(() => {
      if (showWall) {
        setCanClose(true);
      }
    }, anonymousSettings.openAfter + anonymousSettings.canCloseAfter);
    return () => clearTimeout(id);
  }, [anonymousSettings.canCloseAfter, anonymousSettings.openAfter, showWall]);

  return (
    <React.Fragment>
      {anonymousUser && anonymousUser.email && (
        <AuthorizationModal
          show={showLogin}
          email={anonymousUser.email}
          onHide={() => setShowLogin(false)}
        />
      )}

      <Modal
        show={showWall}
        onHide={() => canClose && setShowWall(false)}
        centered
      >
        <Modal.Header closeButton={canClose}>
          <h5>Tell Us More</h5>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>E-Mail</Form.Label>
            <Form.Control
              value={user.email}
              name="email"
              type="email"
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            ></Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          {canClose && (
            <Button variant="light" onClick={() => setShowWall(false)}>
              Close
            </Button>
          )}
          <Button onClick={() => updateAnonymousUser(user)}>Submit</Button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
};
