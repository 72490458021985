import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";

import useComissionRates from "../../../components/hooks/mutations/useComissionRates";
import useCurrency from "../../../components/hooks/helpers/useCurrency";

export default ({ user }) => {
  const currency = useCurrency();
  const [editMode, setEditMode] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [payment, setPayment] = useState({
    down: 10000,
    rent: 1000
  });
  const [
    comission,
    setComission,
    refetchComission,
    saveMinDownPayment,
  ] = useComissionRates(() => setEditMode(false));

  const calcRates = (amount, fee = 0) => {
    const seller = Math.round(amount - amount * fee / 100);
    const rest = amount - seller;
    const stripe = amount * 0.0025 + 0.25 + 2;
    const dojo = rest - stripe;

    return {
      seller,
      stripe,
      dojo
    }
  }

  return (
    <Row className="mt-4">
      <Modal
        size="sm"
        centered
        show={saveModal}
        onHide={(e) => setSaveModal(false)}
      >
        <Modal.Header>
          <h5>Please Confirm</h5>
        </Modal.Header>
        <Modal.Body>
          <p>
            This change will affect any current listings and future offers.
            Existing offers will not be affected.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={(e) => setSaveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              saveMinDownPayment();
              setSaveModal(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Col xs={12}>
        <h2>DojoHome Commission Rates</h2>
      </Col>

      <Col xs={12}>
        <Card>
          <Card.Header className="d-flex">
            <div className="flex-grow-1"></div>
            {editMode ? (
              <React.Fragment>
                <Button
                  variant="light"
                  onClick={() => {
                    setEditMode(false);
                    refetchComission();
                  }}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button variant="primary" onClick={() => setSaveModal(true)}>
                  Save
                </Button>
              </React.Fragment>
            ) : (
              <Button variant="primary" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    Down Payment Commission
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      step={0.25}
                      max={10}
                      disabled={!editMode}
                      value={comission.down}
                      onChange={(e) =>
                        setComission({
                          down: parseFloat(e.target.value),
                        })
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    Monthly Payment Commission
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      step={0.25}
                      max={10}
                      disabled={!editMode}
                      value={comission.monthly}
                      onChange={(e) =>
                        setComission({
                          monthly: parseFloat(e.target.value),
                        })
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col xs={6}>
                <h5>Down Payment Breakdown</h5>
                <ul className="FeatureList">
                  <li>
                    <div>Buyer Pays</div>
                    <div>
                      <Form.Control
                        style={{width: 70, textAlign: "right"}}
                        size="sm"
                        type="number"
                        min={0}
                        step={100}
                        value={payment.down}
                        onChange={(e) =>
                          setPayment({
                            ...payment,
                            down: parseFloat(e.target.value),
                          })
                        }
                      />                    
                    </div>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <div>Seller Gets ({(100 - comission.down || 0).toFixed(2)}%)</div>
                    <div>{currency(calcRates(payment.down, comission.down).seller)}</div>
                  </li>
                  <li>
                    <div>Dojohome Gets ({(comission.down || 0).toFixed(2)}% minus Stripe fee)</div>
                    <div>{currency(calcRates(payment.down, comission.down).dojo)}</div>
                  </li>
                  <li>
                    <div>Stripe Fee</div>
                    <div>{currency(calcRates(payment.down, comission.down).stripe)}</div>
                  </li>
                </ul>
              </Col>
              <Col xs={6}>
                <h5>Monthly Payment Breakdown</h5>
                <ul className="FeatureList">
                  <li>
                    <div>Buyer Pays (monthly)</div>
                    <div>
                      <Form.Control
                        style={{width: 70, textAlign: "right"}}
                        size="sm"
                        type="number"
                        min={0}
                        step={100}
                        value={payment.rent}
                        onChange={(e) =>
                          setPayment({
                            ...payment,
                            rent: parseFloat(e.target.value),
                          })
                        }
                      />
                    </div>
                  </li>
                  <li className="divider"></li>
                  <li>
                    <div>Seller Gets ({(100 - comission.monthly || 0).toFixed(2)}%)</div>
                    <div>{currency(calcRates(payment.rent, comission.monthly).seller)}</div>
                  </li>
                  <li>
                    <div>Dojohome Gets ({(comission.monthly || 0).toFixed(2)}% minus Stripe fee)</div>
                    <div>{currency(calcRates(payment.rent, comission.monthly).dojo)}</div>
                  </li>
                  <li>
                    <div>Stripe Fee</div>
                    <div>{currency(calcRates(payment.rent, comission.monthly).stripe)}</div>
                  </li>
                </ul>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
