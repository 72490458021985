import React, { useState } from "react";
import { Row, Col, Card, Form } from "react-bootstrap";

import ListingAgent from "./ListingAgent";

export default ({ property, setProperty, onUpdatePrice }) => {
  return (
    <React.Fragment>
      <Row className="my-4">
        <Col xs={12}>
          <Form.Check
            id="branded-switch"
            type="switch"
            variant="success"
            checked={!property.branded}
            onChange={(e) => onUpdatePrice(!e.target.checked)}
            label="Brokered Property"
          />
        </Col>
        {!property.branded && (
          <Col xs={12}>
            <Card>
              <Card.Body className="py-2" as={Row}>
                <Col xs={12} sm={6}>
                  <Form.Group>
                    <Form.Label className="my-2">Agent</Form.Label>
                    <ListingAgent
                      agent={property.agent}
                      setAgent={(agent) => setProperty({ ...property, agent })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group>
                    <Form.Label className="my-2">Brokerage</Form.Label>
                    <Form.Control
                      value={
                        property.agent && property.agent.brokerage ? property.agent.brokerage.title : "N/A"
                      }
                      readOnly={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} sm={6}>
                  <Form.Group>
                    <Form.Label className="my-2">MLS Listing ID</Form.Label>
                    <Form.Control
                      value={property.mls || ""}
                      onChange={(e) =>
                        setProperty({ ...property, mls: e.target.value })
                      }
                    />
                  </Form.Group>
                </Col>
              </Card.Body>
            </Card>
          </Col>
        )}
      </Row>
    </React.Fragment>
  );
};
