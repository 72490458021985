import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const MUTATION = gql `
  mutation TrackListingAction($type: ListingEventType!, $listingId: ID!, $anonymousId: ID) {
    trackListingEvent(type: $type listingId: $listingId anonymousId: $anonymousId)
  }
`;

export default ((listing, onCompleted, onError) => {
  const [trackListingEvent, {
    loading,
    error = {}
  }] = useMutation(MUTATION, {
    variables: {
      anonymousId: localStorage.getItem("dojo_id")
    },

    onCompleted: data => onCompleted && onCompleted(data)
  });

  return [(type, listing) => trackListingEvent({
    variables: {
      type,
      listingId: listing._id,
    }
  }), loading, error];
});