import React from "react";

import { makeShortPrice } from "../../tools";

export function PriceTag({
  property,
  selected,
  showPrice = true,
  fontSize = 20,
}) {
  const price = makeShortPrice(property.price);
  const rent = Number(property.rent || 0).toFixed(0);

  return (
    <text
      fill={selected ? "white" : "#243B5D"}
      style={{ whiteSpace: "pre", marginTop: -12 }}
      fontFamily="Roboto Slab, Roboto, Arial, Helvetica"
      fontSize={fontSize * (showPrice ? 1 : 0.8)}
      fontWeight="bold"
      letterSpacing="0em"
    >
      <tspan x="50%" y="43%" dominantBaseline="middle" textAnchor="middle">
        {showPrice ? `$${price}` : `$${rent}/m`}
      </tspan>
    </text>
  );
}

export function TextTag({ children, fontSize = 22 }) {
  return (
    <text
      fill={"#243B5D"}
      style={{ whiteSpace: "pre" }}
      fontFamily="Roboto, Roboto Slab, Arial, Helvetica"
      fontSize={fontSize}
      fontWeight="bold"
      letterSpacing="0em"
    >
      <tspan x="50%" y="45%" dominantBaseline="middle" textAnchor="middle">
        {children}
      </tspan>
    </text>
  );
}

export default ({ width, selected, children }) => {
  return (
    <svg
      width={width}
      height={Math.floor((width * 72) / 114)}
      viewBox="0 0 114 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M19.251 11C17.0418 11 15.251 12.7909 15.251 15V42C15.251 44.2091 17.0418 46 19.251 46L72.2538 46C72.2492 46 72.2446 46 72.2401 46C69.9741 46 62.987 46 55.9999 46C46.0846 46.0001 36.1695 46.0001 39.7462 46.0001C45.8156 46.0001 53.8297 51.0393 56.119 52.5665C56.4976 52.819 56.9964 52.7986 57.356 52.5198C59.3927 50.9405 66.2343 46.0075 72.2401 46C73.1897 46 73.3101 46 72.2538 46L94.251 46C96.4601 46 98.251 44.2091 98.251 42V15C98.251 12.7909 96.4601 11 94.251 11H19.251Z"
          fill={selected ? "#4f7492" : "white"}
        />
      </g>

      {children}

      <defs>
        <filter
          id="filter0_d"
          x="0.250977"
          y="0"
          width="113"
          height="71.7432"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="7.5" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0855556 0 0 0 0 0.158 0 0 0 0 0.266667 0 0 0 0.3 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};
