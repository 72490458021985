import React from "react";
import { Route, Switch } from "react-router-dom";

import { Container } from "react-bootstrap";

import AppMain from "../AppMain";

import Terms from "./Pages/terms";
import Privacy from "./Pages/privacy";
import Disclaimer from "./Pages/disclaimer";
import Pricing from "./Pages/pricing";
import About from "./Pages/about";
import Help from "./Pages/help";
import Member from "./Pages/member";
import Seller from "./Pages/seller";
import Faq from "./Pages/faq";

import "./Pages/pages.scss";

export default ({ match }) => {
  return (
    <AppMain>
      <Container>
        <Switch>
          <Route path="/s/terms" component={Terms} />
          <Route path="/s/privacy" component={Privacy} />
          <Route path="/s/disclaimer" component={Disclaimer} />
          <Route path="/s/pricing" component={Pricing} />
          <Route path="/s/about-us" component={About} />
          <Route path="/s/help" component={Help} />
        </Switch>
      </Container>
    </AppMain>
  );
};
