import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import classNames from "classnames";

import { InfoCircle } from "../icons";

export default ({ id, message, dark, children, placement = "bottom" }) => {
  return (
    <OverlayTrigger
      trigger="hover"
      placement={placement}
      overlay={
        <Popover id={id} className={classNames("text-center", {"popover-dark" : dark})}>
          <Popover.Content>{message}</Popover.Content>
        </Popover>
      }
    >
      {children ? (
        <div>
          {children}
        </div>
      ) : (
        <sup className="generic-tooltip">
          <InfoCircle height={16} />
        </sup>
      )}
    </OverlayTrigger>
  );
};
