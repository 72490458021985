import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Link, useLocation } from "react-router-dom";
import { loader } from "graphql.macro";
import moment from "moment";
import qs from "qs";
import _ from "lodash";
import currencyFormatter from "currency-formatter";

import { Row, Col, Pagination, Table, Badge } from "react-bootstrap";

const TRANSACTION_HISTORY = loader("./MyTransactionHistory.graphql");

export default ({ user, offer }) => {
  const loc = useLocation();
  const page = parseInt(qs.parse(loc.search.substring(1)).page || 1, 10);

  const { data, loading } = useQuery(TRANSACTION_HISTORY, {
    variables: {
      user: user ? user._id : null,
      page,
      offer,
    },
  });

  const direction = (tr) =>
    !user
      ? null
      : tr.payer._id === user._id
      ? "SENT"
      : tr.recipient._id === user._id
      ? "RECEIVED"
      : undefined;

  return (
    <Row>
      <Col xs={12}>
        <h2>Transaction History</h2>

        {loading ? (
          "Loading transactions..."
        ) : (
          <>
            <Table striped hover borderless responsive>
              <thead>
                <tr>
                  <th></th>
                  <th>Equity</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {data &&
                  data.transactions.items.map((tr) => (
                    <tr key={tr._id}>
                      <td className="text-uppercase">
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 3L5 6.99H8V14H10V6.99H13L9 3ZM16 17.01V10H14V17.01H11L15 21L19 17.01H16Z"
                            fill="#243B5D"
                          />
                        </svg>
                        {direction(tr)}
                      </td>
                      <td>
                        {tr.offer.listing
                          ? (
                              (tr.amount / tr.offer.listing.price) *
                              100
                            ).toFixed(1)
                          : "--"}
                        %
                      </td>
                      <td>
                        {currencyFormatter.format(tr.amount || 0, {
                          code: "USD",
                          precision: 0,
                        })}
                      </td>
                      <td>{moment(tr.createdAt).format("LL")}</td>
                      <td>
                        {tr.paymentInfo &&
                        tr.paymentInfo.payment_method_details ? (
                          <Badge variant="secondary">
                            ACH/WIRE XXXXXX
                            {
                              tr.paymentInfo.payment_method_details.ach_debit
                                .last4
                            }
                          </Badge>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td>
                        <div className="text-uppercase">
                          <Badge variant="secondary">{tr.status}</Badge>
                        </div>
                        {tr.lastError && (
                          <div className="small" style={{ whiteSpace: "wrap" }}>
                            <span role="img">⚠️</span> {tr.lastError}
                          </div>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>

            {data && (
              <div className="d-flex mb-3">
                <Pagination style={{ margin: "0 auto" }}>
                  <Pagination.First
                    as={Link}
                    to={loc.pathname}
                    href={loc.pathname}
                  />

                  {_.range(1, data.transactions.pages + 1).map((pg) => (
                    <Pagination.Item
                      key={pg}
                      active={pg === page}
                      as={Link}
                      href={`${loc.pathname}?page=${pg}`}
                      to={`${loc.pathname}?page=${pg}`}
                    >
                      {pg}
                    </Pagination.Item>
                  ))}

                  <Pagination.Last
                    as={Link}
                    href={`${loc.pathname}?page=${data.transactions.pages}`}
                    to={`${loc.pathname}?page=${data.transactions.pages}`}
                  />
                </Pagination>
              </div>
            )}
          </>
        )}
      </Col>
    </Row>
  );
};
