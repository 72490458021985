import React, { useState } from "react";
import { Link, useRouteMatch, useHistory } from "react-router-dom";
import nl2br from "react-nl2br";
import _ from "lodash";
import moment from "moment";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";

import AppMain from "../AppMain";
import PaymentsBreakdown from "../../components/property/PaymentsBreakdown";
import ConfirmOfferButton from "./ConfirmOfferButton";
import PlaidConnect from "../../components/payment/PlaidConnect";
import useGetOffer from "../../components/hooks/queries/offer/useGetOffer";
import useManageOffer from "../../components/hooks/mutations/offer/useManageOffer";
import useCurrency from "../../components/hooks/helpers/useCurrency";

export default ({ user }) => {
  const match = useRouteMatch();
  const history = useHistory();
  const [withdrawModal, setWithdrawModal] = useState(false);
  const currency = useCurrency();

  const [offer, loading] = useGetOffer(match.params.id);
  const { withdrawOffer, updateOffer } = useManageOffer(match.params.id);

  return (
    <AppMain>
      {loading || !offer ? null : (
        <React.Fragment>
          <Modal
            show={withdrawModal}
            onHide={(e) => setWithdrawModal(false)}
            size="sm"
          >
            <Modal.Body className="text-center px-3 py-3">
              <div className="p-5">
                <svg
                  width="96"
                  height="96"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                    fill="#46C9F4"
                  />
                </svg>
              </div>

              <h4 className="mb-4">Withdraw this offer?</h4>

              <div className="d-flex w-100 text-uppercase">
                <Button
                  className="flex-grow-1 mx-1"
                  variant="primary"
                  onClick={(e) => {
                    withdrawOffer();
                    setWithdrawModal(false);
                    history.push("/buyer/offers");
                  }}
                >
                  Yes
                </Button>
                <Button
                  className="flex-grow-1 mx-1"
                  variant="outline-primary"
                  onClick={(e) => setWithdrawModal(false)}
                >
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Row className="mb-5">
            <Col xs={12}>
              <h1 className="text-center mt-5 mb-4">{offer.listing.address}</h1>
              <div className="d-flex">
                <div className="flex-grow-1"></div>
                <Button
                  as={Link}
                  to={`/property/${offer.listing._id}`}
                  variant="outline-secondary"
                  size="sm"
                  className="px-5"
                >
                  VIEW PROPERTY
                </Button>
                <div className="flex-grow-1"></div>
              </div>
            </Col>

            <Col xs={12} lg={{ span: 6, offset: 3 }}>
              <Card className="mt-5">
                <Card.Header className="text-center">
                  <h5>Your Offer</h5>
                </Card.Header>
                <Card.Body className="d-flex">
                  <div className="flex-grow-1 text-center">
                    <div className="small">MONTHLY RENT</div>
                    <h5>{currency(offer.listing.rent)}</h5>
                  </div>
                  <div className="flex-grow-1 text-center">
                    <div className="small">EQUITY DEPOSIT</div>
                    <h5>{currency(offer.down_payment)}</h5>
                  </div>
                  <div className="flex-grow-1 text-center">
                    <div className="small">MOVE IN</div>
                    <h5>{moment(offer.move_in).format("LL")}</h5>
                  </div>
                </Card.Body>
                <Card.Footer className="p-0">
                  <div className="p-3 text-center">
                    {offer.status === "approved" ? (
                      "Seller approved your offer"
                    ) : offer.listing.under_contract ? (
                      "This property is now under contract. You've missed your opportunity"
                    ) : offer.status === "rejected" ? (
                      offer.listing.offers.length >= 3 ? (
                        "Sorry, but seller had rejected your offer"
                      ) : (
                        <Button
                          as={Link}
                          to={`/property/${offer.listing._id}`}
                          size="sm"
                          variant="outline-secondary"
                          className="px-4"
                        >
                          Make Another Offer
                        </Button>
                      )
                    ) : offer.status === "closed" ? (
                      "Offer closed. Please check 'My Home'"
                    ) : offer.status === "new" ? (
                      "Pending response from seller"
                    ) : offer.status === "withdrawn" ? (
                      <React.Fragment>
                        <p>You have withdrawn this offer</p>
                        <Button
                          as={Link}
                          to={`/property/${offer.listing._id}`}
                          size="sm"
                          variant="outline-secondary"
                          className="px-4"
                        >
                          Make Another Offer
                        </Button>
                      </React.Fragment>
                    ) : offer.status === "approvedDocuments" ? (
                      nl2br(
                        "Congratulations! Offer confirmed.\nPlease wait while we review it."
                      )
                    ) : offer.status === "tenantVerification" ? (
                      nl2br(
                        "We need you to complete tenant verification via Naborly App."
                      )
                    ) : offer.status === "documents" ? (
                      nl2br(
                        "Congratulations! Your documents are approved.\nWe are preparing the contract."
                      )
                    ) : null}
                  </div>
                  {offer && (
                    <ConfirmOfferButton originalOffer={offer} user={user} />
                  )}
                </Card.Footer>
              </Card>
            </Col>

            <Col xs={12} lg={{ span: 6, offset: 3 }}>
              <Card className="mt-5">
                <Card.Header className="text-center">
                  <h5>My Payment Schedule</h5>
                </Card.Header>
                <Card.Body className="d-flex">
                  <PaymentsBreakdown offer={offer} listing={offer.listing} />
                </Card.Body>
              </Card>
            </Col>

            {offer.paymentMethod && (
              <Col xs={12} lg={{ span: 6, offset: 3 }}>
                <Card className="mt-5">
                  <Card.Header className="text-center">
                    <h5>Payment Method</h5>
                  </Card.Header>
                  <Card.Body className="d-flex">
                    <PlaidConnect
                      allowChange={false}
                      user={user}
                      defaultPaymentMethod={offer.paymentMethod}
                      setDefaultPaymentMethod={({ id, name, mask }) =>
                        updateOffer({
                          ...offer,
                          paymentMethod: {
                            id,
                            name,
                            mask,
                            type: "plaid",
                          },
                        })
                      }
                    />
                  </Card.Body>
                </Card>
              </Col>
            )}

            {!_.includes(["withdrawn", "closed"], offer.status) ? (
              <Col
                xs={12}
                lg={{ span: 6, offset: 3 }}
                className="text-center py-5"
              >
                <Button
                  variant="outline-secondary"
                  size="xl"
                  className="px-4"
                  onClick={(e) => setWithdrawModal(true)}
                >
                  Withdraw Offer
                </Button>
              </Col>
            ) : null}
          </Row>
        </React.Fragment>
      )}
    </AppMain>
  );
};
