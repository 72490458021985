import React from "react";


const OnBoardingContext = React.createContext({
  onboarding: undefined,
  open: undefined,
  setOnBoarding: () => {},
  setOpen: () => {},
  isSeller: undefined,
})

export default OnBoardingContext;
