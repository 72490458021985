import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const QUERY = gql`
query GetNearbyPlaces($id: ID!) {
    nearbyPlaces(id: $id)
  }`

export default ((id, onCompleted) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY, { variables: { id }, onCompleted: (data) => onCompleted && onCompleted(data) });

  return [data && data.nearbyPlaces, loading, error];
});
