import React from "react";
import { Link } from "react-router-dom";

import AppMain from "../AppMain";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default (() => {
  return (
    <AppMain>
      <div className="d-flex h-100">
        <div className="flex-grow-1"></div>
        <Row>
          <Col xs={12} className="text-center my-5">
            <h1>Account Settings</h1>
          </Col>
          <Col
          md={6}
          lg={{ span: 4, offset: 2 }}
          className="d-flex"
          style={{ justifyContent: "stretch" }}>

            <Card as={Link} className="mb-4" to="/profile/settings/info">
              <Card.Body className="text-secondary">
                <Card.Title>
                  <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">

                    <path
                    d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM7.1 18.3C7.5 17.4 10.1 16.5 12 16.5C13.9 16.5 16.5 17.4 16.9 18.3C15.6 19.4 13.9 20 12 20C10.1 20 8.4 19.4 7.1 18.3ZM18.4 16.8C17 15.1 13.5 14.5 12 14.5C10.5 14.5 7.1 15.1 5.6 16.8C4.6 15.5 4 13.8 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 13.8 19.4 15.5 18.4 16.8ZM12 6C10.1 6 8.5 7.6 8.5 9.5C8.5 11.4 10.1 13 12 13C13.9 13 15.5 11.4 15.5 9.5C15.5 7.6 13.9 6 12 6ZM12 11C11.2 11 10.5 10.3 10.5 9.5C10.5 8.7 11.2 8 12 8C12.8 8 13.5 8.7 13.5 9.5C13.5 10.3 12.8 11 12 11Z"
                    fill="#46C9F4" />

                  </svg>

                  <h4 className="mt-2">Personal Info</h4>
                </Card.Title>
                <p>Your email, first and last name, phone number</p>
              </Card.Body>
            </Card>
          </Col>
          <Col
          md={6}
          lg={{ span: 4 }}
          className="d-flex"
          style={{ justifyContent: "stretch" }}>

            <Card as={Link} className="mb-4" to="/profile/settings/security">
              <Card.Body className="text-secondary">
                <Card.Title>
                  <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">

                    <path
                    d="M12 1L3 5V11C3 16.55 6.84 21.74 12 23C17.16 21.74 21 16.55 21 11V5L12 1ZM12 11.99H19C18.47 16.11 15.72 19.78 12 20.93V12H5V6.3L12 3.19V11.99Z"
                    fill="#46C9F4" />

                  </svg>

                  <h4 className="mt-2">Security</h4>
                </Card.Title>
                <p>Update your password and secure your account</p>
              </Card.Body>
            </Card>
          </Col>
          <Col
          md={6}
          lg={{ span: 4, offset: 2 }}
          className="d-flex"
          style={{ justifyContent: "stretch" }}>

            <Card as={Link} className="mb-4" to="/profile/settings/payments">
              <Card.Body className="text-secondary">
                <Card.Title>
                  <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">

                    <path
                    d="M20 4H4C2.89 4 2.01 4.89 2.01 6L2 18C2 19.11 2.89 20 4 20H20C21.11 20 22 19.11 22 18V6C22 4.89 21.11 4 20 4ZM20 18H4V12H20V18ZM20 8H4V6H20V8Z"
                    fill="#46C9F4" />

                  </svg>
                  <h4 className="mt-2">Payments</h4>
                </Card.Title>
                <p>Attach, edit and manage your payment methods</p>
              </Card.Body>
            </Card>
          </Col>
          <Col
          md={6}
          lg={{ span: 4 }}
          className="d-flex"
          style={{ justifyContent: "stretch" }}>

            <Card as={Link} className="mb-4" to="/profile/settings/documents">
              <Card.Body className="text-secondary">
                <Card.Title>
                  <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">

                    <path
                    d="M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2H6ZM13 9V3.5L18.5 9H13Z"
                    fill="#46C9F4" />

                  </svg>

                  <h4 className="mt-2">Documents</h4>
                </Card.Title>
                <p>
                  W-2, proof of income, statement from your bank and other
                  required docs
                </p>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} className="text-center my-5">
            <Button variant="outline-secondary px-5">LOG OUT</Button>
          </Col>
        </Row>
        <div className="flex-grow-1"></div>
      </div>
    </AppMain>);

});