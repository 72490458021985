import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";

import useMinDownPayment from "../../../components/hooks/mutations/useMinDownPayment";

export default ({ user }) => {
  const [editMode, setEditMode] = useState(false);
  const [saveModal, setSaveModal] = useState(false);
  const [
    minDownPayment,
    setMinDownPayment,
    refetchMinDownPayment,
    saveMinDownPayment,
  ] = useMinDownPayment(() => setEditMode(false));

  return (
    <Row className="mt-4">
      <Modal
        size="sm"
        centered
        show={saveModal}
        onHide={(e) => setSaveModal(false)}
      >
        <Modal.Header>
          <h5>Please Confirm</h5>
        </Modal.Header>
        <Modal.Body>
          <p>
            This change will affect any current listings and future offers.
            Existing offers will not be affected.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={(e) => setSaveModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            onClick={(e) => {
              saveMinDownPayment();
              setSaveModal(false);
            }}
          >
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>

      <Col xs={12}>
        <Card>
          <Card.Header className="d-flex">
            <div className="flex-grow-1"></div>
            {editMode ? (
              <React.Fragment>
                <Button
                  variant="light"
                  onClick={() => {
                    setEditMode(false);
                    refetchMinDownPayment();
                  }}
                >
                  Cancel
                </Button>
                &nbsp;
                <Button variant="primary" onClick={() => setSaveModal(true)}>
                  Save
                </Button>
              </React.Fragment>
            ) : (
              <Button variant="primary" onClick={() => setEditMode(true)}>
                Edit
              </Button>
            )}
          </Card.Header>
          <Card.Body>
            <Row>
              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    Minimum Down
                    <br />
                    Organic Sellers
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      step={0.25}
                      max={10}
                      disabled={!editMode}
                      value={minDownPayment.organic}
                      onChange={(e) =>
                        setMinDownPayment({
                          organic: parseFloat(e.target.value),
                        })
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>

              <Col xs={12} sm={6}>
                <Form.Group>
                  <Form.Label>
                    Minimum Down
                    <br />
                    Brokered Properties
                  </Form.Label>
                  <InputGroup>
                    <Form.Control
                      type="number"
                      min={0}
                      step={0.25}
                      max={10}
                      disabled={!editMode}
                      value={minDownPayment.brokered}
                      onChange={(e) =>
                        setMinDownPayment({
                          brokered: parseFloat(e.target.value),
                        })
                      }
                    />
                    <InputGroup.Append>
                      <InputGroup.Text>%</InputGroup.Text>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
