import React from "react";

import { Row } from "react-bootstrap";

import ChatsList from "../../components/chat/ChatsList";

export default ({ user }) => {
  return (
    <Row className="mt-2">
      <ChatsList user={user} admin={true} />
    </Row>
  );
};
