import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const ALL_OFFERS = gql`
fragment ImageFragment on Image {
  _id
  fileid
  extension
  url
}

fragment UserFragment on User {
  _id
  firstname
  lastname
  email
  profile {
    photo {
      ...ImageFragment
    }
  }
}

query AllOffers($status: String, $page: Int = 1) {
  offers(status: $status, page: $page) {
    page
    pages
    limit

    stats {
      new
      approved
      approvedDocuments
      tenantVerification
      documents
      closed
      rejected
      withdrawn
    }

    items {
      _id
      status
      rent
      down_payment

      paymentMethod {
          id
          name
          mask
          type
        }

      applicant {
        ...UserFragment

        paymentMethod {
          id
          name
          mask
          type
        }
      }

      owner {
        ...UserFragment
      }

      listing {
        _id
        address
        rent
        price
        images {
          ...ImageFragment
        }
      }
    }
  }
}
`;

export default ({ status, page, onCompleted, onError }) => {
  const {
    data,
    loading,
    error } =
  useQuery(ALL_OFFERS, {
    variables: {
      status,
      page },

    fetchPolicy: "network-only",
    onCompleted,
  onError });


  return [data && data.offers, loading, error];
};