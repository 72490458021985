import React, { useState, useEffect } from "react";
import _ from "lodash";
import { Button } from "react-bootstrap";
import classNames from "classnames";

import Main from "../../../../components/landing/Main";
import Section from "../../../../components/landing/Section";
import Accordion from "../../../../components/landing/Accordion";
import Search from "../../../../components/landing/Search";

import faqTabs from "./data/faqTabs.jsx";

import "./Faq.scss";

export default ({ user }) => {
  const [query, setQuery] = useState("");
  const q = new RegExp(query, "i");
  const [currentTab, setCurrentTab] = useState(faqTabs[0].slug);

  useEffect(() => {
    query ? setCurrentTab("searchResults") : currentTab === "searchResults" && setCurrentTab(faqTabs[0].slug);
  }, [currentTab, query]);

  const searchResults = _.chain(faqTabs.map(tab => tab.questions))
    .flatten()
    .filter(item => q.test(item.question))
    .value();

  const queryFaqTabs = [
    ...(query ? [{
      slug: "searchResults",
      title: `Search results (${searchResults.length})`,
      questions: searchResults
    }] : []),
    ...faqTabs
  ];

  const displayItems = _.chain(queryFaqTabs)
    .find(tab => tab.slug === currentTab)
    .get("questions")
    .value();

  return(
    <Main>
      <Section
        navbar
        navbarColor="light"
        id="faq-head"
        centered
      >
        <h1>Frequently Asked Questions</h1>
        {false && <Search
          value={query}
          setValue={setQuery}
          onUpdateSearch={_.debounce(setQuery, 500)}
        />}

        <div className="tab-buttons">
          {queryFaqTabs.map(faqTab => (
            <Button
              variant="tabs"
              onClick={() => setCurrentTab(faqTab.slug)}
              className={
                classNames({
                  "active": faqTab.slug === currentTab
                })
              }
              key={faqTab.slug}
            >
              {faqTab.title}
            </Button>
          ))}
        </div>
      </Section>
      <Section
        id="faq-body"
        centered
        growable
      >
        {displayItems?.length !== 0 ? (
          <Accordion
            items={displayItems}
            thinArrows
          />
        ) : `There are no items that match your query "${query}"`}
      </Section>
    </Main>
  )
};