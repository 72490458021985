import React from "react";
import moment from "moment";
import RangeSlider from "react-bootstrap-range-slider";
import CurrencyInput from "react-currency-input";
import {
  Card,
  Row,
  Col,
  Form,
  ButtonGroup,
  Button,
  InputGroup,
} from "react-bootstrap";
import DatePicker from "react-datepicker";
import { useTranslation } from "react-i18next";

import InfoTooltip from "../../../components/property/InfoTooltip";
import useCurrency from "../../../components/hooks/helpers/useCurrency";
import useUserInfo from "../../../components/hooks/helpers/useUserInfo";
import OfferSummary from "../components/OfferSummary";
import PlanSelectionTimeline from "../components/PlanSelectionTimeline";

export default ({
  user,
  planCalculator,
  applyToProperty,
  showLoginModal,
  applying,
}) => {
  const { t } = useTranslation();
  const currency = useCurrency();
  const { isMember, isAdmin, hasCompleteProfile } = useUserInfo(user);
  const userCanMakeAnOffer = isMember || isAdmin;
  const isCustomPlan = planCalculator.activePlan.id === "custom";

  return (
    <Card id="plan-selection" className="ListingPlanSelection">
      <Card.Header>
        <h5 className="my-1">{t("property.plan.header")}</h5>
      </Card.Header>
      <Card.Body>
        <Row className="pb-4">
          <Col xs={12} lg={4}>
            <Form.Group>
              <div>{t("property.plan.moveInDate")}</div>

              <DatePicker
                selected={moment(planCalculator.offer.move_in).toDate()}
                minDate={new Date()}
                onChange={(date) => planCalculator.setMoveIn(date)}
                placeholder="Choose date"
                className="form-control"
              />
            </Form.Group>

            <Form.Group>
              <div>
                {t("property.plan.downPayment")}{" "}
                <InfoTooltip
                  message={
                    <p className="my-1">
                      {t("property.plan.downPaymentTooltip")}
                    </p>
                  }
                />
              </div>
              <Form.Control
                as={CurrencyInput}
                step="1000"
                decimalSeparator=","
                prefix="$"
                precision={0}
                min={planCalculator.minDownPayment}
                max={planCalculator.maxDownPayment}
                value={
                  planCalculator.displayDownPayment ||
                  planCalculator.down_payment
                }
                isInvalid={
                  planCalculator.displayDownPayment >
                    planCalculator.maxDownPayment ||
                  planCalculator.displayDownPayment <
                    planCalculator.minDownPayment
                }
                onChangeEvent={(e) => {
                  planCalculator.setDownPayment(
                    e.target.value.replace(/[^0-9]/gi, "")
                  );
                }}
              />
              {(planCalculator.displayDownPayment >
                planCalculator.maxDownPayment ||
                planCalculator.displayDownPayment <
                  planCalculator.minDownPayment) && (
                <Form.Control.Feedback type="invalid">
                  {t("property.plan.invalidLimits", {
                    min: currency(planCalculator.minDownPayment),
                    max: currency(planCalculator.maxDownPayment),
                  })}
                </Form.Control.Feedback>
              )}
            </Form.Group>

            <RangeSlider
              min={planCalculator.minEquity}
              max={planCalculator.maxEquity}
              size="sm"
              step={0.25}
              tooltip="off"
              value={planCalculator.equity}
              onChange={(e) => planCalculator.setEquity(e.target.value)}
            ></RangeSlider>

            <div className="d-flex">
              <div className="flex-grow-1">
                {t("property.plan.startingEquity")}
              </div>
              <div>{planCalculator.equity.toFixed(2)}%</div>
            </div>
          </Col>
          <Col xs={12} lg={8}>
            <ButtonGroup className="w-100">
              {planCalculator.plans.map((plan) => (
                <Button
                  key={plan.id}
                  className="px-2 v-middle"
                  variant={
                    planCalculator.activePlan.id === plan.id
                      ? "primary"
                      : "default"
                  }
                  onClick={() => planCalculator.setPlan(plan.id)}
                >
                  <span>
                    <plan.icon
                      color={
                        planCalculator.activePlan.id === plan.id
                          ? "#fff"
                          : undefined
                      }
                    />
                  </span>{" "}
                  <span>{plan.title}</span>
                </Button>
              ))}
            </ButtonGroup>

            <Row className="mt-3">
              <Col xs={12} md={6}>
                <ul className="FeatureList">
                  <li>
                    <div>{t("property.plan.monthlyRent")}</div>
                    <div>{currency(planCalculator.monthlyRent)}</div>
                  </li>
                  <li>
                    <div>{t("property.plan.equityPayment")}</div>
                    {isCustomPlan ? (
                      <div style={{ width: 100 }}>
                        {planCalculator.custom.by === "equity" ? (
                          <React.Fragment>
                            <InputGroup>
                              <Form.Control
                                style={{ width: 100 }}
                                value={
                                  planCalculator.custom.payAsYouGo
                                    ? 0
                                    : planCalculator.custom.monthlyEquity ||
                                      planCalculator.monthlyEquity
                                }
                                size="sm"
                                className="text-right"
                                min={0}
                                disabled={planCalculator.custom.payAsYouGo}
                                step={100}
                                as={CurrencyInput}
                                decimalSeparator=","
                                prefix="$"
                                precision={0}
                                isInvalid={planCalculator.custom.outOfBounds}
                                onChangeEvent={(e) => {
                                  planCalculator.updateCustom({
                                    monthlyEquity: parseFloat(
                                      e.target.value.replace(/[^0-9]/gi, "")
                                    ),
                                  });
                                }}
                              />
                            </InputGroup>
                            <Form.Check
                              checked={planCalculator.custom.payAsYouGo}
                              onChange={(e) =>
                                planCalculator.updateCustom({
                                  payAsYouGo: e.target.checked,
                                })
                              }
                              size="sm"
                              label={t("property.plan.payAsYouGo")}
                            />
                          </React.Fragment>
                        ) : (
                          currency(planCalculator.custom.monthlyEquity)
                        )}
                      </div>
                    ) : (
                      <div>{currency(planCalculator.monthlyEquity)}</div>
                    )}
                  </li>
                  <li className="divider"></li>
                  <li>
                    <div>
                      <strong>{t("property.plan.total")}</strong>
                    </div>
                    <div>
                      <strong>
                        {currency(
                          planCalculator.monthlyRent +
                            planCalculator.monthlyEquity
                        )}
                      </strong>
                    </div>
                  </li>
                </ul>
              </Col>
              <Col xs={12} md={6}>
                <div className="DashInfoBox p-4">
                  {planCalculator.custom.outOfBounds ? (
                    <div className="text-danger">
                      {t("property.plan.outOfBounds", {
                        min: currency(planCalculator.remainingPayment / 60),
                        max: currency(planCalculator.remainingPayment / 12),
                      })}
                    </div>
                  ) : planCalculator.custom.payAsYouGo ? (
                    <div>{t("property.plan.payAsYouGoDescription")}</div>
                  ) : (
                    <div>
                      {t("property.plan.summary", {
                        equity: planCalculator.targetEquity,
                      })}{" "}
                      {planCalculator.targetDuration.years > 0
                        ? t("property.plan.years", {
                            count: planCalculator.targetDuration.years,
                          })
                        : ""}{" "}
                      {planCalculator.targetDuration.months > 0
                        ? t("property.plan.months", {
                            count: planCalculator.targetDuration.months,
                          })
                        : ""}{" "}
                      <br />
                      {planCalculator.custom.by === "date" ? (
                        <DatePicker
                          selected={moment(
                            planCalculator.custom.ownDate
                          ).toDate()}
                          dateFormat="MMMM yyyy"
                          showMonthYearPicker
                          minDate={moment()
                            .add(1, "year")
                            .startOf("month")
                            .toDate()}
                          maxDate={moment()
                            .add(5, "years")
                            .endOf("month")
                            .toDate()}
                          onChange={(date) => {
                            planCalculator.updateCustom({
                              ownDate: date,
                            });
                          }}
                          placeholder="Choose date"
                          className="form-control"
                        />
                      ) : planCalculator.targetDate ? (
                        `(${planCalculator.targetDate.format("LL")})`
                      ) : (
                        "N/A"
                      )}
                    </div>
                  )}
                </div>
                <span className="small" style={{ textDecoration: "underline" }}>
                  {t("property.plan.whatIsThis")}
                </span>

                {isCustomPlan && (
                  <div>
                    <div>
                      <strong>Plan By:</strong>
                    </div>
                    <InputGroup>
                      <Form.Check
                        type="radio"
                        checked={planCalculator.custom.by === "equity"}
                        label="How much can I spend each month"
                        onChange={(e) =>
                          e.target.checked &&
                          planCalculator.updateCustom({ by: "equity" })
                        }
                      />
                      <Form.Check
                        type="radio"
                        checked={planCalculator.custom.by === "date"}
                        onChange={(e) =>
                          e.target.checked &&
                          planCalculator.updateCustom({ by: "date" })
                        }
                        label="When I'll own my home"
                      />
                    </InputGroup>
                  </div>
                )}
              </Col>
              {}
            </Row>
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <PlanSelectionTimeline
              planCalculator={planCalculator}
              plans={planCalculator.plans}
              startDate={planCalculator.moveIn}
              selectedPlan={planCalculator.activePlan}
              selectPlan={(plan) => planCalculator.setPlan(plan.id)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={12}>
            <OfferSummary
              planCalculator={planCalculator}
              userRegistered={userCanMakeAnOffer}
              userProfileComplete={hasCompleteProfile}
              user={user}
              applyToProperty={applyToProperty}
              showLoginModal={showLoginModal}
              applying={applying}
            />
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};
