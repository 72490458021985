import React, { useState } from "react";
import { Row, Col, Form, Button, ButtonGroup, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import useValidatePhone from "../hooks/mutations/useValidatePhone";
import { CheckCircle } from "../icons";

export default ({ user, title, onValidated }) => {
  const { t } = useTranslation();
  const [phone, setPhone] = useState(user.phone);
  const [code, setCode] = useState("");
  const [codeResent, setResendCode] = useState(false);
  const [newPhone, setNewPhone] = useState(false);

  const {
    updatePhone,
    validatePhone,
    validateLoading,
    setLoading,
    setData,
    validateData,
    setError,
    validateError,
  } = useValidatePhone(`${phone}`, code, undefined, onValidated);

  return (
    <React.Fragment>
      {title && <h4>{title}</h4>}

      <div className="mb-3">
        {!user.validated_phone && !user.phone ? (
          t("buyingPower.verifyPhone")
        ) : (
          <React.Fragment>
            Current Phone:{" "}
            {user.validated_phone || user.phone || "Not specified"}{" "}
            <strong>
              {user.validated_phone && <CheckCircle height={16} />}
            </strong>
          </React.Fragment>
        )}
      </div>

      {validateData && validateData.validatePhone.message ? (
        <Alert variant="success">{validateData.validatePhone.message}</Alert>
      ) : setData && setData.setPhone.message ? (
        <Alert variant="success">{setData.setPhone.message}</Alert>
      ) : null}

      {user.phone && !newPhone ? (
        <Form.Group>
          <Form.Label>{t("phone_validation.verification_code")}</Form.Label>
          <Row>
            <Col xs={12} lg={3}>
              <Form.Control
                placeholder="XXXXXX"
                value={code}
                onChange={(e) => {
                  setCode(e.target.value);
                }}
                isInvalid={validateError && !!validateError.message}
              />

              <Form.Control.Feedback type="invalid">
                {validateError ? validateError.graphQLErrors[0].message : null}
              </Form.Control.Feedback>
            </Col>
            <Col xs={12} lg={9}>
              <ButtonGroup>
                <Button
                  variant="outline-primary"
                  disabled={validateLoading}
                  onClick={(e) => validatePhone()}
                >
                  {t("phone_validation.verify")}
                </Button>
                <Button
                  disabled={codeResent || setLoading}
                  variant="outline-primary"
                  onClick={(e) => {
                    updatePhone();
                    setResendCode(true);
                    setNewPhone(false);
                  }}
                >
                  {t("phone_validation.resend")}
                </Button>
                <Button
                  disabled={setLoading}
                  variant="outline-primary"
                  onClick={(e) => {
                    setNewPhone(true);
                  }}
                >
                  {t("phone_validation.change")}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
        </Form.Group>
      ) : (
        <Form.Group>
          <Form.Label>Number</Form.Label>
          <Row>
            <Col xs={8}>
              <PhoneInput
                country={"us"}
                value={phone || ""}
                onChange={(phone) => {
                  setPhone(phone);
                }}
                isInvalid={validateError && !!validateError.message}
              />

              <Form.Control.Feedback type="invalid">
                {setError ? setError.message : null}
              </Form.Control.Feedback>
            </Col>
            <Col xs={4}>
              <Button
                variant="primary"
                disabled={setLoading}
                block
                onClick={(e) => {
                  updatePhone();
                  setNewPhone(false);
                }}
              >
                {t("phone_validation.verify")}
              </Button>
            </Col>
          </Row>
        </Form.Group>
      )}
    </React.Fragment>
  );
};
