import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const MUTATION = gql `
  mutation RequestEmailValidation {
    requestEmailValidation {
      message
    }
  }
`;

export default (( onCompleted, onError ) => {
  const [requestEmailValidation, {
    loading,
    error
  }] = useMutation(
    MUTATION, {
      onCompleted: data => onCompleted && onCompleted(data),
      onError: err => onError && onError(err)
    });



  return [requestEmailValidation, loading, error];
});