import React from "react";
import {
  StepOneSearch,
  StepTwoClick,
  StepThreeMove,
  StepFourMoney,
  StepFiveFavorite,
} from "../../../../../components/landing/icons";

export default [
  {
    title: "Find Your Home",
    icon: <StepOneSearch />,
    text:
      "Use our platform to discover and tour homes. We promise, it’s delightful.",
  },
  {
    title: "Select a Plan",
    icon: <StepTwoClick />,
    text:
      "Straightforward and 100% transparent, our equity building plans are designed to move you from renter to owner at a pace that makes sense for you.",
  },
  {
    title: "Move In",
    icon: <StepThreeMove />,
    text:
      "No haggling, bargaining, or last-minute expenses. After your plan is approved and your bite-sized down payment made, the keys are yours.",
  },
  {
    title: "Grow Equity",
    icon: <StepFourMoney />,
    text:
      "Enjoy saving up for your mortgage from the comfort of your new living room.",
  },
  {
    title: "Own",
    icon: <StepFiveFavorite />,
    text:
      "When your equity reaches 10% of your home’s value, transition from renter to owner with the closure of your mortgage.",
  },
];
