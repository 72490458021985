import React from "react";
import { Form, InputGroup } from "react-bootstrap";
import InfoTooltip from "../property/InfoTooltip";

const controlStyle = { textTransform: "uppercase" };

export default ({
  field,
  label,
  tooltip,
  prepend,
  property,
  onChange,
  propertyErrors,
  children,
  transform = (val) => parseFloat(val),
  ...props
}) => {
  return (
    <Form.Group>
      {label && (
        <Form.Label className="mb-0">
          <p className="mt-1 mb-0 title" style={controlStyle}>
            <span>{label}</span>{" "}
            {tooltip && (
              <InfoTooltip message={<p className="my-1">{tooltip}</p>} />
            )}
          </p>
        </Form.Label>
      )}

      <InputGroup className={!!propertyErrors.rent && "is-invalid"}>
        {prepend && (
          <InputGroup.Prepend>
            <InputGroup.Text className="pr-2">{prepend}</InputGroup.Text>
          </InputGroup.Prepend>
        )}

        <Form.Control
          type="number"
          min="0"
          step="100"
          value={property[field] || ""}
          {...props}
          onChange={(e) => onChange(transform(e.target.value))}
          isInvalid={!!propertyErrors[field]}
        >
          {children}
        </Form.Control>
      </InputGroup>
      <Form.Control.Feedback type="invalid">
        {propertyErrors[field]}
      </Form.Control.Feedback>
    </Form.Group>
  );
};
