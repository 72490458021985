import React from "react";
import {
  StepOneList,
  StepTwoClick,
  StepThreeTakeMoney,
  StepFourValueUp
} from "../../../../../components/landing/icons";

export default [
  {
    title: "List your property",
    icon: <StepOneList />,
    text: "Share your property with us and we'll share it with qualified, aspiring homeowners."
  },
  {
    title: "Select an offer",
    icon: <StepTwoClick />,
    text: "Get matched with the best buyer for you and your home."
  },
  {
    title: "Sit back and see your money roll in",
    icon: <StepThreeTakeMoney />,
    text: "Let your property grow in value while collecting rent and ditching the typical hassles of property management."
  },
  {
    title: "Get fair market value upon sale",
    icon: <StepFourValueUp />,
    text: "Sell your home outright with a fraction of the fees. No negotiations, no problems."
  },
];