import React, { useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Main from "../../../../components/landing/Main";
import Section from "../../../../components/landing/Section";
import StepsGrid from "../../../../components/landing/Steps";
import Goals from "../../../../components/landing/Explanation";
import PropertiesContainer from "../../../../components/landing/Properties";
import CommentsContainer from "../../../../components/landing/Comments";
import ComparisonContainer from "../../../../components/landing/Comparison";
import Accordion from "../../../../components/landing/Accordion";
import OnBoardingContext from "../../../../components/context/OnBoardingContext";
import AnalyticsEvent from "../../../../components/general/AnalyticsEvent";

import steps from "./data/steps";
import properties from "./data/properties";
import comments from "./data/comments";
import comparison from "./data/comparison";
import goals from "./data/goals";
import faq from "./data/faq";

import "./Buyer.scss";

export default ({ user }) => {
  const sections = [
    {
      id: "one-day",
      content: (
        <>
          <h1>
            Helping renters become
            <br />
            <span>owners</span>
          </h1>
          <h3>
            Break free from the instability of renting and the challenges
            <br />
            of traditional-home mortgage requirements.
          </h3>
          <AnalyticsEvent pixelEvent="Find Your Home Main">
            {(onClick) => (
              <Button
                as={Link}
                to="?signup"
                variant="dojobrand"
                onClick={onClick}
              >
                Get Started
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
      backgroundVideo: "Vps0fT3aOcE",
    },
    {
      id: "keys-first",
      content: (
        <>
          <h1>
            Keys <span>first</span>,<br />
            mortgage next
          </h1>
          <p>
            We believe the path to homeownership should be easier and way less
            painful. So we forged a new one. Forget steep down payments and
            unfriendly terms. Dojo allows you to move into your dream home today
            while saving up and qualifying for its mortgage tomorrow.
          </p>
        </>
      ),
    },
    {
      id: "how-dojo-works",
      aside: true,
      content: (
        <>
          <h2 className="mb-3 mt-4">
            How <span>Dojo</span> works
          </h2>
          <StepsGrid steps={steps} />
          <AnalyticsEvent pixelEvent="Sign Up Main">
            {(onClick) => (
              <Button
                variant="dojobrand"
                as={Link}
                to="?signup"
                onClick={onClick}
              >
                Sign Up
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
    },
    {
      id: "our-goal",
      centered: true,
      controls: "dark",
      content: (
        <>
          <h2>
            Our goal is to help you reach <span>yours</span>
          </h2>
          <Goals items={goals} />
          <AnalyticsEvent pixelEvent="Discover Your Home Main">
            {(onClick) => (
              <Button as={Link} to="/explore" variant="dark" onClick={onClick}>
                Discover your Home
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
    },
    {
      id: "you-belong-here",
      centered: true,
      controls: "dark",
      content: (
        <>
          <h2>
            You <span>belong</span> here
          </h2>
          <h3>You can now afford the home you’ve been dreaming of</h3>
          <PropertiesContainer properties={properties} />
        </>
      ),
    },
    {
      id: "comments",
      centered: true,
      content: <CommentsContainer comments={comments} />,
    },
    {
      id: "smarter-choise",
      content: (
        <>
          <div className="flex-grow-1" />
          <h2>
            Why Dojo is the <span>smarter</span> choice
          </h2>
          <ComparisonContainer items={comparison} />
        </>
      ),
      aside: (
        <>
          <h2>
            Don’t get blocked. Get <span>moving</span>
          </h2>
          <p>
            Break free from the instability of renting and the insurmountability
            of traditional-home mortgage requirements. Dojo is a new path to
            home ownership, built for you.
          </p>
        </>
      ),
    },
    {
      id: "day-one",
      controls: "dark",
      centered: true,
      content: (
        <>
          <h1>
            Day one starts <span>here</span>
          </h1>
          <div className="inline-buttons">
            <AnalyticsEvent pixelEvent="Get Started Main">
              {(onClick) => (
                <Button
                  as={Link}
                  to="?signup"
                  onClick={onClick}
                  variant="dojobrand"
                >
                  Get Started
                </Button>
              )}
            </AnalyticsEvent>
            <AnalyticsEvent pixelEvent="Discover Your Home Main 2">
              {(onClick) => (
                <Button
                  as={Link}
                  to="/explore"
                  onClick={onClick}
                  variant="outline-secondary"
                >
                  Discover your Home
                </Button>
              )}
            </AnalyticsEvent>
          </div>
          <AnalyticsEvent pixelEvent="I am a seller Main">
            {(onClick) => (
              <Link to="/seller" className="simple-link" onClick={onClick}>
                I am a seller
              </Link>
            )}
          </AnalyticsEvent>
        </>
      ),
    },
    {
      id: "buyer-faq",
      controls: "dark",
      centered: true,
      growable: true,
      content: (
        <>
          <h2>
            <span>FAQ</span>
          </h2>
          <Accordion items={faq} />
          <AnalyticsEvent pixelEvent="Discover Your Home Main 3">
            {(onClick) => (
              <Button as={Link} to="/explore" variant="dojobrand">
                Discover your Home
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
      hideScroll: true,
    },
    {
      id: "buyer-last",
    },
  ];

  const sectionsControlsColor = sections.map((section) => section.controls);
  const sectionsIDs = sections.map((section) => section.id);

  const [currentSection, setCurrentSection] = useState();

  const handleSectionChange = (number) => {
    setCurrentSection(number);
  };

  const [onboarding, setOnBoarding] = useState({});
  const [open, setOpen] = useState(false);
  const onBoardingContextData = {
    onboarding,
    setOnBoarding,
    open,
    setOpen,
  };

  return (
    <OnBoardingContext.Provider value={onBoardingContextData}>
      <Main
        handleSectionChange={handleSectionChange}
        currentSection={currentSection}
        controlsColor={sectionsControlsColor}
        sectionsIDs={sectionsIDs}
        pager
      >
        {sections.map((section, index) => (
          <Section
            id={section.id}
            sectionsIDs={sectionsIDs}
            centered={section.centered}
            controls={section.controls}
            aside={section.aside}
            index={index}
            total={sections.length}
            hideScroll={section.hideScroll || false}
            key={`section ${index}`}
            backgroundVideo={section.backgroundVideo}
            handleSectionChange={handleSectionChange}
            navbar={index === 0 ? true : false}
            navbarColor={section.navbarColor}
            growable={section.growable}
          >
            {section.content}
          </Section>
        ))}
      </Main>
    </OnBoardingContext.Provider>
  );
};
