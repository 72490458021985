import React, { useEffect } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";

import BackNext from "./BackNext";
import SelectCards from "./SelectCards";
import {
  Pets,
  CityCenter,
  School,
  HomeOffice,
  GrowingFamily,
  Backyard,
} from "./icons";
import useUpdateBuyingPower from "../hooks/mutations/useUpdateBuyingPower";

export default ({ data, setData, onNext, onPrev, stepIndex }) => {
  const dreamHome = data.dreamHome || [];
  const options = [
    { icon: Pets, label: "Pets are Welcome", value: "pets" },
    { icon: CityCenter, label: "Close to City Center", value: "city" },
    { icon: School, label: "Access to Good Schools", value: "schools" },
    { icon: HomeOffice, label: "Space to Work From Home", value: "space" },
    { icon: GrowingFamily, label: "Room for a Growing Family", value: "room" },
    { icon: Backyard, label: "Big Backyard", value: "backyard" },
  ];
  const disableNext = !data || !data.dreamHome || data.dreamHome.length === 0;

  const [updateBuyingPower, updating] = useUpdateBuyingPower(data, () => {
    onNext && onNext();
  });

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateBuyingPower();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateBuyingPower]);

  return (
    <>
      <Form className="form-wide">
        <h3>Describe your dream home</h3>
        <p>Select all that apply.</p>
        <SelectCards
          options={options}
          data={data}
          field="dreamHome"
          setData={setData}
          multiple
        />
      </Form>
      <BackNext
        mode="Buyer"
        stepIndex={stepIndex}
        onNext={updateBuyingPower}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
