import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const QUERY = gql `
  query GetPlaidLink {
    plaidLink
  }
`;

export default (() => {
  const { data, loading, error } = useQuery(QUERY);
  console.log("plaidLink:", data)
  return [data && data.plaidLink, loading, error];
});
