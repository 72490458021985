import {useState} from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const QUERY = gql`
  query GetComission {
    settings {
      comission {
        down
        monthly
      }
    }
  }
`;

const MUTATION = gql`
  mutation SetComission($comission: ComissionInput!) {
    updateSettings(settings: {
      comission: $comission
    }) {
      message
    }
  }
`;

export default (onCompleted) => {
  const [comission, setComission] = useState({})
  const {
    loading,
    error,
    refetch
  } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setComission(_.omit(data.settings.comission, ['__typename']));
    }
  });

  const [saveComission] = useMutation(MUTATION, {
    variables: {comission},
    refetchQueries: () => ['GetComission'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [comission, (update) => setComission({...comission, ...update}), refetch, saveComission, loading, error];
};