import React from "react";
import _ from "lodash";
import { components } from "react-select";
import Select from "react-select";

import Amenities, { matchAmenities, diffAmenities } from "../../data/amenities";
import { CheckCircle } from "../icons";

const storedAmenityToSelectItem = (group, amenity) => {
  return (
    _.find(Amenities[group], { value: amenity }) || {
      value: amenity,
      label: amenity,
      icon: <CheckCircle height={16} />,
    }
  );
};

const getIcon = (group, value) => {
  return storedAmenityToSelectItem(group, value).icon;
};

const Option = ({ innerRef, innerProps, ...props }) => {
  const style = props.getStyles("option", props);
  console.log(style);
  return (
    <div ref={innerRef} style={style} {...innerProps} className="d-flex">
      <span className="px-2">
        {getIcon(props.data.group, props.data.value)}
      </span>
      <span className="flex-grow-1">{props.data.label}</span>
    </div>
  );
};

const MultiValueLabel = (props) => {
  return (
    <div
      className="d-flex p-1 px-2"
      style={{ backgroundColor: "rgba(204, 230, 255, 0.75)" }}
    >
      <span>{getIcon(props.data.group, props.data.value)}</span>
      <span>
        <components.MultiValueLabel {...props} />
      </span>
    </div>
  );
};

const customStyles = {
  control: (provided, state) => {
    const border = "1px solid #CCE6FF";
    return { ...provided, border };
  },
  multiValue: (provided, state) => {
    const background = "rgba(204, 230, 255, 0.75)";
    const border = "1px solid #CCE6FF";
    const borderRadius = 5;
    const color = "#2D596D";
    const fontSize = "1.1rem";
    return {
      ...provided,
      border,
      borderRadius,
      background,
      color,
      fontSize,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
    };
  },
};

export default ({ group, selectedAmenities, onChange }) => {
  const propertyAmenities = matchAmenities(group, selectedAmenities);
  const otherAmenities = diffAmenities(group, selectedAmenities);

  console.log(group, selectedAmenities, propertyAmenities, otherAmenities);

  return (
    <Select
      isMulti
      styles={customStyles}
      components={{ MultiValueLabel, Option }}
      options={Amenities[group]}
      value={propertyAmenities}
      onChange={(items) => {
        onChange([
          ...otherAmenities,
          ...(items ? items.map((it) => it.value) : []),
        ]);
      }}
    />
  );
};
