import gql from "graphql-tag";
import _ from "lodash";
import { useMutation } from "@apollo/react-hooks";

const MUTATION = gql `
  mutation UpdateBuyingPower($anonymousId: ID, $buyingPower: BuyingPowerInput!) {
    updateBuyingPower(anonymousId: $anonymousId, buyingPower: $buyingPower) {
      message
    }
  }
`;

export default ((buyingPower, onCompleted, onError) => {
  const [updateBuyingPower, { loading, error}] = useMutation(MUTATION, {
    variables: {
      anonymousId: localStorage.getItem("dojo_id"),
      buyingPower: _.pick(buyingPower, [
        "buyerType",
        "moveIn",
        "employment",
        "dreamHome",
        "savings",
        "annualIncome",
        "creditScore",
        "submittedFrom",
      ]),
    },
    refetchQueries: () => ["CurrentUser", "AllUsers", "AnonymousUser"],
    onCompleted: data => onCompleted && onCompleted(data.buyingPower),
    onError: err => onError && onError(err)
  });


  return [updateBuyingPower, loading, error];
});