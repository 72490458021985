import React, { useState } from "react";
import { Link } from "react-router-dom";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";

import AppMain from "../AppMain";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const UPDATE_PASSWORD = loader("./UpdatePassword.graphql");

export default (({ user }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const [
  updatePassword,
  { data: dataPassword, loading: settingPassword, error: profileError }] =
  useMutation(UPDATE_PASSWORD, {
    variables: {
      oldPassword,
      newPassword,
      repeatPassword } });



  const profileErrors =
  !settingPassword && profileError ?
  profileError.graphQLErrors[0].extensions.exception.validationErrors :
  {};

  return (
    <AppMain>
      <Row className="pb-5">
        <Col xs={12} className="my-4"></Col>
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <Link to="/profile/settings">&larr; BACK</Link>
        </Col>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
          <h1>Security</h1>
        </Col>

        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <Form>
            <Form.Group>
              <Form.Label>Old Password</Form.Label>
              <Form.Control
              type="password"
              value={oldPassword}
              onChange={e => setOldPassword(e.target.value)}
              isInvalid={!!profileErrors.oldPassword} />

              <Form.Control.Feedback type="invalid">
                {profileErrors.oldPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>New Password</Form.Label>
              <Form.Control
              type="password"
              value={newPassword}
              onChange={e => setNewPassword(e.target.value)}
              isInvalid={!!profileErrors.newPassword} />

              <Form.Control.Feedback type="invalid">
                {profileErrors.newPassword}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>Re-Enter New Password</Form.Label>
              <Form.Control
              type="password"
              value={repeatPassword}
              onChange={e => setRepeatPassword(e.target.value)}
              isInvalid={!!profileErrors.repeatPassword}
              feedback={profileErrors.repeatPassword} />

              <Form.Control.Feedback type="invalid">
                {profileErrors.repeatPassword}
              </Form.Control.Feedback>
            </Form.Group>
            <Button onClick={e => updatePassword()} disabled={settingPassword}>
              Save
            </Button>
            <p className="ml-3 d-inline-block">
              {dataPassword ? "Password Updated" : ""}
            </p>
          </Form>
        </Col>
      </Row>
    </AppMain>);

});