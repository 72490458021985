import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import BackNext from "../BackNext";
import SelectCards from "../SelectCards";
import { Single, Family, Couple, CoupleMirrored } from "../icons";
import useUpdateSellerInfo from "../../hooks/mutations/useUpdateSellerInfo";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const options = [
    { icon: Single, label: "Single", value: "single" },
    { icon: Couple, label: "Couple", value: "couple" },
    { icon: Family, label: "Growing a Family", value: "family" },
    { icon: CoupleMirrored, label: "Empty Nesters", value: "nesters" },
  ];

  const [updateSellerInfo, updating] = useUpdateSellerInfo(data, () => {
    onNext && onNext();
  });

  const disableNext = !data.describe;

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateSellerInfo();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateSellerInfo]);

  return (
    <>
      <Form>
        <h4>What best describes you?</h4>
        <SelectCards
          options={options}
          data={data}
          field="describe"
          setData={setData}
        />
      </Form>
      <BackNext
        mode="Seller"
        stepIndex={stepIndex}
        onNext={updateSellerInfo}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
