import React from "react";
import { DojoLogoLight } from "../../../../../components/landing/icons";

export default [
  {
    title: "Traditional Sale",
    bullets: [
      "Low-Ball Offers",
      "Property sits vacant for months",
      "High closing costs and fees",
      "No monthly income for 1-5 years",
      "No appreciation for 1-5",
      "No tax benefits for 1-5 years"
    ]
  },
  {
    title: <DojoLogoLight />,
    bullets: [
      "Fair market value upon sale",
      "Property is pre-leased to buyers",
      "Low realtor & closing costs",
      "Hassle-free cash flow",
      "Significant appreciation",
      "Tax benefits of ownership"
    ],
    active: true
  },
  {
    title: "Traditional Rental",
    bullets: [
      "Low monthly rent",
      "Property sits vacant for months",
      "Tenants, toilet & trash",
      "Property treated poorly",
      "Late rental payments",
      "High management fees"

    ]
  }
];