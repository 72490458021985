import React from "react";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import { Row, Col, Card, ProgressBar } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import EquityPercentage from "../../components/dashboard/EquityPercentage";
import DownPaymentPercentage from "../../components/dashboard/DownPaymentPercentage";
import PriceHistoryChart from "../general/PriceHistoryChart";

const formatUsd = (amount) =>
  currencyFormatter.format(amount, {
    code: "USD",
    precision: 0,
  });

export default ({ activeOffer, user }) => {
  const { t } = useTranslation();
  const fullPrice = formatUsd(activeOffer.listing.price);

  const isSeller = activeOffer.owner._id === user._id;
  const targetEquityUsd =
    (activeOffer.listing.price * activeOffer.plan.targetEquity) / 100.0;

  const currentEquity = activeOffer.payments || 0;

  const activePlan = activeOffer.plan;
  const payAsYouGo =
    activePlan && activePlan.id === "custom" && activePlan.payAsYouGo;
  const targetDate = !payAsYouGo
    ? moment(activeOffer.move_in).add(activePlan.targetMonths, "months")
    : undefined;

  return (
    <React.Fragment>
      <Row>
        <Col lg={4} xl={3}>
          <EquityPercentage
            currentEquity={currentEquity}
            fullPrice={targetEquityUsd}
          ></EquityPercentage>
          <DownPaymentPercentage
            downPayment={activeOffer.down_payment}
            fullPrice={targetEquityUsd}
          ></DownPaymentPercentage>
        </Col>
        <Col lg={8} xl={9}>
          {isSeller ? (
            <React.Fragment>
              <Card className="mt-1 mb-4">
                <Card.Body>
                  <h4>{formatUsd(activeOffer.listing.rent)}</h4>
                  <div className="small text-uppercase">
                    {t("contract.dashboard.monthly_cashflow")}
                  </div>
                </Card.Body>
              </Card>

              <Card className="mt-1 mb-4">
                <Card.Body>
                  <h4>{formatUsd(currentEquity)}</h4>
                  <div className="d-flex">
                    <div className="small text-uppercase flex-grow-1">
                      {t("contract.dashboard.total_paydown")}
                    </div>
                    <h5>
                      {(
                        ((targetEquityUsd - currentEquity) / targetEquityUsd) *
                        100
                      ).toFixed(0)}
                      {t("contract.dashboard.percent_left")}
                    </h5>
                  </div>

                  <ProgressBar
                    now={(currentEquity / targetEquityUsd) * 100}
                    label={`${currentEquity}/{fullPrice}`}
                    srOnly
                  />
                </Card.Body>
              </Card>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Card className="mt-1 mb-4">
                <Card.Body>
                  <h4>
                    {formatUsd(currentEquity)} of {formatUsd(targetEquityUsd)}
                  </h4>
                  <div className="small text-uppercase">Total Equity</div>
                  <div className="mt-3">
                    <ProgressBar
                      now={(currentEquity / targetEquityUsd) * 100}
                      label={`${currentEquity}/${fullPrice}`}
                      srOnly
                    />
                  </div>
                </Card.Body>
              </Card>

              <Card className="mt-1 mb-4">
                <Card.Body>
                  <h4>
                    {t("contract.dashboard.days", {
                      days: targetDate
                        ? moment(targetDate).diff(moment(), "days")
                        : "N/A",
                    })}
                  </h4>
                  <div className="small text-uppercase">
                    {t("contract.dashboard.days_till_ownership")}
                  </div>
                </Card.Body>
              </Card>
            </React.Fragment>
          )}

          <Card className="mt-1 mb-4">
            <Card.Body>
              <h5>{t("contract.dashboard.price_history")}</h5>
              {activeOffer.listing.attomExtendedInfo && (
                <PriceHistoryChart listing={activeOffer.listing} />
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
