import React, { useMemo } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";
import currencyFormatter from "currency-formatter";
import { useTranslation } from "react-i18next";

import AppMain from "../AppMain";
import PropertyCard from "../../components/general/PropertyCard";
import Loading from "../../components/general/Loading";
import useMyMemberOffers from "../../components/hooks/queries/useMyMemberOffers";
import ConfirmOfferButton from "./ConfirmOfferButton";

const applicationStatus = (app) =>
  app.listing && app.listing.archived
    ? "Deleted"
    : app.status === "new"
    ? "Waiting for Seller"
    : app.status === "approved"
    ? "Waiting for Buyer"
    : app.status === "approvedDocuments"
    ? "Intent Fully Executed"
    : app.status === "tenantVerification"
    ? "Pending Tenant Verification"
    : app.status === "documents"
    ? "Tenant Verification Ok"
    : app.status === "rejected"
    ? "Declined"
    : app.status === "withdrawn"
    ? "Withdrawn"
    : null;

const OfferStatePriority = {
  closed: 1,
  documents: 2,
  tenantVerification: 3,
  approvedDocuments: 4,
  approved: 5,
  new: 6,
  rejected: 10,
  withdrawn: 10,
};

export default ({ user }) => {
  const { t } = useTranslation();
  const [activeOffers, loadingActiveOffers] = useMyMemberOffers(true);
  const [oldOffers, loadingOldOffers] = useMyMemberOffers(false);

  const sortedOffers = useMemo(() => {
    return _.sortBy(activeOffers || [], (o) => OfferStatePriority[o.status]);
  }, [activeOffers]);

  return (
    <AppMain>
      <Row>
        <Col xs={12}>
          <h1 className="text-center mt-5 mb-4">{t("member.menu.offers")}</h1>
        </Col>

        <Col xs={12}>
          {loadingActiveOffers || loadingOldOffers ? (
            <Loading />
          ) : (
            <Row className="mb-5">
              {activeOffers && activeOffers.length === 0 ? (
                <Col xs={12}>
                  <h5 className="text-center">
                    You do not have any active offers.
                  </h5>

                  <div className="text-center my-5">
                    <Button
                      as={Link}
                      variant="primary"
                      to="/search"
                      className="px-5"
                    >
                      Browse Homes
                    </Button>
                  </div>
                </Col>
              ) : null}
              {activeOffers &&
                sortedOffers
                  .filter((app) => !!app.listing)
                  .map((app) => (
                    <Col
                      key={app._id}
                      xs={12}
                      md={6}
                      lg={4}
                      className="d-flex pb-3"
                      style={{ justifyItems: "stretch" }}
                    >
                      <PropertyCard
                        user={user}
                        property={app.listing}
                        status={applicationStatus(app)}
                        link={`/buyer/offers/${app._id}`}
                      >
                        <hr />
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div
                              className="small"
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                                opacity: 0.75,
                              }}
                            >
                              RENT OFFER
                            </div>
                            <h6 className="mt-2">
                              {currencyFormatter.format(app.listing.rent, {
                                code: "USD",
                                precision: 0,
                              })}
                            </h6>
                          </div>
                          <div className="flex-grow-1">
                            <div
                              className="small"
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                                opacity: 0.75,
                              }}
                            >
                              DOWN PAYMENT
                            </div>
                            <h6 className="mt-2">
                              {currencyFormatter.format(app.down_payment, {
                                code: "USD",
                                precision: 0,
                              })}
                            </h6>
                          </div>
                        </div>
                        <ConfirmOfferButton
                          originalOffer={app}
                          user={user}
                        ></ConfirmOfferButton>
                      </PropertyCard>
                    </Col>
                  ))}
            </Row>
          )}
        </Col>

        <Col xs={12}>
          <Row className="mb-5">
            <Col xs={12}></Col>
            {oldOffers && (
              <React.Fragment>
                <hr />

                {oldOffers
                  .filter((app) => !!app.listing)
                  .map((app) => (
                    <Col
                      key={app._id}
                      xs={12}
                      md={6}
                      lg={4}
                      className="d-flex pb-3"
                      style={{ justifyItems: "stretch" }}
                    >
                      <PropertyCard
                        user={user}
                        property={app.listing}
                        status={applicationStatus(app)}
                        link={`/buyer/offers/${app._id}`}
                      >
                        <hr />
                        <div className="d-flex">
                          <div className="flex-grow-1">
                            <div
                              className="small"
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                                opacity: 0.75,
                              }}
                            >
                              RENT OFFER
                            </div>
                            <h6 className="mt-2">
                              {currencyFormatter.format(app.listing.rent, {
                                code: "USD",
                                precision: 0,
                              })}
                            </h6>
                          </div>
                          <div className="flex-grow-1">
                            <div
                              className="small"
                              style={{
                                fontWeight: 600,
                                fontSize: 10,
                                opacity: 0.75,
                              }}
                            >
                              DOWN PAYMENT
                            </div>
                            <h6 className="mt-2">
                              {currencyFormatter.format(app.down_payment, {
                                code: "USD",
                                precision: 0,
                              })}
                            </h6>
                          </div>
                        </div>
                      </PropertyCard>
                    </Col>
                  ))}
              </React.Fragment>
            )}
          </Row>
        </Col>
      </Row>
    </AppMain>
  );
};
