import React from "react";
import { Modal, Button } from "react-bootstrap";

import PhoneValidator from "../general/PhoneValidator";

export default (({ user, show, onHide, onValidated }) => {
  return (
    <Modal show={show} centered onHide={onHide}>
      <Modal.Header>
        <h5>Please Validate Your Phone Number</h5>
      </Modal.Header>
      <Modal.Body>
        <p>
          It's important to have valid contact information on hand so the
          Dojohome team can contact you with regards to your property. We'll
          send you an SMS verification to confirm your phone number.
        </p>
        <PhoneValidator user={user}></PhoneValidator>
      </Modal.Body>
      {user.validated_phone &&
      <Button onClick={() => onValidated(true)}>Continue</Button>}

    </Modal>);

});