import React from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";

const COLORS = ["#46C9F4", "#CCE6FF"];

export default ({ currentEquity, fullPrice }) => {
  const { t } = useTranslation();
  const memberPercent = Math.round((currentEquity / fullPrice) * 100);
  const data = [
    { name: t("contract.member"), value: memberPercent },
    { name: t("contract.seller"), value: 100 - memberPercent },
  ];

  return (
    <Card className="mt-1 mb-4">
      <Card.Body>
        <h5 className="text-center">Equity Percentage</h5>

        <div style={{ width: "100%", height: 130 }}>
          <ResponsiveContainer>
            <PieChart>
              <Pie dataKey="value" data={data} fill="#8884d8" outerRadius={60}>
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <Row>
          {data.map((part, i) => (
            <Col key={i} xs={6} className="text-center">
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: COLORS[i],
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></div>
              <h5 className="my-2">{part.value}%</h5>
              <div className="small text-uppercase">{part.name}</div>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};
