import React, { useState } from "react";
import _ from "lodash";
import classNames from "classnames";
import { Button, Modal, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import IdentityVerification from "../../components/user/IdentityVerification";
import ExtraDocuments from "../../components/user/ExtraDocuments";
import PlaidConnect from "../../components/payment/PlaidConnect";
import PlanData from "./PlanData";

export default ({
  user,
  offer,
  setOffer,
  signOffer,
  setSignOffer,
  sendOffer,
}) => {
  const [currentStep, setCurrentStep] = useState(1);
  const { t } = useTranslation();

  const canGoNext = () => {
    switch (currentStep) {
      case 1:
        return offer.plan && offer.plan.id;
      case 2:
        return offer.paymentMethod;
      default:
        return false;
    }
  };

  return (
    <Modal
      show={signOffer}
      size="lg"
      center="true"
      onHide={(e) => setSignOffer(false)}
    >
      <Modal.Header className="d-flex p-0">
        <h4
          className={classNames("flex-grow-1", "text-center", "my-0", "py-4", {
            "header-shadow": currentStep !== 1,
          })}
        >
          {t("offer.plan")}
        </h4>
        <h4
          className={classNames("flex-grow-1", "text-center", "my-0", "py-4", {
            "header-shadow": currentStep !== 2,
          })}
        >
          {t("offer.payments")}
        </h4>
      </Modal.Header>
      {currentStep === 1 ? (
        <Modal.Body>{offer.listing && <PlanData offer={offer} />}</Modal.Body>
      ) : currentStep === 100 ? (
        <Modal.Body>
          <IdentityVerification user={user}>
            {!user.verified ? (
              <Alert variant="warning">{t("offer.uploadDocuments")}</Alert>
            ) : (
              <Alert variant="success">{t("offer.identityVerified")}</Alert>
            )}

            <div className="p-5">
              <h2>{t("offer.identityVerification")}</h2>
              <p>{t("offer.identityVerificationDesciption")}</p>
            </div>
          </IdentityVerification>
          <ExtraDocuments user={user}>
            <div className="p-5">
              <hr />
              <h2>{t("offer.additionalDocuments")}</h2>
              <p>
                Documents that you will need when you will be applying to the
                property. For example, <strong>W2</strong>,{" "}
                <strong>Bank Statements</strong>,{" "}
                <strong>Last Credit Report</strong>,{" "}
                <strong>Supporting Documents</strong>. Sellers expect to receive
                these documents. Adding theme will increase your chances to be
                approved.
              </p>
            </div>
          </ExtraDocuments>
        </Modal.Body>
      ) : currentStep === 2 ? (
        <Modal.Body>
          <PlaidConnect
            user={user}
            defaultPaymentMethod={offer.paymentMethod}
            setDefaultPaymentMethod={({ id, name, mask }) =>
              setOffer({
                ...offer,
                paymentMethod: {
                  id,
                  name,
                  mask,
                  type: "plaid",
                },
              })
            }
          />
        </Modal.Body>
      ) : null}
      <Modal.Footer className="d-flex">
        <div className="flex-grow-1"></div>
        <Button
          disabled={currentStep === 1}
          variant="light"
          className="px-5"
          onClick={(e) => setCurrentStep(currentStep - 1)}
        >
          &lt; {t("offer.back")}
        </Button>
        {currentStep !== 2 ? (
          <Button
            disabled={!canGoNext()}
            variant="success"
            className="px-5"
            onClick={(e) => setCurrentStep(currentStep + 1)}
          >
            {t("offer.next")}&gt;
          </Button>
        ) : (
          <Button
            disabled={!canGoNext()}
            variant="success"
            className="px-5 text-uppercase"
            onClick={(e) => sendOffer()}
          >
            {t("offer.confirm")}
          </Button>
        )}

        <div className="flex-grow-1"></div>
      </Modal.Footer>
    </Modal>
  );
};
