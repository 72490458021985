import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  Card,
  Button,
  InputGroup,
  Modal,
} from "react-bootstrap";

import useAnonymousSettings from "../../../components/hooks/mutations/useAnonymousSettings";

export default ({ user }) => {
  const [
    anonymousData,
    setAnonymousData,
    refetchAnonymousData,
    saveAnonymousData,
  ] = useAnonymousSettings();

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <Card>
          <Card.Body>
            <Form.Group>
              <Form.Check
                type="checkbox"
                checked={anonymousData.enabled}
                label="Modal Enabled"
                onChange={(e) =>
                  setAnonymousData({
                    enabled: e.target.checked,
                  })
                }
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>Open Data Collection Modal After</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  min={0}
                  step={1}
                  value={anonymousData.openAfter / 1000}
                  onChange={(e) =>
                    setAnonymousData({
                      openAfter: parseFloat(e.target.value) * 1000,
                    })
                  }
                />
                <InputGroup.Append>
                  <InputGroup.Text>seconds</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>

            <Form.Group>
              <Form.Label>Can Close Data Collection Modal After</Form.Label>
              <InputGroup>
                <Form.Control
                  type="number"
                  min={0}
                  step={1}
                  value={anonymousData.canCloseAfter / 1000}
                  onChange={(e) =>
                    setAnonymousData({
                      canCloseAfter: parseFloat(e.target.value) * 1000,
                    })
                  }
                />
                <InputGroup.Append>
                  <InputGroup.Text>seconds</InputGroup.Text>
                </InputGroup.Append>
              </InputGroup>
            </Form.Group>
          </Card.Body>
          <Card.Footer>
            <Button variant="light" onClick={refetchAnonymousData}>
              Reset
            </Button>
            <Button variant="primary" onClick={saveAnonymousData}>
              Save
            </Button>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};
