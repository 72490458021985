import React from "react";
import _ from "lodash";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useNeighbourhoods from "../hooks/queries/useNeighbourhoods";
import PropertyFilterItem from "./PropertyFilterItem";
import RangeSelection from "./RangeSelection";
import { formatUsd } from "../../tools";
import DiscreteSelection from "./DiscreteSelection";
import CheckSelection from "./CheckSelection";
import ExtraFilters from "./ExtraFilters";
import useListingStats from "../hooks/queries/useListingStats";
import useUserInfo from "../hooks/helpers/useUserInfo";

export default ({ user, filter, setFilter }) => {
  const { t } = useTranslation();
  const { isAdmin } = useUserInfo(user);
  const [neighbourhoods = []] = useNeighbourhoods();
  const [searchStats] = useListingStats();
  const makeUsdRange = (min, max) =>
    `${min && min > 0 ? formatUsd(min) : t("filters.noMin")} - ${
      max && max > 0 ? formatUsd(max) : t("filters.noMax")
    }`;

  const makeSimpleRange = (min, max) =>
    `${min && min > 0 ? min : t("filters.noMax")} - ${
      max && max > 0 ? max : t("filters.noMax")
    }`;

  const makeDescreteLabel = (groups) => {
    if (_.filter(groups, (g) => g.value).length === 0) return t("filters.any");

    return _.map(groups, (g) =>
      g.value ? `${g.value}+ ${g.field}` : `${t("filters.any")} ${g.field}`
    ).join(", ");
  };

  const neighbourHoodSelection = _.map(neighbourhoods, (n) => ({
    label: n,
    value: n,
  }));

  const bedsAndBaths = [
    { label: "Beds", value: filter.beds, field: "beds" },
    { label: "Baths", value: filter.baths, field: "baths" },
  ];

  const moreFilters = [
    filter.year_min || filter.year_max,
    filter.parking,
    filter.daysLive,
    filter.amenities,
    filter.categories,
  ].filter((f) => !!f).length;

  return (
    <Row className="mt-2">
      <Col xs={12} sm={6}>
        <PropertyFilterItem
          label={t("filters.purchasePrice")}
          searchText={makeUsdRange(filter.price_min, filter.price_max)}
          selected={filter.price_min || filter.price_max}
          overlay={
            <RangeSelection
              min={0}
              max={searchStats ? searchStats.price.max : 0}
              step={50000}
              pins={searchStats ? searchStats.price.pins : []}
              prefix="$"
              changeable="price"
              value={{
                min: filter.price_min,
                max: filter.price_max,
              }}
              onChange={(min, max) =>
                setFilter({ ...filter, price_min: min, price_max: max })
              }
            />
          }
        />
      </Col>
      <Col xs={12} sm={6}>
        <PropertyFilterItem
          label={t("filters.monthlyRent")}
          searchText={makeUsdRange(filter.rent_min, filter.rent_max)}
          selected={filter.rent_min || filter.rent_max}
          overlay={
            <RangeSelection
              min={0}
              max={searchStats ? searchStats.rent.max : 0}
              pins={searchStats ? searchStats.rent.pins : []}
              step={100}
              prefix="$"
              changeable="rent"
              value={{
                min: filter.rent_min,
                max: filter.rent_max,
              }}
              onChange={(min, max) =>
                setFilter({ ...filter, rent_min: min, rent_max: max })
              }
            />
          }
        />
      </Col>
      <Col xs={12} sm={6}>
        <PropertyFilterItem
          label={t("filters.downPayment")}
          searchText={makeUsdRange(filter.down_min, filter.down_max)}
          selected={filter.down_min || filter.down_max}
          overlay={
            <RangeSelection
              min={0}
              max={searchStats ? searchStats.downPayment.max : 0}
              pins={searchStats ? searchStats.downPayment.pins : []}
              step={500}
              prefix="$"
              changeable="down"
              value={{
                min: filter.down_min,
                max: filter.down_max,
              }}
              onChange={(min, max) =>
                setFilter({ ...filter, down_min: min, down_max: max })
              }
            />
          }
        />
      </Col>
      <Col xs={12} sm={6}>
        <PropertyFilterItem
          label={t("filters.propertyType")}
          searchText={
            filter.propertyTypes
              ? filter.propertyTypes.join(", ")
              : `${t("filters.none")} ${t("filters.selected")}`
          }
          selected={filter.propertyTypes}
          overlay={
            <CheckSelection
              options={[
                { value: "house", label: t("filters.house") },
                { value: "condo", label: t("filters.condo") },
                { value: "townhouse", label: t("filters.townhouse") },
              ]}
              value={filter.propertyTypes || []}
              onChange={(pt) =>
                setFilter({
                  ...filter,
                  propertyTypes: pt && pt.length > 0 ? pt : undefined,
                })
              }
            />
          }
        />
      </Col>
      <Col xs={12} sm={6}>
        <PropertyFilterItem
          label={t("filters.bedsAndBaths")}
          searchText={makeDescreteLabel(bedsAndBaths)}
          selected={makeDescreteLabel(bedsAndBaths) !== t("filters.any")}
          overlay={
            <DiscreteSelection
              groups={bedsAndBaths}
              onChange={(data) => setFilter({ ...filter, ...data })}
            />
          }
        />
      </Col>

      <Col xs={12} sm={6} className="d-none">
        <PropertyFilterItem
          label={t("filters.squareFeet")}
          searchText={makeSimpleRange(filter.sqft_min, filter.sqft_max)}
          selected={filter.sqft_min || filter.sqft_max}
          overlay={
            <RangeSelection
              min={0}
              max={searchStats ? searchStats.sqft.max : 0}
              pins={searchStats ? searchStats.sqft.pins : []}
              step={100}
              changeable="ft2"
              value={{
                min: filter.sqft_min,
                max: filter.sqft_max,
              }}
              onChange={(min, max) =>
                setFilter({ ...filter, sqft_min: min, sqft_max: max })
              }
            />
          }
        />
      </Col>
      <Col xs={12} sm={6} className="d-none">
        <PropertyFilterItem
          label={t("filters.neighbourhoods")}
          searchText={
            filter.neighbourhood
              ? `${filter.neighbourhood.length} ${t("filters.selected")}`
              : t("filters.any")
          }
          selected={filter.neighbourhood}
          overlay={
            <CheckSelection
              options={neighbourHoodSelection}
              searchable
              value={filter.neighbourhood || []}
              onChange={(pt) =>
                setFilter({
                  ...filter,
                  neighbourhood: pt && pt.length > 0 ? pt : undefined,
                })
              }
            />
          }
        />
      </Col>
      <Col xs={12} sm={6}>
        <PropertyFilterItem
          label="&nbsp;"
          placement="left"
          highlighted
          searchText={
            moreFilters
              ? t("filters.moreFiltersSelected", { filters: moreFilters })
              : t("filters.moreFilters")
          }
          selected={moreFilters}
          overlay={
            <ExtraFilters
              filter={filter}
              searchStats={searchStats}
              onChange={(data) => {
                console.log(filter, data);
                setFilter({
                  ...filter,
                  ...data,
                });
              }}
            />
          }
        />
      </Col>
      {isAdmin && (
        <>
          <Col xs={12} sm={6}>
            <PropertyFilterItem
              label={t("filters.daysOnMarket")}
              searchText={makeSimpleRange(
                filter.daysOnMarket_min,
                filter.daysOnMarket_max
              )}
              selected={filter.daysOnMarket_min || filter.daysOnMarket_max}
              overlay={
                <RangeSelection
                  min={0}
                  max={365}
                  pins={[]}
                  step={7}
                  isNumber
                  changeable="days"
                  value={{
                    min: filter.daysOnMarket_min,
                    max: filter.daysOnMarket_max,
                  }}
                  onChange={(min, max) =>
                    setFilter({
                      ...filter,
                      daysOnMarket_min: min,
                      daysOnMarket_max: max,
                    })
                  }
                />
              }
            />
          </Col>
        </>
      )}
    </Row>
  );
};
