import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const QUERY = gql `
  fragment CategorizedPropertiesImageFragment on Image {
    _id
    fileid
    extension
    mimetype
    filename
    url
  }

  query GetCategorizedProperties {
    categorizedProperties {
      _id
      header
      thumbnail

      properties {
        _id

        price
        rent
        equity_deposit

        address
        city
        state
        zip
        neighbourhood

        beds
        baths
        sqft
        year

        owner { _id }

        categories {
          _id
          thumbnail
        }

        images {
          ...CategorizedPropertiesImageFragment
        }

        favorited

        under_contract
        published
        archived
        }
      }
    }
`;

export default ((onCompleted, onError) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY, {
    fetchPolicy: "network-only",
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err)
  });


  return [data && data.categorizedProperties, loading, error];
});
