import React, { useState, useContext } from "react";
import { Link, useHistory } from "react-router-dom";
import _ from "lodash";

import Email from "./Email";
import MemberType from "./MemberType";
import MoveIn from "./MoveIn";
import Employement from "./Employement";
import DreamHome from "./DreamHome";
import Financials from "./Financials";
import Profile from "./Profile";
import Phone from "./Phone";
import Skip from "./Skip";

import Address from "./Seller/Address";
import SellerType from "./Seller/SellerType";
import Describe from "./Seller/Describe";
import PropertyStatus from "./Seller/PropertyStatus";
import HomeDescription from "./Seller/HomeDescription";

import { Avatar, Rocket } from "./icons";
import Disclaimer from "./Disclaimer";
import DojohomeModal from "../modals/DojohomeModal";
import OnBoardingContext from "../context/OnBoardingContext";

const OnBoardingSteps = [
  {
    step: "email",
    fields: "email",
    conditions: ["landing"],
    control: Email,
    subtitle: <Disclaimer buttonName="Next" />,
  },
  {
    step: "type",
    fields: "buyerType",
    conditions: ["landing", "buyer"],
    control: MemberType,
  },
  {
    step: "moveIn",
    fields: "moveIn",
    conditions: ["landing", "buyer"],
    control: MoveIn,
  },
  {
    step: "employment",
    fields: "employment",
    conditions: ["landing", "buyer", "offer"],
    control: Employement,
  },
  {
    step: "dreamHome",
    fields: "dreamHome",
    conditions: ["landing", "buyer"],
    control: DreamHome,
  },
  {
    step: "financials",
    fields: ["savings", "annualIncome", "creditScore"],
    conditions: ["landing", "buyer", "offer"],
    control: Financials,
  },
  {
    step: "profile",
    fields: [
      "firstName",
      "lastName",
      "phone",
      "password",
      "confirmPassword",
      "dateOfBirth",
    ],
    conditions: ["landing"],
    control: Profile,
  },
  {
    step: "phone",
    fields: ["confirmationCode"],
    conditions: ["landing"],
    control: Phone,
  },
];

export default ({ show, user, anonymousUser, onHide }) => {
  const SellerOnBoardingSteps = [
    {
      step: "address",
      control: Address,
      subtitle: (
        <Link
          to="?login"
          className="simple-link d-inline-block"
          onClick={onHide}
        >
          I already have an account.
        </Link>
      ),
    },
    {
      step: "type",
      control: SellerType,
    },
    {
      step: "describe",
      control: Describe,
    },
    {
      step: "propertyStatus",
      control: PropertyStatus,
    },
    {
      step: "homeDescription",
      control: HomeDescription,
    },
    {
      step: "profile",
      fields: [
        "firstName",
        "lastName",
        "phone",
        "password",
        "confirmPassword",
        "dateOfBirth",
      ],
      conditions: ["landing"],
      control: Profile,
    },
    {
      step: "phone",
      fields: ["confirmationCode"],
      conditions: ["landing"],
      control: Phone,
    },
  ];

  const history = useHistory();
  const { setOpen, isSeller } = useContext(OnBoardingContext);
  const [onboarding, setOnBoarding] = useState(
    !isSeller
      ? _.pick(
          {
            creditScore: "good",
            submittedFrom: "Onboarding",
            ...(anonymousUser?.buyingPower || {}),
            ...((user && user.buyingPower) || {}),
          },
          [
            "buyerType",
            "moveIn",
            "employment",
            "dreamHome",
            "savings",
            "annualIncome",
            "creditScore",
          ]
        )
      : _.pick(
          {
            submittedFrom: "Onboarding",
            ...(anonymousUser?.sellerInfo || {}),
            ...((user && user.sellerInfo) || {}),
          },
          [
            "property",
            "sellerType",
            "describe",
            "propertyStatus",
            "homeDescription",
            "submittedFrom",
          ]
        )
  );

  const source = !user
    ? "landing"
    : user.buyingPower && user.buyingPower.moveIn
    ? "offer"
    : "buyer";
  const actualSteps = isSeller
    ? SellerOnBoardingSteps
    : _.filter(OnBoardingSteps, ({ conditions }) =>
        _.includes(conditions, source)
      );
  const [step, setStep] = useState(actualSteps[0].step);
  const activeStep = _.find(actualSteps, { step });
  if (!activeStep) {
    setStep(actualSteps[0].step);
  }

  const activeStepIndex = _.indexOf(actualSteps, activeStep);
  const prevStep = activeStepIndex > 0 ? activeStepIndex - 1 : null;
  const nextStep =
    activeStepIndex < actualSteps.length - 1 ? activeStepIndex + 1 : null;

  const mode = isSeller ? "seller" : "member";
  const prefix = mode === "seller" ? "/seller" : "";

  return (
    activeStep && (
      <DojohomeModal
        show={show}
        onHide={onHide}
        className={activeStep && activeStep.step + "-modal"}
        header={
          activeStepIndex === 0 ? (
            <Link
              to={isSeller ? "/?signup" : "/seller?signup"}
              className="change-mode"
            >
              <Avatar /> &nbsp; I am a {isSeller ? "Buyer" : "Seller"}
            </Link>
          ) : (
            <>
              <Rocket />
              Get Started
            </>
          )
        }
        progress={
          activeStepIndex > 0 && {
            total: actualSteps.length - 1,
            current: activeStepIndex - 1,
          }
        }
      >
        <activeStep.control
          user={user}
          data={onboarding}
          mode={mode}
          stepIndex={activeStepIndex}
          setData={(value) => setOnBoarding({ ...onboarding, ...value })}
          onHide={onHide}
          onNext={(noReload) => {
            if (noReload === true) {
              setOpen(false);
              return;
            }

            if (nextStep) setStep(actualSteps[nextStep].step);
            else {
              onHide();
              history.push(`${prefix}?login&created`);
            }
          }}
          onPrev={prevStep >= 0 && (() => setStep(actualSteps[prevStep].step))}
          subtitle={activeStep.subtitle}
        />
        {false && activeStepIndex !== 0 && <Skip />}
      </DojohomeModal>
    )
  );
};
