import React, { useEffect } from "react";
import Select from "react-select";
import _ from "lodash";

import useGetUsers from "../hooks/queries/useGetUsers";
import Avatar from "../general/Avatar";

const customStyles = {
  control: (provided, state) => {
    const border = "1px solid #CCE6FF";
    return { ...provided, border };
  },
};

const formatOptionLabel = ({ value, label, name, email, data }) => (
  <div className="d-flex">
    <Avatar size={24} user={data} />
    <span className="ml-2 flex-grow-1">
      {name} <span style={{ opacity: 0.33 }}>({email})</span>
    </span>
  </div>
);

export default ({ me, user, setUser }) => {
  const [refetch, users, loading, error] = useGetUsers({ type: "seller" });

  const userToOption = (u) =>
    u && {
      label: `${u.firstname} ${u.lastname} ${u.email}`,
      value: u._id,
      name: `${u.firstname} ${u.lastname}`,
      email: u.email,
      data: u,
    };

  const meUser = {
    ...me,
    firstname: "ME",
    lastname: "",
  };

  const currentUser = userToOption(
    _.find(
      [meUser, ...(users || [])],
      user ? { _id: user._id } : { firstname: "Dojohome" }
    )
  );

  useEffect(() => {
    if (!user && currentUser) {
      setUser(currentUser.value);
    }
  }, [currentUser, setUser, user]);

  const usersOptions = _.chain([meUser, ...(users || [])])
    .map(userToOption)
    .value();

  return (
    <Select
      isDisabled={loading || !users}
      placeholder={loading ? "Loading users..." : "Select seller..."}
      styles={customStyles}
      options={usersOptions}
      value={currentUser}
      formatOptionLabel={formatOptionLabel}
      onChange={(item) => {
        setUser(item.value);
      }}
    ></Select>
  );
};
