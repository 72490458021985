import React from "react";
import classNames from "classnames";

export default ({ children }) => {
  return(
    <div className={classNames(
      "landing-aside",
      {
        "no-content": children === true
      }
    )}>
      {children && children !== true && (
        <div className="landing-aside-content">
          {children}
        </div>
      )}
    </div>
  )
};