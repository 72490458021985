import React from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { Chat } from "../../icons";
import useChatsInfo from "../../hooks/queries/useChatsInfo";

export default ({ user }) => {
  const { t } = useTranslation();
  const [chats] = useChatsInfo();

  const calcUnread =
    !chats || !chats.getMyChats || !user
      ? 0
      : chats.getMyChats.filter(
          (c) =>
            c.lastMessage &&
            !_.some(c.lastRead, {
              participant: user._id,
              message: c.lastMessage ? c.lastMessage._id : null,
            })
        ).length;

  return (
    <Button className="px-3 mx-4" as={Link} to="/messages" variant="light">
      <div className="new-messages-container">
        <Chat height={15} />
        {calcUnread > 0 ? <div className="unread-message-marker"></div> : null}
      </div>{" "}
      &nbsp;
      {t("messages.button")}
    </Button>
  );
};
