import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Creatable from "react-select/creatable";
import useListingSettings from "../../../components/hooks/mutations/useListingSettings";

const customStyles = {
  control: (provided, state) => {
    const border = "1px solid #CCE6FF";
    return { ...provided, border };
  },
  multiValue: (provided, state) => {
    const background = "rgba(204, 230, 255, 0.75)";
    const border = "1px solid #CCE6FF";
    const borderRadius = 5;
    const color = "#2D596D";
    const fontSize = "1.1rem";
    return {
      ...provided,
      border,
      borderRadius,
      background,
      color,
      fontSize,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
    };
  },
};

export default ({}) => {
  const [
    listingSettings,
    setListingSettings,
    refetch,
    saveListingSettings,
    isChanged,
  ] = useListingSettings();

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2 className="mb-4">Available Zip Codes</h2>

        <Creatable
          isMulti
          styles={customStyles}
          options={[]}
          value={(listingSettings.zipCodes || []).map((zip) => ({
            value: zip,
            label: zip,
          }))}
          onChange={(items, action) => {
            setListingSettings({
              zipCodes: items.map(({ value }) => value),
            });
          }}
        />
      </Col>
      <Col xs={12} className="my-2">
        <Button disabled={!isChanged} onClick={() => saveListingSettings()}>
          Save Updates
        </Button>
      </Col>
    </Row>
  );
};
