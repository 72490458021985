import React, { useState } from "react";
import _ from "lodash";
import gql from "graphql-tag";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { useMutation } from "@apollo/react-hooks";

import OfferModal from "./OfferModal";
import AlmostDoneModal from "./AlmostDoneModal";
import { resetQueries } from "../../apollo-tools";
import useGetOffer from "../../components/hooks/queries/offer/useGetOffer";

const SEND_OFFER = gql`
  mutation SendOffer($listing: String!, $offer: PropertyOfferInput!) {
    sendOffer(listing: $listing, offer: $offer) {
      _id
    }
  }
`;

export default ({ originalOffer, user }) => {
  const [signOffer, setSignOffer] = useState(false);
  const [offer, setOffer] = useState({});

  const [almostDoneModal, setAlmostDoneModal] = useState(false);
  const { t } = useTranslation();

  useGetOffer(originalOffer._id, (data) => {
    setOffer({
      move_in: new Date(),
      ..._.omit(data.application, ["__typename", "plan.__typename"]),
    });
  });

  const [sendOffer] = useMutation(SEND_OFFER, {
    variables: {
      listing: offer.listing ? offer.listing._id : null,
      offer: _.omit(offer, ["listing", "plan.desc"]),
    },

    refetchQueries: () => ["GetMyOffer"],
    update: resetQueries([
      "property",
      "getApplications",
      "myProperty",
      "application",
    ]),
    onCompleted: () => {
      setSignOffer(false);
      setAlmostDoneModal(true);
    },
  });

  return (
    <React.Fragment>
      {offer.status === "approved" ? (
        <Button
          block
          variant="success"
          onClick={(e) => {
            e.preventDefault();
            setSignOffer(true);
          }}
        >
          {t("offer.confirm")}
        </Button>
      ) : null}

      <AlmostDoneModal
        almostDoneModal={almostDoneModal}
        setAlmostDoneModal={setAlmostDoneModal}
      />

      <OfferModal
        user={user}
        offer={offer}
        setOffer={setOffer}
        sendOffer={sendOffer}
        signOffer={signOffer}
        setSignOffer={setSignOffer}
      />
    </React.Fragment>
  );
};
