import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, ButtonGroup, Button } from "react-bootstrap";

import Loading from "../../../components/general/Loading";
import PropertyCard from "../../../components/general/PropertyCard";
import useNearbyListings from "../../../components/hooks/queries/useNearbyListings";
import SlickSlider from "../../../components/carousel/SlickSlider";

const sliderProps = {
  slidesToShow: 3,
  slidesToScroll: 3,
  infinite: true,
  cssEase: "linear",
  padding: 48,

  responsive: [
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

export default ({ listing, user }) => {
  const { t } = useTranslation();
  const [nearby, setNearby] = useState(false);
  const [listings, loading] = useNearbyListings(listing, nearby);
  return (
    <Container className="py-5" id="similar">
      <div className="d-flex">
        <div className="flex-grow-1">
          <h3 className="my-4">{t("property.nearby")}</h3>
        </div>
        <div>
          <ButtonGroup>
            <Button
              variant={nearby ? "primary" : "default"}
              onClick={(e) => setNearby(true)}
            >
              Nearby
            </Button>
            <Button
              variant={nearby ? "default" : "primary"}
              onClick={(e) => setNearby(false)}
            >
              Columbus, OH
            </Button>
          </ButtonGroup>
        </div>
      </div>

      {loading ? (
        <Loading />
      ) : (
        <SlickSlider {...sliderProps}>
          {listings &&
            listings.map((l) => (
              <div key={l._id} className="p-3">
                <PropertyCard
                  user={user}
                  property={l}
                  link={`/property/${l._id}`}
                />
              </div>
            ))}
        </SlickSlider>
      )}
    </Container>
  );
};
