import React from "react";
import { Redirect, Route, Switch, Link, useRouteMatch } from "react-router-dom";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";

import AppMain from "../AppMain";
import Transactions from "./mission-control/Transactions";
import MinDownPayments from "./mission-control/MinDownPayments";
import FeaturedProperties from "./mission-control/FeaturedProperties";
import AdminUsers from "./mission-control/AdminUsers";
import ActiveCities from "./mission-control/ActiveCities";
import BuyingPower from "./mission-control/BuyingPower";
import AnonymousModal from "./mission-control/AnonymousModal";
import MlsSettings from "./mission-control/MlsSettings";
import MlsImport from "./mission-control/MlsImport";
import ComissionRates from "./mission-control/ComissionRates";

const AdminRoutes = [
  {
    page: "down-payments",
    title: "Minimum Down Payments",
    component: MinDownPayments,
    exact: true,
  },
  {
    page: "anonymous-modal",
    title: "Anonymous Modal",
    component: AnonymousModal,
    exact: true,
  },
  {
    page: "mls-settings",
    title: "MLS Settings",
    component: MlsSettings,
    exact: true,
  },
  {
    page: "mls-import",
    title: "MLS Import Log",
    component: MlsImport,
    exact: true,
  },
  {
    page: "featured-properties",
    title: "Featured Properties",
    component: FeaturedProperties,
    exact: true,
  },
  {
    page: "allowed-cities",
    title: "Active Cities",
    component: ActiveCities,
    exact: true,
  },
  {
    page: "buying-power",
    title: "Buying Power Responses",
    component: BuyingPower,
    exact: true,
  },
  {
    page: "comission-rates",
    title: "Commission Rates",
    component: ComissionRates,
    exact: true,
  },
  {
    page: "admin-users",
    title: "Admin Users",
    component: AdminUsers,
    exact: true,
  },
  // {
  //   page: "transactions",
  //   title: "Transactions",
  //   component: Transactions,
  //   exact: true,
  // },
];

export default ({ user }) => {
  const match = useRouteMatch();
  const page = match.params.page;
  if (user.mode !== "admin") return <Redirect to="/" />;
  if (!page) return <Redirect to={`/mission-control/${AdminRoutes[0].page}`} />;

  return (
    <AppMain>
      <Row>
        <Col xs={12}>
          <h1 className="my-4">Mission Control</h1>
        </Col>
        <Col xs={12} lg={4} xl={3} className="mt-4">
          <Nav
            variant="pills"
            defaultActiveKey={AdminRoutes[0].page}
            activeKey={page}
            className="flex-column"
          >
            {AdminRoutes.map(
              (route) =>
                route.title && (
                  <Nav.Item key={route.path}>
                    <Nav.Link as={Link} eventKey={route.page} to={route.page}>
                      {route.title}
                    </Nav.Link>
                  </Nav.Item>
                )
            )}
          </Nav>
        </Col>
        <Col xs={12} lg={8} xl={9} className="pb-5">
          <Switch>
            {AdminRoutes.map((route) => (
              <Route
                key={route.page}
                exact={true}
                path={`/mission-control/${route.page}`}
                render={(props) => <route.component user={user} {...props} />}
              />
            ))}
          </Switch>
        </Col>
      </Row>
    </AppMain>
  );
};
