import React from "react";
import { Link } from "react-router-dom";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import AppMain from "../AppMain";
import PropertyCard from "../../components/general/PropertyCard";
import Loading from "../../components/general/Loading";
import useMyFavorites from "../../components/hooks/queries/useMyFavorites";

export default ({ user }) => {
  const { t } = useTranslation();
  const [favorites, loading] = useMyFavorites();

  return (
    <AppMain>
      <Row>
        <Col xs={12}>
          <h1 className="text-center mt-5 mb-4">
            {t("member.menu.favorites")}
          </h1>
        </Col>

        <Col xs={12}>
          <Row>
            {loading || !favorites ? (
              <Loading />
            ) : favorites.length > 0 ? (
              favorites
                .filter(({ listing }) => !!listing)
                .map(({ listing }) => (
                  <Col
                    key={listing._id}
                    xs={12}
                    md={6}
                    lg={4}
                    className="d-flex pb-3"
                    style={{ justifyItems: "stretch" }}
                  >
                    <PropertyCard
                      user={user}
                      property={listing}
                      link={`/property/${listing._id}`}
                    ></PropertyCard>
                  </Col>
                ))
            ) : (
              <Col xs={12}>
                <h5 className="text-center">
                  {t("member.favorites.noFavorites")}
                </h5>

                <div className="text-center my-5">
                  <Button
                    as={Link}
                    variant="primary"
                    to="/search"
                    className="px-5"
                  >
                    {t("member.favorites.browseHomes")}
                  </Button>
                </div>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
    </AppMain>
  );
};
