import React from "react";
import { Modal } from "react-bootstrap";

import { Close } from "../onboarding/icons";

import "./modals.scss";

const Progress = ({ total, current }) => {
  const successWidth = (current / (total - 1)) * 100;

  return (
    <div className="progress">
      <div
        className="progress-bar"
        style={{ width: `calc(${successWidth}% + ${10 - current}px)` }}
      />
      <div className="progress-points">
        {[...Array(total)].map((e, i) => (
          <div className="progress-point" key={`progress point ${i}`} />
        ))}
      </div>
    </div>
  );
};

const CloseButton = ({ onHide }) => (
  <button className="close" onClick={onHide}>
    <Close />
  </button>
);

export default ({
  variant,
  header,
  progress,
  show,
  onHide,
  children,
  className,
  ...props
}) => {
  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      className={`dojohome-modal ${className ? className : ""}`}
      {...props}
    >
      <Modal.Header>
        {progress && (
          <Progress total={progress.total} current={progress.current} />
        )}
        <div className="modal-header-inner">
          {header}
          <CloseButton onHide={onHide} />
        </div>
      </Modal.Header>
      <Modal.Body
        className={variant === "reportModal" && "modal-body px-5 pt-4 pb-5"}
      >
        {children}
      </Modal.Body>
    </Modal>
  );
};
