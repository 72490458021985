import React, { useState } from "react";
import { Row, Col, Form, Button, InputGroup } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import DatePicker from "react-datepicker";
import currencyFormatter from "currency-formatter";
import moment from "moment";
import { useTranslation } from "react-i18next";

import { Location } from "./icons";
import Tooltip from "./Tooltip";
import ReportModal from "./ReportModal";

export default ({ listing, location, priceDefaults }) => {
  const { t } = useTranslation();

  const [defaults, setDefaults] = useState({
    ...priceDefaults,
    improvementsCost: 10000,
  });
  const currency = (amount) =>
    currencyFormatter.format(amount, { code: "USD", precision: 0 });
  const [openReport, setOpenReport] = useState(false);
  const [calculator, setCalculator] = useState({
    ...listing,
    property: location,
    mortgageType: "Standard Mortgage",
    mortgageYears: 5,
    mortgageTerm: 30,
    mortgageInterest: 4,
    isMarried: "no",
    taxes: "Single",
    boughtIn: moment().subtract(2, "years").toDate(),
    isResidence: "yes",
    annualIncome: 50000,
  });

  return (
    <div className="py-4">
      <Row>
        <Col xs={12} lg={4} className="px-5">
          <Form.Group className="field-group">
            <Form.Label>
              Property Address
            </Form.Label>
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>
                  <Location />
                </InputGroup.Text>
              </InputGroup.Prepend>
              <Form.Control value={calculator.property.address} />
            </InputGroup>
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              {t("calculator.marketPrice.label")} <Tooltip dark message={t("calculator.marketPrice.tooltip")} />
            </Form.Label>
            <Form.Control
              as={CurrencyInput}
              step="1000"
              prefix="$"
              value={calculator.marketPrice}
              allowDecimals={false}
              placeholder={currency(defaults.marketPrice)}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  marketPrice: e,
                })
              }
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              {t("calculator.rent.label")} <Tooltip dark message={t("calculator.rent.tooltip")} />
            </Form.Label>
            <Form.Control
              as={CurrencyInput}
              step="100"
              prefix="$"
              value={calculator.rent}
              allowDecimals={false}
              placeholder={currency(defaults.rent)}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  rent: e,
                })
              }
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              {t("calculator.bankOwed.label")} <Tooltip dark message={t("calculator.bankOwed.tooltip")} />
            </Form.Label>
            <Form.Control
              as={CurrencyInput}
              step="1000"
              prefix="$"
              value={calculator.bankOwed}
              allowDecimals={false}
              placeholder={currency(defaults.bankOwed)}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  bankOwed: e,
                })
              }
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              {t("calculator.fees.label")} <Tooltip dark message={t("calculator.fees.tooltip")} />
            </Form.Label>
            <Form.Control
              as={CurrencyInput}
              step="25"
              prefix="$"
              value={calculator.fees}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  fees: e,
                })
              }
            />
          </Form.Group>
        </Col>
        <Col xs={12} lg={4} className="px-5">
          <Form.Group className="field-group">
            <Form.Label>
              Cost of Improvements Made
            </Form.Label>
            <Form.Control
              as={CurrencyInput}
              step="100"
              prefix="$"
              value={calculator.improvementsCost}
              allowDecimals={false}
              placeholder={currency(defaults.improvementsCost)}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  improvementsCost: e,
                })
              }
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              Mortgage Type
            </Form.Label>
            <Form.Control
              as="select"
              value={calculator.mortgageType}
              onChange={(e) =>
                setCalculator({ ...calculator, mortgageType: e.target.value })
              }
            >
              <option value="Standard Mortgage">Standard Mortgage</option>
              <option value="Adjustable Rate Mortgage">
                Adjustable Rate Mortgage
              </option>
              <option value="None">None</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              Years Into Mortgage
            </Form.Label>
            <Form.Control
              value={calculator.mortgageYears}
              onChange={(e) =>
                setCalculator({ ...calculator, mortgageYears: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              Total Mortgage Term
            </Form.Label>
            <Form.Control
              value={calculator.mortgageTerm}
              onChange={(e) =>
                setCalculator({ ...calculator, mortgageTerm: e.target.value })
              }
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              Mortgage Interest %
            </Form.Label>
            <Form.Control
              type="number"
              value={calculator.mortgageInterest}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  mortgageInterest: e.target.value,
                })
              }
            />
          </Form.Group>
        </Col>
        <Col
          xs={12}
          lg={4}
          className="px-5"
          style={{ borderLeft: "1px solid rgba(5, 56, 97, 0.1)" }}
        >
          <Form.Group className="field-group">
            <Form.Label>
              Are you married?
            </Form.Label>
            <Form.Control
              as="select"
              value={calculator.isMarried}
              onChange={(e) =>
                setCalculator({ ...calculator, isMarried: e.target.value })
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              How will you file taxes?
            </Form.Label>
            <Form.Control
              as="select"
              value={calculator.taxes}
              onChange={(e) =>
                setCalculator({ ...calculator, taxes: e.target.value })
              }
            >
              <option value="Single">Single</option>
              <option value="Married_Filing_Jointly">
                Married Filing Jointly
              </option>
              <option value="Married_Filing_Separately">
                Married Filing Separately
              </option>
              <option value="Head_of_Household">Head of Household</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              When did you buy this property?
            </Form.Label>
            <DatePicker
              selected={calculator.boughtIn}
              onChange={(date) =>
                setCalculator({ ...calculator, boughtIn: date })
              }
              placeholder="Choose date"
              className="form-control"
            />
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              Has this property been your primary residence for 2 of the last 5
              years?
            </Form.Label>
            <Form.Control
              as="select"
              value={calculator.isResidence}
              onChange={(e) =>
                setCalculator({ ...calculator, isResidence: e.target.value })
              }
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </Form.Control>
          </Form.Group>

          <Form.Group className="field-group">
            <Form.Label>
              Annual Income <sub>(not including property sale)</sub>
            </Form.Label>
            <Form.Control
              as={CurrencyInput}
              step="100"
              prefix="$"
              value={calculator.annualIncome}
              allowDecimals={false}
              onChange={(e) =>
                setCalculator({
                  ...calculator,
                  annualIncome: e,
                })
              }
            />
          </Form.Group>

          <Button
            variant="dojobrand"
            className="w-100"
            block
            onClick={() => setOpenReport(true)}
          >
            Get My Report
          </Button>
        </Col>
      </Row>

      <ReportModal
        show={openReport}
        calculator={calculator}
        onHide={() => setOpenReport(false)}
      />
    </div>
  );
};
