import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const DELETE_CATEGORY = gql`
  mutation DeleteCategory($id: ID!) {
    deleteCategory(id: $id) {
      message
    }
  }
`;

export default ((id, onCompleted, onError) =>
{
  const [deleteCategory, { loading, error }] = useMutation( DELETE_CATEGORY, {
    variables: { id },
    refetchQueries: () => ['GetPropertyCategories'],
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err) });

  return [deleteCategory, loading, error];
});