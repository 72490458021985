import React, { useState } from "react";
import { Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import _ from "lodash";

import { DojoLogoDark } from "./icons";
import useAnonymous from "../hooks/queries/useAnonymous";
import useSendPropertyReport from "../hooks/mutations/useSendPropertyReport";
import useValidateEmail from "../hooks/queries/useValidateEmail";
import DojohomeModal from "../modals/DojohomeModal";

export default ({ show, onHide, calculator }) => {
  const [reportSent, setReportSent] = useState(false);
  const [canLogin, setCanLogin] = useState(false);
  const [user, setUser] = useState({ email: "" });
  const [validateEmail] = useValidateEmail((exists) => {
    setCanLogin(_.includes(exists, "seller"));
  });
  const [_anonymousUser, updateAnonymous, updateLoading: loading] = useAnonymous(
    () => {
      validateEmail(user.email);
    },
    (data) => {
      setUser(_.pick(data.anonymous || {}, ["email"]));
    }
  );

  const [
    sendPropertyReport,
    reportLoading: loading,
  ] = useSendPropertyReport(user.email, calculator, [], () =>
    setReportSent(true)
  );

  const updating = updateLoading || reportLoading;

  return (
    <DojohomeModal
      show={show}
      onHide={onHide}
      size="sm"
      variant="reportModal"
      className="super-slim"
    >
      <DojoLogoDark />

      {reportSent ? (
        <div className="mt-3">
          <h3 className="my-4">We’ve sent more information to you at</h3>

          <h5 className="muted-text my-4">{user.email}</h5>

          <div className="text-center">
            {canLogin ? (
              <Button size="lg" variant="dojobrand" block as={Link} to="?login">
                Login
              </Button>
            ) : (
              <Button
                size="lg"
                variant="dojobrand"
                block
                as={Link}
                to="?signup"
                onClick={onHide}
              >
                Complete Sign-Up
              </Button>
            )}
          </div>

          <div className="text-center mt-4 mb-2">
            <Link to="#" className="simple-link" onClick={onHide}>
              Not Now
            </Link>
          </div>
        </div>
      ) : (
        <div>
          <h2>Where can we send your report?</h2>
          <Form
            onSubmit={(e) => {
              e.preventDefault();
              if (user.email) {
                updateAnonymous(user);
                sendPropertyReport();
              }
            }}
          >
            <Form.Group>
              <Form.Control
                value={user.email}
                onChange={(e) => setUser({ ...user, email: e.target.value })}
                className="text-center single-control"
                placeholder="E-mail Address"
              />
            </Form.Group>

            <div className="text-center">
              <Button
                size="lg"
                variant="dojobrand"
                block
                className="my-2"
                type="submit"
                disabled={updating}
              >
                {updating && (
                  <Spinner animation="border" size="sm" variant="light" />
                )}{" "}
                Get My Report
              </Button>
            </div>

            <div className="mt-3 text-center">
              By clicking Get My Report, I agree to Dojohome’s <br />{" "}
              <Link to="/s/terms">Terms & Conditions</Link> and{" "}
              <Link to="/s/privacy">Privacy Policy</Link>
            </div>
          </Form>{" "}
        </div>
      )}
    </DojohomeModal>
  );
};
