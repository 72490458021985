import React from "react";
import classNames from "classnames";

import { makeImageUrl } from "../../tools";
import { AttentionIcon } from "../icons";

export default ({ user, size = 32, warning = false }) => {
  return ((user && user.profile) || {}).photo ? (
    <div
      className={classNames("DojoAvatar", { warning })}
      style={{
        backgroundImage: `url(${makeImageUrl((user.profile || {}).photo)}`,
        width: size,
        height: size,
      }}
    >
      <div className="attention">
        <AttentionIcon height={16} />
      </div>
    </div>
  ) : (
    <div className={classNames("DojoAvatar", { warning })}>
      <svg
        width={size}
        height={size}
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 2C6.5 2 2 6.5 2 12C2 17.5 6.5 22 12 22C17.5 22 22 17.5 22 12C22 6.5 17.5 2 12 2ZM7.1 18.3C7.5 17.4 10.1 16.5 12 16.5C13.9 16.5 16.5 17.4 16.9 18.3C15.6 19.4 13.9 20 12 20C10.1 20 8.4 19.4 7.1 18.3ZM18.4 16.8C17 15.1 13.5 14.5 12 14.5C10.5 14.5 7.1 15.1 5.6 16.8C4.6 15.5 4 13.8 4 12C4 7.6 7.6 4 12 4C16.4 4 20 7.6 20 12C20 13.8 19.4 15.5 18.4 16.8ZM12 6C10.1 6 8.5 7.6 8.5 9.5C8.5 11.4 10.1 13 12 13C13.9 13 15.5 11.4 15.5 9.5C15.5 7.6 13.9 6 12 6ZM12 11C11.2 11 10.5 10.3 10.5 9.5C10.5 8.7 11.2 8 12 8C12.8 8 13.5 8.7 13.5 9.5C13.5 10.3 12.8 11 12 11Z"
          fill="#243B5D"
        />
      </svg>
      <div className="attention">
        <AttentionIcon height={16} />
      </div>
    </div>
  );
};
