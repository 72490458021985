import React, { useState } from "react";
import { Row, Col, Button } from "react-bootstrap";

import PlaidPaymentMethod from "./PlaidPaymentMethod";
import useUpdateMe from "../hooks/mutations/useUpdateMe";
import useStripeConnectLink from "../hooks/queries/useStripeConnectLink";
import useStripePayouts from "../hooks/queries/useStripePayouts";
import StripePayoutMethod from "./StripePayoutMethod";

const ConnectButton = ({ url, disabled, onConnected }) => {
  return (
    <Button as="a" className="px-5" href={url} disabled={disabled}>
      Connect via Stripe
    </Button>
  );
};

export default ({ user, defaultPayout = {}, setDefaultPayout }) => {
  const [updateUser] = useUpdateMe(["CurrentUser"]);
  const [stripeLink, loading] = useStripeConnectLink(window.location.href);
  const [accounts, loadingAccounts] = useStripePayouts();

  return (
    <Row>
      {accounts && accounts.length > 0 ? (
        <React.Fragment>
          <Col xs={12}>
            <h5 className="my-4">Select Payout Method</h5>
          </Col>
          {accounts.map((acc) => (
            <Col xs={12} md={6}>
              <StripePayoutMethod
                payoutMethod={acc}
                selectPayoutMethod={(id) =>
                  setDefaultPayout
                    ? setDefaultPayout(id)
                    : updateUser({
                        payoutMethod: {
                          id,
                          type: "stripe",
                        },
                      })
                }
                defaultPayout={defaultPayout.id}
              />
            </Col>
          ))}
        </React.Fragment>
      ) : (
        stripeLink && (
          <Col xs={12}>
            <ConnectButton url={stripeLink} disabled={loading} />
          </Col>
        )
      )}
    </Row>
  );
};
