import React from "react";
import _ from "lodash";

import { formatUsd } from "../../tools";

export default (({ offer, listing }) => {
  const montlyEquityDelta =
  offer.plan && offer.plan.id ?
  (listing.price * (offer.plan.targetEquity / 100.0) -
  offer.down_payment) /
  offer.plan.targetMonths :
  0;

  return offer.plan && offer.plan.targetMonths ?
  <ul>
      {_.range(1, Math.ceil(offer.plan.targetMonths / 12) + 1).map((year) =>
    <li>
          <div>
            <strong>YEAR {year}</strong>
          </div>
          <div className="small">
            Pay <strong>{formatUsd(listing.rent)}</strong> monthly rent and{" "}
            <strong>{formatUsd(montlyEquityDelta)}</strong>{" "}
            monthly equity payment
          </div>
        </li>)}


      {offer.plan && offer.plan.targetMonths &&
    <li>
          <div>
            <strong>RESULT</strong>
          </div>
          <div className="small">
            You will own {offer.plan.targetEquity}% of the property.
          </div>
        </li>}

    </ul> :

  <div>
      <div>
        <strong>EACH YEAR</strong>
      </div>
      <div className="small">
        Pay {formatUsd(listing.rent)}/month and buy equity whenever you like
      </div>
    </div>;

});