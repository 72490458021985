import React, { useState } from "react";
import { loader } from "graphql.macro";
import { Link, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import {
  Row,
  Col,
  Badge,
  Button,
  ListGroup,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  Form,
} from "react-bootstrap";

import AppMain from "../AppMain";
import useGetUsers from "../../components/hooks/queries/useGetUsers";
import Avatar from "../../components/general/Avatar";
import useUpdateUser from "../../components/hooks/mutations/useUpdateUser";

const UserTypes = {
  seller: "Sellers",
  member: "Buyer",
};

const UserVerified = [
  { title: "Incomplete", value: "incomplete" },
  { title: "Not Verified", value: "not-verified" },
  { title: "Need to Send Naborly App", value: "need-naborly" },
  { title: "Tenant Verification", value: "tenant-verification" },
  { title: "Verified", value: "verified" },
];

export default ({ user }) => {
  const match = useRouteMatch();
  const [openEdits, setOpenEdits] = useState({});
  const [userVerified, setUserVerified] = useState("incomplete");
  const [search, setSearch] = useState("");

  const [_refetchUsers, allUsers, loading] = useGetUsers({
    type: match.params["role"],
    status: userVerified,
    q: search,
  });

  const [updateUser] = useUpdateUser();

  const UserActions = [
    {
      title: "Request Verification",
      variant: "info",
      status: ["not-verified"],
      action: (u) => {
        updateUser(u._id, { status: "need-naborly" });
      },
    },
    {
      title: "Add Nabolrly Link",
      variant: "warning",
      status: ["need-naborly"],
      action: (u) => {
        updateUser(u._id, { status: "tenant-verification" });
      },
    },
    {
      title: "Approve",
      variant: "success",
      status: ["tenant-verification"],
      action: (u) => {
        updateUser(u._id, { status: "verified" });
      },
    },
    {
      title: "Reject",
      variant: "danger",
      status: ["tenant-verification", "verified"],
      action: (u) => {
        updateUser(u._id, { status: "not-verified" });
      },
    },
  ];

  return (
    <AppMain>
      <Row className="mt-4">
        <Col xs={12}>
          <h1>{UserTypes[match.params["role"]]}</h1>
        </Col>

        <Col xs={12}>
          <ButtonToolbar>
            <ButtonGroup size="sm">
              {UserVerified.map(({ title, value }) => (
                <Button
                  key={value}
                  variant={
                    userVerified === value ? "secondary" : "outline-secondary"
                  }
                  onClick={(e) => setUserVerified(value)}
                >
                  {title}
                </Button>
              ))}
            </ButtonGroup>

            <InputGroup className="ml-2" variant="secondary">
              <Form.Control
                size="sm"
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></Form.Control>
              <InputGroup.Append>
                <Button
                  size="sm"
                  variant="secondary"
                  onClick={(e) => setSearch("")}
                >
                  Clear
                </Button>
              </InputGroup.Append>
            </InputGroup>
          </ButtonToolbar>
        </Col>
        <Col xs={12}>
          {loading || !allUsers ? (
            "loading..."
          ) : (
            <ListGroup className="mt-2">
              {allUsers.map((u) => (
                <ListGroup.Item className="px-3" key={u._id}>
                  <div className="d-flex">
                    <div className="mr-3">
                      <Avatar user={u} size={42} />
                    </div>

                    <div className="flex-grow-1">
                      <div className="d-flex">
                        <div>
                          {u.email}
                          <br />
                          {u.firstname} {u.lastname}
                        </div>
                        <div>
                          {u.banned ? (
                            <Badge variant="danger" className="ml-2">
                              BANNED
                            </Badge>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="flex-grow-0">
                      <ButtonGroup>
                        {u.mode === "seller" ? (
                          <Button
                            as={Link}
                            to={`/admin/properties?owner=${u._id}`}
                            variant="outline-info"
                            size="sm"
                          >
                            {u.properties && u.properties.length} Listings
                          </Button>
                        ) : (
                          u.offers && (
                            <Button variant="outline-warning" size="sm">
                              {u.offers.length} Offers
                            </Button>
                          )
                        )}

                        <Button
                          as={Link}
                          variant="outline-success"
                          to={`/profile/${u._id}`}
                          size="sm"
                        >
                          See Profile
                        </Button>
                        <Button
                          variant={
                            openEdits[u._id] ? "secondary" : "outline-secondary"
                          }
                          size="sm"
                          onClick={(e) =>
                            setOpenEdits({
                              ...openEdits,
                              [u._id]: !openEdits[u._id],
                            })
                          }
                        >
                          Manage User
                        </Button>
                      </ButtonGroup>
                    </div>
                  </div>
                  {openEdits[u._id] ? (
                    <div>
                      <h6 className="mt-4">Actions</h6>
                      <ButtonGroup size="sm">
                        {UserActions.filter(
                          ({ status }) =>
                            !status || _.includes(status, u.status)
                        ).map(({ title, variant, action }) => (
                          <Button variant={variant} onClick={() => action(u)}>
                            {title}
                          </Button>
                        ))}
                      </ButtonGroup>
                    </div>
                  ) : null}
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
      </Row>
    </AppMain>
  );
};
