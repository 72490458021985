import React from "react";
import { Link } from "react-router-dom";

import currencyFormatter from "currency-formatter";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useTranslation } from "react-i18next";

import { makeImageUrl } from "../../tools";

export default React.forwardRef(({ user, activeOffer, onClick }, ref) => {
  const { t } = useTranslation();
  return (
    <Card
      style={{ overflow: "hidden" }}
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      <div className="d-flex">
        <div
          className=""
          style={{
            width: 220,
            minHeight: 120,
            backgroundSize: "cover",
            backgroundPosition: "center center",
            backgroundImage: `url(${makeImageUrl(
              activeOffer.listing.images[0]
            )})`,
          }}
        ></div>
        <div className="flex-grow-1 py-3 px-4">
          <div className="small text-uppercase">Address</div>
          <div>
            <strong>{activeOffer.listing.address}</strong>
          </div>
        </div>
        <div className="py-3 px-4" style={{ borderLeft: "1px solid #CCE6FF" }}>
          <div className="small text-uppercase">Rental Payment</div>
          <div>
            <strong>
              {currencyFormatter.format(activeOffer.listing.rent || 0, {
                code: "USD",
                precision: 0,
              })}
            </strong>
          </div>
        </div>
        {user._id === activeOffer.applicant._id && (
          <div
            className="py-3 px-4"
            style={{ borderLeft: "1px solid #CCE6FF" }}
          >
            <Button
              size="sm"
              as={Link}
              to={`/property/${activeOffer.listing._id}/chat/admin`}
            >
              {t("contract.card.my_dojoexpert")}
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
});
