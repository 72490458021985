import React from "react";
import { Row, Col } from "react-bootstrap";

import AppMain from "../AppMain";
import AdminSignup from "../../components/auth/Signup";

export default () => {
  return (
    <AppMain>
      <div className="flex-grow-1"></div>
      <Row>
        <Col
          xs={12}
          md={{ span: 8, offset: 2 }}
          lg={{ span: 6, offset: 3 }}
          xl={{ span: 4, offset: 4 }}
        >
          <AdminSignup mode="admin" redirect="/admin/offers" />
        </Col>
      </Row>
      <div className="flex-grow-1"></div>
    </AppMain>
  );
};
