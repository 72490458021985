import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import FilePreview from "./FilePreview";
import UploadedFile from "./UploadedFile";
import Reorder, {
  reorder,
  reorderImmutable,
  reorderFromTo,
  reorderFromToImmutable,
} from "react-reorder";

import _ from "lodash";

import "./FileUploader.scss";
const UPLOAD_IMAGE = loader("./UploadImage.graphql");

function filesToArray(files) {
  console.log(files);
  const array = [];
  for (var i = 0; i < files.length; i++) {
    array.push(files.item(i));
  }
  return array;
}

export default forwardRef(
  (
    {
      multiple,
      disabled,
      size,
      uploadedFiles = [],
      setUploadedFiles,
      reference = "images",
    },
    ref
  ) => {
    const { t } = useTranslation();
    const fileInputRef = useRef(null);
    const [pendingUploads, setPendingUploads] = useState([]);

    function onFilesAdded(files) {
      const arrFiles = filesToArray(files);
      console.log(arrFiles);
      setPendingUploads(_.concat(pendingUploads, arrFiles));
    }

    const [uploadImage] = useMutation(UPLOAD_IMAGE, {
      onCompleted: (result) => {
        const uploaded = _.concat(uploadedFiles, [result.uploadImage]);
        setUploadedFiles(uploaded);
        setPendingUploads(_.slice(pendingUploads, 1));
      },
      onError: (err) => {
        setPendingUploads(_.slice(pendingUploads, 1));
      },
    });

    const removeUploadedFile = (rf) => {
      console.log(rf, uploadedFiles);
      const uploaded = _.filter(uploadedFiles, (fl) => fl._id !== rf);
      console.log(rf, uploaded);
      setUploadedFiles(uploaded);
    };

    useEffect(() => {
      if (pendingUploads.length) {
        uploadImage({
          variables: { file: pendingUploads[0] },
        });
      }
    }, [pendingUploads, uploadImage]);

    useImperativeHandle(ref, () => ({
      clear: () => setUploadedFiles([]),
      remove: (files) =>
        setUploadedFiles(_.differenceBy(uploadedFiles, files, "_id")),
    }));

    const onReorder = (event, previousIndex, nextIndex, fromId, toId) =>
      setUploadedFiles(reorder(uploadedFiles, previousIndex, nextIndex));

    return (
      <div className="FileUploader">
        <input
          ref={fileInputRef}
          className="FileInput"
          type="file"
          multiple={!!multiple}
          onChange={(e) => onFilesAdded(e.target.files)}
        />

        {(!multiple || uploadedFiles.length === 0) && (
          <Row>
            <Col xs={12}>
              <div
                className="DropArea"
                style={{ cursor: disabled ? "default" : "pointer" }}
              >
                <svg
                  width="40"
                  height="40"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 2C4.9 2 4.01 2.9 4.01 4L4 20C4 21.1 4.89 22 5.99 22H18C19.1 22 20 21.1 20 20V8L14 2H6ZM13 9V3.5L18.5 9H13Z"
                    fill="#98B7D3"
                  />
                </svg>

                <div className="mt-2">
                  <span>{t("files.drop")}</span>{" "}
                  <span
                    tabstop="-1"
                    className="link"
                    onClick={(e) => fileInputRef.current.click()}
                  >
                    {t("files.browse")}
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}

        <Reorder
          className="reorderable"
          reorderId={reference}
          onReorder={onReorder}
        >
          {uploadedFiles.map((fl) => (
            <Col
              key={fl._id}
              xs={12}
              sm={6}
              md={4}
              lg={size === "medium" ? 6 : 4}
              xl={size === "medium" ? 6 : 3}
              className="FilePreview my-3"
            >
              <UploadedFile
                file={fl}
                removeImage={(f) => removeUploadedFile(f)}
              />
            </Col>
          ))}
        </Reorder>

        <Row>
          {!pendingUploads || pendingUploads.length === 0
            ? uploadedFiles.length > 0 && (
                <Col
                  xs={12}
                  sm={6}
                  md={4}
                  lg={size === "medium" ? 6 : 4}
                  xl={size === "medium" ? 6 : 3}
                  className="d-flex"
                >
                  <Card
                    onClick={(e) => fileInputRef.current.click()}
                    className="py-1 flex-grow-1 link"
                    style={{
                      borderStyle: "dashed",
                      borderWidth: 2,
                      background: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <Card.Body className="text-center">
                      <h6 className="mt-1">Upload Another Image</h6>
                    </Card.Body>
                  </Card>
                </Col>
              )
            : pendingUploads &&
              pendingUploads.map((fl, i) => (
                <Col
                  key={`pending-${i}`}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={size === "medium" ? 6 : 4}
                  xl={size === "medium" ? 6 : 3}
                  className="FilePreview my-3"
                >
                  <FilePreview file={fl} />
                </Col>
              ))}
        </Row>
      </div>
    );
  }
);
