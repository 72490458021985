import React, { useState, useRef } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

import FileUploader from "../../components/file-uploader/FileUploader";
import UploadedDocument from "../../components/file-uploader/UploadedDocument";

const SAVE_USER_DOCUMENTS = loader("./SaveUserDocuments.graphql");

export default ({ user, uploadLabel = "UPLOAD", children, onSubmit }) => {
  const { t } = useTranslation();
  const [docType, setDocType] = useState("drivers_license");
  const [attachedFiles, setAttachedFiles] = useState([]);
  const uploaderRef = useRef(null);

  console.log(attachedFiles);

  const [saveDocuments] = useMutation(SAVE_USER_DOCUMENTS, {
    variables: {
      file: attachedFiles[0] ? attachedFiles[0]._id : null,
      type: docType,
    },

    onCompleted: () => {
      uploaderRef.current.clear();
      if (onSubmit) {
        onSubmit();
      }
    },
    refetchQueries: () => ["CurrentUser"],
  });

  return (
    <React.Fragment>
      {children}
      <Row>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-2">
          {user.identity ? (
            <Row className="mb-3">
              {user.identity.drivers_license ? (
                <Col xs={12} md={6} className="FilePreview mb-3">
                  <UploadedDocument
                    file={user.identity.drivers_license}
                    caption={t("identity.driversLicense")}
                  />
                </Col>
              ) : null}
              {user.identity.passport ? (
                <Col xs={12} md={6} className="FilePreview mb-3">
                  <UploadedDocument
                    file={user.identity.passport}
                    caption={t("identity.passport")}
                  />
                </Col>
              ) : null}
              {user.identity.id_card ? (
                <Col xs={12} md={6} className="FilePreview mb-3">
                  <UploadedDocument
                    file={user.identity.id_card}
                    caption={t("identity.idCard")}
                  />
                </Col>
              ) : null}
            </Row>
          ) : null}

          <Row>
            <Col>
              <Form.Check
                label={t("identity.driversLicense")}
                checked={docType === "drivers_license"}
                onChange={(e) => setDocType("drivers_license")}
              />
            </Col>
            <Col>
              <Form.Check
                label={t("identity.passport")}
                checked={docType === "passport"}
                onChange={(e) => setDocType("passport")}
              />
            </Col>
            <Col>
              <Form.Check
                label={t("identity.idCard")}
                checked={docType === "id_card"}
                onChange={(e) => setDocType("id_card")}
              />
            </Col>
          </Row>

          <div className="py-4">
            <FileUploader
              reference="identity-documents"
              ref={uploaderRef}
              setUploadedFiles={(files) => {
                setAttachedFiles(files);
              }}
              uploadedFiles={attachedFiles}
              size="medium"
              maxFiles={1}
            />
          </div>

          <Button
            variant="primary"
            className="px-5"
            onClick={(e) => saveDocuments()}
            disabled={attachedFiles.length === 0}
          >
            {uploadLabel}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};
