import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import { protectedUserName } from "../../tools";

const OfferStatePriority = {
  closed: 1,
  documents: 2,
  tenantVerification: 3,
  approvedDocuments: 4,
  approved: 5,
  new: 6,
  rejected: 10,
  withdrawn: 10,
};

export default ({ property, user }) => {
  const sortedOffers = useMemo(() => {
    return _.sortBy(
      property.offers || [],
      ({ status }) => OfferStatePriority[status]
    );
  }, [property.offers]);

  return (
    <Col xs={12} lg={{ span: 10, offset: 1 }} className="mt-5">
      <h1 className="text-center">Available Offers</h1>

      {property.offers.length === 0 ? (
        <h5 className="text-center mt-4">
          There are no offers for this property
        </h5>
      ) : null}

      <Row className="mb-5">
        {sortedOffers.map((a) => (
          <Col
            key={a._id}
            xs={12}
            lg={{ span: 10, offset: 1 }}
            className="mt-3"
          >
            <Card>
              <Card.Body className="d-flex">
                <div className="flex-grow-1 vcenter-flex">
                  <div>
                    <div className="d-flex">
                      <h5 className="flex-grow-0 mr-3">
                        {protectedUserName(a.applicant, user)}
                      </h5>
                      <div>
                        <Badge
                          size="sm"
                          variant={
                            a.status === "approved"
                              ? "success"
                              : a.status === "rejected"
                              ? "danger"
                              : "secondary"
                          }
                          className="text-uppercase"
                        >
                          {a.status}
                        </Badge>
                      </div>
                    </div>
                    <p className="my-0">
                      <span>
                        Down Payment: ${a.down_payment.toFixed(0)} (
                        {((a.down_payment / property.price) * 100).toFixed(2)}%)
                      </span>
                    </p>
                    <p className="my-0">
                      {a.tour_date ? (
                        <span>
                          Scheduled Tour:{" "}
                          {moment(a.tour_date).format("MMMM DD, YYYY")}
                        </span>
                      ) : null}
                    </p>
                  </div>
                </div>
                <div className="flex-grow-0 vcenter-flex">
                  <Button
                    as={Link}
                    to={`/seller/properties/${property._id}/offers/${a._id}`}
                    className="px-5"
                    variant="outline-secondary"
                  >
                    VIEW
                  </Button>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>
    </Col>
  );
};
