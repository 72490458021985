import _ from "lodash";

export default (user) => {
  const isSeller = user && user.mode === "seller";
  const isMember = user && user.mode === "member";
  const isAdmin = user && user.mode === "admin";
  const hasCompleteProfile =
    user &&
    _.get(user, "validated_email") &&
    _.get(user, "validated_phone");

  return {
    isSeller,
    isMember,
    isAdmin,
    hasCompleteProfile,
    ownProperty: (listing) => user && listing.owner._id === user._id
  };
}