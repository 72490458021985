import React from "react";
import { Redirect, useLocation } from "react-router-dom";
import qs from "qs";
import _ from "lodash";

export default ({ to }) => {
  const location = useLocation();
  const query = qs.parse(location.search.substring(1));

  const restrictions = ["login", "signup", "created"];

  return (
    <Redirect to={{ pathname: to, search: qs.stringify(_.omit(query, restrictions)) }}/>
  )
};