import React, { useEffect } from "react";
import { Form } from "react-bootstrap";
import Slider from "rc-slider";
import _ from "lodash";

import "rc-slider/assets/index.css";

import BackNext from "./BackNext";
import SelectCards from "./SelectCards";
import useUpdateBuyingPower from "../hooks/mutations/useUpdateBuyingPower";
import useCurrency from "../../components/hooks/helpers/useCurrency";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const currency = useCurrency();

  const [updateBuyingPower, updating] = useUpdateBuyingPower(data, () => {
    onNext && onNext();
  });
  const disableNext = !data.savings || !data.annualIncome || !data.creditScore;

  const creditScoreOptions = [
    { label: "Fair", subtitle: "550-650", value: "fair" },
    { label: "Good", subtitle: "650-750", value: "good" },
    { label: "Very Good", subtitle: "750-800", value: "veryGood" },
    { label: "Excellent", subtitle: "800+", value: "excellent" },
  ];

  const scoreLabel = (value) =>
    _.find(creditScoreOptions, { value }).label.toLowerCase();

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateBuyingPower();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateBuyingPower]);

  return (
    <>
      <Form>
        <h5>
          I have <strong className="bordered">{currency(data.savings)}</strong>{" "}
          saved for down payment.
        </h5>

        <div className="pt-3 pb-5">
          <Slider
            min={0}
            max={50000}
            marks={{
              0: "$0",
              50000: "$50K+",
            }}
            value={data.savings || 0}
            step={1000}
            onChange={(value) => setData({ savings: parseFloat(value) })}
            defaultValue={20}
          />
        </div>

        <h5>
          My annual household income is about
          <br />{" "}
          <strong className="bordered">
            {currency(data.annualIncome)}
          </strong>{" "}
          before tax.
        </h5>

        <div className="pt-3 pb-5">
          <Slider
            min={0}
            max={150000}
            marks={{
              0: "$0",
              50000: "$50K",
              100000: "$100K",
              150000: "$150K+",
            }}
            step={10000}
            value={data.annualIncome || 0}
            onChange={(value) => setData({ annualIncome: parseFloat(value) })}
          />
        </div>

        <h5>
          My credit score is <strong>{scoreLabel(data.creditScore)}.</strong>
        </h5>

        <SelectCards
          options={creditScoreOptions}
          data={data}
          field="creditScore"
          setData={setData}
          small
        />
      </Form>
      <BackNext
        mode="Buyer"
        stepIndex={stepIndex}
        onNext={updateBuyingPower}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
