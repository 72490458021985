import React from "react";
import { Link } from "react-router-dom";
import { Nav, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default () => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Nav.Link className="px-3" as={Link} to="/">
        {t("menu.members")}
      </Nav.Link>
      <Nav.Link className="px-3" as={Link} to="/seller">
        {t("menu.sellers")}
      </Nav.Link>
      <Nav.Link className="px-3" as={Link} to="/about-us">
        {t("menu.about")}
      </Nav.Link>
      <Nav.Link className="px-3" as={Link} to="/faq">
        {t("menu.faq")}
      </Nav.Link>
      <Button className="py-2" as={Link} variant="outline-primary" to="/?login">
        {t("menu.login")}
      </Button>
      <Button
        className="py-2 ml-1"
        as={Link}
        variant="outline-secondary"
        to="/?signup"
      >
        {t("menu.signup")}
      </Button>
    </React.Fragment>
  );
};
