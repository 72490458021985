import React, { useState, useEffect } from "react";
import { Redirect, Link } from "react-router-dom";
import { Form, Button, Alert, Spinner, InputGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useLogin from "../hooks/queries/useLogin";

export default ({
  onBack,
  email,
  mode,
  redirect,
  message,
  altText,
  onNext,
  onHide,
  showPassModal,
}) => {
  const { t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [password, setPassword] = useState("");
  const [defaultEmail, setDefaultEmail] = useState(email);
  const [loginEmail, setLoginEmail] = useState(email);
  const [login, loading, error] = useLogin(() => {
    setLoggedIn(true);
  });

  useEffect(() => {
    if (defaultEmail !== email) {
      setDefaultEmail(email);
      setLoginEmail(email);
    }
  }, [defaultEmail, email]);

  if (loggedIn) {
    if (onNext) onNext();
    else if (redirect) return <Redirect to={redirect} />;
  }

  const altMode = error?.graphQLErrors[0]?.extensions?.altMode;
  const additionalErrorMsg = altMode &&
    <>
      <button className="simple-link" onClick={() => login(loginEmail, password, altMode)}>
        Click here
      </button>
      to login you as a {altMode.replace(/member/g, "buyer")}.
    </>;

  return (
    <>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          login(loginEmail, password, mode);
        }}
        className="login-form"
      >
        {message && <Alert variant="warning">{message}</Alert>}

        <Form.Group controlId="formBasicEmail" className="single-control">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <svg
                  width="13"
                  height="14"
                  viewBox="0 0 13 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M9.21491 4.07933C9.21491 4.74237 8.95151 5.37825 8.48268 5.84709C8.01384 6.31594 7.37795 6.57933 6.71491 6.57933C6.05187 6.57933 5.41599 6.31594 4.94715 5.84709C4.4783 5.37825 4.21491 4.74237 4.21491 4.07933C4.21491 3.41629 4.4783 2.7804 4.94715 2.31156C5.41599 1.84272 6.05187 1.57933 6.71491 1.57933C7.37795 1.57933 8.01384 1.84272 8.48268 2.31156C8.95151 2.7804 9.21491 3.41629 9.21491 4.07933ZM9.27591 7.15233C9.90721 6.62597 10.361 5.91791 10.5757 5.12447C10.7903 4.33102 10.7552 3.49073 10.4753 2.71791C10.1954 1.94509 9.68421 1.27726 9.01131 0.805268C8.33836 0.333288 7.53636 0.0800781 6.71441 0.0800781C5.89246 0.0800781 5.09046 0.333288 4.41753 0.805268C3.7446 1.27726 3.2334 1.94509 2.9535 2.71791C2.67359 3.49073 2.63855 4.33102 2.85316 5.12447C3.06777 5.91791 3.5216 6.62597 4.15291 7.15233C3.1685 7.61773 2.32918 8.34208 1.72481 9.24783C1.12044 10.1536 0.773767 11.2066 0.721907 12.2943C0.717087 12.4903 0.789187 12.6804 0.922787 12.8238C1.05638 12.9673 1.24084 13.0528 1.43668 13.0619C1.63251 13.0711 1.82414 13.0032 1.97053 12.8728C2.11692 12.7424 2.20644 12.5599 2.21991 12.3643C2.27443 11.2088 2.77181 10.1187 3.60882 9.32033C4.44582 8.52186 5.55815 8.0764 6.71491 8.0764C7.87167 8.0764 8.98401 8.52186 9.82101 9.32033C10.658 10.1187 11.1554 11.2088 11.2099 12.3643C11.2124 12.4642 11.2347 12.5625 11.2757 12.6536C11.3167 12.7447 11.3755 12.8267 11.4486 12.8947C11.5217 12.9628 11.6077 13.0156 11.7014 13.05C11.7952 13.0844 11.8949 13.0997 11.9947 13.095C12.0945 13.0904 12.1923 13.0658 12.2825 13.0228C12.3726 12.9798 12.4533 12.9193 12.5197 12.8447C12.5862 12.7701 12.637 12.683 12.6693 12.5885C12.7017 12.494 12.7148 12.3939 12.7079 12.2943C12.6559 11.2065 12.3091 10.1534 11.7046 9.24763C11.1 8.34188 10.2605 7.61759 9.27591 7.15233Z"
                    fill="#053861"
                  />
                </svg>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              type="email"
              placeholder="Enter email"
              value={loginEmail}
              onChange={(e) => setLoginEmail(e.target.value)}
              className="single-control"
            />
          </InputGroup>
        </Form.Group>

        <Form.Group controlId="formBasicPassword" className="single-control">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text>
                <svg
                  width="13"
                  height="16"
                  viewBox="0 0 13 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.71582 4.9707V6.9707H2.46582C2.00169 6.9707 1.55657 7.15507 1.22838 7.48326C0.90019 7.81145 0.71582 8.25657 0.71582 8.7207V14.2207C0.71582 15.1867 1.49982 15.9707 2.46582 15.9707H10.9658C11.4299 15.9707 11.875 15.7863 12.2032 15.4581C12.5314 15.1299 12.7158 14.6848 12.7158 14.2207V8.7207C12.7158 8.25657 12.5314 7.81145 12.2032 7.48326C11.875 7.15507 11.4299 6.9707 10.9658 6.9707H10.7158V4.9707C10.7158 3.90983 10.2944 2.89242 9.54422 2.14227C8.79412 1.39213 7.77669 0.970703 6.71582 0.970703C5.65495 0.970703 4.63754 1.39213 3.88739 2.14227C3.13725 2.89242 2.71582 3.90983 2.71582 4.9707ZM9.21582 6.9707V4.9707C9.21582 4.30766 8.95242 3.67177 8.48359 3.20293C8.01475 2.73409 7.37886 2.4707 6.71582 2.4707C6.05278 2.4707 5.41689 2.73409 4.94805 3.20293C4.47921 3.67177 4.21582 4.30766 4.21582 4.9707V6.9707H9.21582ZM10.7158 8.4707H10.9658C11.0321 8.4707 11.0957 8.49704 11.1426 8.54392C11.1895 8.59081 11.2158 8.6544 11.2158 8.7207V14.2207C11.2158 14.287 11.1895 14.3506 11.1426 14.3975C11.0957 14.4444 11.0321 14.4707 10.9658 14.4707H2.46582C2.39952 14.4707 2.33593 14.4444 2.28904 14.3975C2.24216 14.3506 2.21582 14.287 2.21582 14.2207V8.7207C2.21582 8.6544 2.24216 8.59081 2.28904 8.54392C2.33593 8.49704 2.39952 8.4707 2.46582 8.4707H10.7158Z"
                    fill="#053861"
                  />
                </svg>
              </InputGroup.Text>
            </InputGroup.Prepend>
            <Form.Control
              disabled={loading}
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              className="single-control"
            />
          </InputGroup>
        </Form.Group>

        {error &&
          error.graphQLErrors.map((e, i) => (
            <div key={i} className="text-danger text-center mt-1 mb-3">
              {e.message}
              {additionalErrorMsg}
            </div>
          ))}

        {showPassModal ? (
          <button
            className="simple-link forgot-password"
            onClick={() => {
              onHide();
              showPassModal();
            }}
          >
            {t("login.forgot_password")}
          </button>
        ) : (
          <Link to="?reset" className="simple-link forgot-password">
            {t("login.forgot_password")}
          </Link>
        )}

        <div className="modal-controls">
          <Button
            variant="dojobrand"
            type="submit"
            className="text-light single-control"
          >
            {loading && (
              <Spinner animation="border" size="sm" variant="light" />
            )}{" "}
            {altText || t("login.login")}
          </Button>
        </div>
      </Form>
    </>
  );
};
