import SignUp from '../../pages/Seller/SignUp';
import AddProperty from '../../pages/Seller/AddProperty';
import SellerDashboard from '../../pages/Seller/SellerDashboard';
import SellerProperties from '../../pages/Seller/SellerProperties';
import SellerProperty from '../../pages/Seller/SellerProperty';


export default [
{
  path: "/seller/login",
  component: SignUp },
{
  path: "/seller/signup",
  component: SignUp
},


{
  path: "/seller/property/:id",
  component: AddProperty,
  private: true },
{
  path: "/seller/properties/:id/offers/:aid",
  component: SellerProperty,
  private: true },
{
  path: "/seller/properties/:id",
  component: SellerProperty,
  private: true },
{
  path: "/seller/properties",
  component: SellerProperties,
  private: true },
{
  path: "/seller/dashboard",
  component: SellerDashboard,
  private: true }];