import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";
import _ from "lodash";

const PROPERTIES_SEARCH = gql `
query AllProperties(
    $filters: PropertyFiltersInput = {},
    $search: String,
    $page: Int,
  ) {
    search(filters: $filters, q: $search, page: $page) {
    page
    limit
    pages
    total

    properties {
      _id

      created_at

      price
      rent
      equity_deposit

      mls
      source

      address
      city
      state
      zip

      beds
      baths
      sqft

      pet_allowance
      available_from

      categories {
        _id
        thumbnail
      }

      location {
        coordinates
      }

      owner {
        _id
        firstname
        lastname
        verified
      }

      images(max: 1) {
        _id
        extension
        fileid
        mimetype
        filename
        url
      }

      under_contract
      published
      archived
      verified

      daysOnMarket
    }
  }

  propertyStats {
    pending
    live
    under_contract
    closed
    archived
  }
}`;


export default ((params, onCompleted, onError) => {
  const {
    loading,
    data,
    error
  } = useQuery(PROPERTIES_SEARCH, {
    variables: _.pickBy(params, _.identity),

    fetchPolicy: "network-only",
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err)
  });

  return [data && data.search, data && data.propertyStats, loading, error];
});