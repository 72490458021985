import React from "react";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";

import useBrokerage from "../hooks/mutations/useBrokerage";

const customStyles = {
  control: (provided, state) => {
    const border = "1px solid #CCE6FF";
    return { ...provided, border };
  },
};

export default ({ brokerage, setBrokerage }) => {
  const [brokerages, createBrokerage, loading, error] = useBrokerage(
    ({ createBrokerage }) => {
      setBrokerage(createBrokerage._id);
    }
  );

  const options = (brokerages || []).map(({ _id, title }) => ({
    label: title,
    value: _id,
  }));
  const selectedBrokerage = _.find(options, { value: brokerage });

  return (
    <CreatableSelect
      disabled={loading}
      styles={customStyles}
      options={options}
      value={selectedBrokerage}
      onChange={(item) => {
        setBrokerage(item.value);
      }}
      onCreateOption={(value) => {
        createBrokerage({ title: value });
      }}
    ></CreatableSelect>
  );
};
