import React from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

import useCurrency from "../../../components/hooks/helpers/useCurrency";
import InfoSlider from "../../../components/carousel/InfoSlider";

export default ({ listing, details }) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  return (
    <div className="mt-3">
      <h6>{t("property.propertyDetails")}</h6>

      <InfoSlider>
        <div>
          <ul className="FeatureList two-column">
            <li className="labelled">
              <div>{t("property.details.beds")}</div>
              <div>{listing.beds}</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.baths")}</div>
              <div>{listing.baths}</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.totalFurnished")}</div>
              <div>
                {t("property.details.totalFurnishedValue", {
                  area: listing.sqft.toLocaleString(),
                })}
              </div>
            </li>
            <li className="labelled">
              <div>{t("property.details.stories")}</div>
              <div>
                {details && details.features && details.features.floorCount
                  ? details.features.floorCount
                  : "—"}
              </div>
            </li>
            <li className="labelled">
              <div>{t("property.details.lotSize")}</div>
              <div>
                {details && details.lotSize
                  ? t("property.details.lotSizeValue", {
                      area: details.lotSize.sqft.toLocaleString(),
                    })
                  : "—"}
              </div>
            </li>
            <li className="labelled">
              <div>{t("property.details.year")}</div>
              <div>{listing.year}</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.renovated")}</div>
              <div>—</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.county")}</div>
              <div>{details ? `${details.county} County` : "—"}</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.apn")}</div>
              <div>{details ? details.apn : "—"}</div>
            </li>
          </ul>
        </div>
        <div>
          <p>{t("property.details.courtesyOf")}</p>
          <ul className="FeatureList two-column">
            {listing.agent && (
              <React.Fragment>
                <li className="labelled">
                  <div>{t("property.details.agentName")}</div>
                  <div>{listing.agent.name}</div>
                </li>
                <li className="labelled">
                  <div>{t("property.details.brokerage")}</div>
                  <div>
                    {listing.agent.brokerage
                      ? listing.agent.brokerage.title
                      : ""}
                  </div>
                </li>
              </React.Fragment>
            )}
            <li className="labelled">
              <div>{t("property.details.mls")}</div>
              <div>{listing.mls}</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.listingPrice")}</div>
              <div>{currency(listing.price)}</div>
            </li>
            <li className="labelled">
              <div>{t("property.details.daysOnDojo")}</div>
              <div>
                {t("property.details.daysOnDojoValue", {
                  days: moment
                    .duration(moment().diff(moment(listing.created_at)))
                    .asDays()
                    .toFixed(),
                })}
              </div>
            </li>
          </ul>
          {listing.source === "HomeJunction" && (
            <p className="mt-2 mb-0">
              Copyright &copy; {moment().format("LL")} Columbus and Central Ohio
              Regional MLS. All Rights Reserved.
            </p>
          )}
        </div>
      </InfoSlider>
    </div>
  );
};
