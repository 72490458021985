import React from "react";

export default ({
  user,
  above,
  below,
  source,
  onCompleted,
  as: Component,
  children,
  showProfileModal,
  ...props
}) => {
  return(
    <React.Fragment>
      {above}
      <Component
        {...props}
        onClick={showProfileModal}
      >
        {children}
      </Component>
      {below}
    </React.Fragment>
  )
};
