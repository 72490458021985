import {
  useState
} from "react";
import gql from "graphql-tag";
import {
  useQuery,
} from "@apollo/react-hooks";
import _ from "lodash";

const QUERY = gql `
  query AvailableZip($location: String) {
    availableArea(location: $location)
  }
`;


export default () => {
  const {
    loading,
    error,
    refetch
  } = useQuery(QUERY);

  return async (location) => {
    const { data } = await refetch({ location });
    return data ? data.availableArea : false;
  };
};
