import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import qs from "qs";

import {
  Row,
  Col,
  Button,
  ButtonGroup,
  ButtonToolbar,
  InputGroup,
  Form,
  Badge,
  Pagination,
} from "react-bootstrap";

import PropertyPanel from "../../components/admin/PropertyPanel";
import AppMain from "../AppMain";
import useAdminPropertySearch from "../../components/hooks/queries/useAdminPropertySearch";
import Loading from "../../components/general/Loading";
import PropertyFilter from "../../components/property/PropertyFilter";

const PropertyStatus = [
  {
    title: "Pending Verification",
    status: "pending",
    filters: {
      archived: false,
      verified: false,
      under_contract: undefined,
      closed: undefined,
    },
  },

  {
    title: "Live",
    status: "live",
    filters: {
      archived: false,
      verified: true,
      under_contract: false,
      closed: false,
    },
  },

  {
    title: "Under Contract",
    status: "under_contact",
    filters: {
      archived: false,
      verified: true,
      under_contract: true,
      closed: false,
    },
  },

  {
    title: "Closed",
    status: "closed",
    filters: {
      archived: undefined,
      verified: undefined,
      closed: true,
      under_contract: undefined,
    },
  },

  {
    title: "Archived",
    status: "archived",
    filters: {
      archived: true,
      closed: false,
    },
  },
];

const filterMatch = (filterFull, filterSub) =>
  _.every(filterSub, (value, key) => filterFull[key] === value);

export default ({ user }) => {
  const loc = useLocation();
  const page = parseInt(qs.parse(loc.search.substring(1)).page || 1, 10);
  const owner = qs.parse(loc.search.substring(1)).owner;

  const [filters, setFilters] = useState({
    archived: false,
    verified: false,
    sortBy: "-created_at",
  });

  const setFilter = (filter) => {
    //workaround to hide filter popover
    setFilters({ ...filters, ...filter });
    document.body.click();
  };

  const [search, setSearch] = useState("");

  const [listings, stats, loading] = useAdminPropertySearch({
    filters,
    page,
    owner,
    search: search.trim(),
  });

  return (
    <AppMain fluid>
      <Row className="mt-4">
        <Col xs={12}>
          <h1>Properties</h1>
        </Col>

        <Col xs={12} className="mb-2">
          <ButtonToolbar>
            <ButtonGroup size="sm" className="mr-2">
              {PropertyStatus.map((o) => (
                <Button
                  className="px-4"
                  variant={
                    filterMatch(filters, o.filters)
                      ? "secondary"
                      : "outline-secondary"
                  }
                  onClick={(e) => setFilters({ ...filters, ...o.filters })}
                >
                  {o.title}
                  <Badge size="sm">{stats ? stats[o.status] : 0}</Badge>
                </Button>
              ))}
            </ButtonGroup>

            <InputGroup className="ml-2" variant="secondary">
              <Form.Control
                type="text"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              ></Form.Control>
              <InputGroup.Append>
                <Button variant="secondary" onClick={(e) => setSearch("")}>
                  Clear
                </Button>
              </InputGroup.Append>
            </InputGroup>

            <Form.Group className="px-3 my-0">
              <Form.Control
                as="select"
                value={filters.sortBy}
                onChange={(e) =>
                  setFilters({ ...filters, sortBy: e.target.value })
                }
              >
                <option value="created_at">Sort By Creation Date Asc</option>
                <option value="-created_at">Sort By Creation Date Desc</option>
                <option value="price">Sort By Price Asc</option>
                <option value="-price">Sort By Price Desc</option>
                <option value="rent">Sort By Rent Asc</option>
                <option value="-rent">Sort By Rent Desc</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="px-3 my-0">
              <Form.Check
                label="Show Non-branded"
                checked={!filters.branded}
                onChange={(e) =>
                  setFilters({
                    ...filters,
                    branded: e.target.checked ? undefined : true,
                  })
                }
              />
            </Form.Group>
          </ButtonToolbar>
        </Col>
        {loading || !listings ? (
          <Loading />
        ) : (
          <React.Fragment>
            <Col xs={12} md={6} lg={8}>
              <h6>{listings.total} properties found</h6>

              {listings.properties.map((p) => (
                <PropertyPanel user={user} property={p} />
              ))}

              {listings.pages === 0 && (
                <h5 className="text-center my-5">
                  There are no properties matching your search criteria
                </h5>
              )}
              {listings.pages > 1 ? (
                <Row>
                  <Col
                    xs={12}
                    className="text-center my-5"
                    style={{ display: "inline-flex" }}
                  >
                    <Pagination style={{ margin: "0 auto" }}>
                      <Pagination.First
                        as={Link}
                        to="/admin/properties"
                        href="/admin/properties"
                      />

                      {_.range(1, listings.pages + 1).map((pg) => (
                        <Pagination.Item
                          key={pg}
                          active={pg === page}
                          as={Link}
                          href={`/admin/properties?page=${pg}`}
                          to={`/admin/properties?page=${pg}`}
                        >
                          {pg}
                        </Pagination.Item>
                      ))}

                      <Pagination.Last
                        as={Link}
                        href={`/admin/properties?page=${listings.pages}`}
                        to={`/admin/properties?page=${listings.pages}`}
                      />
                    </Pagination>
                  </Col>
                </Row>
              ) : null}
            </Col>
            <Col xs={12} md={6} lg={4}>
              <PropertyFilter
                user={user}
                filter={filters}
                setFilter={setFilter}
              />
            </Col>
          </React.Fragment>
        )}
      </Row>
    </AppMain>
  );
};
