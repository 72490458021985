import React from "react";
import { Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default ({ show, onHide, children, onApprove }) => {
  const { t } = useTranslation();
  return (
    <Modal show={show} onHide={onHide} size="sm" centered>
      <Modal.Body className="text-center px-3 py-3">
        <h5 className="mb-4">{children}</h5>

        <div className="d-flex w-100 text-uppercase">
          <Button
            className="flex-grow-1 mx-1"
            variant="primary"
            onClick={onApprove}
          >
            {t("modal.yes")}
          </Button>
          <Button
            className="flex-grow-1 mx-1"
            variant="outline-primary"
            onClick={onHide}
          >
            {t("modal.no")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
};
