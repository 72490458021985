import React from "react";
import { Link } from "react-router-dom";
import { Row, Col } from "react-bootstrap";

import AppMain from "../AppMain";
import IdentityVerification from "../../components/user/IdentityVerification";
import ExtraDocuments from "../../components/user/ExtraDocuments";

export default (({ user }) => {
  return (
    <AppMain>
      <IdentityVerification user={user}>
        <Row>
          <Col xs={12} className="my-4"></Col>
          <Col xs={12} lg={{ span: 4, offset: 2 }}>
            <Link to="/profile/settings">&larr; BACK</Link>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h1>Documents</h1>
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <hr />
            <h2>Identity Verification</h2>
            <p>
              A valid government ID helps us to ensure that there is a real
              person behind the transaction. It creates a healthy and safe
              environment for our Dojohome community.
            </p>
          </Col>
        </Row>
      </IdentityVerification>

      <ExtraDocuments user={user}>
        <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <hr />
            <h2>Additional Documents</h2>
            <p>
              Documents that you will need when you will be applying to the
              property. For example, <strong>W2</strong>,{" "}
              <strong>Bank Statements</strong>,{" "}
              <strong>Last Credit Report</strong>,{" "}
              <strong>Supporting Documents</strong>. Sellers expect to receive
              these documents. Adding theme will increase your chances to be
              approved.
            </p>
          </Col>
        </Row>
      </ExtraDocuments>
    </AppMain>);

});