import gql from "graphql-tag";
import { useLazyQuery } from "@apollo/react-hooks";

const GET_PRICE_ESTIMATE = gql`
  query GetPriceEstimate($address: String!, $city: String, $state: String, $zip: String) {
    zestimate(address: $address, city: $city, state: $state, zip: $zip) {
      zpid
      rentzestimate {
        amount
      }
    }
  }
`;

export default ((property) => {
  const [getPriceEstimate, { data: { zestimate } = {} } = {}, loading, error] = useLazyQuery(GET_PRICE_ESTIMATE, {
    variables: {
      address: property.address,
      city: property.city,
      state: property.state,
      zip: property.zip
    }
  });

  return [zestimate, getPriceEstimate, loading, error];
});
