import React, { useState, useEffect, useCallback, useRef } from "react";
import { useLocation } from "react-router-dom";
import qs from "qs";
import _ from "lodash";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import AppMain from "../AppMain";
import PropertyCard from "../../components/general/PropertyCard";
import CustomSwitch from "../../components/general/CustomSwitch";
import usePropertySearch from "../../components/hooks/queries/usePropertySearch";
import useCurrentUser from "../../components/hooks/queries/useCurrentUser";
import Loading from "../../components/general/Loading";
import SearchMap from "../../components/general/SearchMap";
import PropertyFilter from "../../components/property/PropertyFilter";
import { ShowMore } from "../../components/icons"; 
import { useWindowDimensions } from "../../tools";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";
import "./PropertySearch.scss";
import ExploreSection from "../../components/sections/ExploreSection";

export default () => {
  const { t } = useTranslation();
  const { height, width } = useWindowDimensions();
  const loc = useLocation();
  const [user] = useCurrentUser();

  const loadMoreRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const activePropertyRef = useRef(null);

  const [location, setLocation] = useState({});
  const [activeFilter, setActiveFilter] = useState({
    verified: true,
    under_contract: false,
    closed: false,
  });
  const [searchBounds, setSearchBounds] = useState(null);
  const [showPrice, setShowPrice] = useState(true);
  const [activeProperty, setActiveProperty] = useState(null);

  const [carouselWidth, setCarouselWidth] = useState(0);
  const [carouselScroll, setCarouselScroll] = useState(0);

  const [search, loading, _error, fetchMore] = usePropertySearch({
    activeFilter,
    searchBounds,
    location,
  });

  useEffect(() => {
    const locationQuery = qs.parse(loc.search.substring(1));
    setLocation(locationQuery);
  }, [loc.search]);

  const loadMore = useCallback(
    (entries) => {
      if (entries[0].isIntersecting && !loading) {
        fetchMore({
          variables: {
            page: search.page + 1,
          },

          updateQuery: (prev, { fetchMoreResult }) => {
            console.log(prev, fetchMoreResult);
            if (!fetchMoreResult) return prev;
            console.log(prev);
            return Object.assign({}, prev, {
              search: {
                ...fetchMoreResult.search,
                properties: [
                  ...prev.search.properties,
                  ...fetchMoreResult.search.properties,
                ],
              },
            });
          },
        });
      }
    },
    [loading, fetchMore, search]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(loadMore, {
      rootMargin: "0px",
      treshold: 0.25,
    });

    if (loadMoreRef && loadMoreRef.current) {
      const current = loadMoreRef.current;
      observer.observe(current);
      return () => observer.unobserve(current);
    }

    return () => {};
  }, [loadMoreRef, loadMore]);

  useEffect(() => {
    scrollContainerRef.current.scrollTo({
      left: _.get(activePropertyRef, "current.offsetLeft"),
      behavior: "smooth",
    });
  }, [activeProperty]);

  useEffect(() => {
    setCarouselWidth(_.get(scrollContainerRef, "current.scrollWidth"));
  }, [search]);

  const setFilter = (filter) => {
    //workaround to hide filter popover
    document.body.click();
    setActiveFilter(filter);
  };

  const handleScroll = useCallback(
    _.debounce(() => setCarouselScroll(scrollContainerRef.current.scrollLeft), 100),
    []
  );

  const cardsToScroll = 3;

  return (
    <AppMain fluid={true}>
      <Container fluid className="mt-3" style={{ position: "relative" }}>
        <Row>
          <Col xs={12} md={6} lg={7}>
            <div className="p-3 price-rent-switch">
              <CustomSwitch
                checked={!showPrice}
                onChange={(e) => setShowPrice(!e.target.checked)}
                checkedLabel={t("filters.monthlyRent")}
                uncheckedLabel={t("filters.purchasePrice")}
              />
            </div>

            <SearchMap
              showPrice={showPrice}
              listings={search && search.properties}
              height={height / 2.5}
              user={user}
              location={location}
              setSearchBounds={_.debounce(setSearchBounds, 1000)}
              {...{ activeProperty, setActiveProperty }}
            />
          </Col>

          <Col xs={12} md={6} lg={5}>
            <PropertyFilter
              user={user}
              filter={activeFilter}
              setFilter={setFilter}
            />
          </Col>

          <Col xs={12}>
            {search && (
              <p className="mt-2 mb-0">
                <span>
                  {t("filters.showing")}{" "}
                  {t("property.propertyWithCount", {
                    count: search.total,
                    current: search.properties.length,
                  })}
                </span>{" "}
                {_.filter(activeFilter).length > 0 && (
                  <Button size="sm" onClick={() => setActiveFilter({})}>
                    {t("filters.reset")}
                  </Button>
                )}
              </p>
            )}
            <div className="position-relative">
              <Row
                className="pb-5 flex-grow-1 mt-2 flex-row flex-nowrap h-scroll mb-2"
                style={{ overflowX: "scroll" }}
                ref={scrollContainerRef}
                onScroll={handleScroll}
              >
                {loading ? (
                  <Loading />
                ) : !search ? null : (
                  search.properties.map((listing) => {
                    const selected = listing._id === _.get(activeProperty, "_id");
                    return (
                      <Col
                        ref={selected ? activePropertyRef : null}
                        key={listing._id}
                        className="col-xs-12-narrow col-sm-6-narrow col-lg-4-narrow"
                      >
                        <PropertyCard
                          user={user}
                          property={listing}
                          selected={selected}
                          proportion={2 / 5}
                          own={user && user._id === listing.owner._id}
                          link={`/property/${listing._id}`}
                          small
                        />
                      </Col>
                    );
                  })
                )}
                {search && search.properties.length < search.total ? (
                  <div ref={loadMoreRef} className="text-center">
                    Loading...
                  </div>
                ) : null}
              </Row>
              {carouselWidth > width && carouselWidth - carouselScroll > width && (
                <button
                  className="btn-show-more"
                  onClick={() => {
                    const cardWidth = scrollContainerRef.current.children[0].offsetWidth;
                    scrollContainerRef.current.scrollTo({
                      left: (Math.ceil(carouselScroll / cardWidth) * cardWidth) + cardWidth * cardsToScroll,
                      behavior: "smooth",
                    });
                  }}
                >
                  <span>
                    View more
                  </span>
                  <ShowMore />
                </button>
              )}
            </div>
          </Col>
        </Row>

        <ExploreSection user={user} />
      </Container>
    </AppMain>
  );
};
