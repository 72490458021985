import { useQuery } from "@apollo/react-hooks";
import gql from 'graphql-tag';

const QUERY = gql`
query MyFavorites {
  favorites {
    listing {
      _id
      address
      city
      state

      price
      rent

      beds
      baths
      sqft

      under_contract
      archived
      favorited

      images {
        extension
        fileid
        mimetype
        filename
        url
      }
    }
  }
}
`


export default (() => {
  const {
    loading,
    data,
    error
  } = useQuery(QUERY);

  return [data && data.favorites, loading, error];
});
