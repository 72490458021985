import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { useTranslation } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import AppMain from "../AppMain";
import OfferSelector from "../../components/dashboard/OfferSelector";
import InfoTab from "../../components/dashboard/InfoTab";
import DashboardTab from "../../components/dashboard/DashboardTab";
import PayoutTab from "../../components/dashboard/PayoutTab";

const ACTIVE_OFFERS = loader("./GetMyOffers.graphql");
const UPDATE_OFFER = loader("./UpdateOffer.graphql");

export default ({ user }) => {
  const { t } = useTranslation();
  const [key, setKey] = useState("dashboard");
  const [activeOfferId, setActiveOfferId] = useState(undefined);

  const { data, loading } = useQuery(ACTIVE_OFFERS, {
    onCompleted: (data) => {
      if (data.mySellerOffers.filter(offer => offer.listing).length > 0) setActiveOfferId(0);
    },
  });

  const mySellerOffers = data ? data.mySellerOffers.filter(offer => offer.listing) : []

  const activeOffer = () =>
    activeOfferId !== undefined ? mySellerOffers[activeOfferId] : null;

  const [updateOffer] = useMutation(UPDATE_OFFER);

  const setSelectedPayoutMethod = (payoutMethod) =>
    updateOffer({
      variables: {
        offer: {
          _id: activeOffer()._id,
          default_payout: payoutMethod,
        },
      },

      refetchQueries: () => ["GetMyOffers"],
    });

  return (
    <AppMain>
      <Row>
        {!loading && activeOffer() ? (
          <React.Fragment>
            <Col xs={12} className="mt-5">
              <OfferSelector
                user={user}
                offers={mySellerOffers}
                activeOffer={activeOffer()}
                setActiveOfferId={setActiveOfferId}
              />
            </Col>

            <Col xs={12} className="mt-5">
              <Tabs
                fill
                id="dashboard-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="info" title="Info">
                  <InfoTab user={user} activeOffer={activeOffer()} />
                </Tab>
                <Tab eventKey="dashboard" title="Dashboard">
                  <DashboardTab
                    user={user}
                    activeOffer={activeOffer()}
                  ></DashboardTab>
                </Tab>
                <Tab eventKey="payouts" title="Payouts">
                  <PayoutTab
                    user={user}
                    activeOffer={activeOffer()}
                    setPayoutMethod={setSelectedPayoutMethod}
                  ></PayoutTab>
                </Tab>
              </Tabs>
            </Col>
          </React.Fragment>
        ) : (
          <div className="text-center mt-5">
            <h4>{t("seller.dashboard.noOffers.title")}</h4>
            <h5 className="mt-5">{t("seller.dashboard.noOffers.text")}</h5>
          </div>
        )}
      </Row>
    </AppMain>
  );
};
