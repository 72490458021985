import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

export default () => {
  const { t } = useTranslation();

  return (
    <div>
      <Row>
        <Col xs={12} className="my-5 py-5 text-center">
          <h2 className="my-4">Help Center</h2>
          <Button as={Link} to="/explore" size="lg">
            {t("home.browse_homes")}
          </Button>
        </Col>
      </Row>
    </div>
  );
};
