import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const APPLY_RESET_PASSWORD = gql`
  mutation ApplyResetPassword($code: String!, $password: String!, $passwordRepeat: String!) {
    applyResetPassword(code: $code, password: $password, passwordRepeat: $passwordRepeat) {
      message
    }
  }
`;

export default ((code, password, passwordRepeat, onCompleted, onError) => {
  const [applyResetPassword, {
    data,
    loading,
    error
  }] = useMutation(APPLY_RESET_PASSWORD, {
    variables: {
      code,
      password,
      passwordRepeat
    },
    refetchQueries: () => ["CurrentUser"],
    onCompleted: resetData => onCompleted && onCompleted(resetData),
    onError: resetError => onError && onError(resetError)
  });

  return [applyResetPassword, data, loading, error];
});