import React, { useRef, useState, useEffect } from "react";
import { LightgalleryItem } from "react-lightgallery";

import Slick from "react-slick";
import { makeImageUrl } from "../../../tools";
import { Expand, VirtualTour } from "../../../components/icons";
import { Row } from "react-bootstrap";
import { Col } from "react-bootstrap";

const defaultSlackSettings = {
  infinite: true,
  pauseOnHover: true,
  focusOnSelect: true,
};

const bottomSlickSettings = {
  ...defaultSlackSettings,
  slidesToShow: 4,
  slidesToScroll: 1,
  arrows: false,
  // lazyLoad: true,

  responsive: [
    {
      breakpoint: 993,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
  ],
};

const Image = ({ img, className = "", children }) => {
  return (
    <div className={className}>
      <div
        className="back-image"
        style={{
          backgroundImage: `url(${makeImageUrl(img)})`,
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default React.forwardRef(({ listing }, ref) => {
  const sliderBig = useRef(null);
  const sliderMulti = useRef(null);
  const [sliders, setSliders] = useState({
    sliderBig: null,
    sliderMulti: null,
  });

  useEffect(() => {
    setSliders({
      navBig: sliderBig.current,
      navMulti: sliderMulti.current,
    });
  }, []);

  return (
    <div ref={ref} className="ListingCarouselContainer mb-3">
      <Slick
        ref={(slider) => (sliderBig.current = slider)}
        asNavFor={sliders.navMulti}
        {...defaultSlackSettings}
      >
        {listing.images.map((img) => (
          <Image id={img._id} key={img._id} className="LargeSlide" img={img}>
            <div className="Actions">
              {listing.extendedInfo && listing.extendedInfo.tourURL && (
                <a
                  className="Tour"
                  href={listing.extendedInfo.tourURL}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VirtualTour /> Virtual Tour
                </a>
              )}

              <div className="Expand">
                <LightgalleryItem group="property" src={makeImageUrl(img)}>
                  <Expand height={16} />
                </LightgalleryItem>
              </div>
            </div>
          </Image>
        ))}
      </Slick>
      {listing.images.length > 3 ? (
        <div className="mt-3 SliderSlides">
          <Slick
            ref={(slider) => (sliderMulti.current = slider)}
            asNavFor={sliders.navBig}
            {...bottomSlickSettings}
          >
            {listing.images.map((img) => (
              <div className="px-1 mx-0" key={`t-${img._id}`}>
                <Image img={img} className="ThumbnailSlide" />
              </div>
            ))}
          </Slick>
        </div>
      ) : (
        <Row className="mt-3 SliderSlides">
          {listing.images.map((img, i) => (
            <Col
              className="px-1 mx-0"
              xs={2}
              md={3}
              onClick={() => sliderBig.current.slickGoTo(i)}
            >
              <Image img={img} className="ThumbnailSlide" />
            </Col>
          ))}
        </Row>
      )}
    </div>
  );
});
