import React from "react";
import { Form } from "react-bootstrap";

import "./CustomSwitch.scss";

export default ({ checked, onChange, checkedLabel, uncheckedLabel }) => {
  return (
    <Form className="large-custom-switch">
      <Form.Check
        id="price-rent-switch"
        type="switch"
        variant="primary"
        className={checked ? "text-white" : "text-secondary"}
        label={
          <span
            className={`${
              checked ? "ml-n3" : ""
            } d-block my-2 position-relative`}
          >
            {checked ? checkedLabel : uncheckedLabel}
          </span>
        }
        {...{
          checked,
          onChange,
        }}
      />
    </Form>
  );
};
