import React, { useState, useEffect } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import { Card, ButtonGroup, Button } from "react-bootstrap";
import qs from "qs";
import _ from "lodash";
import { CSSTransitionGroup } from "react-transition-group";
import { useTranslation } from "react-i18next";

import EmailValidation from "./EmailValidation";
import LoginForm from "./LoginForm";
import RequestPassResetModal from "./RequestPassResetModal";
import ApplyPassResetModal from "./ApplyPassResetModal";
import useValidateEmail from "../hooks/queries/useValidateEmail";

const cardTitle = (mode, step) => {
  let type = "";
  switch (mode) {
    case "member":
      type = "Buyer";
      break;
    case "seller":
      type = "Seller";
      break;
    case "admin":
      type = "Support";
      break;
    default:
      return "Unexpected Input";
  }

  switch (step) {
    case "email-login":
      return `Log In as ${type}`;
    case "email-signup":
      return `Sign Up as ${type}`;
    case "login":
      return `Log In as ${type}`;
    case "register":
      return `Sign Up as ${type}`;
    default:
      return "Unexpected Input";
  }
};

export default ({
  mode,
  user,
  defaultEmail,
  registrationComponent: RegistrationForm,
  redirect,
}) => {
  const { t } = useTranslation();
  const loc = useLocation();
  const history = useHistory();
  const loginMode = loc.pathname.split("/")[2];
  const params = qs.parse(loc.search.substring(1));
  const [originalEmail, setOriginalEmail] = useState(
    (params.email && params.email.replace(" ", "+")) || defaultEmail || ""
  );
  const [otherModes, setOtherModes] = useState([]);

  const [validateEmail, loading] = useValidateEmail((exists) => {
    const next = _.includes(exists, mode) ? "login" : "register";
    console.log(exists, [mode]);
    setOtherModes(_.difference(exists, [mode]));
    setStep(next);
    setOriginalEmail("");
  });

  const [step, setStep] = useState(`email-${loginMode}`);
  const [email, setEmail] = useState(originalEmail);

  const hideModal = (dialog) => history.push(`?${qs.stringify(_.omit(params, dialog))}`);

  useEffect(() => {
    if (step.startsWith("email") && !loading && originalEmail) {
      validateEmail(originalEmail);
    }
  }, [params.email, loading, originalEmail, step, validateEmail]);

  const reset = () => {
    setStep(`email-${loginMode}`);
    //setEmail("");
  };

  return (
    <>
      <RequestPassResetModal
        show={params.reset !== undefined}
        mode={mode}
        onHide={() => hideModal('reset')}
      />

      <ApplyPassResetModal
        show={params.code !== undefined}
        code={params.code}
        onHide={() => hideModal('code')}
      />

      <Card>
        <Card.Title className="pt-4 px-3">
          <h2 className="text-center">{cardTitle(mode, step)}</h2>
        </Card.Title>

        <Card.Body className="overflow-hidden">
          {mode !== "admin" && step.startsWith("email") && (
            <div className="text-center">
              <ButtonGroup className="mx-auto mb-3 w-100">
                <Button
                  variant={mode === "member" ? "primary" : "outline-primary"}
                  as={Link}
                  to={`/member/${loginMode}`}
                >
                  {t("login.member")}
                </Button>
                <Button
                  variant={mode === "seller" ? "primary" : "outline-primary"}
                  as={Link}
                  to={`/seller/${loginMode}`}
                >
                  {t("login.seller")}
                </Button>
              </ButtonGroup>
            </div>
          )}

          <div style={{ position: "relative" }}>
            <CSSTransitionGroup
              transitionName={
                step.startsWith("email") ? "pageSliderRight" : "pageSliderLeft"
              }
              transitionEnterTimeout={500}
              transitionLeaveTimeout={500}
            >
              {step.startsWith("email") && (
                <EmailValidation
                  originalEmail={originalEmail}
                  email={email}
                  setEmail={setEmail}
                  onNext={(exists, other) => {
                    setOriginalEmail(false);
                    setOtherModes(other);
                    setStep(exists || !RegistrationForm ? "login" : "register");
                  }}
                  mode={mode}
                />
              )}

              {step === "login" && (
                <LoginForm
                  onBack={reset}
                  email={email}
                  mode={mode}
                  redirect={redirect}
                  message={params.message}
                />
              )}

              {RegistrationForm && step === "register" && (
                <React.Fragment>
                  <RegistrationForm
                    loggedUser={user}
                    email={email}
                    onBack={reset}
                    redirect={redirect}
                  />

                  {otherModes.length > 0 && (
                    <div className="text-center my-2">
                      &mdash; or &mdash;
                      <div>
                        {otherModes.map((om) => (
                          <Button
                            as={Link}
                            to={`/${om}/login?email=${email}`}
                            size="sm"
                            variant="outline-primary"
                          >
                            {t("login.login_as", { om })}
                          </Button>
                        ))}
                      </div>
                    </div>
                  )}
                </React.Fragment>
              )}
            </CSSTransitionGroup>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};
