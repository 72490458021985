import React, { useState } from "react";
import currencyFormatter from "currency-formatter";
import { Badge, Card } from "react-bootstrap";
import classNames from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import { makeImageUrl } from "../../tools";
import AuthorizationModal from "../auth/AuthorizationModal";
import useUpdateList from "../hooks/mutations/useUpdateList";
import {
  Bed,
  BathTub,
  Heart,
  HeartOutline,
  ChevronRight,
  ChevronLeft,
} from "../icons";

import "./PropertyCard.scss";
import SlickSlider from "../carousel/SlickSlider";

export default ({
  user,
  property,
  own,
  status,
  link,
  children,
  proportion = 3 / 7,
  small,
  selected,
  hideBadges,
}) => {
  const { t } = useTranslation();
  const [showLogin, setShowLogin] = useState(false);
  const openLink = link || `/seller/properties/${property._id}`;

  const [updateFavorite] = useUpdateList(property._id, "favorite", [
    "GetSingleListing",
    "MyFavorites",
    "PropertySearch",
  ]);

  const switchFavorite = () =>
    updateFavorite({
      variables: {
        action: property.favorited ? "remove" : "add",
      },
    });

  const category = _.get(property, "categories[0].thumbnail");

  return (
    <React.Fragment>
      <Card
        as="a"
        href={openLink}
        target="_blank"
        className={classNames("PropertyCard", "flex-grow-1", {
          disabled: property.archived,
          selected,
        })}
      >
        <div className="CarouselContainer">
          <SlickSlider arrows infinite>
            {property.images.map((img) => (
              <div
                key={img._id}
                className="SlideContainer"
                style={{ paddingTop: `${proportion * 100}%` }}
              >
                <div
                  style={{
                    backgroundImage: `url(${makeImageUrl(img)})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center center",
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                  }}
                ></div>
              </div>
            ))}
          </SlickSlider>

          {!hideBadges && (
            <div
              className="p-3 d-flex justify-content-between w-100 text-uppercase"
              style={{ position: "absolute", top: 0 }}
            >
              <div>
                {category && <Badge variant="primary">{category}</Badge>}
              </div>
              <div>
                {property.closed ? (
                  <Badge variant="lightgray">{t("property.sold")}</Badge>
                ) : (
                  property.under_contract && (
                    <Badge className="text-primary ml-2" variant="light">
                      {t("property.pending")}
                    </Badge>
                  )
                )}
              </div>
            </div>
          )}
        </div>
        <Card.Footer className={`text-secondary ${small ? "p-2" : "p-3"}`}>
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-end flex-wrap mb-2">
              <h5 className="text-primary mr-2 mb-0">
                {own
                  ? property.closed
                    ? t("property.closed")
                    : property.under_contract
                    ? t("property.underContract")
                    : property.verified
                    ? t("property.onSale")
                    : t("property.notVerified")
                  : currencyFormatter.format(property.price, {
                      code: "USD",
                      precision: 0,
                    })}
              </h5>
              {!own && (
                <h6 className="property-rent text-gray">
                  {currencyFormatter.format(property.rent, {
                    code: "USD",
                    precision: 0,
                  })}{" "}
                  {t("property.monthly")}
                </h6>
              )}
            </div>
            {status ? (
              <div>{status.toUpperCase()}</div>
            ) : (
              !own &&
              user &&
              property.favorited !== undefined && (
                <div
                  tabstop="-1"
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    e.nativeEvent.stopImmediatePropagation();
                    switchFavorite();
                  }}
                >
                  {property.favorited ? (
                    <Heart height="26" />
                  ) : (
                    <HeartOutline height="26" />
                  )}
                </div>
              )
            )}
          </div>

          <h6 className="m-0 property-address">{property.address}</h6>
          <p className="text-gray small mb-2">
            {property.city}, {property.state}
          </p>

          <div className="d-flex small property-details">
            <div
              className={`mr-2 border rounded d-flex align-items-center ${
                small ? "px-1" : "px-2"
              }`}
            >
              <span className="mr-2">{property.beds}</span>
              <Bed height={18} />
            </div>
            <div
              className={`mr-2 border rounded d-flex align-items-center ${
                small ? "px-1" : "px-2"
              }`}
            >
              <span className="mr-2">{property.baths}</span>
              <BathTub height={13} />
            </div>
            <div className={`border rounded ${small ? "px-1" : "px-2"}`}>
              <span className="text-nowrap">
                {property.sqft} FT<sup>2</sup>
              </span>
            </div>
          </div>

          {!property.branded && property.agent && (
            <div className="small mt-2">
              {property.agent.name} |{" "}
              {property.agent.brokerage ? property.agent.brokerage.title : ""},{" "}
              {property.agent.phone}
            </div>
          )}

          {children}
        </Card.Footer>
      </Card>

      {!user && (
        <AuthorizationModal
          show={showLogin}
          onHide={() => setShowLogin(false)}
          link={openLink}
        />
      )}
    </React.Fragment>
  );
};
