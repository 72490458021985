import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";
import _ from "lodash";

const PROPERTIES_SEARCH = gql `
query NearbySearch(
    $beds: Float
    $baths: Float
    $priceSqft: Float
    $city: String
    $state: String
    $coordinates: [Float]

) {
  nearby(
    beds: $beds
    baths: $baths
    priceSqft: $priceSqft
    city: $city
    state: $state
    coordinates: $coordinates
  ) {
      _id

      price
      rent
      equity_deposit

      mls
      source

      address
      city
      state
      zip

      beds
      baths
      sqft

      location {
        coordinates
      }

      branded
      agent {
        _id
        name
        phone
        brokerage {
          _id
          title
        }
      }

      images(max: 6) {
        _id
        extension
        fileid
        mimetype
        filename
        url
      }


      categories {
        _id
        thumbnail
      }

      under_contract
      closed
      published
      archived
      verified

      favorited
    }
  }`;


export default ((listing, nearby, onCompleted, onError) => {
  const {
    loading,
    data,
    error,
    fetchMore
  } = useQuery(PROPERTIES_SEARCH, {
    variables: {
      beds: listing.beds,
      baths: listing.baths,
      priceSqft: listing.price / listing.sqft,
      ...(nearby ? {
        coordinates: listing.location.coordinates
      } : {
        city: "Columbus",
        state: "OH",
      })
    },

    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err)
  });

  return [data && data.nearby, loading, error, fetchMore];
});
