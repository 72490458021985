import React, { useState } from "react";
import _ from "lodash";
import { Popover, Row, Col, Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const selectionStyle = {
  width: 38,
};

export default React.forwardRef(({ groups = [], onChange, ...props }, ref) => {
  const { t } = useTranslation();
  const pressed = (value, target) =>
    value === target ? "primary" : "outline-primary";

  const [val, setVal] = useState(
    _.transform(groups, (acc, { field, value }) => (acc[field] = value), {})
  );

  return (
    <Popover id={`discrete-filter`} ref={ref} {...props}>
      <Popover.Content style={{ width: 280 }}>
        <Row>
          {groups.map((group) => (
            <Col xs={12}>
              <div className="d-flex my-1">
                <p className="my-1" style={{ minWidth: 50 }}>
                  {group.label}
                </p>
                <ButtonGroup size="sm">
                  <Button
                    variant={pressed(val[group.field], undefined)}
                    onClick={() => setVal({ ...val, [group.field]: undefined })}
                  >
                    Any
                  </Button>
                  <Button
                    style={selectionStyle}
                    variant={pressed(val[group.field], 1)}
                    onClick={() => setVal({ ...val, [group.field]: 1 })}
                  >
                    1+
                  </Button>
                  <Button
                    style={selectionStyle}
                    variant={pressed(val[group.field], 2)}
                    onClick={() => setVal({ ...val, [group.field]: 2 })}
                  >
                    2+
                  </Button>
                  <Button
                    style={selectionStyle}
                    variant={pressed(val[group.field], 3)}
                    onClick={() => setVal({ ...val, [group.field]: 3 })}
                  >
                    3+
                  </Button>
                  <Button
                    style={selectionStyle}
                    variant={pressed(val[group.field], 4)}
                    onClick={() => setVal({ ...val, [group.field]: 4 })}
                  >
                    4+
                  </Button>
                </ButtonGroup>
              </div>
            </Col>
          ))}
        </Row>
        <hr />
        <div className="d-flex justify-content-end">
          <Button
            size="sm"
            variant="light"
            className="px-4 mr-2"
            onClick={() => {
              const resetValue = _.transform(
                groups,
                (acc, { field }) => (acc[field] = undefined),
                {}
              );
              console.log(resetValue);
              setVal(resetValue);
              onChange(resetValue);
            }}
          >
            {t("filters.clear")}
          </Button>
          <Button
            size="sm"
            varinat="primary"
            className="px-4"
            onClick={() => onChange(val)}
          >
            {t("filters.save")}
          </Button>
        </div>
      </Popover.Content>
    </Popover>
  );
});
