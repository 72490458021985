import React, {
  useState,
  useCallback,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  GoogleMap,
  Marker,
  MarkerClusterer,
  useLoadScript,
} from "@react-google-maps/api";
import _ from "lodash";

import HouseMarker, { PriceTag } from "./HouseMarker";

import { encodeSvg } from "../../tools";

const options = {
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_BOTTOM,
    // ...otherOptions
  },
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

const clusterOptions = {
  maxZoom: 12,
  imagePath: "/images/map-marker-group-",
};

const makeLatLngLiteral = (bounds) => ({
  sw: {
    lat: bounds.getSouthWest().lat(),
    lng: bounds.getSouthWest().lng(),
  },

  ne: {
    lat: bounds.getNorthEast().lat(),
    lng: bounds.getNorthEast().lng(),
  },
});

export default forwardRef(
  (
    {
      listings = [],
      height,
      location,
      user,
      setSearchBounds,
      showPrice,
      activeProperty,
      setActiveProperty,
    },
    ref
  ) => {
    const { isLoaded } = useLoadScript({
      googleMapsApiKey: process.env.REACT_APP_GOOLE_MAPS_KEY,
    });

    const mapContainerStyle = {
      width: "100%",
      minHeight: "265px",
      borderRadius: 10,
      height,
    };

    const [mapRef, setMapRef] = useState(null);

    const fitBounds = useCallback(
      (map) => {
        const mapObj = map || mapRef;
        console.log(map, mapRef, mapObj);
        console.log(listings);
        if (listings.length === 0) {
          mapObj.setCenter({
            lat: 39.962222,
            lng: -83.000556,
          });

          mapObj.setZoom(9);
          return;
        }

        const initialBounds = new window.google.maps.LatLngBounds();
        listings.map((listing) => {
          initialBounds.extend({
            lat: listing.location.coordinates[1],
            lng: listing.location.coordinates[0],
          });

          return listing._id;
        });

        const zoomChangeBoundsListener = window.google.maps.event.addListener(
          mapObj,
          "bounds_changed",
          function (event) {
            window.google.maps.event.removeListener(zoomChangeBoundsListener);
            console.log("setting zoom");
            mapObj.setZoom(Math.min(10, mapObj.getZoom()));
          }
        );

        mapObj.fitBounds(initialBounds);
      },
      [listings, mapRef]
    );

    useEffect(() => {
      console.log(mapRef, location);
      if (mapRef && location && location.lat && location.lng) {
        mapRef.setCenter({
          lat: Number(location.lat),
          lng: Number(location.lng),
        });
        mapRef.setZoom(13);
      }
    }, [location, mapRef]);

    useImperativeHandle(ref, () => ({
      fitBounds,
    }));

    const onLoad = useCallback(
      (map) => {
        console.log("onload");
        setMapRef(map);
        fitBounds(map);
      },
      [fitBounds]
    );

    const updateBoundingBox = () => {
      if (!mapRef) return;
      const mapBounds = mapRef.getBounds();
      const newBounds = makeLatLngLiteral(mapBounds);
      setSearchBounds(newBounds);
    };

    if (!isLoaded) return "Loading Maps...";

    return (
      <GoogleMap
        mapContainerStyle={mapContainerStyle}
        zoom={13}
        options={options}
        onLoad={onLoad}
        onBoundsChanged={updateBoundingBox}
        onClick={(e) => setActiveProperty(null)}
      >
        <MarkerClusterer options={clusterOptions}>
          {(clusterer) =>
            listings.map((listing) => (
              <Marker
                key={`marker-${listing._id}`}
                position={{
                  lat: listing.location.coordinates[1],
                  lng: listing.location.coordinates[0],
                }}
                icon={encodeSvg(
                  <HouseMarker
                    width={64}
                    selected={listing._id === _.get(activeProperty, "_id")}
                  >
                    <PriceTag
                      property={listing}
                      showPrice={showPrice}
                      selected={listing._id === _.get(activeProperty, "_id")}
                    />
                  </HouseMarker>
                )}
                clusterer={clusterer}
                onClick={(e) => setActiveProperty(listing)}
              />
            ))
          }
        </MarkerClusterer>
      </GoogleMap>
    );
  }
);
