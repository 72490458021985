import React, { useRef, useState } from "react";
import Slick from "react-slick";
import _ from "lodash";
import classNames from "classnames";

import "./InfoSlider.scss";

const defaultProps = {
  arrows: false,
  dots: false,
};

const SlickDot = ({ onClick, active }) => (
  <div className={classNames("SlickDot", { active })} onClick={onClick}></div>
);
export default ({ children, ...props }) => {
  const slick = useRef(null);
  const [activeSlide, setActiveSlide] = useState(0);

  const slidesToScroll = props.slidesToScroll || 1;

  return (
    <div className="InfoSlider">
      <Slick
        ref={slick}
        {...defaultProps}
        {...props}
        beforeChange={(_current, next) => setActiveSlide(next)}
      >
        {children}
      </Slick>
      <div className="SlickNavigation">
        <button
          className="SlickPrev"
          onClick={(e) => slick.current.slickPrev()}
        >
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.05687 1.77902C8.17565 1.89637 8.17576 2.08812 8.05711 2.20561L3.43089 6.78629C3.31233 6.90368 3.31233 7.09525 3.43089 7.21265L8.05711 11.7933C8.17576 11.9108 8.17565 12.1026 8.05687 12.2199L6.92662 13.3366C6.80977 13.4521 6.62179 13.4521 6.50493 13.3366L0.306821 7.21288C0.187951 7.09543 0.187951 6.9035 0.30682 6.78606L6.50493 0.662332C6.62178 0.546877 6.80976 0.546877 6.92662 0.662332L8.05687 1.77902Z"
              fill="#1D1F2A"
            />
          </svg>
        </button>
        {_.range(Math.ceil(children.length / slidesToScroll)).map((i) => (
          <SlickDot
            key={i}
            active={i === Math.floor(activeSlide / slidesToScroll)}
            onClick={(e) => slick.current.slickGoTo(i * slidesToScroll)}
          />
        ))}
        <button
          className="SlickNext"
          onClick={(e) => slick.current.slickNext()}
        >
          <svg
            width="9"
            height="14"
            viewBox="0 0 9 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.943126 12.221C0.824348 12.1036 0.824243 11.9119 0.942894 11.7944L5.56911 7.21371C5.68767 7.09632 5.68767 6.90475 5.56911 6.78735L0.942893 2.20667C0.824242 2.08919 0.824347 1.89744 0.943125 1.78008L2.07338 0.663396C2.19023 0.547941 2.37821 0.547941 2.49507 0.663396L8.69318 6.78712C8.81205 6.90457 8.81205 7.0965 8.69318 7.21394L2.49507 13.3377C2.37822 13.4531 2.19024 13.4531 2.07338 13.3377L0.943126 12.221Z"
              fill="#1D1F2A"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
