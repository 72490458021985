import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

import ActiveContractCard from "./ActiveContractCard";

export default ({ user, offers, activeOffer, setActiveOfferId }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle
        as={ActiveContractCard}
        activeOffer={activeOffer}
        user={user}
      ></Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Header>Choose Offer</Dropdown.Header>
        {offers
          ? offers.map((ac, i) => (
              <Dropdown.Item
                key={i}
                eventKey={i}
                onClick={(e) => {
                  setActiveOfferId(i);
                }}
              >
                {ac.listing.address}
              </Dropdown.Item>
            ))
          : null}
      </Dropdown.Menu>
    </Dropdown>
  );
};
