import React, { useState, useEffect } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import Geosuggest from "react-geosuggest";
import { useHistory } from "react-router-dom";
import qs from "qs";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import useAddCityRequest from "../../components/hooks/mutations/useAddCityRequest";
import { getAddressParts } from "../../tools";

export default ({ user, show, requestCity, onHide }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [step, setStep] = useState(0);
  const [email, setEmail] = useState(user ? user.email : "");
  const [city, setCity] = useState({});
  const [confirmation, setConfirmation] = useState(false);
  const [addCityRequest] = useAddCityRequest(
    {
      email,
      userType: (user && user.mode) || "no account",
      ...(city || {}),
    },
    () => {
      onHide();
      setStep(0);
      setConfirmation(true);
    }
  );

  useEffect(() => {
    if (user) setEmail(user.email);
    if (requestCity) {
      setCity(_.omit(requestCity, ["address"]));
    }
  }, [requestCity, user]);

  return (
    <React.Fragment>
      <Modal
        show={show}
        centered
        onHide={() => {
          onHide();
          setStep(0);
        }}
      >
        <Modal.Header>
          <h5 className="my-0">
            {step === 0 && t("noProperties.sorry")}
            {step === 1 && t("noProperties.requestCity")}
          </h5>
        </Modal.Header>

        {step === 0 && (
          <React.Fragment>
            <Modal.Body>
              <p>{t("noProperties.text")}</p>

              <div>
                <Button
                  variant="outline-primary"
                  className="btn-rounded"
                  onClick={() => {
                    history.push(
                      `/search?${qs.stringify({
                        address: "Columbus, OH",
                        city: "Columbus",
                        state: "OH",
                        zip: "",
                      })}`
                    );
                  }}
                >
                  Columbus, OH Metro Area
                </Button>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button varint="primary" onClick={() => setStep(1)}>
                Request Your City
              </Button>
            </Modal.Footer>
          </React.Fragment>
        )}
        {step === 1 && (
          <React.Fragment>
            <Modal.Body>
              <Form>
                <Form.Group>
                  <Form.Label>E-Mail</Form.Label>
                  <Form.Control
                    type="email"
                    disabled={user}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>City, Zip Code</Form.Label>
                  <Geosuggest
                    country="us"
                    types={["(regions)"]}
                    className="flex-grow-1"
                    placeDetailFields={[
                      "address_component",
                      "geometry",
                      "name",
                    ]}
                    initialValue={requestCity && requestCity.address}
                    getSuggestLabel={(suggest) =>
                      suggest.description.replace(", USA", "")
                    }
                    onSuggestSelect={(e) => {
                      if (e) {
                        const location = getAddressParts(e.gmaps);
                        setCity({
                          city: location.locality,
                          state: location.administrative_area_level_1,
                          zip: location.postal_code,
                        });
                      } else {
                        setCity(undefined);
                      }
                    }}
                  ></Geosuggest>
                </Form.Group>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="primary-outline"
                onClick={() => {
                  onHide();
                  setStep(0);
                }}
              >
                Close
              </Button>
              <Button
                disabled={!city || !email}
                variant="primary"
                onClick={() => addCityRequest()}
              >
                Send Request
              </Button>
            </Modal.Footer>
          </React.Fragment>
        )}
      </Modal>

      <Modal
        centered
        size="sm"
        show={confirmation}
        onHide={() => setConfirmation(false)}
      >
        <Modal.Header>
          <h5 className="my-0">Thank You!</h5>
        </Modal.Header>
        <Modal.Body>
          <p className="text-center p-4">
            We`ll send you an email when Dojohome arrives in {city.city},{" "}
            {city.state}. For now, stay-up to date with us on social media.
          </p>
          <div className="mt-4">
            <Button block onClick={() => setConfirmation(false)}>
              Ok
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};
