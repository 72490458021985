import React from "react";
import { Row, Col, ListGroup, ListGroupItem } from "react-bootstrap";
import Reorder, { reorder } from "react-reorder";

import InfoTooltip from "../../../components/property/InfoTooltip";
import CategoryListItem from "./CategoryListItem";
import usePropertyCategories from "../../../components/hooks/queries/usePropertyCategories";
import useReorderCategories from "../../../components/hooks/mutations/useReorderCategories";

export default ({ status, showTooltip = false, onEdit }) => {
  const [categories = []] = usePropertyCategories(status);
  const [reorderCategories] = useReorderCategories();

  const onReorder = (event, previousIndex, nextIndex, fromId, toId) =>
    reorderCategories(reorder(categories, previousIndex, nextIndex));

  return (
    <Row className="mb-5 CategoryList">
      <Col xs={12}>
        <Row>
          <Col md={4}>
            Header{" "}
            {showTooltip && (
              <InfoTooltip message="This text will show as the title of the featured category to the end-user." />
            )}
          </Col>
          <Col md={3}>
            Thumbnail Badge{" "}
            {showTooltip && (
              <InfoTooltip message="This text will show as a badge on properties that appear in search results. Keep it short and sweet!" />
            )}
          </Col>
          <Col md={2}>Properties Labelled</Col>
          <Col md={3}></Col>
        </Row>
        <ListGroup>
          <Reorder
            className="reorderable"
            reorderId={`${status}-categories`}
            onReorder={onReorder}
          >
            {categories.map((cat) => (
              <div key={cat._id}>
                <CategoryListItem category={cat} onEdit={onEdit} />
              </div>
            ))}
          </Reorder>
        </ListGroup>
      </Col>
    </Row>
  );
};
