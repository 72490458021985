import React from "react";
import { DojoLogoLight } from "../../../../../components/landing/icons";

export default [
  {
    title: "Traditional Renting",
    bullets: [
      "No path to home ownership",
      "Can be removed from property post-lease",
      "Inferior property selection",
      "Vulnerable to landlord whims",
      "Hard to recoup security payments",
      "Ongoing repair hassles",
    ],
  },
  {
    title: <DojoLogoLight />,
    bullets: [
      "Clear and flexible path to home ownership",
      "Owner security at renter-sized rates",
      "Access superior properties",
      "There is no landlord. You’re in charge.",
      "Recoupable equity payments",
      "Repair management on us",
    ],
    active: true,
  },
  {
    title: "Bank Mortgage",
    bullets: [
      "Limited to renting until downpayment ready",
      "Vulnerable to mortgage rejection",
      "Exposed to price hikes and hidden fees",
      "Susceptible to costly home-repair surprises",
    ],
  },
];
