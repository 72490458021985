import React, { useState, useEffect, useRef } from "react";
import { loader } from "graphql.macro";
import { useQuery, useMutation, useSubscription } from "@apollo/react-hooks";
import { useRouteMatch, Link } from "react-router-dom";
import _ from "lodash";

import AppMain from "../AppMain";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

import Avatar from "../../components/general/Avatar";
import ChatMessage from "../../components/chat/ChatMessage";

import "./PropertyChat.scss";
import { protectedUserName } from "../../tools";

const GET_CHAT = loader("./GetChat.graphql");
const SEND_MESSAGE = loader("./SendMessage.graphql");
const MARK_READ = loader("./MarkChatRead.graphql");
const NEW_MESSAGE_SUBSCRIPTION = loader("./NewMessageSubscription.graphql");

export default ({ user }) => {
  const match = useRouteMatch();
  const messagesEndRef = useRef(null);
  const newMessageFormRef = useRef(null);
  const [chatUser, setChatUser] = useState({});
  const [chatMessages, setChatMessages] = useState([]);
  const [chatMessage, setChatMessage] = useState("");
  const [isSupportChat, setIsSupportChat] = useState(false);
  const { loading, data } = useQuery(GET_CHAT, {
    variables: {
      id: match.params.id,
    },

    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const chat = data.getChatById;
      const otherUser = _.find(
        chat.participants,
        (u) =>
          u._id !== user._id && (!chat.admin || !_.includes(u.roles, "admin"))
      );

      setIsSupportChat(!!chat.admin);
      setChatUser(otherUser || {});
      setChatMessages(chat.messages);
    },
  });

  const [markChatRead] = useMutation(MARK_READ, {
    variables: {
      chat: match.params.id,
    },
  });

  const [sendMessage] = useMutation(SEND_MESSAGE, {
    variables: {
      chat: match.params.id,
      text: chatMessage.trim(),
    },
  });

  useSubscription(NEW_MESSAGE_SUBSCRIPTION, {
    variables: {
      chat: match.params.id,
    },

    onSubscriptionData: ({ subscriptionData }) => {
      if (subscriptionData.data.newMessageAdded) {
        setChatMessages([
          ...chatMessages,
          subscriptionData.data.newMessageAdded,
        ]);
      }
    },
  });

  const scrollToBottom = () => {
    messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [chatMessages]);
  useEffect(() => {
    markChatRead();
  }, [markChatRead]);

  const sendChatMessage = () => {
    sendMessage();
    setChatMessage("");
  };

  const messageLines = chatMessage.split("\n").length;

  const onEnterPress = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      sendChatMessage();
    }
  };

  return (
    <AppMain fluid={true} hideFooter={true}>
      <Row className="participant-block">
        <Col xs={12}>
          {loading || !data ? null : (
            <Container>
              <div className="d-flex p-3">
                {(!isSupportChat || _.includes(user.roles, "admin")) && (
                  <div className="flex-grow-0 pr-3">
                    <Avatar user={chatUser} size={48}></Avatar>
                  </div>
                )}
                <div className="flex-grow-1">
                  {isSupportChat && (
                    <div>
                      <strong>Dojoexpert Support</strong>
                    </div>
                  )}
                  {(!isSupportChat || _.includes(user.roles, "admin")) && (
                    <div>
                      <strong>{protectedUserName(chatUser, user)}</strong>
                    </div>
                  )}
                  <div>
                    <Link to={`/property/${data.getChatById.listing._id}`}>
                      {data.getChatById.listing.address}
                    </Link>
                  </div>
                </div>
              </div>
            </Container>
          )}

          <hr className="my-0" />
        </Col>
      </Row>

      <Row
        className="flex-grow-1"
        style={{
          maxHeight: "calc(100vh - 210px)",
          overflow: "scroll",
        }}
      >
        <Col xs={12}>
          <Container>
            {loading || !data
              ? "Loading..."
              : chatMessages.map((message) => {
                  return (
                    <ChatMessage
                      key={message._id}
                      user={user}
                      message={message}
                      showAvatar={
                        isSupportChat && user._id !== message.from._id
                      }
                    ></ChatMessage>
                  );
                })}
            <div ref={messagesEndRef}></div>
          </Container>
        </Col>
      </Row>

      <Row className="py-3 send-message-block">
        <Col>
          <Container>
            <Row>
              <Col xs={12} md={9}>
                <Form
                  ref={newMessageFormRef}
                  onSubmit={(e) => {
                    e.preventDefault();
                    sendChatMessage();
                  }}
                >
                  <Form.Control
                    placeholder="Type your message..."
                    as="textarea"
                    rows={messageLines > 1 ? 2 : 1}
                    value={chatMessage}
                    onKeyDown={onEnterPress}
                    onChange={(e) => setChatMessage(e.target.value)}
                  ></Form.Control>
                </Form>
              </Col>
              <Col xs={12} md={3}>
                <Button
                  block
                  onClick={(e) => sendChatMessage()}
                  className="text-uppercase"
                >
                  Send
                </Button>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </AppMain>
  );
};
