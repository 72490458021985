import React, { useState } from "react";
import {
  Row,
  Col,
  ButtonToolbar,
  ButtonGroup,
  Button,
  Badge,
} from "react-bootstrap";

import useGetOffers from "../../components/hooks/queries/useGetOffers";
import OfferCard from "../../components/admin/OfferCard";
import AppMain from "../AppMain";

export default ({ user }) => {
  const [status, setStatus] = useState("new");
  const [offers, loading] = useGetOffers({ status });

  const OfferStatuses = [
    { title: "Waiting on Seller", status: "new" },
    { title: "Waiting on Buyer", status: "approved" },
    { title: "Intent Fully Executed", status: "approvedDocuments" },
    { title: "Pending Tenant Verification", status: "tenantVerification" },
    { title: "Tenant Verification OK", status: "documents" },
    { title: "Closed", status: "closed" },
  ];

  const OfferStatusesRejected = [
    { title: "Rejected", status: "rejected" },
    { title: "Withdrawn", status: "withdrawn" },
  ];

  return (
    <AppMain>
      <Row className="mt-4">
        <Col xs={12}>
          <h1>Offers</h1>
        </Col>
        <Col xs={12} className="mb-2">
          <ButtonToolbar>
            <ButtonGroup size="sm" className="mr-2">
              {OfferStatuses.map((s) => (
                <Button
                  key={s.status}
                  variant={
                    status === s.status ? "secondary" : "outline-secondary"
                  }
                  onClick={(e) => setStatus(s.status)}
                >
                  {s.title}{" "}
                  <Badge
                    size="sm"
                    variant="light"
                    pill
                    className="px-2 py-1 ml-1"
                  >
                    {(offers && offers.stats && offers.stats[s.status]) || 0}
                  </Badge>
                </Button>
              ))}
            </ButtonGroup>

            <ButtonGroup size="sm" className="mr-2">
              {OfferStatusesRejected.map((s) => (
                <Button
                  key={s.status}
                  variant={status === s.status ? "warning" : "outline-warning"}
                  onClick={(e) => setStatus(s.status)}
                >
                  {s.title}{" "}
                  <Badge
                    size="sm"
                    variant="light"
                    pill
                    className="px-2 py-1 ml-1"
                  >
                    {(offers && offers.stats && offers.stats[s.status]) || 0}
                  </Badge>
                </Button>
              ))}
            </ButtonGroup>
          </ButtonToolbar>
        </Col>

        <Col xs={12}>
          {loading || !offers ? (
            "Loading..."
          ) : offers.pages === 0 ? (
            <h5 className="text-center my-5">
              There are no offers matching your search criteria
            </h5>
          ) : (
            offers.items.map((offer) =>
              offer.listing ? (
                <OfferCard key={offer._id} user={user} offer={offer} />
              ) : null
            )
          )}
        </Col>
      </Row>
    </AppMain>
  );
};
