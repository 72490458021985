import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";
import _ from "lodash";

import useAgents from "../hooks/mutations/useAgents";
import { Form, Modal, Button } from "react-bootstrap";

import ListingBrokerage from "./ListingBrokerage";

const customStyles = {
  control: (provided, state) => {
    const border = "1px solid #CCE6FF";
    return { ...provided, border };
  },
};

export default ({ agent, setAgent }) => {
  const [newAgentModal, setNewAgentModal] = useState(false);
  const [newAgent, setNewAgent] = useState({});
  const [agents = [], createAgent, loading, error] = useAgents(
    ({ createAgent }) => {
      setNewAgentModal(false);
      setNewAgent({});
      setAgent(createAgent);
    }
  );

  console.log(agent);

  const options = agents.map(({ _id, name }) => ({
    label: name,
    value: _id,
  }));
  const selectedAgent = agent && _.find(options, { value: agent._id });

  return (
    <React.Fragment>
      <Modal show={newAgentModal} onHide={() => setNewAgentModal(false)}>
        <Modal.Header>
          <h5>Add New Listing Agent</h5>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>Agent Name</Form.Label>
            <Form.Control
              value={newAgent.name}
              onChange={(e) =>
                setNewAgent({ ...newAgent, name: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Brokerage Name</Form.Label>
            <ListingBrokerage
              brokerage={newAgent.brokerage && newAgent.brokerage._id}
              setBrokerage={(brokerage) =>
                setNewAgent({ ...newAgent, brokerage: { _id: brokerage } })
              }
            />
          </Form.Group>
          <pre>{JSON.stringify(newAgent, false, 2)}</pre>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={() => setNewAgentModal(false)}>
            Cancel
          </Button>
          <Button
            variant="primary"
            disabled={!newAgent.name || !newAgent.brokerage}
            onClick={(e) =>
              createAgent({ ...newAgent, brokerage: newAgent.brokerage._id })
            }
          >
            Create
          </Button>
        </Modal.Footer>
      </Modal>

      <CreatableSelect
        disabled={loading}
        styles={customStyles}
        options={options}
        value={selectedAgent}
        //formatOptionLabel={formatOptionLabel}
        onChange={(item) => {
          setAgent(_.find(agents, { _id: item.value }));
        }}
        onCreateOption={(value) => {
          setNewAgent({
            name: value,
          });
          setNewAgentModal(true);
        }}
      ></CreatableSelect>
    </React.Fragment>
  );
};
