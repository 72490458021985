import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import BackNext from "./BackNext";
import SelectCards from "./SelectCards";
import {
  Employee,
  Business,
  Contractor,
  GigWorker,
  Student,
  Retirement,
} from "./icons";
import useUpdateBuyingPower from "../hooks/mutations/useUpdateBuyingPower";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const options = [
    { icon: Employee, label: "Employee of a Company", value: "employee" },
    { icon: Business, label: "Small Business Owner", value: "business" },
    { icon: Contractor, label: "Independent Contractor", value: "contractor" },
    { icon: GigWorker, label: "Gig Worker", value: "worker" },
    { icon: Student, label: "Student", value: "student" },
    { icon: Retirement, label: "Retired", value: "retired" },
  ];
  const disableNext = !data.employment;

  const [updateBuyingPower, updating] = useUpdateBuyingPower(data, () => {
    onNext && onNext();
  });

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateBuyingPower();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateBuyingPower]);

  return (
    <>
      <Form className="form-wide">
        <h3>What do you do for a living?</h3>
        <SelectCards
          options={options}
          data={data}
          field="employment"
          setData={setData}
        />
      </Form>
      <BackNext
        mode="Buyer"
        stepIndex={stepIndex}
        onNext={updateBuyingPower}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
