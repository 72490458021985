import {useState} from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const GET_MIN_DOWN_PAYMENT = gql`
  query GetMinDownPayment {
    settings {
      minDownPayment {
        organic
        brokered
      }
    }
  }
`;

const SET_MIN_DOWN_PAYMENT = gql`
  mutation SetMinDownPayment($minDownPayment: DownPaymentInput!) {
    updateSettings(settings: {
      minDownPayment: $minDownPayment
    }) {
      message
    }
  }
`;

export default (onCompleted) => {
  const [minDownPayment, setMinDownPayment] = useState({})
  const {
    loading,
    error,
    refetch
  } = useQuery(GET_MIN_DOWN_PAYMENT, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMinDownPayment(_.omit(data.settings.minDownPayment, ['__typename']));
    }
  });

  const [saveMinDownPayment] = useMutation(SET_MIN_DOWN_PAYMENT, {
    variables: {minDownPayment},
    refetchQueries: () => ['GetMinDownPayment'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [minDownPayment, (update) => setMinDownPayment({...minDownPayment, ...update}), refetch, saveMinDownPayment, loading, error];
};