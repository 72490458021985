import React from "react";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import _ from "lodash";
import { useTranslation } from "react-i18next";

import useValidateEmail from "../hooks/queries/useValidateEmail";

export default ({ email, setEmail, mode, onNext }) => {
  const { t } = useTranslation();

  const [validateEmail, loading, error] = useValidateEmail((exists) => {
    onNext(_.includes(exists, mode), _.difference(exists, [mode]));
  });

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        validateEmail(email);
      }}
    >
      {error &&
        error.graphQLErrors.map((err) => (
          <Alert variant="danger">{err.message}</Alert>
        ))}

      <Form.Group controlId="formBasicEmail">
        <Form.Label>Email</Form.Label>
        <Form.Control
          type="email"
          disabled={loading}
          placeholder="Your Email address"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Form.Group>

      <div className="d-flex mt-5">
        <div className="flex-grow-1"></div>
        <div>
          <Button
            disabled={loading}
            variant="primary"
            type="submit"
            style={{ width: 200, padding: 9 }}
          >
            {loading && (
              <Spinner animation="border" size="sm" variant="light" />
            )}{" "}
            <span>{t("login.continue")}</span>
          </Button>
        </div>
        <div className="flex-grow-1"></div>
      </div>
    </Form>
  );
};
