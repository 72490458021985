import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const ASSESSMENT_HISTORY = gql`
query AssessmentHistory($attomId: AttomId!) {
  assessmentHistory(attomId: $attomId) {
    identifier {
      attomId
    }

    assessmenthistory {
      assessed {
        assdttlvalue
      }
      market {
        mktttlvalue
      }

      tax {
        taxyear
      }
    }
  }
}`;

export default ((attomId, onCompleted, onError) => {
  const {
    data,
    loading,
    error } =
  useQuery(ASSESSMENT_HISTORY, {
    variables: { attomId },
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err) });


  return [data && data.assessmentHistory, loading, error];
});