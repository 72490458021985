import React from "react";
import { Link } from "react-router-dom";
import { Nav, Button } from "react-bootstrap";
import _ from "lodash";

export default ({ user }) => {
  const isAdmin = _.includes(user.roles, "admin");

  return (
    <React.Fragment>
      {isAdmin && (
        <Nav.Link className="px-3" as={Link} to="/mission-control">
          Mission Control
        </Nav.Link>
      )}
      <Button
        as={Link}
        variant="outline-secondary"
        to="/seller/property/new"
        className="mx-2"
      >
        List a Property
      </Button>
    </React.Fragment>
  );
};
