import React from "react";

export default [
  {
    title: "General",
    slug: "general",
    questions: [
      {
        question: "How does Dojohome work?",
        answer: "Dojohome is a platform that matches aspiring homeowners with sellers who are looking to generate additional income from their property through a gradual home sale."
      },
      {
        question: "What is gradual homeownership?",
        answer: "Gradual homeownership is the process of buying or selling a home over an extended period of time. Buyers make a partial down payment, then they move into the home and pay rent to the seller on an agreed upon timeline until they have saved up enough to become ‘mortgage-ready’. When that happens, Dojo prepares both parties for closing the sale, and the property officially changes hands."
      },
      {
        question: "Does Dojohome own the properties listed?",
        answer: "No. We neither own nor hold titles to the properties listed on our platform. We simply connect motivated buyers with interested sellers and facilitate gradual home sales that satisfy both parties."
      },
      {
        question: "How does Dojohome make money?",
        answer: "Dojohome receives a one-time fee from the buyer and a monthly processing fee from the seller."
      },
      {
        question: "How is Dojohome different from a residential lease?",
        answer: "Residential leases are for rental purposes only. For the tenant, there is no path to homeownership, and post-lease, the landlord can remove the tenant for any reason. For the landlord, leases entail managing tenants, chasing payments and handling all maintenance issues. The Dojohome platform puts buyers on a structured path to ownership, provides maintenance support, and manages all financial transactions."
      },
      {
        question: "How is Dojo different from a traditional home sale?",
        answer: "Traditional home sales require mortgage approval and a full down payment of 10-20% from the buyer, while sellers are stuck with unpredictable fees and expenses. On the Dojo platform, each property continues to be owned by the seller until the buyer purchases the property outright. The length of time it takes to purchase the property is dependent on the agreed upon equity plan but can range from 1 to 5 years. If the buyer does not purchase the property within the established timeline, any equity contributed after the down payment is then returned to the buyer."
      },
      {
        question: "Where does Dojohome operate?",
        answer: "We currently operate in select markets in the Midwest of the U.S., but we are expanding rapidly. Sign up and we will let you know when we launch in your city!"
      },
      {
        question: "How are the value of homes determined?",
        answer: "Homes are listed at fair market value to ensure that both the buyer and seller are getting an equally fair deal. To determine fair market value, we have partnered with a gold standard company, one that leading banks use, to utilize their AVM (Automated Valuation Models) technology."
      },
      {
        question: "What type of homes are listed on Dojohome?",
        answer: "A wide variety of properties can be found on our platform, including single-family homes, townhomes, and condos."
      },
      {
        question: "How long does the purchase process take?",
        answer: "From application to approval, the process typically takes a matter of days. Then when you are ready to purchase the home outright at the end of the equity building stage, which can last between 1 and 5 years, you will need to go through the traditional lender process, which usually takes a few weeks."
      },
      {
        question: "What happens if either party backs out of the contract?",
        answer: "Dojohome contracts ensure that both sides are protected. Buyers cannot be removed from the home while under contract. They retain the right to rent the home and exercise their purchase option until the agreed upon timeline ends. If buyers become unable to meet their monthly payment demands, our platform’s renters insurance guarantees the seller’s monthly rental income."
      },
      {
        question: "Who is responsible for repairs in a Dojohome contract?",
        answer: "As future owners of the home, buyers will generally be responsible for repairs up to $150. The seller will generally be responsible for repairs over $150."
      },
      {
        question: "What repair services does Dojohome provide?",
        answer: "Dojo performs a ‘health home inspection’ twice a year - free-of-charge. After each service, we also provide you with a Home Wellness Report of other recommendations to keep your home running smoothly."
      },
      {
        question: "What happens if there is major destruction (fire, flood, etc.) during the purchase?",
        answer: "Homeowners are required to have insurance. Dojo also provides an additional layer of insurance to serve the best interest of our customers."
      },
    ]
  },
  {
    title: "Buyer questions",
    slug: "buyer",
    questions: [
      {
        question: "What is a down payment?",
        answer: "A down payment is the amount of money you agree to pay when signing the home contract. Traditionally, down payments are 10-20% of the home value, however, with Dojohome you can put as little as 2% down while you save for a larger down payment for a bank mortgage. When a buyer purchases the property outright, their down payment converts into equity."
      },
      {
        question: "How are monthly payments calculated?",
        answer: "Your monthly payments will be a combination of the fair market rent and the equity payments based on your specific plan. The rent will go primarily to the seller, with Dojo collecting a small transaction and processing fee and your equity will accumulate in your Escrow account."
      },
      {
        question: "What is an equity payment?",
        answer: "An equity payment is the amount you invest each month to gain more ownership of the property. Your equity payment is calculated each month using the fair market value of your home at the time of payment."
      },
      {
        question: "Can I tour homes before making an offer?",
        answer: "Absolutely! You can schedule a tour through our platform at any time."
      },
      {
        question: "What is included in an offer?",
        answer: "When you make an offer, you tell the seller how much you’re willing to commit in a down payment and the pace you’d like to contribute the first 10% equity stake in the property."
      },
      {
        question: "What do I need to qualify?",
        answer: "Our application process is straightforward. You will need to provide personal documentation (drivers license, etc.), proof of income and your credit score."
      },
      {
        question: "What if I have past credit issues?",
        answer: "If you can reasonably afford the rental + equity payment and can put 2-5% towards the down payment, we encourage you to apply. As it relates to existing credit issues, we’ll work with you to fix these issues as you prepare for the mortgage application down the line. Qualifying for Dojohome is easier than a bank mortgage and we invite everyone to go through our application process."
      },
      {
        question: "Do I need to put cash down? How much cash will I need?",
        answer: "Yes, and the amount depends on the value of the home. You can estimate needing between 2-5% of the home value for your down payment. For properties listed directly by sellers, we collect 2%. For properties that are listed by agents, there is an additional realtor fee that is usually 3% or less, while we still collect 2%."
      },
      {
        question: "What happens if the value of the home increases before I purchase it?",
        answer: "Buyers and sellers will both be impacted by market fluctuations. At the time of the purchase, fair market value will always be used to inform the exact closing price."
      },
      {
        question: "Can I terminate the contract if my financial situation changes? What happens to my equity if that happens?",
        answer: "If the contract is terminated, since all buyers and sellers will be signing a modified lease that builds in the intent of finalizing a home sale, there will be a penalty in line with your state’s local leasing regulations. However, you will also have all of your equity returned, except for your initial down payment. For example, if you put $3,000 toward your down payment and pay $15,000 in equity during your time living in the home, you will have the $15,000 returned upon contract termination. For more detail, please review our terms and conditions and let us know if you have any questions."
      },
    ]
  },
  {
    title: "Seller questions",
    slug: "seller",
    questions: [
      {
        question: "How do I make 3-4x profits?",
        answer: <div>
          <div>Traditional home sales include fees throughout the process - realtor fees, concession costs, holding costs, etc. Dojohome eliminates or reduces these fees.</div>
          <div>The gradual home sale model allows you to collect monthly rent from the buyer while they accumulate the equity required to buy the home outright.</div>
          <div>You will also maintain the tax benefits of homeownership throughout the gradual sale process.</div>
          <div>Then when the sale closes, fair market value is used to determine the price, allowing you to collect the profits of the sale at full appreciation.</div>
        </div>
      },
      {
        question: "How do I list a home?",
        answer: (<div>
          <div>he process is simple. Once you go through the following steps, buyers can check out your property. It’s that easy.</div>
          <ul>
            <li>✓ Sign up for an account</li>
            <li>✓ Tell us about your property</li>
            <li>✓ Complete a simple application</li>
            <li>✓ Upload pictures of your property</li>
          </ul>
        </div>)
      },
      {
        question: "Does Dojo offer sellers the option to cash out their equity?",
        answer: "Yes. We have partnered with local banks to provide sellers the option to cash out, based on their individual needs and circumstances."
      },
      {
        question: "What do I need to qualify as a seller?",
        answer: "All you need to qualify is a property you want to sell and a desire to make more from that sale. Whether you're an investor looking to unload multiple properties or a single-family homeowner, Dojohome's gradual home sale platform can help you get the most out of your home sale."
      },
      {
        question: "What types of properties can be listed?",
        answer: "We list a wide range of properties including; detached single family homes, townhomes and condos."
      },
      {
        question: "What if my property needs some repairs and updates, can I still list it?",
        answer: "Yes! We’re happy to review any property, even if they require updates, to see if they will be a fit for Dojohome."
      },
      {
        question: "When do I need to be out of my property?",
        answer: "Dojo requires sellers to be out of the property prior to the buyer move-in date."
      },
      {
        question: "Can I choose who is buying my home?",
        answer: "Sellers will have the opportunity to accept or decline a buyer’s offer. Each offer will include the down payment %, equity plan, approximate years until home purchase, and the financial strength of the buyer."
      },
      {
        question: "What am I committing to when I accept an offer on my property?",
        answer: "Dojo’s contracts do two things primarily: First, they serve as a residential lease, and second, they include a purchase option agreement for the buyer. These two parts put a general timeline in place for the gradual home sale and ensure that you'll collect rent until the sale is closed."
      },
      {
        question: "At what point does ownership transfer to the new buyer?",
        answer: "You are still the legal owner of the property throughout this process.  There is no transfer of deed or title until the member purchases the home from you, typically after three to five years. In that time, you continue to receive all of the benefits of owning your home, including property value increase, tax benefits, and cash flow from the rent."
      },
    ]
  },
];