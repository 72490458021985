import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";
import _ from "lodash";

const REORDER_CATEGORIES = gql `
  mutation ReorderCategories($ids: [ID!], $status: PropertyCategoryStatus) {
    reorderCategories(ids: $ids, status: $status) {
      _id
    }
  }
`;

export default ((onCompleted, onError) => {
  const [reorderCategories, {
    loading,
    error = {}
  }] = useMutation(REORDER_CATEGORIES, {
    refetchQueries: () => ["GetPropertyCategories"],

    onCompleted: data => onCompleted && onCompleted(data)
  });


  return [(categories) => reorderCategories({
    variables: {
      ids: _.map(categories, "_id")
    }
  }), loading, error];
});
