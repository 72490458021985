import { useState } from "react";
import _ from "lodash";
import moment from "moment";

import plans from "../../../data/plans";
import { Plan } from "../../icons";

export default (listing = {}, offer = {}, setOffer) => {
  const plan = offer.plan;
  const updateOffer = (params) => setOffer({ ...offer, ...params });
  const [displayDownPayment, setDisplayDownPayment] = useState();

  const [customPlan, setCustomPlan] = useState({
    payAsYouGo: false,
    targetMonths: 4 * 12,
    monthlyEquity: Math.round((listing.price - offer.down_payment) / (4 * 12)),
    ownDate: moment().add(4, "years").toDate(),
    by: "equity",
  });

  const customPlanUpdated = !customPlan.payAsYouGo && customPlan.targetMonths >= 12 && (plan.id === "custom" || customPlan.targetMonths !== 4 * 12);
  const activePlans = customPlanUpdated ? [{
    id: "custom",
    icon: Plan,
    title: "Make your own plan",
    targetEquity: 10,
    targetMonths: customPlan.targetMonths
  }, ...plans] : plans;

  const activePlan = _.find(activePlans, { id: plan && plan.id });

  const payAsYouGo = activePlan && activePlan.id === "custom" && activePlan.payAsYouGo;
  const targetEquity = activePlan ? activePlan.targetEquity || 10 : 10;
  const durationDiff = !payAsYouGo ? moment.duration(activePlan.targetMonths, "months") : moment()

  return {
    activePlan,
    plans,
    offer,

    moveIn: moment(offer.move_in),
    
    equity: (offer.down_payment ? offer.down_payment : listing.equity_deposit) / listing.price * 100,
    minEquity: Math.ceil(((listing.equity_deposit / listing.price) * 100) / 0.25 ) * 0.25,
    maxEquity: targetEquity,
    targetEquity,

    minDownPayment: listing.equity_deposit,
    maxDownPayment: (listing.price * targetEquity) / 100,

    monthlyRent: listing.rent,
    monthlyEquity: !payAsYouGo ? Math.round((listing.price * targetEquity / 100 - offer.down_payment) / activePlan.targetMonths) : 0,

    down_payment: offer ? offer.down_payment : undefined,
    displayDownPayment,
    remainingPayment: Math.round((listing.price * targetEquity / 100 - offer.down_payment)),

    targetDate: !payAsYouGo ? moment(offer.move_in).add(activePlan.targetMonths, "months") : undefined,
    targetDuration: {
      years: durationDiff.years(),
      months: durationDiff.months(),
    },

    custom: activePlan.id === "custom" ? customPlan : {},
    
    updateCustom: (data) => {
      const update = data
      const payAsYouGo = data.payAsYouGo || (activePlan && activePlan.id === "custom" && activePlan.payAsYouGo);
      if (update.ownDate) {
        update.targetMonths = Math.round(moment.duration(moment(update.ownDate).diff(moment(offer.moveIn))).asMonths());
        update.monthlyEquity = Math.round((listing.price / 10 - offer.down_payment) / update.targetMonths)
        update.outOfBounds = !payAsYouGo && (update.targetMonths < 12 || update.targetMonths > 60)
      }
      else if (update.monthlyEquity) {
        update.targetMonths = Math.round((listing.price / 10 - offer.down_payment) / update.monthlyEquity)        
        update.ownDate = moment(offer.moveIn).add(update.targetMonths, 'months').toDate();
        update.outOfBounds = !payAsYouGo && (update.targetMonths < 12 || update.targetMonths > 60)
      } else {
        update.outOfBounds = !payAsYouGo && (customPlan.targetMonths < 12 || customPlan.targetMonths > 60)
        if (data.by === "date" && update.outOfBounds) {
          update.targetMonths = 4 * 12;
          update.ownDate = moment(offer.moveIn).add(update.targetMonths, 'months').toDate();
          update.monthlyEquity = Math.round((listing.price / 10 - offer.down_payment) / update.targetMonths)
          update.outOfBounds = false
        }
      }

      return setCustomPlan({
        ...customPlan,
        ...update,
      })
    },

    setEquity: (value) => {
      const newDownPayment = Math.floor(parseFloat(value) * listing.price / 100);
      setDisplayDownPayment(newDownPayment);
      updateOffer({down_payment: newDownPayment})
    },
    setDownPayment: (value) => {
      setDisplayDownPayment(parseFloat(value));
      updateOffer({
        down_payment: Math.min(parseFloat(value), (listing.price * targetEquity) / 100)
      });
    },
    setPlan: (id) => updateOffer({ plan: _.find(plans, { id }) }),
    setMoveIn: (date) => updateOffer({ move_in: date }),
    forecastDate: (months) => moment(offer.move_in).add(months, "months"),
    forecastPayment: (months) => listing.rent + (listing.price * targetEquity / 100 - offer.down_payment) / months,

    getPlan: (year, month) => _.find(activePlans, {
        targetMonths: year * 12 + month
      })
  };
}
