import React from "react";
import { Row, Col, Card } from "react-bootstrap";
import { ResponsiveContainer, PieChart, Pie, Cell } from "recharts";
import { useTranslation } from "react-i18next";

const COLORS = ["#46C9F4", "#CCE6FF"];

export default ({ downPayment, fullPrice }) => {
  const { t } = useTranslation();
  const memberPercent = Math.round((downPayment / fullPrice) * 100);
  const data = [
    { name: t("contract.equity"), value: 100 - memberPercent },
    { name: t("contract.downPayment"), value: memberPercent },
  ];

  return (
    <Card className="mt-1 mb-4">
      <Card.Body>
        <h5 className="text-center">Equity / Down Payment Percentage</h5>

        <div style={{ width: "100%", height: 180, position: "relative" }}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              marginTop: "-1rem",
              left: 0,
              right: 0,
              textAlign: "center",
            }}
          >
            <h5>
              {data[0].value}% / {data[1].value}%
            </h5>
          </div>
          <ResponsiveContainer>
            <PieChart>
              <Pie
                dataKey="value"
                data={data}
                fill="#8884d8"
                innerRadius={60}
                outerRadius={80}
              >
                {data.map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </div>

        <Row>
          {data.map((part, i) => (
            <Col key={i} xs={6} className="text-center">
              <div
                style={{
                  width: 12,
                  height: 12,
                  backgroundColor: COLORS[i],
                  borderRadius: "50%",
                  display: "inline-block",
                }}
              ></div>
              <div className="small text-uppercase">{part.name}</div>
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};
