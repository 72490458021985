import React from "react";
import Chart from "react-apexcharts";

import useAssessmentHistory from "../../components/hooks/queries/useAssessmentHistory";
import { makeShortPrice } from "../../tools";

const PriceHistoryChart = ({ listing }) => {
  const [attomSaleHistory] = useAssessmentHistory(
    listing.attomExtendedInfo.identifier.obPropId
  );

  const saleHistory = (
    (attomSaleHistory && attomSaleHistory.assessmenthistory) ||
    []
  )

    .map((sale) => {
      return {
        x: sale.tax.taxyear || "",
        y: (sale.market.mktttlvalue || 0) + (sale.assessed.assdttlvalue || 0),
      };
    })
    .reverse();

  return (
    <Chart
      options={{
        chart: {
          toolbar: {
            show: false,
          },
        },

        yaxis: {
          labels: {
            formatter: (value) => `$${makeShortPrice(value)}`,
          },
        },

        dataLabels: {
          enabled: false,
        },

        tooltip: {
          y: {
            title: {
              formatter: () => "",
            },

            formatter: (value, { dataPointIndex }) =>
              `$${value} (${saleHistory[dataPointIndex]})`,
          },
        },
      }}
      series={[
        {
          data: saleHistory,
        },
      ]}
      type="area"
    />
  );
};

export default PriceHistoryChart;
