import { gql } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";

const MUTATION = gql`
mutation BuyEquity($offer_id: String!, $amount: Float!, $payment: PaymentMethodInput) {
  buyEquity(offer_id: $offer_id, amount: $amount, payment: $payment) {
    _id
    payer {
      email
    }
    recipient {
      email
    }
    offer {
      _id
    }
    amount
    status
  }
}
`



export default (offer, moreEquity, selectedPaymentMethod, onCompleted, onError) => {
  const [buyEquity, { loading, errors }] = useMutation(MUTATION, {
    variables: {
      offer_id: offer._id,
      amount: moreEquity,
      payment: selectedPaymentMethod,
    },

    refetchQueries: () => ["GetMyOffers"],
    onCompleted,
    onError,
  });

  return [buyEquity, loading, errors];
}