import React from "react";
import { Link } from "react-router-dom";
import { Nav, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default ({ user }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Nav.Link className="px-3" as={Link} to="/seller/properties">
        {t("seller.menu.properties")}
      </Nav.Link>
      <Nav.Link className="px-3" as={Link} to="/seller/dashboard">
        {t("seller.menu.dashboard")}
      </Nav.Link>
      <Button
        as={Link}
        variant="outline-secondary"
        to="/seller/property/new"
        className="mx-2"
      >
        {t("seller.menu.listProperty")}
      </Button>
    </React.Fragment>
  );
};
