import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import BackNext from "../BackNext";
import SelectCards from "../SelectCards";
import { HouseSingle, HouseRent, HouseSecond } from "../icons";
import useUpdateSellerInfo from "../../hooks/mutations/useUpdateSellerInfo";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const options = [
    { icon: HouseSingle, label: "Owner-occupied", value: "ownerOccupied" },
    { icon: HouseRent, label: "Investment property / Rental", value: "rental" },
    { icon: HouseSecond, label: "Second home", value: "second" },
  ];

  const [updateSellerInfo, updating] = useUpdateSellerInfo(data, () => {
    onNext && onNext();
  });

  const disableNext = !data.homeDescription;

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateSellerInfo();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateSellerInfo]);

  return (
    <>
      <Form className="form-wide">
        <h4>What best describes your home?</h4>
        <SelectCards
          options={options}
          data={data}
          field="homeDescription"
          setData={setData}
        />
      </Form>
      <BackNext
        mode="Seller"
        stepIndex={stepIndex}
        onNext={updateSellerInfo}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
