import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";

const PROPERTIES_SEARCH = gql`
query PropertySearch($filters: PropertyFiltersInput!, $page: Int) {
  search(filters: $filters, page: $page, limit: 24) {
    page
    limit
    pages
    total

    properties {
      _id

      price
      rent
      equity_deposit

      mls
      source

      address
      city
      state
      zip

      beds
      baths
      sqft

      location {
        coordinates
      }

      owner {
        _id
      }

      branded
      agent {
        _id
        name
        phone
        brokerage {
          _id
          title
        }
      }

      pet_allowance
      available_from

      images(max: 6) {
        _id
        extension
        fileid
        mimetype
        filename
        url
      }

      myOffer {
        _id
        down_payment
      }

      categories {
        _id
        thumbnail
      }

      under_contract
      closed
      published
      archived
      verified

      favorited
    }
  }
}`;


export default (({ activeFilter, searchBounds, location }, onCompleted, onError) => {
  const { loading, data, error, fetchMore } = useQuery(PROPERTIES_SEARCH, {
    variables: {
      filters: {
        ...(_.pickBy(activeFilter, _.identity)),
        ...(searchBounds ? { location: searchBounds } : _.pick(location, ["city", "state"]))
      },
    },

    fetchPolicy: "network-only",
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err)
  });

  return [data && data.search, loading, error, fetchMore];
});