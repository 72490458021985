import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const QUERY = gql `
  query GetStripePayouts {
    stripePayouts {
      id
      account_holder_name
      account_holder_type
      bank_name
      country
      currency
      last4
      routing_number
    }
  }
`;

export default ((returnLink) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY);
  return [data && data.stripePayouts, loading, error];
});
