import React from "react";
import { Table } from "react-bootstrap";

export default () => {
  return (
    <div className="terms-content">
      <h2>Terms and Conditions ("Terms")</h2>
      <p>
        Last updated: September 1, 2020
        <br />
        <br />
        Please read these Terms and Conditions ("Terms", "Terms and Conditions")
        carefully before using the Dojohome website and web application (the
        "Service", “Site”) operated by Dojohome ("us", "we", or "our").
        <br />
        <br />
        Your access to and use of the Service is conditioned on your acceptance
        of and compliance with these Terms. These Terms apply to all visitors,
        users and others who access or use the Service.
        <br />
        <br />
        <strong>
          By accessing or using the Service you agree to be bound by these
          Terms. If you disagree with any part of the terms then you may not
          access the Service.
        </strong>
      </p>

      <h3>Services Provided</h3>
      <p>
        Dojohome provides a platform for homeowners looking to sell their home
        to connect with those looking to purchase a home. After a transaction is
        facilitated with the Dojohome platform, additional services (e.g. home
        repair) may be available at additional cost through the platform.
        <br />
        <br />
        Dojohome does not own any properties or offer any lending services.
      </p>

      <h3>Purchases</h3>
      <p>
        If you wish to purchase any product or service (including, but not
        limited to, a la carte equity payments, down payments, broker’s fee,
        home maintenance/repair fees) made available through the Service
        ("Purchase"), you may be asked to supply certain information relevant to
        your Purchase including, without limitation, your full legal name,
        financial status, email address, phone number, and payment information
        (such as bank account details).
        <br />
        <br />
        Any details as to your specific purchases will be outlined in separate
        agreements between You, Dojohome, and any relevant third parties,
        including but not limited to other Dojohome users and partners.
        <br />
        <br />
        As a Dojohome Buyer, your initial purchase will include:
      </p>

      <Table>
        <tbody>
          <tr>
            <td>Minimum Down Payment</td>
            <td>
              2% of Fair Market Value, as displayed on individual property
              listings
            </td>
          </tr>
          <tr>
            <td>Broker’s Fee</td>
            <td>
              If applicable, 3% of Fair Market Value, as displayed on individual
              property listings
            </td>
          </tr>
          <tr>
            <td>Additional Down Payment</td>
            <td>0-8% of Fair Market Value, as defined by You</td>
          </tr>
          <tr>
            <td>1st Month’s Rent</td>
            <td>As displayed on individual property listings</td>
          </tr>
          <tr>
            <td>Month 0 Rent</td>
            <td>
              As displayed on individual property listings, prorated to the amount
              of days left in Month 0
            </td>
          </tr>
          <tr>
            <td>1st Month’s Equity Payment</td>
            <td>As defined by Your Offer</td>
          </tr>
        </tbody>
      </Table>

      <h3>Subscriptions</h3>
      <p>
        Some parts of the Service – including, but not limited to, monthly
        recurring rent and equity payments – are billed on a subscription basis
        ("Subscription(s)").
        <br />
        <br />
        Any details as to your specific subscriptions (including price) will be
        outlined in separate agreements between You, Dojohome, and any relevant
        third parties, including but not limited to other Dojohome users and
        partners.
        <br />
        <br />
        As a Dojohome Buyer, your monthly subscription will include:
      </p>

      <Table>
        <tbody>
          <tr>
            <td>Monthly Rent</td>
            <td>
              As displayed on individual property listings, starting on Month 2
            </td>
          </tr>
          <tr>
            <td>Monthly Equity Payment</td>
            <td>As defined by Your Offer, starting on Month 2</td>
          </tr>
        </tbody>
      </Table>

      <h3>Content</h3>
      <p>
        For purposes of these Terms: (a) " Content" means text, graphics,
        images, music, software, audio, video, works of authorship of any kind,
        and information or other materials that are posted, generated, provided
        or otherwise made available through the Service; and (b) " User Content"
        means any Content that you or other users provide to be made available
        through the Service. Content includes without limitation User Content.
        <br />
        <br />
        Dojohome does not claim any ownership rights in any User Content that
        you make available through the Service and nothing in these Terms will
        be deemed to restrict any rights that you may have to use and exploit
        your User Content. Subject to the foregoing, Dojohome and its licensors
        exclusively own all right, title and interest in and to the Service and
        Content, including all associated intellectual property rights. You
        acknowledge that the Service and Content are protected by copyright,
        trademark, and other laws of the United States and foreign countries.
        You agree not to remove, alter or obscure any copyright, trademark,
        service mark or other proprietary rights notices incorporated in or
        accompanying the Services.
        <br />
        <br />
        By making any User Content available through the Service, you hereby
        grant to Dojohome and each of its affiliates a non-exclusive,
        transferable, sublicensable, worldwide, royalty-free license to use,
        copy, modify, create derivative works based upon, publicly display,
        publicly perform and distribute your User Content in connection with
        operating and providing the Services and Content to you and to other
        users.
        <br />
        <br />
        You are solely responsible for all your User Content. You represent and
        warrant that you own all your User Content or you have all rights that
        are necessary to grant us the license rights in your User Content under
        these Terms. You also represent and warrant that neither your User
        Content, nor your use and provision of your User Content to be made
        available through the Services, nor any use of your User Content by
        Dojohome or other users on or through the Service will infringe,
        misappropriate or violate a third party's intellectual property rights,
        or rights of publicity or privacy, or result in the violation of any
        applicable law or regulation.
        <br />
        <br />
        You can remove your User Content by specifically deleting it. However,
        in certain instances, some of your User Content may not be completely
        removed and copies of your User Content may continue to exist on the
        Services. Neither Divvy nor its affiliates are responsible or liable for
        the removal or deletion of (or the failure to remove or delete) any of
        your User Content.
        <br />
        <br />
        Our Service allows you to post, link, store, share and otherwise make
        available certain information, text, graphics, videos, or other material
        ("Content"). You grant Dojohome the permission to use the Content you
        provide to facilitate the provision of Service.
      </p>

      <h3>Links To Other Web Sites</h3>
      <p>
        Our Service may contain links to third-party web sites or services that
        are not owned or controlled by Dojohome.
        <br />
        <br />
        Dojohome has no control over, and assumes no responsibility for, the
        content, privacy policies, or practices of any third party web sites or
        services. You further acknowledge and agree that My Company (change
        this) shall not be responsible or liable, directly or indirectly, for
        any damage or loss caused or alleged to be caused by or in connection
        with use of or reliance on any such content, goods or services available
        on or through any such web sites or services.
      </p>

      <h3>Consent to Receipt of Communications</h3>
      <p>
        By using the Service, you consent to receive information from us via
        email, SMS, MMS and/or OTT messages sent through an automated telephone
        dialing system (“Text Service”), even if you have opted in to the
        National Do Not Call List, any state Do not Call List, or the internal
        Do Not Call List of any company. You may be required to respond to an
        initial message as instructed to complete your registration and confirm
        enrollment in the Text Service. The enrollment process will disclose the
        program and options to cancel your enrollment. You do not have to
        participate in the Text Service in order to use the Service. In the
        event you no longer want to participate in the Text Service, you agree
        to notify us directly. In the event you change or deactivate your mobile
        telephone number, you agree to promptly update your Company account
        information to ensure that your messages are not sent to the person that
        acquires your old number.
        <br />
        <br />
        There is no additional charge for the Text Service, but your mobile
        carrier’s standard message and data rates apply to any messages you send
        or receive through the Text Service, including confirmations and
        subsequent texts. Your carrier may prohibit or restrict certain mobile
        features and certain mobile features may be incompatible with your
        carrier or mobile device. We are not liable for any delays in the
        receipt of, or any failures to receive, any SMS, MMS or OTT messages, as
        delivery is subject to effective transmission by your mobile carrier and
        compatibility of your mobile device. Please contact your mobile carrier
        if you have any questions regarding these issues or your mobile data and
        messaging plan.
        <br />
        <br />
        As described in the Text Service enrollment and welcome messages,
        including messages sent to a shortcode associated with the Text Service
        or by reply to any message you receive from us, you may text “STOP” to
        cancel. If you choose to cancel your Text Service, you agree to receive
        a final text message from the Text Service confirming your cancellation.
        <br />
        <br />
        In the event that you have the ability to invite others to use the
        Services through the Text Service by providing the numbers of those you
        want to invite or by selecting the individuals you want to invite from
        your contacts list, and by asking us to invite others to use the
        Services, you represent to us that those you invite consented to receive
        the automated invitation messages and that you are authorized to convey
        that consent to us.
      </p>

      <h3>
        No Unlawful or Prohibited Use/Intellectual Property
      </h3>
      <p>
        You are granted a non-exclusive, non-transferable, revocable license to
        access and use the Service strictly in accordance with these terms of
        use. As a condition of your use of the Service, you warrant to Dojohome
        that you will not use the Service for any purpose that is unlawful or
        prohibited by these Terms. You may not use the Service in any manner
        which could damage, disable, overburden, or impair the Site or other
        Service, or interfere with any other party's use and enjoyment of the
        Site or other Service. You may not obtain or attempt to obtain any
        materials or information through any means not intentionally made
        available or provided for through the Site or other Service.
        <br />
        <br />
        You will not modify, publish, transmit, reverse engineer, participate in
        the transfer or sale, create derivative works, or in any way exploit any
        of the content, in whole or in part, found on the Site. Dojohome content
        is not for resale. Your use of the Site or other Service does not
        entitle you to make any unauthorized use of any protected content, and
        in particular you will not delete or alter any proprietary rights or
        attribution notices in any content. You will use protected content
        solely for your personal use, and will make no other use of the content
        without the express written permission of Dojohome and the copyright
        owner. You agree that you do not acquire any ownership rights in any
        protected content. We do not grant you any licenses, express or implied,
        to the intellectual property of Dojohome or our licensors except as
        expressly authorized by these Terms.
      </p>

      <h3>Eligibility</h3>
      <p>
        You may only use the Service if you (1) are 18 years or older, (2) are a
        legal resident of the United States, (3) are capable of forming a
        binding contract, (4) have not been previously suspended from the
        Services, and (5) are not barred from using the Services under
        applicable law.
      </p>

      <h3>Changes</h3>
      <p>
        We reserve the right, at our sole discretion, to modify or replace these
        Terms at any time. If a revision is material we will try to provide at
        least 30 (change this) days' notice prior to any new terms taking
        effect. What constitutes a material change will be determined at our
        sole discretion.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about these Terms, please contact us at <a href="mailto:support@dojohome.com">support@dojohome.com.</a>
      </p>
    </div>
  );
};
