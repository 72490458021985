import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const ADD_CATEGORY = gql `
  mutation AddCategory($header: String!, $thumbnail: String, $status: PropertyCategoryStatus) {
    createPropertyCategory(
        header: $header
        thumbnail: $thumbnail
        status: $status
    ) {
      _id
    }
  }
`;

export default ((category, onCompleted, onError) => {
  const [addCategory, {
    loading,
    error = {}
  }] = useMutation(ADD_CATEGORY, {
    variables: {
      ...category
    },
    refetchQueries: () => ["GetPropertyCategories"],

    onCompleted: data => onCompleted && onCompleted(data)
  });


  return [addCategory, loading, error];
});