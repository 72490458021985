import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const MUTATION = gql`
  mutation PayDownPayment($offer_id: String!, $type: TransactionItemType = down, $amount: Float = 0) {
    buyEquity(offer_id: $offer_id, type: $type, amount: $amount) {
      _id
      payer {
        email
      }
      recipient {
        email
      }
      offer {
        _id
      }
      amount
      status
    }
  }
`

export default (id, amount, onCompleted, onError) => {
  const [buyEquity, { loading, error }] = useMutation(MUTATION, {
      variables: {
        offer_id: id,
        amount
      },

      refetchQueries: () => ["AllProperties", "AllOffers"],
      onCompleted,
      onError
    }
  );

  return [buyEquity, loading, error]
}
