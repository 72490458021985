import gql from "graphql-tag";
import {
useQuery,
useSubscription } from
"@apollo/react-hooks";


const MY_CHATS = gql`
  query GetMyChats {
    getMyChats {
      _id
      lastMessage {
        _id
      }
      lastRead {
        participant
        message
      }
    }
  }
`;

const CHAT_UPDATED = gql`
subscription onChatUpdated {
  chatReadUpdated {
    _id
    lastMessage {
      _id
    }
    lastRead {
      participant
      message
    }
  }
}
`;

export default (() => {
  const { data: chats, loading, error } = useQuery(MY_CHATS, {
    onError: () => {} });


  useSubscription(CHAT_UPDATED, {
    onSubscriptionData: ({
      subscriptionData,
      client }) =>
    {
      const currentChats = client.cache.readQuery({
        query: MY_CHATS
      }).getMyChats;
      
      if (!currentChats) return;
      if (subscriptionData.data.chatReadUpdated) {
        const updatedChat = subscriptionData.data.chatReadUpdated;
        const updatedChats = currentChats.map((chat) =>
        chat._id === updatedChat._id ?
        {
          ...chat,
          lastMessage: updatedChat.lastMessage,
          lastRead: updatedChat.lastRead } :

        chat);

        client.cache.writeQuery({
          query: MY_CHATS,
          data: {
            getMyChats: updatedChats } });


      }
    } });


  return [chats, loading, error];
});