import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { useTranslation } from "react-i18next";

import TransactionHistory from "../../components/payment/TransactionHistory";

export default ({ user, activeOffer, setSelectedPaymentMethod }) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <>
        {/* <Row>
          <Col xs={12}>
            <Card className="mt-1 mb-4">
              <Card.Body>
                <h3>{t("contract.payment.method")}</h3>

                <p>{t("contract.payment.method_label")}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}

        <Row>
          <Col xs={12}>
            <Card className="mt-1 mb-4">
              <Card.Body>
                <TransactionHistory user={user} offer={activeOffer._id} />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </>
    </React.Fragment>
  );
};
