import React from "react";
import PropertyImage1 from "../img/property1.jpg";
import PropertyImage2 from "../img/property2.jpg";
import PropertyImage3 from "../img/property3.jpg";
import { StatusBadge } from "../../../../../components/landing/Badges";

export default [
  {
    price: "140,000",
    priceMonthly: "1,200",
    term: 3,
    equityPayment: 390,
    image: PropertyImage1,
    statusBadge: <StatusBadge status="sold" />,
    sellerInfo: {
      owed: "118,500",
      traditionalProfits: "15,500",
      dojohomeProfits: "43,590"
    }
  },
  {
    price: "225,000",
    priceMonthly: "2,400",
    term: 5,
    equityPayment: 375,
    image: PropertyImage2,
    statusBadge: <StatusBadge status="sold" />,
    sellerInfo: {
      owed: "173,800",
      traditionalProfits: "22,595",
      dojohomeProfits: "63,550"
    }
  },
  {
    price: "350,000",
    priceMonthly: "3,200",
    term: 5,
    equityPayment: 583,
    image: PropertyImage3,
    statusBadge: <StatusBadge status="sold" />,
    sellerInfo: {
      owed: "266,250",
      traditionalProfits: "50,661",
      dojohomeProfits: "107,945"
    }
  }
];
