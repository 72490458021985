import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const UPDATE_CATEGORY = gql `
  mutation UpdateCategory($_id: ID!, $header: String, $thumbnail: String, $status: PropertyCategoryStatus) {
    updatePropertyCategory(
        id: $_id,
        header: $header
        thumbnail: $thumbnail
        status: $status
    ) {
      _id
    }
  }
`;

export default ((category, onCompleted, onError) => {
  const [updateCategory, {
    loading,
    error = {}
  }] = useMutation(UPDATE_CATEGORY, {
    variables: category,
    refetchQueries: () => ["GetPropertyCategories"],

    onCompleted: data => onCompleted && onCompleted(data)
  });


  return [updateCategory, loading, error];
});