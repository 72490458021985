import React, { useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import Calendar from "react-calendar";
import moment from "moment";

import BackNext from "./BackNext";
import useUpdateBuyingPower from "../hooks/mutations/useUpdateBuyingPower";
import { Prev, Next } from "./icons";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const [updateBuyingPower, updating] = useUpdateBuyingPower(data, () => {
    onNext && onNext();
  });

  const [selectedDay, setSelectedDay] = useState(data.moveIn || undefined);
  const disableNext = !data.moveIn;

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateBuyingPower();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateBuyingPower]);

  return (
    <>
      <Form>
        <h3>I'm looking to move in by</h3>
        <Calendar
          value={selectedDay ? moment(selectedDay).toDate() : undefined}
          onChange={(date) => {
            setSelectedDay(date);
            setData({ moveIn: date });
          }}
          locale="en-US"
          calendarType="ISO 8601"
          className="onboarding-calendar"
          minDetail="year"
          minDate={new Date()}
          showNeighboringMonth={false}
          prev2Label={null}
          next2Label={null}
          prevLabel={<Prev />}
          nextLabel={<Next />}
          tileClassName={"calendar-element"}
          formatShortWeekday={(locale, date) =>
            ["Su", "Mo", "Tu", "We", "Th", "Fr", "Sa"][date.getDay()]
          }
        />
      </Form>
      <BackNext
        mode="Buyer"
        stepIndex={stepIndex}
        onNext={updateBuyingPower}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
