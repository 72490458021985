import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import _ from "lodash";
import qs from "qs";

import AppMain from "../AppMain";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Pagination from "react-bootstrap/Pagination";
import { useTranslation, Trans } from "react-i18next";

import PropertyCard from "../../components/general/PropertyCard";
import IdentityVerification from "../../components/user/IdentityVerification";

const MY_PROPERTIES = loader("./MyProperties.graphql");

export default ({ user }) => {
  const { t } = useTranslation();
  const [verifyModal, setVerifyModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const loc = useLocation();
  const page = parseInt(qs.parse(loc.search.substring(1)).page || 1, 10);
  const { loading, data } = useQuery(MY_PROPERTIES, {
    variables: {
      page,
    },
  });

  return (
    <AppMain>
      {loading || !data || !data.myProperties ? null : (
        <React.Fragment>
          <Modal
            show={verifyModal}
            onHide={(e) => setVerifyModal(false)}
            size="lg"
          >
            <Modal.Body className="p-0">
              <IdentityVerification
                user={user}
                uploadLabel={<span>{t("seller.verifyModal.submit")} &gt;</span>}
                onSubmit={(e) => {
                  setVerifyModal(false);
                  setConfirmationModal(true);
                }}
              >
                <h5 className="py-4 text-center">
                  {t("seller.verifyModal.secondaryTitle")}
                </h5>
                <div style={{ background: "#EEF7FF" }} className="p-5">
                  <h2>{t("seller.verifyModal.title")}</h2>
                  <p>{t("seller.verifyModal.text")}</p>
                </div>
              </IdentityVerification>
            </Modal.Body>
          </Modal>
          <Modal
            show={confirmationModal}
            onHide={(e) => setConfirmationModal(false)}
            size="sm"
          >
            <Modal.Body className="p-4 text-center">
              <div className="p-4">
                <svg
                  width="96"
                  height="96"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.99 2C6.47 2 2 6.48 2 12C2 17.52 6.47 22 11.99 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 11.99 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                    fill="#46C9F4"
                  />

                  <path
                    d="M12.5 7H11V13L16.25 16.15L17 14.92L12.5 12.25V7Z"
                    fill="#46C9F4"
                  />
                </svg>
              </div>
              <h4>{t("seller.confirmationModal.title")}</h4>
              <Trans i18nKey="seller.confirmationModal.text" />

              <Button
                block
                className="text-uppercase"
                variant="primary"
                onClick={(e) => setConfirmationModal(false)}
              >
                {t("seller.menu.properties")}
              </Button>
            </Modal.Body>
          </Modal>
          <Row>
            <Col xs={12}>
              <h1 className="text-center mt-5 mb-4">
                {t("seller.menu.properties")}
              </h1>
            </Col>
            {!user.verified ? (
              <Col xs={12}>
                <Alert variant="primary" className="d-flex">
                  <div className="flex-grow-1 text-light">
                    <p className="pt-2">{t("seller.verifyAlert")}</p>
                  </div>
                  <div className="flex-grow-0">
                    <Button
                      variant="primary"
                      className="px-4"
                      onClick={(e) => setVerifyModal(true)}
                      style={{
                        textDecoration: "none",
                        fontWeight: "bold",
                        border: "solid 2px #fff",
                      }}
                    >
                      {t("seller.verifyButton")}
                    </Button>
                  </div>
                </Alert>
              </Col>
            ) : null}
            <Col xs={12}>
              <Row>
                <Col
                  xs={12}
                  md={6}
                  lg={4}
                  className="d-flex pb-3"
                  style={{ justifyItems: "stretch" }}
                >
                  <Card
                    as={Link}
                    to="/seller/property/new"
                    className="py-4 flex-grow-1"
                    style={{
                      borderStyle: "dashed",
                      borderWidth: 2,
                      background: "transparent",
                      boxShadow: "none",
                    }}
                  >
                    <Card.Body className="d-flex flex-column justify-content-center align-items-center">
                      <svg
                        width="64"
                        height="64"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 20V14H14V20H19V12H22L12 3L2 12H5V20H10Z"
                          fill="#46C9F4"
                        />
                      </svg>

                      <h5 className="mt-1">{t("seller.addProperty")}</h5>
                    </Card.Body>
                  </Card>
                </Col>

                {_.chain(data.myProperties.properties)
                  .sortBy(["closed", "under_contract", "verified"])
                  .reverse()
                  .map((property) => (
                    <Col
                      key={property._id}
                      xs={12}
                      md={6}
                      lg={4}
                      className="d-flex pb-3"
                      style={{ justifyItems: "stretch" }}
                    >
                      <PropertyCard
                        user={user}
                        property={property}
                        own={true}
                      />
                    </Col>
                  ))
                  .value()}
              </Row>
              {data.myProperties.pages > 1 ? (
                <Row>
                  <Col
                    xs={12}
                    className="text-center my-5"
                    style={{ display: "inline-flex" }}
                  >
                    <Pagination style={{ margin: "0 auto" }}>
                      <Pagination.First
                        as={Link}
                        to="/seller/properties"
                        href="/seller/properties"
                      />

                      {_.range(1, data.myProperties.pages + 1).map((pg) => (
                        <Pagination.Item
                          key={pg}
                          active={pg === page}
                          as={Link}
                          href={`/seller/properties?page=${pg}`}
                          to={`/seller/properties?page=${pg}`}
                        >
                          {pg}
                        </Pagination.Item>
                      ))}

                      <Pagination.Last
                        as={Link}
                        href={`/seller/properties?page=${data.myProperties.pages}`}
                        to={`/seller/properties?page=${data.myProperties.pages}`}
                      />
                    </Pagination>
                  </Col>
                </Row>
              ) : null}
            </Col>
          </Row>
        </React.Fragment>
      )}
    </AppMain>
  );
};
