import React from "react";
import _ from "lodash";
import moment from "moment";

import { Row, Col, Button, Form, Table } from "react-bootstrap";

import useGetBuyingPower from "../../../components/hooks/queries/useGetBuyingPower";
import Avatar from "../../../components/general/Avatar";

export default ({ user }) => {
  const [users, loading, error] = useGetBuyingPower();

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2>Buying Power Responses</h2>
      </Col>

      <Col xs={12}>
        {loading || !users ? (
          "loading..."
        ) : (
          <Table striped bordered hover size="sm">
            <thead>
              <th>Buyer</th>
              <th>Data</th>
              <th>Submitted From</th>
              <th>Submitted On</th>
            </thead>
            <tbody>
              {users.map((u) => (
                <tr key={u._id}>
                  <td>
                    <div>
                      <Avatar user={u} size={20} />{" "}
                      <span>
                        {u.firstname} {u.lastname}
                      </span>
                    </div>
                    <div>{u.email}</div>
                  </td>
                  <td>
                    <Form.Check
                      checked={u.buyingPower.isEligible}
                      label="Is Eligble?"
                      size="sm"
                    />
                  </td>
                  <td>{u.buyingPower.submittedFrom}</td>
                  <td>{moment(u.buyingPower.submittedAt).format("LLL")}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Col>
      <Col xs={12} className="mt-3">
        <Button variant="outline-primary" onClick={() => {}}>
          Export (TODO)
        </Button>
      </Col>
    </Row>
  );
};
