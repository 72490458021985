import React from "react";
import classNames from "classnames";
import { Link } from "react-scroll";

const linkStyle = {
  cursor: "pointer",
};

export default ({ scrollable, isSticky, listing }) => (
  <div className={classNames("QuickAccessMenu d-flex", { sticky: isSticky })}>
    <div>
      {scrollable
        .filter(({ hidden }) => !hidden)
        .map(({ section, title }) => (
          <Link
            activeClass="text-primary"
            key={section}
            to={section}
            spy={true}
            smooth={true}
            offset={-128}
            style={linkStyle}
          >
            {title}
          </Link>
        ))}
    </div>
  </div>
);
