import React from "react";
import {
  MoneyBox,
  AnnualPay,
  Growth
} from "../../../../../components/landing/icons";

export default [
  {
    icon: <MoneyBox />,
    title: "Save on fees",
    text: "The Dojo platform significantly reduces unpredictable costs and holding expenses, which on average, eat up to 50% of expected net profits."
  },
  {
    icon: <AnnualPay />,
    title: "Earn monthly income",
    text: "Earn rental income every month while our platform handles the heavy lifting of property management so you don't have to."
  },
  {
    icon: <Growth />,
    title: "Maintain tax & appreciation benefits",
    text: "Continue to enjoy tax and appreciation benefits on your property until it’s purchased outright."
  }
]