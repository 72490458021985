import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import BackNext from "../BackNext";
import SelectCards from "../SelectCards";
import { HouseWork, HouseCheck, HouseShiny } from "../icons";
import useUpdateSellerInfo from "../../hooks/mutations/useUpdateSellerInfo";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const options = [
    { icon: HouseWork, label: "Needs a lot of work", value: "needsWork" },
    { icon: HouseCheck, label: "Is move-in ready", value: "ready" },
    { icon: HouseShiny, label: "Updated and pristine", value: "pristine" },
  ];

  const [updateSellerInfo, updating] = useUpdateSellerInfo(data, () => {
    onNext && onNext();
  });

  const disableNext = !data.propertyStatus;

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateSellerInfo();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateSellerInfo]);

  return (
    <>
      <Form className="form-wide">
        <h4>My property...</h4>
        <SelectCards
          options={options}
          data={data}
          field="propertyStatus"
          setData={setData}
        />
      </Form>
      <BackNext
        mode="Seller"
        stepIndex={stepIndex}
        onNext={updateSellerInfo}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
