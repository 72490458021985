import React from "react";
import moment from "moment";

import {
  DoubleBed,
  BathTub,
  Key,
  House,
  Appointment,
} from "../../../components/icons";
import ListingSummaryFeature from "../components/ListingSummaryFeature";
import useCurrency from "../../../components/hooks/helpers/useCurrency";
import ListingSummaryInfo from "../components/ListingSummaryInfo";
import ListingDescription from "../components/ListingDescription";

export default ({ listing }) => {
  const currency = useCurrency();

  return (
    <div id="summary">
      <div className="ListingSummaryFeatures">
        <ListingSummaryFeature icon={DoubleBed} value={listing.beds} />
        <ListingSummaryFeature icon={BathTub} value={listing.baths} />
        <ListingSummaryFeature
          label="Price"
          value={currency(listing.price)}
        />
        <ListingSummaryFeature
          label="Monthly Rent"
          value={currency(listing.rent)}
        />
        <ListingSummaryFeature
          label={`${currency(listing.price / listing.sqft)} / Square Feet`}
          value={
            <React.Fragment>
              {listing.sqft} FT<sup>2</sup>
            </React.Fragment>
          }
        />
      </div>

      <ListingSummaryInfo
        icon={Key}
        text={`Ready for you to move in on ${moment(
          listing.available_from
        ).format("LL")}`}
      />
      {listing.openHouse && (
        <ListingSummaryInfo
          icon={House}
          text={`Open house on ${moment(listing.openHouse).format("LL")}`}
        />
      )}

      <ListingSummaryInfo
        icon={Appointment}
        text="Schedule Tour"
        action={{
          func: () => {
            window.HubSpotConversations &&
              window.HubSpotConversations.widget &&
              window.HubSpotConversations.widget.open();
          },
          label: "CONTACT US",
        }}
      />

      <ListingDescription listing={listing} />
    </div>
  );
};
