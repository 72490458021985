import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, Alert } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import _ from "lodash";
import classNames from "classnames";
import { Link } from "react-router-dom";

import "react-phone-input-2/lib/style.css";

import useRegisterMember from "../hooks/queries/useRegisterMember";
import useRegisterSeller from "../hooks/queries/useRegisterSeller";
import useAnonymous from "../hooks/queries/useAnonymous";
import BackNext from "./BackNext";
import { Hint, Show } from "./icons";
import Disclaimer from "./Disclaimer";

export default ({ setData, onNext, onPrev, mode, stepIndex }) => {
  const { t } = useTranslation();

  const [user, setUser] = useState({ terms: true });
  useAnonymous(undefined, (data) => {
    setUser({ ...user, email: data.anonymous.email });
  });

  const getValue = (param) => user[param] || "";
  const setValue = (param, value) => setUser({ ...user, [param]: value });

  const onSuccess = ({ _id }) => {
    setData({ phone: user.phone, user_id: _id });
    onNext();
  };

  const onError = (error) => console.log(error);

  const [registerMember, loadingMember, errorMember] = useRegisterMember(
    onSuccess,
    onError
  );
  const [registerSeller, loadingSeller, errorSeller] = useRegisterSeller(
    onSuccess,
    onError
  );

  const { register, error, loading } =
    mode === "seller"
      ? {
          register: registerSeller,
          error: errorSeller,
          loading: loadingSeller,
        }
      : {
          register: registerMember,
          error: errorMember,
          loading: loadingMember,
        };

  const errorMessage =
    error && error.graphQLErrors && error.graphQLErrors[0].message;

  const validationErrors =
    error &&
    error.graphQLErrors &&
    error.graphQLErrors[0].extensions.validationErrors;

  const [inputType, setInputType] = useState("password");

  const [changedFields, setChangedFields] = useState({});

  const disableDropdown = process.env.REACT_APP_PHONE_COUNTRY_DROPDOWN !== "enable";

  const MemberFields = [
    {
      label: t("login.registration_form.firstname"),
      path: "firstname",
      type: "text",
      placeholder: t("login.registration_form.firstname_placeholder"),
      isValid: user.firstname && user.firstname.length > 1,
    },

    {
      label: t("login.registration_form.lastname"),
      path: "lastname",
      type: "text",
      placeholder: t("login.registration_form.firstname_placeholder"),
      isValid: user.lastname && user.lastname.length > 1,
    },

    {
      label: t("login.registration_form.email"),
      path: "email",
      type: "email",
      placeholder: t("login.registration_form.email_placeholder"),
      disabled: mode === "member",
      postError: (
        <React.Fragment>
          . Do you want to <Link to="?login">login</Link>?
        </React.Fragment>
      ),
      isValid: user.email,
    },
    {
      label: t("login.registration_form.phone"),
      path: "phone",
      type: "tel",
      isValid: user.phone,
      render: () => (
        <React.Fragment>
          <PhoneInput
            country={"us"}
            value={user.phone || ""}
            placeholder={t("login.registration_form.phone")}
            onChange={(phone) => {
              setUser({ ...user, phone });
              setChangedFields({ ...changedFields, phone: true });
            }}
            disableDropdown={disableDropdown}
            countryCodeEditable={!disableDropdown}
            buttonStyle={disableDropdown ? {cursor: "default"} : {}}
            isInvalid={
              changedFields.phone
                ? null
                : validationErrors && !!validationErrors.phone
            }
          />
        </React.Fragment>
      ),
    },
    {
      label: t("login.registration_form.password"),
      path: "password",
      type: inputType,
      placeholder: t("login.registration_form.password_placeholder"),
      isValid: user.password && user.password.length >= 8,
    },
    {
      label: t("login.registration_form.passwordRepeat"),
      path: "passwordrepeat",
      type: inputType,
      placeholder: t("login.registration_form.passwordRepeat_placeholder"),
      isValid: user.password && user.password === user.passwordrepeat,
    },
  ];

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 13) {
        setChangedFields({});
        register(user);
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [register, user]);

  return (
    <>
      <h2>Almost Done!</h2>
      <Form className="profile-form">
        {errorMessage && (
          <Alert variant="danger">
            <p>{errorMessage}</p>
          </Alert>
        )}
        <div className="d-flex">
          {_.filter(MemberFields, (field) =>
            ["firstname", "lastname"].includes(field.path)
          ).map((field) => (
            <Form.Group
              controlId={`form-${field.path}-group`}
              key={field.path}
              className={classNames(field.path, {
                check: field.isValid,
              })}
            >
              <Form.Control
                type={field.type}
                disabled={loading || field.disabled}
                placeholder={field.label}
                value={getValue(field.path)}
                onChange={(e) => {
                  setValue(field.path, e.target.value);
                  setChangedFields({ ...changedFields, [field.path]: true });
                }}
                isInvalid={
                  changedFields[field.path]
                    ? null
                    : validationErrors && validationErrors[field.path]
                }
              />

              {validationErrors && validationErrors[field.path] && (
                <Form.Control.Feedback type="invalid">
                  {validationErrors[field.path].join(", ")}
                  {field.postError || null}
                </Form.Control.Feedback>
              )}
            </Form.Group>
          ))}
        </div>
        {_.filter(
          MemberFields,
          (field) => !["firstname", "lastname"].includes(field.path)
        ).map((field) => (
          <Form.Group
            controlId={`form-${field.path}-group`}
            key={field.path}
            className={classNames(field.path, {
              check: field.isValid,
            })}
          >
            {(field.render && field.render()) || (
              <React.Fragment>
                {field.type === "check" && (
                  <Form.Check
                    type="checkbox"
                    disabled={loading}
                    label={field.label}
                    value={getValue(field.path)}
                    onChange={(e) => {
                      setValue(field.path, e.target.checked);
                      setChangedFields({
                        ...changedFields,
                        [field.path]: true,
                      });
                    }}
                    isInvalid={
                      changedFields[field.path]
                        ? null
                        : validationErrors && validationErrors[field.path]
                    }
                  />
                )}

                {field.type !== "check" && (
                  <React.Fragment>
                    <Form.Control
                      type={field.type}
                      disabled={loading || field.disabled}
                      placeholder={field.label}
                      value={getValue(field.path)}
                      onChange={(e) => {
                        setValue(field.path, e.target.value);
                        setChangedFields({
                          ...changedFields,
                          [field.path]: true,
                        });
                      }}
                      isInvalid={
                        changedFields[field.path]
                          ? null
                          : validationErrors && validationErrors[field.path]
                      }
                    />
                  </React.Fragment>
                )}

                {field.path === "password" && (
                  <Button
                    className="password-show"
                    onClick={() =>
                      inputType === "password"
                        ? setInputType("text")
                        : setInputType("password")
                    }
                  >
                    <Show />
                  </Button>
                )}

                {validationErrors && validationErrors[field.path] && (
                  <Form.Control.Feedback type="invalid">
                    {validationErrors[field.path].join(", ")}
                    {field.postError || null}
                  </Form.Control.Feedback>
                )}
              </React.Fragment>
            )}
          </Form.Group>
        ))}{" "}
        {false && (
          <Row>
            <Col>
              <span>
                What year were you born? <Hint />
              </span>
            </Col>
            <Col>
              <Form.Group className={classNames("year", { check: user.year })}>
                <Form.Control
                  as="select"
                  disabled={loading}
                  value={getValue("year")}
                  isInvalid={
                    changedFields.year
                      ? null
                      : validationErrors && validationErrors.year
                  }
                  onChange={(e) => {
                    setValue("year", e.target.value);
                    setChangedFields({ ...changedFields, year: true });
                  }}
                >
                  <option disabled />
                  {_.range(2010, 1920).map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Form.Control>

                {validationErrors && validationErrors.year && (
                  <Form.Control.Feedback type="invalid">
                    {validationErrors.year.join(", ")}
                  </Form.Control.Feedback>
                )}
              </Form.Group>
            </Col>
          </Row>
        )}
      </Form>
      <BackNext
        mode={_.capitalize(mode)}
        stepIndex={stepIndex}
        onNext={() => {
          setChangedFields({});
          register(user);
        }}
        updating={loading}
        subtitle={<Disclaimer />}
        nextText="Create Account"
      />
    </>
  );
};
