import React, { useEffect } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";

import { DojoLogoDark } from "../../landing/icons";
import useUpdateSellerInfo from "../../hooks/mutations/useUpdateSellerInfo";
import AddressSearch from "../../landing/AddressSearch";
import BackNext from "../BackNext";

export default ({ data, setData, subtitle, onNext, stepIndex }) => {
  const [updateSellerInfo, updating] = useUpdateSellerInfo(
    data,
    () => {
      onNext && onNext();
    },
    (error) => console.log(error)
  );

  const disableNext = updating || !data.property;

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateSellerInfo();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateSellerInfo]);

  return (
    <>
      <DojoLogoDark />
      <h2>Get Started</h2>
      <Form>
        <h4>Let’s start with your property address:</h4>
        <AddressSearch
          setData={setData}
          field="property"
          placeholder="Your property address"
          className="single-control"
          value={data && data.property && `${data.property.address}`}
        />
      </Form>
      <BackNext
        mode="Seller"
        stepIndex={stepIndex}
        onNext={updateSellerInfo}
        subtitle={subtitle}
        disableNext={disableNext}
      />
    </>
  );
};
