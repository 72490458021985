import React from "react";
import { OverlayTrigger, Popover } from "react-bootstrap";
import classNames from "classnames";

import { Info } from "../landing/icons";

export default ({ id, message, dark, placement = "bottom" }) => {
  return (
    <OverlayTrigger
      trigger="hover"
      placement={placement}
      overlay={
        <Popover id={id} className={classNames({"popover-dark" : dark})}>
          <Popover.Content>
            <p className="my-1 text-center">{message}</p>
          </Popover.Content>
        </Popover>
      }
    >
      <sup className="landing-tooltip">
        <Info />
      </sup>
    </OverlayTrigger>
  );
};
