import gql from "graphql-tag";
import _ from "lodash";
import { useMutation } from "@apollo/react-hooks";

import { resetQueries } from "../../../../apollo-tools";

const WITHDRAW_OFFER = gql`
  mutation WithdrawAOffer($id: String!) {
    updateApplication(id: $id, status: withdrawn) {
      message
    }
  }
`

const UPDATE_OFFER = gql`
  mutation UpdateOffer($offer: PropertyOfferInput!) {
    updateOffer(offer: $offer) {
      _id
    }
  }
`

const stripOffer = (offer) => 
  _.omit(offer, [
    "__typename",
    "listing",
    "plan.__typename"
  ])


export default (id, onCompleted, onError) => {
  const refetchers = {
    refetchQueries: () => ["GetMyOffer", "MyMemberOffers"],
    update: resetQueries(["property", "getApplications", "myProperty"]),
  }

  const [withdrawOffer, {loading: withdrawing, error: withdrawError}] = useMutation(WITHDRAW_OFFER, {
    variables: { id },
    ...refetchers,

    onCompleted,
    onError
  });

  const [updateOffer, {loading: updating, error: updateError}] = useMutation(UPDATE_OFFER, {
    variables: { id },

    ...refetchers,
    onCompleted,
    onError
  });

  return {
    withdrawOffer,
    updateOffer: (offer) => updateOffer({variables: {offer: stripOffer(offer)}}),
    loading: withdrawing || updating,
    errors: {
      update: updateError,
      withdraw: withdrawError
    }
  }
};
