import React, { useState, useRef } from "react";
import {
  Button,
  Row,
  Col,
  Form,
  Card,
  ListGroup,
  Alert,
} from "react-bootstrap";
import _ from "lodash";
import currencyFormatter from "currency-formatter";
import { useTranslation } from "react-i18next";

import { DojoLogoDark } from "./icons";
import AddressSearch from "./AddressSearch";
import FullCalculator from "./FullCalculator";
import CalculatorInput from "./CalculatorInput";
import AnalyticsEvent from "../general/AnalyticsEvent";

export default () => {
  const { t } = useTranslation();

  const [defaults, setDefaults] = useState({
    marketPrice: 300000,
    rent: 1800,
    bankOwed: 237000,
    fees: 1350 + 125 + 125,
  });

  const currency = (amount) =>
    currencyFormatter.format(amount, { code: "USD", precision: 0 });
  const [calculatorFull, setCalculatorFull] = useState(false);
  const [location, setLocation] = useState({});
  const [listing, setListing] = useState({});

  const marketPrice = {
    default: defaults.marketPrice,
    isValid: !listing.marketPrice || listing.marketPrice >= 50000,
    errorMessage: `Please enter amount above ${currency(50000)}`,
    value:
      listing.marketPrice && listing.marketPrice >= 50000
        ? listing.marketPrice
        : defaults.marketPrice,
  };

  const rent = {
    default: defaults.rent,
    isValid: !listing.rent || listing.rent >= listing.marketPrice * 0.005,
    errorMessage: `Please enter amount above ${currency(
      listing.marketPrice * 0.005
    )}`,
    value:
      listing.rent && listing.rent >= marketPrice.value * 0.005
        ? listing.rent
        : marketPrice.value * 0.005,
  };

  const bankOwed = {
    default: defaults.bankOwed,
    isValid: !listing.bankOwed || listing.bankOwed <= marketPrice.value * 0.9,
    errorMessage: `Please enter amount below ${currency(
      marketPrice.value * 0.9
    )}`,
    value:
      listing.bankOwed && listing.bankOwed <= marketPrice.value * 0.9
        ? listing.bankOwed
        : listing.marketPrice
        ? marketPrice.value * 0.9
        : defaults.bankOwed,
  };

  const fees = {
    default: defaults.fees,
    isValid: true,
    value: listing.fees ? listing.fees : defaults.fees,
  };

  const listingNumbers = {
    marketPrice: Number(marketPrice.value),
    rent: Number(rent.value),
    bankOwed: Number(bankOwed.value),
    fees: Number(fees.value),
  };

  const propertyMaintenance = listingNumbers.rent * 0.05;

  const realtorFees =
    listingNumbers.marketPrice * 0.02 +
    listingNumbers.marketPrice * 0.97 * 0.06 +
    listingNumbers.marketPrice * 0.97 * 0.01 +
    listingNumbers.fees * 4;

  const dojoCalc = _.transform(
    _.range(0, 5),
    (result, year) => {
      const transactionFees = listingNumbers.marketPrice * 0.02;
      const closingCosts =
        (listingNumbers.marketPrice + result.annualAppreciation) * 0.005;

      const mortgageEquityPaydown =
        result.mortgageEquityPaydown +
        listingNumbers.bankOwed * (0.025 + 0.001 * year);
      const netRentalIncome =
        (listingNumbers.rent - (listingNumbers.fees + propertyMaintenance)) *
        12;
      const annualAppreciation =
        result.annualAppreciation +
        (result.annualAppreciation + listingNumbers.marketPrice) * 0.02;

      result.transactionFees = transactionFees;
      result.closingCosts = closingCosts;
      result.holdingCosts = listingNumbers.fees;
      result.totalFees = transactionFees + closingCosts + listingNumbers.fees;

      result.mortgageEquityPaydown = mortgageEquityPaydown;
      result.netRentalIncome = netRentalIncome;
      result.annualAppreciation = annualAppreciation;
      result.totalIncome =
        mortgageEquityPaydown + netRentalIncome + annualAppreciation;

      return result;
    },
    {
      closingCosts: 0,
      mortgageEquityPaydown: 0,
      annualAppreciation: 0,
    }
  );

  const dojoFees = dojoCalc.totalFees;
  const dojoIncome = dojoCalc.totalIncome;

  const hasData =
    listingNumbers.marketPrice &&
    listingNumbers.rent &&
    listingNumbers.bankOwed &&
    listingNumbers.fees;

  const addressRef = useRef(null);
  const marketPriceRef = useRef(null);
  const rentRef = useRef(null);
  const bankOwedRef = useRef(null);
  const feesRef = useRef(null);

  const [reportError, setReportError] = useState({});
  const handleError = ({ field, ref }) => {
    setReportError({ [field]: "Please fill in this field" });
    if (document.documentElement.scrollIntoViewIfNeeded) {
      ref.current.scrollIntoViewIfNeeded(false);
    } else {
      ref.current.scrollIntoView();
    }
  };

  const fields = [
    {
      field: "address",
      ref: addressRef,
      condition: !!location.address,
    },
    {
      field: "marketPrice",
      ref: marketPriceRef,
      condition: !!listing.marketPrice,
    },
    {
      field: "rent",
      ref: rentRef,
      condition: !!listing.rent,
    },
    {
      field: "bankOwed",
      ref: bankOwedRef,
      condition: !!listing.bankOwed,
    },
    {
      field: "fees",
      ref: feesRef,
      condition: !!listing.fees,
    },
  ];

  const canAskReport = !fields.map((field) => field.condition).includes(false);

  const handleClick = () => {
    if (canAskReport) {
      setCalculatorFull(true);
    } else {
      handleError(_.find(fields, ["condition", false]));
    }
  };

  const setValue = (field, value) => setListing({ ...listing, [field]: value });

  return !calculatorFull ? (
    <div className="landing-calculator">
      <Row className="pb-5">
        <Col lg={{ span: 6, offset: 3 }}>
          <AddressSearch
            setData={setLocation}
            className={reportError.address && "is-invalid"}
            ref={addressRef}
          />
          <Form.Control.Feedback type="invalid">
            {reportError.address}
          </Form.Control.Feedback>
        </Col>
      </Row>
      <Row>
        <Col className="calculator-aside d-flex" xs={12} lg={4}>
          <Card className="w-100">
            <Form noValidate>
              <Card.Body>
                <CalculatorInput
                  currency
                  label={t("calculator.marketPrice.label")}
                  tooltip={t("calculator.marketPrice.tooltip")}
                  value={listing.marketPrice}
                  placeholder={marketPrice.default}
                  onChange={(v) => {
                    setValue("marketPrice", v);
                    reportError.marketPrice && setReportError({});
                  }}
                  maxLength={7}
                  step="1000"
                  ref={marketPriceRef}
                  errorMessage={
                    !marketPrice.isValid
                      ? marketPrice.errorMessage
                      : reportError.marketPrice
                  }
                />

                <CalculatorInput
                  currency
                  label={t("calculator.rent.label")}
                  tooltip={t("calculator.rent.tooltip")}
                  value={listing.rent}
                  placeholder={rent.default}
                  onChange={(v) => {
                    setValue("rent", v);
                    reportError.rent && setReportError({});
                  }}
                  maxLength={5}
                  step="100"
                  ref={rentRef}
                  errorMessage={
                    !rent.isValid
                      ? rent.errorMessage
                      : reportError.rent
                  }
                />

                <CalculatorInput
                  currency
                  label={t("calculator.bankOwed.label")}
                  tooltip={t("calculator.bankOwed.tooltip")}
                  value={listing.bankOwed}
                  placeholder={bankOwed.default}
                  onChange={(v) => {
                    setValue("bankOwed", v);
                    reportError.bankOwed && setReportError({});
                  }}
                  maxLength={7}
                  step="1000"
                  ref={bankOwedRef}
                  errorMessage={
                    !bankOwed.isValid
                      ? bankOwed.errorMessage
                      : reportError.bankOwed
                  }
                />

                <CalculatorInput
                  currency
                  label={t("calculator.fees.label")}
                  tooltip={t("calculator.fees.tooltip")}
                  value={listing.fees}
                  placeholder={fees.default}
                  onChange={(v) => {
                    setValue("fees", v);
                    reportError.fees && setReportError({});
                  }}
                  maxLength={5}
                  step="100"
                  ref={feesRef}
                  errorMessage={reportError.fees}
                />

                {!_.isEmpty(reportError, true) && (
                  <Alert variant="danger">
                    <p>Please fill in all the required fields.</p>
                  </Alert>
                )}

                <AnalyticsEvent pixelEvent="Get a Detailed Report ">
                  {(onClick) => (
                    <Button
                      variant="dojobrand"
                      className="px-3 py-3"
                      style={{ borderRadius: 6 }}
                      onClick={() => {
                        handleClick();
                        onClick();
                      }}
                      block
                    >
                      Get a detailed report
                    </Button>
                  )}
                </AnalyticsEvent>
              </Card.Body>
            </Form>
          </Card>
        </Col>

        <Col className="calculator-main d-flex" xs={12} lg={8}>
          <Card className="w-100">
            <Card.Body>
              <Row className="text-primary p-3">
                <Col lg={6}>
                  <h4 className="text-dark">Estimated Net Profits</h4>
                </Col>
                <Col lg={3}>
                  <h4 className="text-dark" style={{ opacity: 0.5 }}>
                    Traditional
                  </h4>
                </Col>
                <Col lg={3}>
                  <DojoLogoDark />
                </Col>
              </Row>

              <ListGroup>
                <ListGroup.Item>
                  <Row>
                    <Col xs={12} lg={6}>
                      Eventual Sale Price
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(listingNumbers.marketPrice)}
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(
                        listingNumbers.marketPrice + dojoCalc.annualAppreciation
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col xs={12} lg={6}>
                      Realtor and other Fees & Costs
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(-realtorFees)}
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(-dojoFees)}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col xs={12} lg={6}>
                      Mortgage Balance upon sale
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(-listingNumbers.bankOwed)}
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(
                        -listingNumbers.bankOwed +
                          dojoCalc.mortgageEquityPaydown
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item className="summary">
                  <Row>
                    <Col xs={12} lg={6}>
                      Total Net Profit
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(
                        listingNumbers.marketPrice -
                          listingNumbers.bankOwed -
                          realtorFees
                      )}
                    </Col>
                    <Col xs={12} lg={3}>
                      {currency(
                        listingNumbers.marketPrice +
                          dojoCalc.annualAppreciation -
                          dojoFees -
                          listingNumbers.bankOwed +
                          dojoCalc.mortgageEquityPaydown
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
              </ListGroup>

              {hasData ? (
                <Alert className="text-center mt-3 py-3">
                  <div className="text-dark">
                    Make {currency(dojoIncome + realtorFees - dojoFees)} more{" "}
                    <strong className="text-primary">with Dojohome</strong> vs.
                    a traditional sale
                  </div>
                </Alert>
              ) : (
                <Alert className="text-center mt-3 py-3">
                  <div className="text-dark">
                    <span className="pr-2">
                      <svg
                        width="20"
                        height="16"
                        viewBox="0 0 16 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15.6423 7.64226C15.997 7.28755 15.997 6.71245 15.6423 6.35774L9.86191 0.577384C9.5072 0.222672 8.9321 0.222672 8.57738 0.577384C8.22267 0.932095 8.22267 1.5072 8.57738 1.86191L13.7155 7L8.57738 12.1381C8.22267 12.4928 8.22267 13.0679 8.57738 13.4226C8.9321 13.7773 9.5072 13.7773 9.86191 13.4226L15.6423 7.64226ZM0 7.9083H15V6.0917H0V7.9083Z"
                          fill="#1CB5FA"
                        />
                      </svg>
                    </span>{" "}
                    Enter your info to see how much more you can make with{" "}
                    <strong className="text-primary">Dojo</strong>!
                  </div>
                </Alert>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  ) : (
    <div className="landing-calculator">
      <Card>
        <Card.Body>
          <FullCalculator
            listing={listingNumbers}
            location={location}
            priceDefaults={defaults}
          />
        </Card.Body>
      </Card>
    </div>
  );
};
