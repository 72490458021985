import React, { useState } from "react";

import Main from "../../../../components/landing/Main";
import Section from "../../../../components/landing/Section";
import Explanation from "../../../../components/landing/Explanation";
import Steps from "../../../../components/landing/Steps";
import Comments from "../../../../components/landing/Comments";
import Card from "../../../../components/landing/Card";
import Calculator from "../../../../components/landing/Calculator";
import Properties from "../../../../components/landing/Properties";
import Comparison from "../../../../components/landing/Comparison";
import Accordion from "../../../../components/landing/Accordion";
import OnBoardingContext from "../../../../components/context/OnBoardingContext";
import AnalyticsEvent from "../../../../components/general/AnalyticsEvent";

import explanation from "./data/explanation";
import steps from "./data/steps";
import comment from "./data/comment";
import comments from "./data/comments";
import properties from "./data/properties";
import perks from "./data/perks.jsx";
import comparison from "./data/comparison";
import faq from "./data/faq";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import "./Seller.scss";

export default ({ user }) => {
  const [onboarding, setOnBoarding] = useState({});
  const [open, setOpen] = useState(false);
  const isSeller = true;
  const onBoardingContextData = {
    onboarding,
    setOnBoarding,
    open,
    setOpen,
    isSeller,
  };

  const sections = [
    {
      id: "maximize-your-profits",
      navbarColor: "gradient",
      centered: true,
      className: "nopadding",
      content: (
        <>
          <h1>
            Maximize your profits,
            <br />
            minimize your hassle
          </h1>

          <h3>
            Earn 2-3x more by selling your home with Dojo, the world's first {" "}
            <br />
            marketplace for Rent to Own properties
          </h3>

          <div className="inline-buttons">
            <AnalyticsEvent pixelEvent="Sign Up Seller">
              {(onClick) => (
                <Button variant="dojobrand" as={Link} to="?signup" onClick={onClick}>
                  Get Started
                </Button>
              )}
            </AnalyticsEvent>

            <AnalyticsEvent pixelEvent="Learn How Seller">
              {(onClick) => (
                <Button variant="outline-light" href="#put-more-profit" onClick={onClick}>
                  Learn Now
                </Button>
              )}
            </AnalyticsEvent>
          </div>
        </>
      ),
    },
    {
      id: "what-is",
      centered: true,
      controls: "dark",
      content: (
        <>
          <h2>
            Why should you <span>sell your home with Dojo?</span>
          </h2>

          <p className="text-center">
            When you sell your property through Dojo, the future owner pays you
            rent as they save for a down payment and become “mortgage-ready”.
          </p>

          <p className="text-center">
            Our platform makes this process transparent and hassle-free by
            connecting aspiring homeowners with sellers and managing all
            transactions from start to finish. Allowing you to sit back,
            generate income from monthly rent, and ultimately sell your property
            in the most profitable way possible.
          </p>

          <AnalyticsEvent pixelEvent="Sign Up Seller">
            {(onClick) => (
              <Button variant="dark" as={Link} to="?signup" onClick={onClick}>
                Sign Up
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
    },
    {
      id: "put-more-profit",
      centered: true,
      controls: "dark",
      content: (
        <>
          <h2>
            Put <span>more profit</span> in your pocket,
            <br />
            every step of the way
          </h2>

          <Explanation items={explanation} />

          <AnalyticsEvent pixelEvent="Calculator">
            {(onClick) => (
              <Button variant="dark" href="#calculator" onClick={onClick}>
                Calculate your profits
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
    },
    {
      id: "radically-easy",
      aside: true,
      content: (
        <>
          <h2>
            Radically <span>Easy</span> & Delightfully <span>Profitable</span>
          </h2>
          <h3>From start to close our platform’s got you covered</h3>
          <Steps steps={steps} />
          <div className="inline-buttons">
            <AnalyticsEvent pixelEvent="Get Started Seller">
              {(onClick) => (
                <Button variant="dojobrand" as={Link} to="?signup" onClick={onClick}>
                  Get Started
                </Button>
              )}
            </AnalyticsEvent>
            <AnalyticsEvent pixelEvent="Calculate your profits 2">
              {(onClick) => (
                <Button variant="outline-light" href="#calculator" onClick={onClick}>
                  Calculate Your Profits
                </Button>
              )}
            </AnalyticsEvent>
          </div>
        </>
      ),
    },
    {
      id: "single-comment",
      centered: true,
      content: (
        <Card
          image={comment.image}
          text={comment.text}
          name={comment.name}
          single={true}
        />
      ),
    },
    {
      id: "calculator",
      centered: true,
      controls: "dark",
      content: (
        <>
          <h2>
            Calculate your profits <span>with Dojo</span>
          </h2>

          <Calculator />
        </>
      ),
    },
    {
      id: "your-home-belongs-here",
      centered: true,
      controls: "dark",
      content: (
        <>
          <h2>
            Your home <span>belongs</span> here
          </h2>

          <h3>See how much more our sellers made using Dojohome</h3>

          <Properties properties={properties} />
        </>
      ),
    },
    {
      id: "perks",
      centered: true,
      content: (
        <>
          <h2>
            Perks of the Platform... <span>Beyond the Profits</span>
          </h2>

          <Explanation items={perks} />

          <AnalyticsEvent pixelEvent="Learn More Seller">
            {(onClick) => (
              <Button variant="dojobrand" onClick={onClick} href="#see-how">Learn more</Button>
            )}
          </AnalyticsEvent>
        </>
      ),
    },
    {
      id: "see-how",
      aside: (
        <>
          <h2>
            Dojo brings <br />
            <span>future owners</span>
            <br /> to you.
          </h2>

          <p>
            Forget the headaches of lowball offers or the uncertainties of
            trusting renters with your property.
          </p>
        </>
      ),
      content: (
        <>
          <h2>
            <span>See how we’re different</span>
          </h2>

          <Comparison items={comparison} />
        </>
      ),
    },
    {
      id: "seller-faq",
      controls: "dark",
      centered: true,
      growable: true,
      content: (
        <>
          <h2>
            <span>FAQ</span>
          </h2>
          <Accordion items={faq} />
          <AnalyticsEvent pixelEvent="Learn More Seller 2">
            {(onClick) => (
              <Button as={Link} to="/faq" variant="dojobrand" onClick={onClick}>
                See all FAQs
              </Button>
            )}
          </AnalyticsEvent>
        </>
      ),
      hideScroll: true,
    },
    {
      id: "seller-cta",
      controls: "dark",
      centered: true,
      content: (
        <>
          <h1>
            Maximize your profits <span>today</span>.
          </h1>
          <div className="inline-buttons">
            <AnalyticsEvent pixelEvent="Calculate Profits">
              {(onClick) => (
              <Button href="#calculator" variant="dojobrand" onClick={onClick}>
                Calculate profits
              </Button>
              )}
            </AnalyticsEvent>
            <AnalyticsEvent pixelEvent="List Your Property 2">
              {(onClick) => (
                <Button as={Link} variant="outline-secondary" to="/seller?signup" onClick={onClick}>
                  List your property
                </Button>
              )}
            </AnalyticsEvent>
          </div>
          <Link to="/" className="simple-link">
            I am a Buyer
          </Link>
        </>
      ),
    },
    {
      id: "seller-last",
      centered: true,
      content: (
        <>
          <Comments comments={comments} />
        </>
      ),
    },
  ];

  const sectionsControlsColor = sections.map((section) => section.controls);
  const sectionsIDs = sections.map((section) => section.id);

  const [currentSection, setCurrentSection] = useState();

  const handleSectionChange = (number) => {
    setCurrentSection(number);
  };

  return (
    <OnBoardingContext.Provider value={onBoardingContextData}>
      <Main
        handleSectionChange={handleSectionChange}
        currentSection={currentSection}
        controlsColor={sectionsControlsColor}
        sectionsIDs={sectionsIDs}
        pager
      >
        {sections.map((section, index) => (
          <Section
            id={section.id}
            className={section.className}
            sectionsIDs={sectionsIDs}
            centered={section.centered}
            controls={section.controls}
            aside={section.aside}
            index={index}
            total={sections.length}
            hideScroll={section.hideScroll || false}
            key={`section ${index}`}
            backgroundVideo={section.backgroundVideo}
            handleSectionChange={handleSectionChange}
            navbar={index === 0 ? true : false}
            navbarColor={section.navbarColor}
            growable={section.growable}
          >
            {section.content}
          </Section>
        ))}
      </Main>
    </OnBoardingContext.Provider>
  );
};
