import React from "react";
import _ from "lodash";
import { Card, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { useTranslation } from "react-i18next";

import useCurrency from "../../../components/hooks/helpers/useCurrency";
import useUpdateList from "../../../components/hooks/mutations/useUpdateList";
import CompleteProfileButton from "../../../components/user/CompleteProfileButton";
import PaymentCalculator from "../../../components/property/PaymentCalculatior";
import InfoTooltip from "../../../components/property/InfoTooltip";
import { Heart, HeartOutline } from "../../../components/icons";
import useUserInfo from "../../../components/hooks/helpers/useUserInfo";

export default ({
  user,
  listing,
  planCalculator,
  applying,
  applyToProperty,
  showLoginModal,
}) => {
  const { t } = useTranslation();
  const currency = useCurrency();
  const ownProperty = user && listing.owner._id === user._id;
  const { isMember, isAdmin, hasCompleteProfile } = useUserInfo(user);
  const userCanMakeAnOffer = isMember || isAdmin;

  const [updateFavorite] = useUpdateList(listing._id, "favorite", [
    "GetSingleListing",
  ]);

  const switchFavorite = (favorite) =>
    updateFavorite({
      variables: {
        action: favorite ? "add" : "remove",
      },
    });

  return (
    <Card className="bg-secondary text-light">
      <Card.Body>
        <div className="d-flex">
          <p className="flex-grow-1">
            {t("property.price")}{" "}
            {/* <InfoTooltip message={t("property.priceTooltip")} /> */}
          </p>
          <h5 className="my-0">{currency(listing.price)}</h5>
        </div>
        {listing.myOffer && (
          <Button
            as={Link}
            to={`/buyer/offers/${listing.myOffer._id}`}
            variant="success"
            block
            size="lg"
            className="mt-3 text-uppercase"
          >
            {t("property.viewOffer")}
          </Button>
        )}

        {!listing.myOffer &&
          (isMember && listing.offers && listing.offers.length >= 3 ? (
            <Alert className=" mt-4" variant="warning">
              {t("property.offerLimit")}
            </Alert>
          ) : (
            <React.Fragment>
              <PaymentCalculator
                user={user}
                listing={listing}
                planCalculator={planCalculator}
              />
              {!userCanMakeAnOffer ? (
                <CompleteProfileButton
                  user={user}
                  as={Button}
                  size="sm"
                  source="Make an Offer"
                  variant="primary"
                  block
                  className="mt-3 text-uppercase"
                  showProfileModal={showLoginModal}
                >
                  {t("property.makeOffer")}
                </CompleteProfileButton>
              ) : hasCompleteProfile ? (
                <Button
                  variant="primary"
                  block
                  className="mt-3 text-uppercase py-2"
                  size="sm"
                  disabled={
                    !planCalculator.offer.plan ||
                    !planCalculator.offer.move_in ||
                    applying
                  }
                  onClick={() => applyToProperty()}
                >
                  {t("property.makeOffer")}
                </Button>
              ) : (
                <Button
                  as={Link}
                  to="?complete"
                  variant="primary"
                  block
                  className="mt-3 text-uppercase py-2"
                  size="sm"
                  disabled={
                    !planCalculator.offer.plan ||
                    !planCalculator.offer.move_in ||
                    applying
                  }
                >
                  {t("property.makeOffer")}
                </Button>
              )}
              <ScrollLink
                to="plan-selection"
                spy={true}
                smooth={true}
                offset={-128}
                className="my-2 py-2 small text-center ScrollLinkButton"
                onClick={() => planCalculator.setPlan("custom")}
              >
                <strong>{t("property.plan.makeOwn")}</strong>
              </ScrollLink>
            </React.Fragment>
          ))}
        {user ? (
          <Button
            variant="transparent"
            block
            size="sm"
            className="text-center text-white"
            onClick={() => switchFavorite(!listing.favorited)}
          >
            {!listing.favorited ? (
              <span>
                <HeartOutline height={14} color="#fff" /> &nbsp;Add to favorites
              </span>
            ) : (
              <span>
                <Heart height={14} color="#fff" /> &nbsp;Remove from favorites
              </span>
            )}
          </Button>
        ) : (
          <CompleteProfileButton
            user={user}
            as={Button}
            size="sm"
            source="Favorite"
            variant="transparent"
            block
            className="text-center text-white"
          >
            <HeartOutline height={14} color="#fff" /> &nbsp;Add to favorites
          </CompleteProfileButton>
        )}
        {ownProperty && (
          <Button
            variant="outline-secondary"
            block
            as={Link}
            to={`/seller/properties/${listing._id}`}
          >
            {t("property.viewOffer")}
          </Button>
        )}
      </Card.Body>
    </Card>
  );
};
