import React, { useState } from "react";
import currencyFormatter from "currency-formatter";
import { Modal, Row, Col, Card, Button } from "react-bootstrap";
import Slider from "rc-slider";

import { useTranslation } from "react-i18next";
import PlaidConnect from "../payment/PlaidConnect";
import useBuyEquity from "../hooks/mutations/useBuyEquity";

export default ({ user, offer, show, onHide }) => {
  const { t } = useTranslation();
  const [moreEquity, setMoreEquity] = useState(0);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [buyEquity, loading] = useBuyEquity(
    offer,
    Math.round(moreEquity),
    selectedPaymentMethod,
    (data) => {
      console.log(data);
      onHide();
    }
  );

  const canBuyEquity =
    (selectedPaymentMethod || offer.paymentMethod) &&
    moreEquity > 0 &&
    !loading;

  const downPaymentPercent = (
    ((offer.down_payment + offer.payments) / offer.listing.price) *
    100
  ).toFixed(2);

  const totalEquityPercent =
    ((offer.down_payment + offer.payments + moreEquity) / offer.listing.price) *
    100;

  console.log(totalEquityPercent);

  const moreEquityPercent = ((moreEquity / offer.listing.price) * 100).toFixed(
    2
  );

  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <h4 className="text-center">{offer.listing.address}</h4>
      </Modal.Header>
      <Modal.Body className="p-5">
        <Row>
          <Col md={8}>
            <div className="small text-uppercase">
              {t("contract.info.i_want_to_buy")}
            </div>
            <Slider.Range
              marks={{
                0: "0%",
                [downPaymentPercent]: `${downPaymentPercent}%`,
                [totalEquityPercent]: (
                  <strong>+&nbsp;{moreEquityPercent}%</strong>
                ),
                10: "10%",
              }}
              count={2}
              min={0}
              max={10}
              value={[
                0,
                downPaymentPercent,
                Math.round(
                  ((offer.down_payment + offer.payments + moreEquity) /
                    offer.listing.price) *
                    10000
                ) / 100,
              ]}
              onChange={(value) =>
                setMoreEquity(
                  Math.max(
                    0,
                    (offer.listing.price * parseFloat(value[2])) / 100.0 -
                      (offer.down_payment + offer.payments)
                  )
                )
              }
              step={0.01}
              trackStyle={[
                { backgroundColor: "#053861" },
                //{ backgroundColor: "green" },
              ]}
              handleStyle={[
                { backgroundColor: "#053861" },
                { backgroundColor: "#053861" },
              ]}
              //railStyle={{ backgroundColor: "green" }}
            />
          </Col>
          <Col md={4}>
            <div className="small text-uppercase">
              {t("contract.info.i_will_pay")}
            </div>
            <Card>
              <Card.Body>
                <h3 className="text-center my-0">
                  {currencyFormatter.format(moreEquity, {
                    code: "USD",
                    precision: 0,
                  })}
                </h3>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <hr />
        <h5 className="my-3">{t("contract.info.payment_method")}</h5>
        <PlaidConnect
          user={user}
          defaultPaymentMethod={selectedPaymentMethod || offer.paymentMethod}
          setDefaultPaymentMethod={({ id, name, mask }) =>
            setSelectedPaymentMethod({
              id,
              name,
              mask,
              type: "plaid",
            })
          }
        />
      </Modal.Body>
      <Modal.Footer className="d-flex">
        <div className="flex-grow-1"></div>
        <Button variant="outline-secondary" className="px-5" onClick={onHide}>
          &lt; {t("contract.info.back")}
        </Button>
        <Button
          className="px-5"
          variant="success"
          disabled={!canBuyEquity}
          onClick={(e) => buyEquity()}
        >
          {t("contract.info.buy_now")}
        </Button>
        <div className="flex-grow-1"></div>
      </Modal.Footer>
    </Modal>
  );
};
