import gql from "graphql-tag";
import {
useMutation } from
"@apollo/react-hooks";

const SET_PHONE = gql`
  mutation SetPhone($phone: String) {
    setPhone(phone: $phone) {
      message
    }
  }
`;

const RESEND_CODE = gql`
  mutation ResendCode($user: ID, $phone: String) {
    resendCode(user: $user, phone: $phone) {
      message
    }
  }
`;

const VALIDATE_PHONE = gql`
  mutation ValidatePhone($user: ID, $code: String, $phone: String) {
    validatePhone(user: $user, phone: $phone, code: $code) {
      message
    }
  }
`;

export default ((phone, code, user, onValidated) => {
  const [
  updatePhone,
  {
    data: setData,
    loading: setLoading,
    error: setError }] =

  useMutation(SET_PHONE, {
    variables: {
      phone },

    refetchQueries: () => ["CurrentUser"] });

  const [
    resendCode,
    {
      data: resendData,
      loading: resendLoading,
      error: resendError}] =

  useMutation(RESEND_CODE, {
    variables: {
      phone,
      user },

    refetchQueries: () => ["CurrentUser"] });

  const [
  validatePhone,
  {
    data: validateData,
    loading: validateLoading,
    error: validateError }] =

  useMutation(VALIDATE_PHONE, {
    variables: {
      code,
      phone,
      user
      },

    refetchQueries: () => ["CurrentUser"],
    onCompleted: () => onValidated && onValidated(true),
    onError: err => onValidated && onValidated(false) });


  return { updatePhone, resendCode, validatePhone, setData, resendData, validateData, setLoading, resendLoading, validateLoading, setError, resendError, validateError };
});