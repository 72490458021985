import React from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";

import { Row, Col } from "react-bootstrap";

import AppMain from "../AppMain";
import TransactionHistory from "../../components/payment/TransactionHistory";
import PlaidConnect from "../../components/payment/PlaidConnect";
import StripeConnect from "../../components/payment/StripeConnect";

const SET_DEFAULT_PAYMENT = loader("./SetDefaultPayment.graphql");

export default ({ user }) => {
  const [setDefaultPayment] = useMutation(SET_DEFAULT_PAYMENT, {
    refetchQueries: () => ["CurrentUser"],
  });

  return (
    <AppMain>
      <Row className="pb-5">
        <Col xs={12} className="my-4"></Col>
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <Link to="/profile/settings">&larr; BACK</Link>
        </Col>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
          <h2>Default Payment Method</h2>

          <PlaidConnect
            user={user}
            defaultPaymentMethod={user.paymentMethod}
            setDefaultPaymentMethod={({ id, name, mask }) =>
              setDefaultPayment({
                variables: {
                  paymentMethod: {
                    id,
                    name,
                    mask,
                    type: "plaid",
                  },
                },
              })
            }
          />

          <hr />
        </Col>

        {user.mode === "seller" && (
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h2>Payouts</h2>
            <p>Connect a payout system to receive your earning.</p>
            <StripeConnect
              user={user}
              defaultPaymentMethod={user.default_payout}
              setDefaultPaymentMethod={(id) =>
                setDefaultPayment({
                  variables: {
                    id,
                  },
                })
              }
            />
            <hr />
          </Col>
        )}

        <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
          <TransactionHistory user={user} />
        </Col>
      </Row>
    </AppMain>
  );
};
