import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { Form, Button, Alert, Spinner } from "react-bootstrap";
import { useTranslation, Trans } from "react-i18next";

import useRegisterSeller from "../hooks/queries/useRegisterSeller";

export default ({
  onBack,
  email,
  loggedUser,
  redirect = "/seller/properties",
}) => {
  const { t } = useTranslation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [user, setUser] = useState(
    loggedUser
      ? {
          email,
          firstname: loggedUser.firstname,
          lastname: loggedUser.lastname,
        }
      : { email }
  );

  const getValue = (param) => user[param] || "";
  const setValue = (param, value) => setUser({ ...user, [param]: value });

  const [register, loading, error] = useRegisterSeller(() => {
    setLoggedIn(true);
  });

  const validationErrors =
    error && error.graphQLErrors[0].extensions.validationErrors;
  if (loggedIn) return <Redirect to={redirect} />;

  const MemberFields = [
    {
      label: t("login.registration_form.firstname"),
      path: "firstname",
      type: "text",
      placeholder: t("login.registration_form.firstname_placeholder"),
    },

    {
      label: t("login.registration_form.lastname"),
      path: "lastname",
      type: "text",
      placeholder: t("login.registration_form.lastname_placeholder"),
    },

    {
      label: t("login.registration_form.email"),
      path: "email",
      type: "email",
      placeholder: t("login.registration_form.email_placeholder"),
      disabled: true,
    },

    {
      label: t("login.registration_form.password"),
      path: "password",
      type: "password",
      placeholder: t("login.registration_form.password_placeholder"),
    },

    {
      label: (
        <span>
          <Trans
            i18nKey="login.terms"
            components={{
              Link: <Link to="/s/terms" target="_blank" />,
            }}
          />
        </span>
      ),

      path: "terms",
      type: "check",
    },
  ];

  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        register(user);
      }}
    >
      {error &&
        error.graphQLErrors.map((e, i) => (
          <Alert key={i} variant="danger">
            {e.message}
          </Alert>
        ))}

      {MemberFields.map((field) => (
        <Form.Group controlId={`form-${field.path}-group`} key={field.path}>
          {field.type === "check" && (
            <Form.Check
              type="checkbox"
              disabled={loading}
              label={field.label}
              value={getValue(field.path)}
              onChange={(e) => setValue(field.path, e.target.checked)}
              isInvalid={validationErrors && validationErrors[field.path]}
            />
          )}

          {field.type !== "check" && (
            <React.Fragment>
              <Form.Label>{field.label}</Form.Label>
              <Form.Control
                type={field.type}
                disabled={loading || field.disabled}
                placeholder={field.placeholder}
                value={getValue(field.path)}
                onChange={(e) => setValue(field.path, e.target.value)}
                isInvalid={validationErrors && validationErrors[field.path]}
              />
            </React.Fragment>
          )}

          <Form.Control.Feedback type="invalid">
            {validationErrors &&
              validationErrors[field.path] &&
              validationErrors[field.path].join(", ")}
          </Form.Control.Feedback>
        </Form.Group>
      ))}

      <div className="d-flex mt-5">
        <div className="flex-grow-1"></div>

        {onBack && (
          <React.Fragment>
            <Button
              variant="light"
              style={{ width: 200, padding: 9 }}
              onClick={() => onBack()}
            >
              {t("login.back")}
            </Button>
            <Button
              variant="primary"
              disabled={loading}
              type="submit"
              style={{ width: 200, padding: 9 }}
            >
              {loading && (
                <Spinner animation="border" size="sm" variant="light" />
              )}{" "}
              <span> {t("login.register")}</span>
            </Button>
          </React.Fragment>
        )}

        <div className="flex-grow-1"></div>
      </div>
    </Form>
  );
};
