import React, { useEffect, useState } from "react";
import _ from "lodash";
import { Form } from "react-bootstrap";
import { DojoLogoDark } from "../landing/icons";
import { useHistory } from "react-router-dom";

import useAnonymous from "../hooks/queries/useAnonymous";
import BackNext from "./BackNext";
import useValidateEmail from "../hooks/queries/useValidateEmail";

export default ({ subtitle, mode, setData, onNext, stepIndex }) => {
  const [user, setUser] = useState({});
  const history = useHistory();
  const disableNext = !user.email;

  const [_anonymousUser, updateAnonymousUser] = useAnonymous(onNext, (data) => {
    if (data && data.anonymous) setData(_.pick(data.buyingPower));
    setUser(_.pick(data.anonymous || {}, ["email"]));
  });

  const [validateEmail] = useValidateEmail((exists) => {
    if (_.includes(exists, mode)) {
      history.push("?login&existing");
      onNext(true);
    } else {
      updateAnonymousUser(user);
    }
  });

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && validateEmail(user.email);

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, user.email, validateEmail]);

  return (
    <>
      <DojoLogoDark />
      <h2>Get Started</h2>
      <Form onSubmit={(e) => e.preventDefault()}>
        <h4>My preferred e-mail address is</h4>
        <Form.Control
          type="email"
          name="email"
          value={user.email || ""}
          placeholder="E-mail Address"
          onChange={(e) => setUser({ email: e.target.value })}
          className="text-center single-control"
        />
      </Form>
      <BackNext
        mode="Buyer"
        stepIndex={stepIndex}
        onNext={() => validateEmail(user.email)}
        subtitle={subtitle}
        disableNext={disableNext}
      />
    </>
  );
};
