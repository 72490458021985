import React, { useState } from "react";
import { Redirect, Link } from "react-router-dom";
import { Navbar, Nav } from "react-bootstrap";

import Messages from "./components/Messages";
import SearchBar from "./components/SearchBar";
import SellerMenu from "./components/SellerMenu";
import MemberMenu from "./components/MemberMenu";
import UnregisteredMenu from "./components/UnregisteredMenu";
import UserMenu from "./components/UserMenu";
import AdminMenu from "./components/AdminMenu";
import SupportMenu from "./components/SupportMenu";
import useCurrentUser from "../../components/hooks/queries/useCurrentUser";

import "./MainNavBar.scss";
import { DojoLogoLight, DojoLogoDark } from "../landing/icons";

export default ({ hideSearch }) => {
  const [user, loading] = useCurrentUser();
  const [loggedOut, setLoggedOut] = useState(false);

  const noSearch = hideSearch || (user && user.mode === "admin");

  return loggedOut ? (
    <Redirect to="/" />
  ) : (
    <Navbar fixed="top" bg="light" className="py-1">
      <Navbar.Brand>
        <Link to="/" className="pl-3">
          <DojoLogoDark />
        </Link>
      </Navbar.Brand>

      {user && user.mode === "admin" && <SupportMenu user={user} />}

      {noSearch ? (
        <div className="flex-grow-1"></div>
      ) : (
        <SearchBar user={user} />
      )}

      <Nav
        className="mx-2 my-1"
        style={{ alignSelf: "stretch", alignItems: "center" }}
      >
        {user ? (
          <React.Fragment>
            {user.mode === "seller" && <SellerMenu user={user} />}
            {user.mode === "member" && <MemberMenu user={user} />}
            {user.mode === "admin" && <AdminMenu user={user} />}
            <Messages user={user} />
            <UserMenu user={user} setLoggedOut={setLoggedOut} />
          </React.Fragment>
        ) : (
          <UnregisteredMenu />
        )}
      </Nav>
    </Navbar>
  );
};
