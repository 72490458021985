import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

import { resetQueries } from "../../../apollo-tools";

const UPDATE_LIST = gql`
  mutation UpdateList($type: String!, $listing_id: String!, $action: AddRemoveAction) {
    updateList(type: $type, listing_id: $listing_id, action: $action) {
      message
    }
  }
`;

export default ((id, type, refetchQueries = []) => {
  const [updateList] = useMutation(UPDATE_LIST, {
    variables: {
      type,
      listing_id: id },

    refetchQueries: () => ["GetCategorizedProperties", "PropertySearch", ...refetchQueries],
    update: resetQueries(["favorites"]) //, "categorizedProperties", "search", "property"
    });


  return [updateList];
});