import React from "react";

import { Card, Button } from "react-bootstrap";

export default ({
  paymentMethod,
  selectedPaymentMethod = {},
  selectPaymentMethod,
}) => {
  console.log("selected", selectedPaymentMethod, paymentMethod);
  return (
    <Card
      className="mb-3 flex-grow-1"
      bg="primary"
      text="white"
      tabstop="-1"
      onClick={() => selectPaymentMethod(paymentMethod)}
    >
      <Card.Body className="d-flex">
        <div className="text-center flex-grow-1">
          <h6>{paymentMethod.name}</h6>
          <div className="d-flex">
            <div className="flex-grow-1">
              <div className="small">Account</div>
              <h6>**{paymentMethod.mask}</h6>
            </div>
            <div className="flex-grow-1">
              <div className="small">Type</div>
              <h6>
                {paymentMethod.type} / {paymentMethod.subtype}
              </h6>
            </div>
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="p-0">
        {selectedPaymentMethod &&
        paymentMethod.id === selectedPaymentMethod.id ? (
          <Button
            block
            size="sm"
            variant="secondary"
            style={{ borderRadius: "0 0 8px 8px" }}
          >
            Selected Method
          </Button>
        ) : null}
      </Card.Footer>
    </Card>
  );
};
