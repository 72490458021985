import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import _ from "lodash";

const SupportLinks = [
  {
    label: "Buyers",
    path: "/admin/users/member",
    roles: ["admin", "success-manager"],
  },
  {
    label: "Sellers",
    path: "/admin/users/seller",
    roles: ["admin", "success-manager"],
  },
  {
    label: "Properties",
    path: "/admin/properties",
    roles: ["admin", "success-manager", "data-entry"],
  },
  {
    label: "Offers",
    path: "/admin/offers",
    roles: ["admin", "success-manager"],
  },
];

export default ({ user }) => {
  const hasRole = (required, available) =>
    _.intersection(required, available).length > 0;

  return (
    <Nav
      className="mx-2 my-2"
      style={{ alignSelf: "stretch", alignItems: "center" }}
    >
      {SupportLinks.map(
        ({ label, path, roles }) =>
          hasRole(roles, user.roles) && (
            <Nav.Link key={path} className="px-3" as={Link} to={path}>
              {label}
            </Nav.Link>
          )
      )}
    </Nav>
  );
};
