import React, { useState } from "react";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { Trans } from "react-i18next";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import AppMain from "../AppMain";
import OfferSelector from "../../components/dashboard/OfferSelector";
import InfoTab from "../../components/dashboard/InfoTab";
import DashboardTab from "../../components/dashboard/DashboardTab";
import PaymentTab from "../../components/dashboard/PaymentTab";
import Loading from "../../components/general/Loading";

const ACTIVE_OFFERS = loader("./GetMyOffers.graphql");
const UPDATE_OFFER = loader("./UpdateOffer.graphql");

export default ({ user }) => {
  const [key, setKey] = useState("dashboard");
  const [activeOfferId, setActiveOfferId] = useState(undefined);

  const { data, loading } = useQuery(ACTIVE_OFFERS, {
    onCompleted: (data) => {
      if (data.myBuyerOffers.length > 0) setActiveOfferId(0);
    },
  });

  const activeOffer = () =>
    activeOfferId !== undefined ? data.myBuyerOffers[activeOfferId] : null;

  const [updateOffer] = useMutation(UPDATE_OFFER);

  const setSelectedPaymentMethod = (paymentMethod) =>
    updateOffer({
      variables: {
        offer: {
          _id: activeOffer()._id,
          //          default_method: paymentMethod,
        },
      },

      refetchQueries: () => ["GetMyOffers"],
    });

  return (
    <AppMain>
      <Row>
        {loading ? (
          <Loading />
        ) : activeOffer() ? (
          <React.Fragment>
            <Col xs={12} className="mt-5">
              <OfferSelector
                user={user}
                offers={data.myBuyerOffers}
                activeOffer={activeOffer()}
                setActiveOfferId={setActiveOfferId}
              />
            </Col>

            <Col xs={12} className="mt-5">
              <Tabs
                fill
                id="dashboard-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
              >
                <Tab eventKey="info" title="My Plan">
                  <InfoTab user={user} activeOffer={activeOffer()} />
                </Tab>
                <Tab eventKey="dashboard" title="Dashboard">
                  <DashboardTab
                    user={user}
                    activeOffer={activeOffer()}
                  ></DashboardTab>
                </Tab>
                <Tab eventKey="payments" title="Payments">
                  <PaymentTab
                    user={user}
                    activeOffer={activeOffer()}
                    setSelectedPaymentMethod={setSelectedPaymentMethod}
                  ></PaymentTab>
                </Tab>
              </Tabs>
            </Col>
          </React.Fragment>
        ) : (
          <Col className="text-center mt-5">
            <h4>
              <Trans
                i18nKey="member.home.noAcceptedOffer"
                components={{ Link: <Link to="/buyer/offers" /> }}
              />
            </h4>
          </Col>
        )}
      </Row>
    </AppMain>
  );
};
