import React, { useEffect, useContext } from "react";
import classNames from "classnames";
import Scrollspy from "react-scrollspy";
import { useLocation, useHistory } from "react-router-dom";
import qs from "qs";
import _ from "lodash";

import Footer from "./Footer";
import OnboardingDialog from "../user/OnboardingDialog";
import AuthorizationModal from "../auth/AuthorizationModal";
import RequestPassResetModal from "../auth/RequestPassResetModal";
import ApplyPassResetModal from "../auth/ApplyPassResetModal";
import useAnonymous from "../hooks/queries/useAnonymous";
import OnBoardingContext from "../context/OnBoardingContext";
import useCurrentUser from "../hooks/queries/useCurrentUser";

import "./style/index.scss";

const Pager = ({
  currentSection,
  handleSectionChange,
  total,
  controlsColor,
  sectionsIDs,
}) => {
  const checkCurrent = (array) =>
    array && array[sectionsIDs.indexOf(currentSection)];

  return (
    <Scrollspy
      items={sectionsIDs}
      currentClassName="active"
      className={classNames("landing-pager", {
        dark: checkCurrent(controlsColor) === "dark",
      })}
      componentTag="div"
      onUpdate={(section) => handleSectionChange(section.id)}
      offset={-200}
    >
      {[...Array(total)].map((el, i) => (
        <a
          href={`#${sectionsIDs[i]}`}
          key={`indicator ${i}`}
          tabIndex={2}
          className="indicator-outer"
        >
          <div className="indicator-line" />
        </a>
      ))}
    </Scrollspy>
  );
};

export default ({
  children,
  currentSection,
  handleSectionChange,
  controlsColor,
  sectionsIDs,
  pager,
}) => {
  const [user] = useCurrentUser();

  const loc = useLocation();
  const query = qs.parse(loc.search.substring(1)) || {};
  const { login, signup, reset, code, complete } = query;
  const history = useHistory();

  const mode = loc.pathname === "/seller" ? "seller" : "member";
  const { setOpen } = useContext(OnBoardingContext);

  const [anonymousUser] = useAnonymous();

  const handleClose = (dialog) =>
    history.push(`?${qs.stringify(_.omit(query, dialog))}`);

  useEffect(() => {
    document.documentElement.classList.add("landing");

    if (signup !== undefined) {
      setOpen(true);
    }

    if (!user && complete !== undefined) {
      history.push("?login&complete");
    }

    return () => document.documentElement.classList.remove("landing");
  }, [setOpen, signup, user, complete, history]);

  return (
    <>
      {false && pager && (
        <Pager
          total={children.length}
          handleSectionChange={handleSectionChange}
          currentSection={currentSection}
          controlsColor={controlsColor}
          sectionsIDs={sectionsIDs}
        />
      )}
      {children}
      <OnboardingDialog
        anonymousUser={anonymousUser}
        onHide={() => handleClose("signup")}
      />
      <AuthorizationModal
        show={login !== undefined}
        email={anonymousUser ? anonymousUser.email : ""}
        onHide={() => handleClose("login")}
        mode={mode}
      />
      <RequestPassResetModal
        show={reset !== undefined}
        onHide={() => handleClose("reset")}
        mode={mode}
      />
      <ApplyPassResetModal
        show={code !== undefined}
        code={code}
        onHide={() => handleClose("code")}
      />
      <Footer />
    </>
  );
};
