import gql from "graphql-tag";
import {
useMutation } from
"@apollo/react-hooks";

const QUERY = gql`
  mutation Login($email: String!, $password: String!, $mode: UserMode!) {
    login(email: $email, password: $password, mode: $mode) {
      _id
    }
  }
`;

export default ((onCompleted, onError) => {
  const [validateEmail, {
    loading,
    error,
    client }] =
  useMutation(QUERY, {
    onCompleted: data => {
      client.resetStore();
      onCompleted && onCompleted(data && data.login);
    },
    onError: err => onError && onError(err) });


  return [(email, password, mode) => validateEmail({
    variables: {
      email, password, mode } }),

  loading, error];
});