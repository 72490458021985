import React, { useState, useEffect, useRef } from "react";
import { useRouteMatch, useLocation, useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { Container, Row, Col } from "react-bootstrap";
import { Sticky, StickyContainer } from "react-sticky";
import { useMixpanel } from "react-mixpanel-browser";
import StickyBox from "react-sticky-box";
import { useTranslation } from "react-i18next";
import qs from "qs";

import AppMain from "../AppMain";
import useGetListing from "../../components/hooks/queries/useGetListing";
import useApplyToProperty from "../../components/hooks/mutations/useApplyToProperty";
import useTrackListingEvent from "../../components/hooks/mutations/useTrackListingEvent";
import usePlanCaluclator from "../../components/hooks/helpers/usePlanCaluclator";
import AddressWall from "../../components/sections/AddressWall";
import Loading from "../../components/general/Loading";
import ListingImageCarousel from "./sections/ListingImageCarousel";
import ListingPlanSelectionSidebar from "./sections/ListingPlanSelectionSidebar";
import ListingSummary from "./sections/ListingSummary";
import ListingNeighbourhood from "./sections/ListingNeighbourhood";
import ListingAdminRawData from "./sections/ListingAdminRawData";
import ListingSchools from "./sections/ListingSchools";
import ListingBrokerInfo from "./sections/ListingBrokerInfo";
import ListingInfo from "./sections/ListingInfo";
import ListingAddress from "./sections/ListingAddress";
import ListingNavigationBar from "./sections/ListingNavigationBar";
import ListingNearby from "./sections/ListingNearby";
import ListingPlanSelection from "./sections/ListingPlanSelection";

import "./ViewProperty.scss";

import plans from "../../data/plans";
import AuthorizationModal from "../../components/auth/AuthorizationModal";
import RequestPassResetModal from "../../components/auth/RequestPassResetModal";

export default ({ user }) => {
  const loc = useLocation();
  const query = qs.parse(loc.search.substring(1)) || {};

  const history = useHistory();

  const { t } = useTranslation();
  const match = useRouteMatch();
  const mixpanel = useMixpanel();
  const menuRef = useRef();

  const [loginModal, setLoginModal] = useState(false);
  const showLoginModal = () => setLoginModal(true);
  const hideLoginModal = () => setLoginModal(false);

  const [resetPassModal, setResetPassModal] = useState(false);
  const showPassModal = () => setResetPassModal(true);
  const hidePassModal = () => setResetPassModal(false);

  const [offer, setOffer] = useState({
    plan: plans[0],
    move_in: new Date(),
  });
  const [trackEvent] = useTrackListingEvent();

  const [property, loading, error] = useGetListing(match.params.id, (data) => {
    setOffer({
      ...offer,
      move_in: new Date(
        Math.max.apply(null, [
          moment(data.property.available_from).toDate(),
          new Date(),
        ])
      ),
      down_payment: data.property.myOffer
        ? data.property.myOffer.down_payment || data.property.equity_deposit
        : data.property.equity_deposit,
    });

    trackEvent("view", data.property);
  });

  const planCalculator = usePlanCaluclator(property, offer, setOffer);

  const [applyToProperty, applying] = useApplyToProperty(
    match.params.id,
    {
      ...offer,
      plan: planCalculator.activePlan,
    },
    () => {
      window.location.reload();
    }
  );

  useEffect(() => {
    if (property) {
      mixpanel.track("View Property", {
        id: property._id,
        price: property.price,
        rent: property.rent,
      });
      mixpanel.people.append("property_viewed", property._id);
    }

    if ("login" in query) {
      showLoginModal();
      history.push(`?${qs.stringify(_.omit(query, "login"))}`);
    }
  }, [query, mixpanel, property, history]);

  const listing = property || { owner: {} };
  const isAdmin = user && _.includes(user.roles, "admin");

  return (
    <AppMain fluid={true}>
      <AuthorizationModal
        show={loginModal}
        onHide={hideLoginModal}
        onNext={() => {
          applyToProperty();
          hideLoginModal();
        }}
        variant="innerPages"
        altText={t("login.loginOffer")}
        showPassModal={showPassModal}
      />
      <RequestPassResetModal
        show={resetPassModal}
        onHide={hidePassModal}
      />
      {!user && <AddressWall />}
      {loading && <Loading />}
      {!loading && property && (
        <Container fluid className="py-5 PropertyViewContainer">
          <div className="offset"></div>

          <StickyContainer>
            <Container>
              <Row className="StickyContainer">
                <Col xs={12} md={6} lg={8} className="pt-3">
                  <ListingImageCarousel listing={listing} />

                  <ListingAddress listing={listing} />
                  <div ref={menuRef}></div>
                  <Sticky
                    topOffset={
                      menuRef.current ? menuRef.current.offsetTop - 48 : 0
                    }
                  >
                    {({ style, isSticky }) => (
                      <div style={{ ...style, top: 56, zIndex: 2 }}>
                        <ListingNavigationBar
                          isSticky={isSticky}
                          listing={listing}
                          scrollable={[
                            { section: "summary", title: "Summary" },
                            {
                              section: "plan-selection",
                              title: "Your Plan",
                              hidden: listing.myOffer,
                            },
                            { section: "info", title: "Property Info" },
                            {
                              section: "neighbourhood",
                              title: "Neighbourhood",
                            },
                            { section: "schools", title: "Schools" },
                            { section: "similar", title: "Similar Homes" },
                          ]}
                        />
                      </div>
                    )}
                  </Sticky>

                  <ListingSummary listing={listing} />
                </Col>
                <StickyBox offsetTop={122}>
                  <div className="pb-3 flex-grow-1">
                    <ListingPlanSelectionSidebar
                      user={user}
                      listing={listing}
                      planCalculator={planCalculator}
                      applying={applying}
                      applyToProperty={applyToProperty}
                      showLoginModal={showLoginModal}
                    />
                  </div>
                </StickyBox>
              </Row>
              {!listing.myOffer && (
                <Row>
                  <Col xs={12}>
                    <ListingPlanSelection
                      user={user}
                      planCalculator={planCalculator}
                      applying={applying}
                      applyToProperty={applyToProperty}
                      showLoginModal={showLoginModal}
                    />
                  </Col>
                </Row>
              )}

              <Row className="StickyContainer">
                <Col xs={12} md={8} lg={8}>
                  <ListingInfo listing={listing} />

                  <ListingNeighbourhood listing={listing} />

                  <ListingSchools listing={listing} />
                </Col>
                <StickyBox offsetTop={122}>
                  <div className="py-3 flex-grow-1">
                    <ListingPlanSelectionSidebar
                      user={user}
                      listing={listing}
                      planCalculator={planCalculator}
                      applying={applying}
                      applyToProperty={applyToProperty}
                      showLoginModal={showLoginModal}
                    />
                  </div>
                </StickyBox>
              </Row>
              <Row>
                {isAdmin && listing && (
                  <React.Fragment>
                    {!listing.branded && (
                      <Col xs={12}>
                        <ListingBrokerInfo user={user} listing={listing} />
                      </Col>
                    )}
                    <Col xs={12}>
                      <ListingAdminRawData user={user} listing={listing} />
                    </Col>
                  </React.Fragment>
                )}
              </Row>
            </Container>
          </StickyContainer>
        </Container>
      )}
      <Container fluid>
        <Container>
          <ListingNearby user={user} listing={listing} />
        </Container>
      </Container>
    </AppMain>
  );
};
