import Profile from '../../pages/User/Profile';
import Account from '../../pages/User/Account';
import ProfileInfo from '../../pages/User/Info';
import ProfileSecurity from '../../pages/User/Security';
import ProfilePayments from '../../pages/User/Payments';
import ProfileDocuments from '../../pages/User/Documents';
import ViewProperty from '../../pages/Property/ViewProperty';
import StartPropertyChat from '../../pages/Chat/StartPropertyChat';
import PropertyChat from '../../pages/Chat/PropertyChat';
import AllMessages from '../../pages/Chat/AllMessages';


export default [
{
  path: "/property/:id/chat/:user_id",
  component: StartPropertyChat,
  private: true },


{
  path: "/property/:id/chat",
  component: StartPropertyChat,
  private: true },


{
  path: "/property/:id",
  component: ViewProperty  },

{
  path: "/profile/settings/info",
  component: ProfileInfo,
  private: true },
{
  path: "/profile/settings/security",
  component: ProfileSecurity,
  private: true },
{
  path: "/profile/settings/payments",
  component: ProfilePayments,
  private: true },
{
  path: "/profile/settings/documents",
  component: ProfileDocuments,
  private: true },


{
  path: "/profile/settings",
  component: Account,
  private: true },


{
  path: "/chats/:id",
  component: PropertyChat,
  private: true },


{
  path: "/messages",
  component: AllMessages,
  private: true }];