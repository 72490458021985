import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const ALL_USERS = gql `
  fragment ImageFragment on Image {
    _id
    fileid
    extension
    url
  }

  query BuyingPower {
    buyingPowerResponses {
      _id
      email
      firstname
      lastname

      profile {
        photo {
          ...ImageFragment
        }
      }

      buyingPower {
        isEligible
        buyerType
        moveIn
        employment
        dreamHome
        savings
        annualIncome
        creditScore

        completed
        submittedFrom
        submittedAt
      }

      verified
    }
  }
`;

export default () => {
  const {
    data,
    loading,
    error
  } =
  useQuery(ALL_USERS, {
    fetchPolicy: "network-only"
  });


  return [(data && data.buyingPowerResponses) || [], loading, error];
};