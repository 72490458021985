import {
  useState
} from "react";
import gql from "graphql-tag";
import {
  useQuery,
  useMutation
} from "@apollo/react-hooks";
import _ from "lodash";

const GET_SETTINGS = gql `
  query GetCategorySettings {
    settings {
      categories {
        minProperties
      }
    }
  }
`;

const SET_SETTINGS = gql `
  mutation SetCategorySettings($categories: CategoriesInput!) {
    updateSettings(settings: {
      categories: $categories
    }) {
      message
    }
  }
`;

export default (onCompleted) => {
  const [categories, setCategories] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const {
    loading,
    error,
    refetch
  } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setIsChanged(false);
      setCategories(_.omit(data.settings.categories, ['__typename']));
    }
  });

  const [saveCategories] = useMutation(SET_SETTINGS, {
    variables: {
      categories
    },
    refetchQueries: () => ['GetCategorySettings'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [categories, (update) => {
    setCategories({
      ...categories,
      ...update
    });
    setIsChanged(true)
  }, refetch, saveCategories, isChanged, loading, error];
};