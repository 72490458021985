import React, { useState, useRef } from "react";
import { Button, Overlay } from "react-bootstrap";
import classNames from "classnames";

export default ({
  label,
  searchText,
  placement = "bottom",
  overlay,
  selected,
  highlighted,
}) => {
  const [showOverlay, setShowOverlay] = useState(false);
  const target = useRef(null);

  return (
    <div className="text-center mt-4 position-relative">
      <h5 className="my-2">{label}</h5>
      <Button
        block
        variant="outline-default"
        className={classNames("filter-button", {
          selected,
          selecting: showOverlay,
        })}
        ref={target}
        onClick={() => setShowOverlay(!showOverlay)}
      >
        <span
          className={classNames("font-italic", {
            "font-weight-normal": !highlighted,
          })}
        >
          {searchText}
        </span>
      </Button>
      <Overlay
        rootClose
        onHide={() => setShowOverlay(false)}
        target={target.current}
        show={showOverlay}
        placement={placement}
      >
        {overlay}
      </Overlay>
    </div>
  );
};
