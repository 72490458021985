import React from "react";

import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";

export default (({ payoutMethod, selectPayoutMethod, defaultPayout }) => {
  return (
    <Card className="mb-3 flex-grow-1">
      <Card.Body>
        <div className="text-center">
          <h6>ACH Transfer</h6>
          <div>
            <h5 className="text-uppercase">{payoutMethod.bank_name}</h5>
            <h6>****{payoutMethod.last4}</h6>
          </div>
        </div>
      </Card.Body>
      <Card.Footer className="p-0">
        {payoutMethod.bankAccountId === defaultPayout ?
        <Button block size="sm" style={{ borderRadius: "0 0 8px 8px" }}>
            Default Method
          </Button> :

        <Button
        block
        size="sm"
        style={{ borderRadius: "0 0 8px 8px" }}
        variant="outline-primary"
        onClick={e => selectPayoutMethod(payoutMethod.id)}>
          Select as Default
        </Button>}

      </Card.Footer>
    </Card>);
});