import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const LOGOUT = gql` mutation { logout } `;

export default ((onCompleted, onError) => {
  const [logout, { loading, error, client }] = useMutation(LOGOUT, {
    onCompleted: data => {
      client.resetStore();
      onCompleted && onCompleted(data);
    },
    onError: err => onError && onError(err) });



  return [logout, loading, error];
});