import React from "react";
import { useMutation } from "@apollo/react-hooks";
import { loader } from "graphql.macro";
import { Link } from "react-router-dom";
import { Card, Button, Badge } from "react-bootstrap";
import currencyFormatter from "currency-formatter";
import moment from "moment";

import { makeImageUrl } from "../../tools";
import useDeleteProperty from "../../components/hooks/mutations/useDeleteProperty";
import useRoleManager from "../hooks/helpers/useRoleManager";

const UPDATE_PROPERTY = loader("./UpdateProperty.graphql");

const mainStatus = (property) =>
  property.archived
    ? "archived"
    : !property.verified
    ? "unverified"
    : property.under_contract
    ? "contract"
    : "verified";

export default ({ property, user }) => {
  const { requireRole } = useRoleManager(user);
  const [updateProperty] = useMutation(UPDATE_PROPERTY, {
    refetchQueries: () => ["AllProperties"],
  });

  const [deleteProperty] = useDeleteProperty({
    refetchQueries: ["AllProperties"],
  });

  const updatePropertyParams = (params) =>
    updateProperty({
      variables: {
        id: property._id,
        params,
      },
    });

  const PropertyActions = {
    archived: [],
    unverified: [
      {
        title: "Confirm",
        action: () => updatePropertyParams({ verified: true }),
      },
    ],
    verified: [],
    contract: [],
  };

  return (
    <Card style={{ overflow: "hidden" }} className="mb-3">
      <div className="d-flex">
        <div
          className="flex-grow-0"
          style={{ minWidth: 160, position: "relative" }}
        >
          <div
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              backgroundImage: `url(${makeImageUrl(property.images[0])})`,
              backgroundSize: "cover",
              backgroundPosition: "center center",
            }}
          ></div>
        </div>
        <div className="flex-grow-1 py-2 px-3">
          <div className="small">Owner</div>
          <h6>
            <Link to={`/profile/${property.owner._id}`}>
              {property.owner.firstname} {property.owner.lastname}
            </Link>
          </h6>
          <div className="small">Address</div>
          <h6>{property.address}</h6>
          <div className="d-flex">
            <div className="pr-3">
              <div className="small">Created On</div>
              <div>{moment(property.created_at).format("LLL")}</div>
            </div>
            {property.source && (
              <div>
                <div className="small">Source</div>
                <div>
                  <Badge variant="primary" size="sm" className="py-1">
                    MLS: {property.source}
                  </Badge>{" "}
                  {property.under_contract && (
                    <Badge variant="warning" size="sm" className="py-1">
                      Pending
                    </Badge>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
        <div
          className="py-3 px-3"
          style={{ borderLeft: "1px solid #CCE6FF", minWidth: 120 }}
        >
          <div>Price</div>
          <h6>
            {currencyFormatter.format(property.price, {
              code: "USD",
              precision: 0,
            })}
          </h6>
          <div>Rent</div>
          <h6>
            {currencyFormatter.format(property.rent, {
              code: "USD",
              precision: 0,
            })}
          </h6>
        </div>
        <div className="py-3 px-3" style={{ borderLeft: "1px solid #CCE6FF" }}>
          <div>Listing&nbsp;Status</div>
          <h6>
            {property.archived
              ? "Archived"
              : !property.verified
              ? "Verification"
              : property.under_contract
              ? "Contract"
              : "Active"}
          </h6>
          {requireRole(["admin", "success-manager"]) &&
            PropertyActions[mainStatus(property)].map((action) => (
              <Button
                block
                size="sm"
                variant="secondary"
                onClick={action.action}
              >
                {action.title}
              </Button>
            ))}

          {requireRole(["admin", "success-manager"]) && property.daysOnMarket && (
            <>
              <div>Days&nbsp;on&nbsp;Market</div>
              <h6>{property.daysOnMarket}</h6>
            </>
          )}
        </div>
        {!property.archived && (
          <div
            className="d-flex flex-column"
            style={{ borderLeft: "1px solid #CCE6FF" }}
          >
            <Button
              as={Link}
              to={`/property/${property._id}`}
              className="flex-grow-1"
              variant="primary"
              style={{ borderRadius: 0 }}
              disabled={!property.verified}
            >
              View
            </Button>

            <Button
              as={Link}
              to={`/seller/property/${property._id}`}
              variant="secondary"
              style={{ borderRadius: 0 }}
              className="flex-grow-1"
            >
              Edit
            </Button>

            <Button
              className="flex-grow-1"
              variant="danger"
              style={{ borderRadius: 0 }}
              disabled={property.closed || property.under_contract}
              onClick={(e) => {
                deleteProperty(property._id);
              }}
            >
              Delete
            </Button>
          </div>
        )}
      </div>
    </Card>
  );
};
