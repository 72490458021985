import React from "react";
import { DojoLogoLight, Facebook, LinkedIn } from "./icons";
import { Link } from "react-router-dom";

import NavLinks from "./NavLinks";
import AnalyticsEvent from "../general/AnalyticsEvent";

export default () => (
  <footer className="dojohome-footer">
    <div className="footer-up">
      <div className="dojohome-footer-inner">
        <a href="/">
          <DojoLogoLight />
        </a>
        <AnalyticsEvent pixelEvent="phone main">
          {(onClick) => (
            <a href="tel:+1 (844) 5GO-DOJO" onClick={onClick}>+1 (844) 5GO-DOJO</a>
          )}
        </AnalyticsEvent>
        <AnalyticsEvent pixelEvent="email main">
          {(onClick) => (
            <a href="mailto:hello@dojohome.com" onClick={onClick}>hello@dojohome.com</a>
          )}
        </AnalyticsEvent>
        <div className="footer-links">
          <NavLinks simpleLinks />
        </div>
      </div>
    </div>
    <div className="footer-down">
      <div className="dojohome-footer-inner">
        <div className="social-links">
          <a
            href={`https://web.facebook.com/DojohomeUS?_rdc=1&_rdr`}
            target="_blank"
            rel="noopener noreferrer"
            id="facebook"
          >
            <Facebook />
          </a>
          <a
            href={`https://www.linkedin.com/company/dojohome/about/`}
            target="_blank"
            rel="noopener noreferrer"
            id="linkedin"
          >
            <LinkedIn />
          </a>
          <span className="footer-down-text">
            Copyright © 2020 Dojohome. All rights reserved.
          </span>
        </div>
        <div className="footer-links">
          <Link to="/s/terms" className="footer-down-text">
            Terms & Conditions
          </Link>
          <Link to="/s/privacy" className="footer-down-text">
            Privacy Policy
          </Link>
        </div>
      </div>
    </div>
  </footer>
);
