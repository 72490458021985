import React from "react";
import { Row, Col, Table } from "react-bootstrap";
import currencyFormatter from "currency-formatter";

import UploadedFile from "../../components/file-uploader/UploadedFile";
import { makeImageUrl } from "../../tools";

export default (({ application }) => {
  return (
    <React.Fragment>
      {application.applicant.validated_phone &&
      <React.Fragment>
          <div className="my-4">
            <h6>✓ Validated Phone Number</h6>
            <div>{application.applicant.validated_phone}</div>
          </div>
          <hr />
        </React.Fragment>}


      <div className="my-4">
        <h6>Identity Documents</h6>

        {application.applicant.identity &&
        <Row>
            {application.applicant.identity.drivers_license &&
          <Col
          as="a"
          href={makeImageUrl(
          application.applicant.identity.drivers_license)}

          xs={12}
          md={4}
          lg="3"
          className="FilePreview my-3"
          target="_blank">

                <h6>Driver's License</h6>
                <UploadedFile
            file={application.applicant.identity.drivers_license} />

              </Col>}

            {application.applicant.identity.id_card &&
          <Col
          as="a"
          href={makeImageUrl(application.applicant.identity.id_card)}
          xs={12}
          md={4}
          lg="3"
          className="FilePreview my-3"
          target="_blank">

                <h6>ID Card</h6>
                <UploadedFile file={application.applicant.identity.id_card} />
              </Col>}

            {application.applicant.identity.passport &&
          <Col
          as="a"
          href={makeImageUrl(application.applicant.identity.passport)}
          xs={12}
          md={4}
          lg="3"
          className="FilePreview my-3"
          target="_blank">

                <h6>Passport</h6>
                <UploadedFile file={application.applicant.identity.passport} />
              </Col>}

          </Row>}

      </div>

      <div className="my-4">
        <h6>Extra Documents</h6>
        <Row>
          {application.applicant.documents.map((d) =>
          <Col
          as="a"
          href={makeImageUrl(d)}
          key={d._id}
          xs={12}
          md={4}
          lg="3"
          className="FilePreview my-3"
          target="_blank">

              <UploadedFile file={d} />
            </Col>)}

        </Row>
      </div>
      <hr />

      {application.employment &&
      <React.Fragment>
          <div className="my-4">
            <h6>Employment</h6>
            <div style={{ textTransform: "uppercase" }}>
              {application.employment === "employed" ?
            "Employed" :
            application.employment === "student" ?
            "Student" :
            application.employment === "unemployed" ?
            "Self-Employed" :
            null}
            </div>
            <div>
              Source of income: {application.income_source || "Unspecified"},
              &nbsp;
              {currencyFormatter.format(application.monthly_income, {
              code: "USD",
              precision: 0 })}
            {" "}
              per month
            </div>
          </div>
          <hr />
        </React.Fragment>}


      {
        //      <div className="my-4">
        //        <h6>Rental History</h6>
        //        <div>
        //          <Table responsive striped borderless>
        //            <thead>
        //              <tr>
        //                <th>Address</th>
        //                <th>City</th>
        //                <th>Rent</th>
        //                <th>Term</th>
        //              </tr>
        //            </thead>
        //            <tbody>
        //              {application.rental_history.map((rh) => (
        //                <tr>
        //                  <td>{rh.address}</td>
        //                  <td>{rh.city}</td>
        //                  <td>
        //                    {currencyFormatter.format(rh.monthly_rent, {
        //                      code: "USD",
        //                      precision: 0,
        //                    })}
        //                  </td>
        //                  <td>{rh.term}</td>
        //                </tr>
        //              ))}
        //            </tbody>
        //          </Table>
        //        </div>
        //      </div>
        //      <hr />
        //
        //      <div className="my-4">
        //        <h6>References</h6>
        //        <div>
        //          <Table responsive striped borderless>
        //            <tbody>
        //              {application.references.map((ref) => (
        //                <tr>
        //                  <td>{ref.full_name}</td>
        //                  <td>{ref.relationship}</td>
        //                  <td>
        //                    <a href={`tel:${ref.phone}`}>{ref.phone}</a>
        //                  </td>
        //                  <td>
        //                    <a href={`mailto:${ref.email}`}>{ref.email}</a>
        //                  </td>
        //                </tr>
        //              ))}
        //            </tbody>
        //          </Table>
        //        </div>
        //      </div>
      }
    </React.Fragment>);

});