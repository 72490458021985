import React from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import moment from "moment";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";

import Avatar from "../general/Avatar";

import "./ChatsList.scss";

const GET_CHATS = loader("./GetMyChats.graphql");

export default ({ user, admin }) => {
  const { loading, data } = useQuery(GET_CHATS, {
    fetchPolicy: "network-only",
  });

  const otherUser = (chat) => {
    return _.find(
      chat.participants,
      (u) =>
        u._id !== user._id &&
        (!chat.admin ||
          !_.includes(user.roles, "admin") ||
          !_.includes(u.roles, "admin"))
    );
  };

  const history = useHistory();
  const openChat = (id) => history.push(`/chats/${id}`);

  const calcUnread = (chat) =>
    chat.lastMessage &&
    !_.some(chat.lastRead, {
      participant: user._id,
      message: chat.lastMessage ? chat.lastMessage._id : null,
    });

  const chats = _.chain(data)
    .get("getMyChats", [])
    .thru((chats) => (admin ? _.filter(chats, "admin") : chats))
    .value();

  return (
    <>
      {chats.map((chat) => (
        <Col key={chat._id} xs={12} lg={{ span: 8, offset: 2 }}>
          <Card onClick={(e) => openChat(chat._id)} className="my-3">
            <Card.Body className="d-flex">
              <div className="flex-grow-0 pr-3">
                <Avatar user={otherUser(chat)} size={48}></Avatar>
              </div>
              <div className="flex-grow-1">
                <h6>{chat.listing.address}</h6>
                <div>{chat.lastMessage ? chat.lastMessage.text : null}</div>
              </div>
              <div className="flex-grow-0">
                {calcUnread(chat) > 0 ? (
                  <div className="messages-unread-badge">1</div>
                ) : (
                  moment(chat.lastUpdate).fromNow()
                )}
              </div>
            </Card.Body>
          </Card>
        </Col>
      ))}
    </>
  );
};
