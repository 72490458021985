import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";
import _ from "lodash";

const MUTATION = gql `
  mutation CreateUser($user: UserInput) {
    createUser(user: $user) {
      _id
    }
  }
`;

export default ((user, refetchQueries = [], onCompleted) => {
  const [createUser, {
    loading,
    error
  }] = useMutation(MUTATION, {
    variables: {
      user: {...(_.omit(user, ["role"])), roles: [user.role]}
    },
    refetchQueries: () => ["AllUsers", ...refetchQueries],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [createUser, loading, error];
});
