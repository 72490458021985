import React, { useState } from "react";
import { Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";

import DojohomeModal from "../modals/DojohomeModal";
import useApplyResetPassword from "../hooks/mutations/useApplyResetPassword";
import { Show } from "../onboarding/icons";

export default ({ show, code, onHide }) => {
  const [password, setPassword] = useState("");
  const [passwordRepeat, setPasswordRepeat] = useState("");
  const [passwordReset, setPasswordReset] = useState(false);
  const [
    applyResetPassword,
    data,
    loading,
    error,
  ] = useApplyResetPassword(code, password, passwordRepeat, () => setPasswordReset(true));
  
  const [inputType, setInputType] = useState("password");
  const validationErrors = error && error.graphQLErrors[0].extensions.exception.validationErrors;

  return (
    <DojohomeModal show={show} onHide={onHide} className="super-slim">
      {passwordReset ? (
        <>
          <h3>{data.applyResetPassword.message}</h3>
          <div className="modal-controls">
            <Button
              variant="dojobrand"
              as={Link}
              to="?login"
              className="single-control"
            >
              Proceed to login
            </Button>
          </div>
        </>
      ) : (
        <>
          <Form
            onSubmit={e => {
              e.preventDefault();
              applyResetPassword();
            }}
          >
            <h4>Enter your new password</h4>
            <Form.Group className={`single-control ${validationErrors && validationErrors.password ? "error" : ""}`}>
              <Form.Control
                type={inputType}
                value={password}
                className="single-control"
                onChange={(e) => setPassword(e.target.value)}
                isInvalid={validationErrors && validationErrors.password}
              />

              <Form.Control.Feedback type="invalid">
                {validationErrors && validationErrors.password}
              </Form.Control.Feedback>
              
              {(password || passwordRepeat) && (
                <Button
                  className="password-show"
                  onClick={() => inputType === "password" ? setInputType("text") : setInputType("password")}
                >
                  <Show />
                </Button>
              )}
            </Form.Group>
            
            <h4>Repeat your new password</h4>
            <Form.Group className={`single-control ${validationErrors && validationErrors.passwordRepeat ? "error" : ""}`}>
              <Form.Control
                type={inputType}
                value={passwordRepeat}
                className="single-control"
                onChange={(e) => setPasswordRepeat(e.target.value)}
                isInvalid={validationErrors && validationErrors.passwordRepeat}
              />

              <Form.Control.Feedback type="invalid">
                {validationErrors && validationErrors.passwordRepeat}
              </Form.Control.Feedback>
            </Form.Group>

            <div className="modal-controls">
              <Button
                variant="dojobrand"
                disabled={!password || !passwordRepeat || loading}
                className="single-control"
                type="submit"
              >
                Confirm
              </Button>
            </div>
          </Form>
          {validationErrors && validationErrors.code && (
            <Alert variant="danger">
              {validationErrors.code}
            </Alert>
          )}
        </>
      )}
    </DojohomeModal>
  );
};
