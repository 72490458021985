import React, { useState, forwardRef } from "react";
import { Form } from "react-bootstrap";
import CurrencyInput from "react-currency-input-field";
import currencyFormatter from "currency-formatter";

import Tooltip from "./Tooltip";

export default forwardRef(
  (
    {
      label,
      tooltip,
      placeholder,
      value,
      onChange,
      errorMessage,
      currency,
      ...props
    },
    ref
  ) => {
    const [internalValue, setInternalValue] = useState(value);

    const makeCurrency = (amount) =>
      currencyFormatter.format(amount, { code: "USD", precision: 0 });

    return (
      <Form.Group className="field-group" ref={ref}>
        <Form.Label>
          {label} <Tooltip message={tooltip} dark />
        </Form.Label>
        <Form.Control
          {...(currency
            ? {
                as: CurrencyInput,
                prefix: "$",
                allowDecimals: false,
                ...props,
              }
            : props)}
          placeholder={makeCurrency(placeholder)}
          value={internalValue}
          onChange={(v) => setInternalValue(v)}
          onBlur={() => onChange(internalValue)}
          isInvalid={!!errorMessage}
        />
        <Form.Control.Feedback type="invalid">
          {errorMessage}
        </Form.Control.Feedback>
      </Form.Group>
    );
  }
);
