import React, { useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import Geosuggest from "react-geosuggest";
import qs from "qs";
import { useTranslation } from "react-i18next";

import { parseAddress, stateCode } from "../../../tools";
import RequestCity from "../../../pages/Property/RequestCity";
import useAvailableZip from "../../hooks/helpers/useAvailableZip";
import { useMixpanel } from "react-mixpanel-browser";

const DEFAULT_CITIES = [
  {
    label: "Columbus, OH",
    description: "Columbus, OH, USA",
  },
];

export default ({ placeholder, user }) => {
  const { t } = useTranslation();
  const loc = useLocation();
  const mixpanel = useMixpanel();
  const history = useHistory();
  const [location, setLocation] = useState(qs.parse(loc.search.substring(1)));
  const [requestCity, setRequestCity] = useState(undefined);
  const isAvailable = useAvailableZip();

  const reportLocationSelection = (location) => {
    mixpanel.track("Location Interest", {
      Location: location,
    });
    mixpanel.people.append("location_interest", location);
  };

  return (
    <Form
      inline
      className="flex-grow-1 d-flex"
      style={{ alignSelf: "stretch", alignItems: "center" }}
      onSubmit={(e) => e.preventDefault()}
    >
      <RequestCity
        user={user}
        show={!!requestCity}
        requestCity={requestCity}
        onHide={() => setRequestCity(undefined)}
      ></RequestCity>

      <Geosuggest
        type="text"
        country="us"
        types={["(regions)"]}
        fixtures={DEFAULT_CITIES}
        initialValue={location.address}
        className="flex-grow-1"
        placeholder={placeholder || t("searchBar.placeholder")}
        placeDetailFields={["address_component", "geometry", "name"]}
        getSuggestLabel={(suggest) => suggest.description.replace(", USA", "")}
        onSuggestSelect={async (e) => {
          if (e) {
            const address = parseAddress(e.description);

            const newLocation = {
              address: e.description.replace(", USA", ""),
              city: address["city"],
              state: stateCode(address["province"]),
              zip: address["postalCode"],
            };

            reportLocationSelection(
              `${address["city"]}, ${stateCode(address["province"])}`
            );

            const cityAvailable = await isAvailable(
              `${e.location.lat} ${e.location.lng}`
            );

            if (cityAvailable) {
              setLocation(newLocation);
              history.push(
                `/search?${qs.stringify({ ...newLocation, ...e.location })}`
              );
            } else {
              setRequestCity(newLocation);
            }
          } else {
            setLocation({
              address: undefined,
              city: undefined,
              state: undefined,
              zip: undefined,
            });
          }
        }}
      ></Geosuggest>
    </Form>
  );
};
