import React from "react";
import { Card, Row, Col } from "react-bootstrap";

export default ({ listing }) => {
  return (
    <Card>
      <Card.Header>
        <h5>Broker Information</h5>
      </Card.Header>
      {listing.agent && (
        <Card.Body>
          <Row>
            <Col xs md={12}>
              <h6>MLS ID</h6>
              <p>{listing.mls}</p>
            </Col>
            <Col xs md={6}>
              <h6>Brokerage</h6>
              <p>{listing.agent.brokerage ? listing.agent.brokerage.title : ""}</p>
            </Col>
            <Col xs md={6}>
              <h6>Agent Name</h6>
              <p>{listing.agent.name}</p>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );
};
