import React, { useState, forwardRef } from "react";
import Geosuggest from "react-geosuggest";
import classNames from "classnames";

import { parseAddress, stateCode } from "../../tools";
import { Location } from "./icons";

export default forwardRef(
  ({ setData, field, placeholder, className, value }, ref) => {
    const [location, setLocation] = useState(value);

    return (
      <div className={classNames("property-address", className)} ref={ref}>
        <div className="property-address-icon">
          <Location />
        </div>
        <Geosuggest
          type="text"
          country="us"
          types={["geocode"]}
          className="flex-grow-1"
          placeDetailFields={["address_component", "geometry", "name"]}
          placeholder={placeholder || "Let’s start with your property address"}
          initialValue={location || ""}
          getSuggestLabel={(suggest) =>
            suggest.description.replace(", USA", "")
          }
          onSuggestSelect={async (e) => {
            if (e) {
              const address = parseAddress(e.description);

              const newLocation = {
                address: e.description.replace(", USA", ""),
                city: address["city"],
                state: stateCode(address["province"]),
                zip: address["postalCode"],
              };
              setData(field ? { [field]: newLocation } : newLocation);
              setLocation(newLocation.address);
            } else {
              const undefinedLocation = {
                address: undefined,
                city: undefined,
                state: undefined,
                zip: undefined,
              };
              setData(
                field ? { [field]: undefinedLocation } : undefinedLocation
              );
              setLocation(undefined);
            }
          }}
          renderSuggestItem={(googleSuggest) => {
            const { offset, length } = googleSuggest.matchedSubstrings;
            const start = googleSuggest.label.substring(0, offset);
            const highlight = googleSuggest.label.substring(offset, length);
            const end = googleSuggest.label.substring(offset + length);

            return (
              <div>
                <Location />{" "}
                <span className="ml-2">
                  {start}
                  <strong>{highlight}</strong>
                  {end}
                </span>
              </div>
            );
          }}
        />
      </div>
    );
  }
);
