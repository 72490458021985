import React, { useCallback, useState } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { usePlaidLink } from "react-plaid-link";

import usePlaidConnect from "../hooks/queries/usePlaidLink";
import PlaidPaymentMethod from "./PlaidPaymentMethod";
import useUpdateMe from "../hooks/mutations/useUpdateMe";

const ConnectButton = ({ label, token, disabled, onConnected }) => {
  const config = {
    token,
    env: process.env.REACT_APP_PLAID_ENV || "sandbox",
    onSuccess: onConnected,
  };

  const { open, ready } = usePlaidLink(config);

  return (
    <Button
      className="px-5"
      onClick={() => open()}
      disabled={!ready || disabled}
    >
      {label}
    </Button>
  );
};

export default ({
  user,
  defaultPaymentMethod,
  setDefaultPaymentMethod,
  allowChange = true,
}) => {
  const [updateUser] = useUpdateMe(["CurrentUser"]);
  const [plaidLink, loading] = usePlaidConnect();
  const [accounts, setAccounts] = useState([]);

  const onConnected = useCallback(
    (token, metadata) => {
      updateUser({ plaidToken: token });
      setAccounts(metadata.accounts);
      if (!defaultPaymentMethod && metadata.accounts.length > 0) {
        setDefaultPaymentMethod(metadata.accounts[0]);
      }
    },
    [defaultPaymentMethod, setDefaultPaymentMethod, updateUser]
  );

  const actualPaymentMethod = defaultPaymentMethod || user.paymentMethod;

  return (
    <Row>
      {actualPaymentMethod && actualPaymentMethod.id && (
        <Col xs className="my-3">
          <Row>
            <Col xs="auto">
              <h5>Name</h5>
              <p>{actualPaymentMethod.name}</p>
            </Col>
            <Col xs="auto">
              <h5>Account</h5>
              <p>**{actualPaymentMethod.mask}</p>
            </Col>
          </Row>
        </Col>
      )}
      {accounts && accounts.length > 0 ? (
        <Col xs={12}>
          <Row>
            <Col xs={12}>
              <h5 className="my-4">Select Payment Account</h5>
            </Col>
            {accounts
              .filter(({ type }) => type === "depository")
              .map((acc) => (
                <Col xs={12} md={6}>
                  <PlaidPaymentMethod
                    paymentMethod={acc}
                    selectedPaymentMethod={defaultPaymentMethod}
                    selectPaymentMethod={setDefaultPaymentMethod}
                  />
                </Col>
              ))}
          </Row>
        </Col>
      ) : (
        plaidLink &&
        allowChange && (
          <Col xs={12} className="text-center">
            <ConnectButton
              token={plaidLink}
              disabled={loading}
              onConnected={onConnected}
              label={
                actualPaymentMethod && actualPaymentMethod.id
                  ? "Update Default Payment Method"
                  : "Connect to Your Bank via Plaid"
              }
            />
          </Col>
        )
      )}
    </Row>
  );
};
