import React from "react";

export const InfoBadge = ({ text }) => (
  <div className="badge badge-info">
    {text}
  </div>
);

export const StatusBadge = ({ status = "" }) => (
  <div className={`badge badge-status ${status}`}>
    {status}
  </div>
);