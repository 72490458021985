import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const REQUEST_RESET_PASSWORD = gql`
  mutation RequestResetPassword($email: String!, $mode: String) {
    requestResetPassword(email: $email, mode: $mode) {
      message
    }
  }
`;

export default ((email, mode, onCompleted, onError) => {
  const [requestResetPassword, {
    data,
    loading,
    error
  }] = useMutation(REQUEST_RESET_PASSWORD, {
    variables: {
      email,
      mode
    },
    refetchQueries: () => ["CurrentUser"],
    onCompleted: resetData => onCompleted && onCompleted(resetData),
    onError: resetError => onError && onError(resetError)
  });

  return [requestResetPassword, data, loading, error];
});