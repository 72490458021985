import React, { useState, useCallback } from "react";
import {
  GoogleMap,
  Marker,
  InfoWindow,
  useLoadScript,
} from "@react-google-maps/api";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { encodeSvg } from "../../tools";
import PriceTag, { TextTag } from "./HouseMarker";
import HouseMarker from "./HouseMarker";

const options = {
  zoomControlOptions: {
    position: window.google.maps.ControlPosition.RIGHT_CENTER, // ,
    // ...otherOptions
  },
  disableDefaultUI: true,
  zoomControl: true,
  mapTypeControl: false,
  scaleControl: false,
  streetViewControl: false,
  rotateControl: false,
  fullscreenControl: false,
};

export default ({ lat, lng, nearbyMarkers = [] }) => {
  const { t } = useTranslation();
  const [selectedPlace, setSelectedPlace] = useState(undefined);
  const [mapRef, setMapRef] = useState(null);

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOLE_MAPS_KEY,
  });

  const fitBounds = useCallback(
    (map) => {
      const mapObj = map || mapRef;
      if (nearbyMarkers.length === 0) {
        mapObj.setCenter({ lat, lng });
        mapObj.setZoom(15);
        return;
      }

      const initialBounds = new window.google.maps.LatLngBounds();
      initialBounds.extend({ lat, lng });
      nearbyMarkers.map((marker) => {
        initialBounds.extend({
          lat: marker.lat,
          lng: marker.lng,
        });
        return marker.id;
      });

      mapObj.fitBounds(initialBounds);
    },
    [lat, lng, mapRef, nearbyMarkers]
  );

  const onLoad = useCallback(
    (map) => {
      console.log("onload");
      setMapRef(map);
      fitBounds(map);
    },
    [fitBounds]
  );

  if (!isLoaded) return "Loading Maps...";

  return (
    <GoogleMap
      onLoad={onLoad}
      mapContainerStyle={{
        width: "100%",
        height: 400,
        borderRadius: 4,
      }}
      zoom={13}
      center={{ lat, lng }}
      options={options}
    >
      <Marker
        position={{ lat, lng }}
        icon={encodeSvg(
          <HouseMarker width={64}>
            <TextTag>YOU</TextTag>
          </HouseMarker>
        )}
      />

      {nearbyMarkers &&
        nearbyMarkers.map((marker) => (
          <Marker
            key={marker.id}
            icon={marker.icon}
            position={{ lat: marker.lat, lng: marker.lng }}
            onClick={() => setSelectedPlace(marker)}
          ></Marker>
        ))}

      {selectedPlace && (
        <InfoWindow
          onCloseClick={() => setSelectedPlace(undefined)}
          position={{ lat: selectedPlace.lat, lng: selectedPlace.lng }}
        >
          <div>
            <h6 className="mb-0">{selectedPlace.title}</h6>
            <div className="mb-2">
              <small>{selectedPlace.type}</small>
            </div>
            <Button
              size="sm"
              as="a"
              target="_blank"
              href={`https://www.google.com/maps/place/${selectedPlace.lat},${selectedPlace.lng}/`}
            >
              {t("map.open_on_gmaps")}
            </Button>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};
