import SignUp from '../../pages/Member/SignUp';
import FavoriteProperties from '../../pages/Member/FavoriteProperties';
import MemberApplication from '../../pages/Member/MemberApplication';
import MemberApplications from '../../pages/Member/MemberApplications';
import MyHome from '../../pages/Member/MyHome';


export default [
{
  path: "/buyer/login",
  component: SignUp },
{
  path: "/buyer/signup",
  component: SignUp
},


{
  path: "/buyer/offers/:id",
  component: MemberApplication,
  private: true },


{
  path: "/buyer/offers",
  component: MemberApplications,
  private: true },


{
  path: "/buyer/my-home",
  component: MyHome,
  private: true },


{
  path: "/buyer/favorites",
  component: FavoriteProperties,
  private: true }];