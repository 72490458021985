import React from "react";

import "./Loading.scss";
import { DojoLogoDark } from "../landing/icons";

export default () => {
  return (
    <div className="p-5 loading-container">
      <div className="text-center pulse-logo">
        <DojoLogoDark />
      </div>
    </div>
  );
};
