import React from "react";
import { Col } from "react-bootstrap";

export default ({ amenity }) => {
  return (
    <Col md={6} lg={4} className="px-2" key={amenity.value}>
      <div className="mb-2 p-0 ListingAmenity">
        <amenity.icon height={16} />{" "}
        <span
          style={{
            verticalAlign: "middle",
            display: "inline-block",
            padding: "0 12px 0 0",
          }}
        >
          {amenity.label}
        </span>
      </div>
    </Col>
  );
};
