import gql from "graphql-tag";
import {
  useQuery,
  useMutation
} from "@apollo/react-hooks";

const GET_BROKERAGES = gql `
  query GetBrokerages {
    brokerages {
      _id
      title
    }
  }
`;

const CREATE_BROKERAGE = gql `
  mutation CreateBrokerage($title: String!) {
    createBrokerage(title: $title) {
      _id
    }
  }
`;

export default (onCompleted) => {
  const {
    data,
    loading,
    error
  } = useQuery(GET_BROKERAGES);

  const [createBrokerage] = useMutation(CREATE_BROKERAGE, {
    refetchQueries: () => ['GetBrokerages'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [data && data.brokerages, (brokerage) => createBrokerage({
    variables: brokerage
  }), loading, error];
};