import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import _ from "lodash";
import classNames from "classnames";
import phoneFormatter from "phone-number-formats";

import DojohomeModal from "../modals/DojohomeModal";
import useEmailValidation from "../hooks/mutations/useEmailValidation";
import useValidatePhone from "../hooks/mutations/useValidatePhone";

export default ({ show, onHide, user }) => {
  if (!user) return null;

  const [emailMessage, setEmailMessage] = useState("");
  const [requestEmailValidation, loading, error] = useEmailValidation(
    (data) =>
      setEmailMessage(
        data &&
          data.requestEmailValidation &&
          data.requestEmailValidation.message
      ),
    (err) => console.log(err)
  );
  const [code, setCode] = useState("");

  const {
    resendCode,
    validatePhone,
    validateError,
    resendData,
    resendError,
  } = useValidatePhone(`${user.phone}`, code, user._id);

  const [codeResent, setCodeResent] = useState(false);

  useEffect(() => {
    if (code.length === 6) {
      _.debounce(validatePhone, 1000)();
    }
  }, [code, validatePhone]);

  const phoneFormatted = user.phone
    ? new phoneFormatter(user.phone)
        .format({ type: "international" })
        .toString()
    : "";

  return (
    <DojohomeModal
      header="Complete your profile"
      show={show}
      onHide={onHide}
      className="phone-modal"
    >
      {!user.validated_email &&
        (!emailMessage ? (
          <div className="profile-completion">
            <h2>Validate your email</h2>
            <Button
              variant="dojobrand"
              className="single-control"
              onClick={requestEmailValidation}
              disabled={loading}
            >
              Resend e-mail verification
            </Button>
            {error && (
              <Form.Control.Feedback type="invalid">
                {error.graphQLErrors[0].message}
              </Form.Control.Feedback>
            )}
          </div>
        ) : (
          <h3>{emailMessage}</h3>
        ))}
      {!user.validated_phone && (
        <div className="profile-completion">
          <Form className="form-phone">
            <h2>
              Confirm your <br />
              phone number
            </h2>
            <p>
              We sent a one-time verification <br />
              code to you at
            </p>
            <h4 className="muted-text">{phoneFormatted}</h4>
            <ReactCodeInput
              type="number"
              fields={6}
              onChange={(value) => setCode(value)}
              className={classNames("code-input", {
                "is-invalid": validateError,
              })}
            />
            {codeResent
              ? resendData && (
                  <div className="text-success text-center mb-4 mt-4">
                    {resendData.resendCode.message}
                  </div>
                )
              : validateError && (
                  <Form.Control.Feedback type="invalid">
                    {validateError.graphQLErrors[0].message.includes(
                      "was not found"
                    )
                      ? "Expired verification code, please resend code"
                      : validateError.graphQLErrors[0].message}
                  </Form.Control.Feedback>
                )}
          </Form>
          <Button
            className="single-control"
            variant="light"
            onClick={() => {
              setCodeResent(true);
              resendCode();
            }}
          >
            Resend code
          </Button>
          <div className="modal-controls">
            <Button
              variant="dojobrand"
              className="single-control"
              onClick={validatePhone}
            >
              Confirm code
            </Button>
          </div>
        </div>
      )}
    </DojohomeModal>
  );
};
