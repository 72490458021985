import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Form } from "react-bootstrap";

import UserSelector from "../../../components/user/UserSelector";
import useMlsSettings from "../../../components/hooks/mutations/useMlsSettings";

export default ({ user }) => {
  const { t } = useTranslation();
  const [
    mlsSettings,
    setMlsSettings,
    _refetch,
    saveMlsSettings,
  ] = useMlsSettings();

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2>MLS Import Settings</h2>
      </Col>

      <Col xs={12}>
        <div className="mb-3">
          <Form.Label>{t("settings.listPropertyAs")}</Form.Label>
          <UserSelector
            me={user}
            user={mlsSettings.owner ? { _id: mlsSettings.owner } : undefined}
            setUser={(owner) => setMlsSettings({ ...mlsSettings, owner })}
          />
        </div>
      </Col>
      <Col xs={6} className="mb-3">
        <Form.Label>{t("settings.minPrice")}</Form.Label>
        <Form.Control
          type="number"
          value={mlsSettings.minPrice}
          onChange={(e) =>
            setMlsSettings({
              ...mlsSettings,
              minPrice: parseFloat(e.target.value),
            })
          }
        />
      </Col>
      <Col xs={6} className="mb-3">
        <Form.Label>{t("settings.maxPrice")}</Form.Label>
        <Form.Control
          type="number"
          value={mlsSettings.maxPrice}
          onChange={(e) =>
            setMlsSettings({
              ...mlsSettings,
              maxPrice: parseFloat(e.target.value),
            })
          }
        />
      </Col>
      <Col xs={12}>
        <Button onClick={saveMlsSettings}>Save Settings</Button>
      </Col>
    </Row>
  );
};
