import React from "react";
import currencyFormatter from "currency-formatter";
import { Row, Col, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const dataCellStyle = { lineHeight: "1.7rem", fontWeight: "600" };

export default ({ offer }) => {
  const { t } = useTranslation();
  const montlyEquityDelta =
    offer.plan && offer.plan.id
      ? parseFloat(
          (offer.listing.price * (offer.plan.targetEquity / 100.0) -
            offer.down_payment) /
            offer.plan.targetMonths
        )
      : 0;

  return (
    <Row>
      <Col md={6}>
        <h4>Down Payment</h4>
        <Card>
          <Card.Body>
            <div className="d-flex my-2">
              <div className="flex-grow-1 small" style={dataCellStyle}>
                {t("offer.price")}
              </div>
              <h5>
                {currencyFormatter.format(offer.listing.price, {
                  code: "USD",
                  precision: 0,
                })}
              </h5>
            </div>
            <div className="d-flex my-2">
              <div className="flex-grow-1 small" style={dataCellStyle}>
                {t("offer.downPayment")}
              </div>
              <h5>
                {currencyFormatter.format(offer.down_payment, {
                  code: "USD",
                  precision: 0,
                })}
              </h5>
            </div>
            <div className="d-flex my-2">
              <div className="flex-grow-1 small" style={dataCellStyle}>
                {t("offer.startingEquity")}
              </div>
              <h5>
                {((offer.down_payment / offer.listing.price) * 100).toFixed()}%
              </h5>
            </div>
          </Card.Body>
        </Card>
      </Col>
      <Col md={6}>
        <h4>{t("offer.monthlyPayment")}</h4>
        <Card>
          <Card.Body>
            <div className="d-flex my-2">
              <div className="flex-grow-1 small" style={dataCellStyle}>
                {t("offer.rent")}
              </div>
              <h5>
                {currencyFormatter.format(offer.listing.rent, {
                  code: "USD",
                  precision: 0,
                })}
              </h5>
            </div>
            <div className="d-flex my-2">
              <div className="flex-grow-1 small" style={dataCellStyle}>
                {t("offer.equityPayment")}
              </div>
              <h5>
                {montlyEquityDelta > 0
                  ? currencyFormatter.format(montlyEquityDelta, {
                      code: "USD",
                      precision: 0,
                    })
                  : "Buy as needed"}
                {montlyEquityDelta > 0
                  ? ` (+${(
                      (montlyEquityDelta / offer.listing.price) *
                      100
                    ).toFixed(1)}%)`
                  : null}
              </h5>
            </div>
            <div className="d-flex my-2">
              <div className="flex-grow-1 small" style={dataCellStyle}>
                {t("offer.total")}
              </div>
              <h5>
                {currencyFormatter.format(
                  (montlyEquityDelta || 0) + offer.listing.rent,
                  {
                    code: "USD",
                    precision: 0,
                  }
                )}
              </h5>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
