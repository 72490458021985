import React from "react";
import _ from "lodash";

import MapComponent from "./MapComponent";
import { getCategory } from "../../pages/Property/data/placeTypes";
import { MapEmergency, MapStore, MapFood, MapPharmacy } from "./Icons";
import { encodeSvg } from "../../tools";

const IconMap = {
  emergency: <MapEmergency />,
  store: <MapStore />,
  food: <MapFood />,
  pharmacy: <MapPharmacy />,
};

export default ({ listing, places = [] }) => {
  const [lng, lat] = _.get(listing, "location.coordinates", []);

  const markers = places.map((place) => {
    return {
      id: place.id,
      title: place.name,
      icon: {
        url: encodeSvg(IconMap[getCategory(place.types)]),
        scaledSize: new window.google.maps.Size(36, 36), // scaled size
        origin: new window.google.maps.Point(0, 0), // origin
        anchor: new window.google.maps.Point(18, 18), // anchor
      },
      type: getCategory(place.types),
      ...place.geometry.location,
    };
  });

  return <MapComponent lat={lat} lng={lng} nearbyMarkers={markers} />;
};
