import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import useCurrency from "../../../components/hooks/helpers/useCurrency";
import CompleteProfileButton from "../../../components/user/CompleteProfileButton";
import { Calendar, Discount, Card, Receipt } from "../../../components/icons";
import { Link } from "react-router-dom";

export default ({
  planCalculator,
  userRegistered,
  userProfileComplete,
  user,
  applyToProperty,
  showLoginModal,
  applying,
}) => {
  const currency = useCurrency();
  const { t } = useTranslation();

  return (
    <div className="OfferSummary">
      <Row>
        <Col xs={12} md={8} lg={6}>
          <h4>
            <strong>{t("property.plan.offerSummary")}</strong>
          </h4>
          <p>
            <span className="offer-icon">
              <Calendar />
            </span>
            <span className="offer-info">
              {t("property.plan.movinOn", {
                date: planCalculator.moveIn.format("LL"),
              })}
            </span>
          </p>
          <p>
            <span className="offer-icon">
              <Discount />
            </span>
            <span className="offer-info">
              {t("property.plan.downOnSigning", {
                down: currency(planCalculator.down_payment),
                percent: planCalculator.equity.toFixed(2),
              })}
            </span>
          </p>
          <p>
            <span className="offer-icon">
              <Card />
            </span>
            <span className="offer-info">
              {t("property.plan.payPerMonth", {
                amount: currency(
                  planCalculator.monthlyRent + planCalculator.monthlyEquity
                ),
              })}
            </span>
          </p>
          <p>
            <span className="offer-icon">
              <Receipt />
            </span>
            <span className="offer-info">
              {t("property.plan.ownOn", {
                equity: planCalculator.targetEquity,
                date: planCalculator.targetDate
                  ? planCalculator.targetDate.format("LL")
                  : "Unknown",
              })}
            </span>
          </p>
        </Col>
        <Col xs={12} md={4} lg={6} className="text-center">
          <p className="px-3 pt-4 pb-1">{t("property.plan.didYouKnow")}</p>
          <Button variant="default" className="px-4">
            {t("property.plan.howItWorks")}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={12}>
          {!userRegistered ? (
            <CompleteProfileButton
              user={user}
              as={Button}
              source="Make an Offer"
              block
              variant="primary"
              showProfileModal={showLoginModal}
            >
              {t("property.makeOffer")}
            </CompleteProfileButton>
          ) : userProfileComplete ? (
            <Button
              block
              variant="primary"
              disabled={
                !planCalculator.offer.plan ||
                !planCalculator.offer.move_in ||
                applying
              }
              onClick={() => applyToProperty()}
            >
              {t("property.makeOffer")}
            </Button>
          ) : (
            <Button
              as={Link}
              to="?complete"
              block
              variant="primary"
              disabled={
                !planCalculator.offer.plan ||
                !planCalculator.offer.move_in ||
                applying
              }
            >
              {t("property.makeOffer")}
            </Button>
          )}
        </Col>
      </Row>
    </div>
  );
};
