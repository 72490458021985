import React from "react";
import { Row, Col } from "react-bootstrap";

export default ({ listing }) => (
  <Row className="pt-3">
    <Col xs={12}>
      <h1>
        {listing.address}, {listing.city}, {listing.state.toUpperCase()}
      </h1>
    </Col>
  </Row>
);
