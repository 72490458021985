import React from "react";
import { useTranslation } from 'react-i18next';

import Loading from "../general/Loading";
import PropertyCard from "../general/PropertyCard";
import Slider from "../carousel/SlickSlider";
import { HighlightedArrow } from "../icons";
import useCategorizedProperties from "../hooks/queries/useCategorizedProperties";

const slickSettings = {
  slidesToScroll: 1,
  infinite: true,
  cssEase: "linear",
  arrows: true,
  padding: 128,
  centerMode: false,

  responsive: [
    {
      breakpoint: 960,
      settings: {
        arrows: false,
        infinite: false,
        slidesToShow: 2.2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 550,
      settings: {
        arrows: false,
        infinite: false,
        slidesToShow: 1.11,
        slidesToScroll: 1,
      },
    },
  ],
};

export default ({user}) => {
  const { t } = useTranslation();
  const [categories, loading] = useCategorizedProperties();

  return (
    <>
      <h2 className="font-weight-bold text-center mt-5 mb-4">
        {t("homePage.heading")}
      </h2>

      <div className="px-lg-5 mx-lg-3 px-0 mx-0">
      {loading || !categories ? (
        <Loading />
      ) : (
        categories.map(category =>
          category.properties && category.properties.length > 0 ? (
            <div key={category._id}>
              <h4 className="ml-3 my-0 properties-category-name">
                {category.header}
              </h4>
              <Slider
                {...slickSettings}
                slidesToShow={category.properties.length < 3 ? category.properties.length : 3}
                className="left-align-slick explore-slider"
                prevArrow={
                  <div>
                    <HighlightedArrow className="mt-n5" />
                  </div>
                }
                nextArrow={
                  <div>
                    <HighlightedArrow className="mt-n5" right />
                  </div>
                }
              >
                {(category.properties || []).map((listing) => (
                  <div className="py-3 px-2 px-xl-3" key={listing._id}>
                    <PropertyCard
                      user={user}
                      property={listing}
                      own={user && user._id === listing.owner._id}
                      link={`/property/${listing._id}`}
                      hideBadges
                    ></PropertyCard>
                  </div>
                ))}
              </Slider>
            </div>
          ) : null
        )
      )}
    </div> 
  </>
  )
}