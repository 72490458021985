import ReactDOMServer from 'react-dom/server';
import { useState, useEffect } from 'react';
import currencyFormatter from "currency-formatter";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height };

}

function useWindowDimensions() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}

function encodeSvg(reactElement) {
  return 'data:image/svg+xml,' + encodeURIComponent(ReactDOMServer.renderToStaticMarkup(reactElement));
}

String.indexOfAny = function (s, arr, begin) {
  var minIndex = -1;
  for (var i = 0; i < arr.length; i++) {
    var index = s.indexOf(arr[i], begin);
    if (index != -1) {
      if (minIndex == -1 || index < minIndex) {
        minIndex = index;
      }
    }
  }
  return minIndex;
};

String.splitByAny = function (s, arr) {
  var parts = [];

  var index;
  do {
    index = String.indexOfAny(s, arr);
    if (index != -1) {
      parts.push(s.substr(0, index));
      s = s.substr(index + 1);
    } else {
      parts.push(s);
    }
  } while (index != -1);

  return parts;
};

function parseAddress(address) {
  var obj = {
    address: "",
    city: "",
    province: "",
    postalCode: "",
    country: "" };


  if (!address) {
    return obj;
  }

  var parts = address.split(',');
  for (var i = 0; i < parts.length; i++) {
    parts[i] = parts[i].trim();
  }
  var i = parts.length - 1;

  var fnIsPostalCode = function (value) {
    return /^\d+$/.test(value);
  };

  var fnParsePostalCode = function (value) {
    var subParts = String.splitByAny(value, [' ', '-']);
    for (var j = 0; j < subParts.length; j++) {
      if (fnIsPostalCode(subParts[j].trim())) {
        obj.postalCode = subParts[j].trim();
        if (j > 0) {
          return subParts[j - 1];
          break;
        }
      }
    }

    return value;
  };

  if (i >= 0) {
    if (fnIsPostalCode(parts[i])) {obj.postalCode = parts[i];i--;}
    var part = fnParsePostalCode(parts[i]);
    if (part) {
      obj.country = part;
    }
    i--;
  }

  if (i >= 0) {
    if (fnIsPostalCode(parts[i])) {obj.postalCode = parts[i];i--;}
    var part = fnParsePostalCode(parts[i]);
    if (part) {
      obj.province = part;
    }
    i--;
  }

  if (i >= 0) {
    if (fnIsPostalCode(parts[i])) {obj.postalCode = parts[i];i--;}
    var part = fnParsePostalCode(parts[i]);
    if (part) {
      obj.city = part;
    }
    i--;
  }

  if (i >= 0) {
    parts = parts.slice(0, i + 1);
    obj.address = parts.join(', ');
  }

  return obj;
}

const emptyImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mP8z/C/HgAGgwJ/lK3Q6wAAAABJRU5ErkJggg==";

const makeImageUrl = img => {
  if (!img) return emptyImage;
  if (img.url) return img.url;

  const { fileid, extension } = img;

  return fileid
    ? `${process.env.REACT_APP_SITE_URL}/uploads/images/${fileid.substr(0, 4)}/${fileid}_o${extension}`
    : emptyImage;
};

const states_hash = {
  Alabama: 'AL',
  Alaska: 'AK',
  'American Samoa': 'AS',
  Arizona: 'AZ',
  Arkansas: 'AR',
  California: 'CA',
  Colorado: 'CO',
  Connecticut: 'CT',
  Delaware: 'DE',
  'District Of Columbia': 'DC',
  'Federated States Of Micronesia': 'FM',
  Florida: 'FL',
  Georgia: 'GA',
  Guam: 'GU',
  Hawaii: 'HI',
  Idaho: 'ID',
  Illinois: 'IL',
  Indiana: 'IN',
  Iowa: 'IA',
  Kansas: 'KS',
  Kentucky: 'KY',
  Louisiana: 'LA',
  Maine: 'ME',
  'Marshall Islands': 'MH',
  Maryland: 'MD',
  Massachusetts: 'MA',
  Michigan: 'MI',
  Minnesota: 'MN',
  Mississippi: 'MS',
  Missouri: 'MO',
  Montana: 'MT',
  Nebraska: 'NE',
  Nevada: 'NV',
  'New Hampshire': 'NH',
  'New Jersey': 'NJ',
  'New Mexico': 'NM',
  'New York': 'NY',
  'North Carolina': 'NC',
  'North Dakota': 'ND',
  'Northern Mariana Islands': 'MP',
  Ohio: 'OH',
  Oklahoma: 'OK',
  Oregon: 'OR',
  Palau: 'PW',
  Pennsylvania: 'PA',
  'Puerto Rico': 'PR',
  'Rhode Island': 'RI',
  'South Carolina': 'SC',
  'South Dakota': 'SD',
  Tennessee: 'TN',
  Texas: 'TX',
  Utah: 'UT',
  Vermont: 'VT',
  'Virgin Islands': 'VI',
  Virginia: 'VA',
  Washington: 'WA',
  'West Virginia': 'WV',
  Wisconsin: 'WI',
  Wyoming: 'WY' };


function stateCode(stateName) {
  return states_hash[stateName] || stateName;
}

const formatUsd = (price) =>
currencyFormatter.format(price, {
  code: "USD",
  precision: 0 });


const protectedUserName = (user, loggedUser) => {
  const isAdmin = loggedUser && loggedUser.mode === "admin";
  const lastname = !isAdmin && user.lastname ? `${user.lastname[0]}.` : user.lastname;
  return `${user.firstname} ${lastname}`;
};

const protectedLastName = (user, loggedUser) => {
  const isAdmin = loggedUser && loggedUser.mode === "admin";
  return !isAdmin && user.lastname ? `${user.lastname[0]}.` : user.lastname;
}

function getAddressParts(object) {
  let address = {};
  const address_components = object.address_components;
  address_components.forEach((element) => {
    address[element.types[0]] = element.short_name;
  });
  return address;
}

const makeShortPrice = (price) =>
  price < 1000 ?
  price :
  price < 10000 ?
  (price / 1000).toFixed(2) + "K" :
  price < 100000 ?
  (price / 1000).toFixed(1) + "K" :
  price < 1000000 ?
  (price / 1000).toFixed(0) + "K" :
  price < 10000000 ?
  (price / 1000000).toFixed(2) + "M" :
  price < 100000000 ?
  (price / 1000000).toFixed(1) + "M" :
  (price / 1000000).toFixed(0) + "M";



export {
makeImageUrl,
encodeSvg,
parseAddress,
stateCode,
formatUsd,
protectedUserName,
protectedLastName,

useWindowDimensions,
getAddressParts,
makeShortPrice
};