import React from "react";
import { useTranslation } from "react-i18next";
import { Row, Col } from "react-bootstrap";
import useCurrency from "../../../components/hooks/helpers/useCurrency";

export default ({ details }) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  console.log("details", details);

  return details && details.assessments ? (
    <div className="mt-3">
      <h6>{t("property.taxDetails")}</h6>
      <Row>
        <Col xs={12} sm={6}>
          <h6 className="subtitle my-3">{t("property.taxableValue")}</h6>
          <ul className="FeatureList">
            <li>
              <div>Land</div>
              <div>{currency(details.assessments.land)}</div>
            </li>
            <li>
              <div>Additions</div>
              <div>{currency(details.assessments.improvements)}</div>
            </li>
            <li className="divider"></li>
            <li>
              <div>Total</div>
              <div>{currency(details.assessments.value)}</div>
            </li>
          </ul>
        </Col>
        <Col xs={12} sm={6}>
          <h6 className="subtitle my-3">{t("property.taxRecord")}</h6>
          <ul className="FeatureList">
            <li>
              <div>{details.taxYear}</div>
              <div className="highlight">{currency(details.taxAmount)}</div>
            </li>
          </ul>
        </Col>
      </Row>
    </div>
  ) : null;
};
