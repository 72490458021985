import React from "react";
import AdminSignUp from '../../pages/Admin/SignUp';

import ExploreDojo from '../../pages/General/HomePage/ExploreDojo';
import StaticPage from '../../pages/General/StaticPage';
import PropertySearch from '../../pages/Property/PropertySearch';
import Buyer from '../../pages/General/Landing/Buyer/Buyer';
import Seller from '../../pages/General/Landing/Seller/Seller';
import Faq from '../../pages/General/Landing/Faq/Faq';
import About from '../../pages/General/Landing/About/About';

import CustomRedirect from "../../components/general/CustomRedirect"

export default [
  {
    path: "/s/:page",
    component: StaticPage
  },
  {
    path: "/search",
    component: PropertySearch
  },
  {
    path: "/admin/signup",
    component: AdminSignUp
  },
  {
    path: "/explore",
    component: ExploreDojo,
  },
  {
    path: "/buyer",
    component: Buyer
  },
  {
    path: "/seller",
    component: ({ loading, user }) => {
      if (loading) return null;

      if (user && user.mode === "seller")
        return <CustomRedirect to="/seller/properties" />;

      if (user && user.mode === "member")
        return <CustomRedirect to="/explore" />;

      return <Seller />;
    }
  },
  {
    path: "/faq",
    component: Faq
  },
  {
    path: "/about-us",
    component: About
  },
  {
    path: "/",
    component: ({ loading, user }) => {
      if (loading) return null;

      if (user && user.mode === "member")
        return <CustomRedirect to="/explore" />;

      if (user && user.mode === "seller")
        return <CustomRedirect to="/seller/properties" />;

      if (user && user.mode === "admin")
        return <CustomRedirect to="/admin/offers" />;

      return <Buyer />;
    }
  }
];