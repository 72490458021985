import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import classNames from "classnames";
import { LightgalleryProvider } from "react-lightgallery";
import { Container } from "react-bootstrap";
import qs from "qs";
import _ from "lodash";

import MainNavBar from "../components/sections/MainNavBar";
import Footer from "../components/landing/Footer";
import OnboardingDialog from "../components/user/OnboardingDialog";
import OnBoardingContext from "../components/context/OnBoardingContext";
import CompleteProfileModal from "../components/user/CompleteProfileModal";
import useCurrentUser from "../components/hooks/queries/useCurrentUser";

import "./AppMain.scss";
import useAnonymous from "../components/hooks/queries/useAnonymous";

export default ({ children, fluid, hideFooter, hideSearch }) => {
  const [user] = useCurrentUser();

  const location = useLocation();
  const query = qs.parse(location.search.substring(1)) || {};
  const history = useHistory();

  const [anonymousUser] = useAnonymous();
  const [onboarding, setOnBoarding] = useState({});
  const [open, setOpen] = useState(false);
  const onBoardingContextData = {
    onboarding,
    setOnBoarding,
    open,
    setOpen,
  };

  const handleClose = () => history.push(_.omit(query, "complete"));

  const incompleteProfile = !(
    _.get(user, "validated_phone") && _.get(user, "validated_email")
  );

  return (
    <OnBoardingContext.Provider value={onBoardingContextData}>
      <MainNavBar hideSearch={hideSearch} />
      <LightgalleryProvider galleryClassName="dojo-gallery">
        <Container
          fluid={fluid}
          className={classNames("AppMain", { "px-0": fluid })}
        >
          <div className="AppPage">{children}</div>
        </Container>
      </LightgalleryProvider>

      {!hideFooter && <Footer />}

      <OnboardingDialog anonymousUser={anonymousUser} />

      <CompleteProfileModal
        show={incompleteProfile && query.complete !== undefined}
        onHide={handleClose}
        user={user}
      />
    </OnBoardingContext.Provider>
  );
};
