import React from "react";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import { useRouteMatch, Redirect } from "react-router-dom";

const GET_PROPERTY_CHAT = loader("./GetPropertyChat.graphql");

export default (({ user }) => {
  const match = useRouteMatch();
  const { loading, data } = useQuery(GET_PROPERTY_CHAT, {
    variables: {
      listing_id: match.params.id,
      user_id: match.params.user_id } });



  return loading || !data ? null :
  <Redirect to={`/chats/${data.getChatByProperty._id}`}></Redirect>;

});