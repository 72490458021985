import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import qs from "qs";

import LoginForm from "./LoginForm";
import { DojoLogoDark } from "../landing/icons";
import { Avatar } from "../onboarding/icons";
import DojohomeModal from "../modals/DojohomeModal";
import OnBoardingContext from "../context/OnBoardingContext";

export default ({
  show,
  email,
  onHide,
  onNext,
  mode = "member",
  variant = "landingPages",
  altText,
  showPassModal,
}) => {
  const loc = useLocation();
  const { created, existing } = qs.parse(loc.search.substring(1));
  const { setOpen } = useContext(OnBoardingContext);

  return (
    show && (
      <DojohomeModal
        show={show}
        onHide={onHide}
        header={
          variant === "landingPages" && (
            <Link
              to={mode === "member" ? "/seller?login" : "/?login"}
              className="change-mode"
            >
              <Avatar /> &nbsp; I am a {mode === "member" ? "Seller" : "Buyer"}
            </Link>
          )
        }
      >
        <DojoLogoDark />

        {created !== undefined && (
          <div className="text-success text-center mt-4">
            Your account has been created.
          </div>
        )}

        {existing !== undefined && (
          <div className="text-success text-center mt-4">
            You already have an account with
            <br />
            this email address.
          </div>
        )}

        <h2 className="mt-4 mb-2">Login</h2>

        <h6 className="my-3 text-center">
          Enter your info below to log in <br />
          to your Dojohome {mode === "seller" ? "Seller" : "Buyer"} account.
        </h6>

        <LoginForm
          email={email}
          mode={mode}
          message={""}
          onNext={onNext}
          altText={altText}
          onHide={onHide}
          showPassModal={showPassModal}
        />

        {variant === "landingPages" ? (
          <Link
            to={mode === "member" ? "/seller?signup" : "/?signup"}
            className="simple-link"
          >
            Create {mode === "member" ? "Seller" : "Buyer"} Account
          </Link>
        ) : (
          <button
            className="simple-link"
            onClick={() => {
              onHide();
              setOpen(true);
            }}
          >
            Create {mode === "member" ? "Buyer" : "Seller"} Account
          </button>
        )}
      </DojohomeModal>
    )
  );
};
