import React from "react";
import { Card } from "react-bootstrap";

export default ({ icon: Icon, label, value }) => {
  return (
    <Card className="ListingSummaryFeature no-shadow">
      <Card.Body className="text-center px-0 py-3">
        <h5>{value}</h5>
        <div>
          {label}
          {Icon && <Icon height={24} />}
        </div>
      </Card.Body>
    </Card>
  );
};
