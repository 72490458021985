import React, { useState } from "react";
import { Row, Col, Form, Button, Modal } from "react-bootstrap";
import _ from "lodash";

import InfoTooltip from "../../../components/property/InfoTooltip";
import CategoryList from "./CategoryList";
import useAddCategory from "../../../components/hooks/mutations/useAddCategory";
import useUpdateCategory from "../../../components/hooks/mutations/useUpdateCategory";
import useCategorySettings from "../../../components/hooks/mutations/useCategorySettings";

export default ({ user }) => {
  const [
    categorySettings,
    updateCategorySettings,
    refetchCategorySettings,
    saveCategorySettings,
    isChanged,
  ] = useCategorySettings();
  const [category, setCategory] = useState(undefined);
  const [addCategory] = useAddCategory(category, () => setCategory(undefined));
  const [updateCategory] = useUpdateCategory(_.omit(category, ["status"]), () =>
    setCategory(undefined)
  );

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2>Featured Categories</h2>

        <div className="d-flex">
          <p className="flex-grow-1 mb-0">
            Minimum number of Properties to Show Active Category
          </p>
          <Form.Control
            style={{ width: 100 }}
            size="sm"
            type="number"
            min={1}
            step={1}
            className="ml-2"
            value={categorySettings.minProperties || ""}
            onChange={(e) =>
              updateCategorySettings({
                minProperties: parseInt(e.target.value, 10),
              })
            }
          />
          <div className="pl-2 pr-3">
            <InfoTooltip message="Categories with less than this amount of labeled properties will not be visible to users." />
          </div>
          <div className="d-flex" style={{ width: 120 }}>
            {isChanged && (
              <React.Fragment>
                <Button
                  className="flex-grow-1"
                  variant="light"
                  size="sm"
                  onClick={() => refetchCategorySettings()}
                >
                  Cancel
                </Button>
                <Button
                  className="flex-grow-1"
                  size="sm"
                  onClick={() => saveCategorySettings()}
                >
                  Save
                </Button>
              </React.Fragment>
            )}
          </div>
        </div>
        <hr />
      </Col>

      <Col xs={12} className="d-flex">
        <div className="flex-grow-1"></div>
        <Button
          onClick={() => setCategory({ header: "", thumbnail: "" })}
          size="sm"
        >
          Add New Category
        </Button>
      </Col>

      <Modal
        size="sm"
        centered
        show={category}
        onHide={() => setCategory(undefined)}
      >
        <Modal.Header>
          <h5>Add New Category</h5>
        </Modal.Header>
        {category && (
          <Modal.Body>
            <Form.Group>
              <Form.Label>Header</Form.Label>
              <Form.Control
                value={category.header}
                maxlength={50}
                onChange={(e) =>
                  setCategory({ ...category, header: e.target.value })
                }
              ></Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Thumbnail Badge</Form.Label>
              <Form.Control
                value={category.thumbnail}
                maxlength={25}
                onChange={(e) =>
                  setCategory({
                    ...category,
                    thumbnail: e.target.value.toUpperCase(),
                  })
                }
              ></Form.Control>
            </Form.Group>
          </Modal.Body>
        )}

        <Modal.Footer>
          <Button variant="light" onClick={() => setCategory(undefined)}>
            Cancel
          </Button>
          <Button
            onClick={() => (category._id ? updateCategory() : addCategory())}
          >
            {category && category._id ? "UPDATE" : "ADD"}
          </Button>
        </Modal.Footer>
      </Modal>

      <Col xs={12}>
        <h5>Active Categories</h5>
        <CategoryList
          status="active"
          showTooltip={true}
          onEdit={(cat) => setCategory(cat)}
        />
      </Col>

      <Col xs={12}>
        <h5>Hidden Categories</h5>
        <CategoryList status="hidden" onEdit={(cat) => setCategory(cat)} />
      </Col>
    </Row>
  );
};
