import React from "react";
import { useTranslation } from "react-i18next";

import useCurrentUser from "../../../components/hooks/queries/useCurrentUser";
import AppMain from "../../AppMain";
import SearchBar from "../../../components/sections/components/SearchBar";

import "slick-carousel/slick/slick.scss";
import "slick-carousel/slick/slick-theme.scss";

import "./ExploreDojo.scss";
import ExploreSection from "../../../components/sections/ExploreSection";


export default () => {
  const { t } = useTranslation();
  const [user] = useCurrentUser();

  return (
    <AppMain fluid hideSearch>
      <div className="hero d-flex justify-content-center align-items-center w-100">
        <div className="hero-search w-100">
          <SearchBar
            user={user}
            placeholder={t("homePage.searchPlaceholder")}
          />
        </div>
      </div>

      <ExploreSection user={user} />
    </AppMain>
  );
};
