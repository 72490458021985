import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const MUTATION = gql `
  mutation UpdateUser($id: String!, $params: UserParamsInput) {
    updateUser(id: $id, params: $params) {
      _id
    }
  }
`;

export default ((refetchQueries = []) => {
  const [updateUser, { loading, error }] = useMutation(MUTATION, {
    refetchQueries: () => ["AllUsers", ...refetchQueries],
  });


  return [(id, params) => updateUser({variables: {id, params}}), loading, error];
});
