import React from "react";
import { Link } from "react-router-dom";

import { ArrowRight, Profit } from "./icons";
import { HeartOutline } from "../icons";
import AnalyticsEvent from "../general/AnalyticsEvent";

export default ({
  infoBadge,
  statusBadge,
  image,
  price,
  priceMonthly,
  term,
  equityPayment,
  sellerInfo,
}) => (
  <div className="landing-property-card">
    {infoBadge}
    {statusBadge}
    <div
      className="landing-property-image"
      style={{ backgroundImage: `url(${image})` }}
    />
    <div className="landing-property-card-inner">
      <div className="price">
        <span>${price}</span>
        <p className="price-monthly">${priceMonthly} monthly</p>
        <HeartOutline height={22} />
      </div>
      <p className="term">
        Term: <span>{term} Years</span>
      </p>
      <p className="payment">
        Monthly equity payment: <span>${equityPayment}</span>
      </p>
      {sellerInfo ? (
        <>
          <p className="owed">
            Amount owed to bank: <span>{`$${sellerInfo.owed}`}</span>
          </p>
          <div className="profits">
            <div className="traditional-profits">
              <Profit />
              <p>Traditional sale profits:</p>
              <span>{`$${sellerInfo.traditionalProfits}`}</span>
            </div>

            <div className="dojohome-profits">
              <Profit />
              <p>Dojohome profits:</p>
              <span>{`$${sellerInfo.dojohomeProfits}`}</span>
            </div>
          </div>
        </>
      ) : (
        <AnalyticsEvent pixelEvent="Explore Main">
          {(onClick) => (
            <Link to="/explore" className="action" onClick={onClick}>
              <span>Explore</span>
              <ArrowRight />
            </Link>
          )}
        </AnalyticsEvent>
      )}
    </div>
  </div>
);
