import React, { useEffect } from 'react';
import { Route, Switch, useLocation } from "react-router-dom";
import { useMixpanel } from 'react-mixpanel-browser';
import ReactPixel from 'react-facebook-pixel';

import MemberRoutes from './routes/member';
import SellerRoutes from './routes/seller';
import CommonRoutes from './routes/common';
import GeneralRoutes from './routes/general';
import AdminRoutes from "./routes/admin";
import useCurrentUser from "../components/hooks/queries/useCurrentUser";
import PrivateRoute from '../components/general/PrivateRoute';

import './App.scss';

function App() {
  const location = useLocation();
  const mixpanel = useMixpanel();
  const [user, loading] = useCurrentUser();

  useEffect(() => {
    mixpanel.track("Page View", {
      path: location.pathname
    });

    ReactPixel.pageView();
  
    const onConversationsAPIReady = () => {
      window.HubSpotConversations.on(
        "conversationStarted",
        () => {
          console.log("Click detected");
          ReactPixel.trackCustom("chat");
        }
      );
    }
    
    if (window.HubSpotConversations) {
      onConversationsAPIReady();
    } else {
      window.hsConversationsOnReady = [onConversationsAPIReady];
    }
  }, [location.pathname, mixpanel]);

  return (
    <div className="App">
      <Switch>
        {
        MemberRoutes.map((route) =>
        route.private ?
        <PrivateRoute key={route.path} exact={!!route.exact} path={route.path} component={route.component} user={user} loading={loading} /> :
        <Route key={route.path} exact={!!route.exact} path={route.path} render={() => <route.component loading={loading} user={user} />} />)}


        {
        SellerRoutes.map((route) =>
        route.private ?
        <PrivateRoute key={route.path} exact={!!route.exact} path={route.path} component={route.component} user={user} loading={loading} /> :
        <Route key={route.path} exact={!!route.exact} path={route.path} render={() => <route.component loading={loading} user={user} />} />)}


        {
        CommonRoutes.map((route) =>
        route.private ?
        <PrivateRoute key={route.path} exact={!!route.exact} path={route.path} component={route.component} user={user} loading={loading} /> :
        <Route key={route.path} exact={!!route.exact} path={route.path} render={() => <route.component loading={loading} user={user} />} />)}

        {
        AdminRoutes.map((route) =>
          <PrivateRoute key={route.path} exact={!!route.exact} path={route.path} fallback={route.fallback} roles={route.roles} component={route.component} user={user} loading={loading} />)}
        
        {
        GeneralRoutes.map((route) =>
        route.private ?
        <PrivateRoute key={route.path} exact={!!route.exact} path={route.path} component={route.component} user={user} loading={loading} /> :
        <Route key={route.path} exact={!!route.exact} path={route.path} render={() => <route.component loading={loading} user={user} />} />)}

      </Switch>
    </div>);

}

export default App;
