import React from "react";
import PropertyCard from "./PropertyCard";

export default ({ properties }) => (
  <div className="landing-properties">
    {properties.map((property, index) => (
      <PropertyCard
        price={property.price}
        priceMonthly={property.priceMonthly}
        term={property.term}
        equityPayment={property.equityPayment}
        image={property.image}
        infoBadge={property.infoBadge}
        statusBadge={property.statusBadge}
        sellerInfo={property.sellerInfo}
        key={`property ${index}`}
      />
    ))}
  </div>
);