import React, { useRef } from "react";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";
import _ from "lodash";

import { Col } from "react-bootstrap";

import FileUploader from "../../components/file-uploader/FileUploader";

const UPDATE_EXTRA_DOCUMENTS = loader("./UpdateExtraDocuments.graphql");

export default ({ user, children }) => {
  const uploaderRef = useRef(null);

  const [updateDocuments] = useMutation(UPDATE_EXTRA_DOCUMENTS, {
    refetchQueries: () => ["CurrentUser"],
  });

  return (
    <React.Fragment>
      {children}
      <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-2">
        <div className="py-4">
          <FileUploader
            reference="extra-documents"
            setUploadedFiles={(files) => {
              updateDocuments({
                variables: {
                  files: _.map(files, "_id"),
                },
              });
            }}
            ref={uploaderRef}
            multiple={true}
            uploadedFiles={user.documents}
          />
        </div>
      </Col>
    </React.Fragment>
  );
};
