import CommentImage1 from "../img/comment1.jpg";
import CommentImage2 from "../img/comment2.jpg";

export default [
  {
    text: "The idea was so simple: I got to choose my dream starter home and pay market rent until I saved up for a 10% down payment. And as a new homeowner, it was a huge relief to have their team supporting me every step of the way.",
    name: "Mia Z.",
    image: CommentImage1
  },
  {
    text: "My mortgage broker told me about Dojo when I couldn’t get approved because my income is so inconsistent. They understood my situation, gave me options I could work with, and helped me find a house now with a plan in place to become ‘mortgage-ready’ later.",
    name: "Lindsey G.",
    image: CommentImage2
  }
];