export default [
  {
    question: "What is gradual homeownership?",
    answer: "Gradual homeownership is the process of buying or selling a home over an extended period of time. Buyers make a partial down payment, then they move into the home and pay rent to the seller on an agreed upon timeline until they have saved up enough to become ‘mortgage-ready’. When that happens, Dojo prepares both parties for closing the sale, and the property officially changes hands."
  },
  {
    question: "How is Dojohome different from a residential lease?",
    answer: "Residential leases are for rental purposes only. For the tenant, there is no path to homeownership, and post-lease, the landlord can remove the tenant for any reason. For the landlord, leases entail managing tenants, chasing payments and handling all maintenance issues. The Dojohome platform puts buyers on a structured path to ownership, provides maintenance support, and manages all financial transactions."
  },
  {
    question: "How is Dojo different from a traditional home sale?",
    answer: "Traditional home sales require mortgage approval and a full down payment of 10-20% from the buyer, while sellers are stuck with unpredictable fees and expenses. On the Dojo platform, each property continues to be owned by the seller until the buyer purchases the property outright. The length of time it takes to purchase the property is dependent on the agreed upon equity plan but can range from 1 to 5 years. If the buyer does not purchase the property within the established timeline, any equity contributed after the down payment is then returned to the buyer."
  },
  {
    question: "How are monthly payments calculated?",
    answer: "Your monthly payments will be a combination of the fair market rent and the equity payments based on your specific plan. The rent will go primarily to the seller, with Dojo collecting a small transaction and processing fee and your equity will accumulate in your Escrow account."
  },
];