import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const GET_LISTING = gql`
  fragment ImageFragment on Image {
    _id
    fileid
    extension
    mimetype
    filename
    url
  }

  query GetSingleListing($listing_id: String!) {
    property(listing_id: $listing_id) {
      _id
      propertyType

      price
      rent
      equity_deposit

      mls
      source
      agent {
        _id
        name
        brokerage {
          _id
          title
        }
      }

      address
      city
      state
      zip 
      neighbourhood
      
      beds
      baths
      sqft
      year
      parking

      location {
        coordinates
      }

      description
      pet_allowance
      amenities

      available_from

      owner {
        _id
        firstname
        lastname
        email
        validated_phone
        phone

        profile {
          photo {
            ...ImageFragment
          }
        }
        
        identity {
          drivers_license {
            ...ImageFragment
          }
          passport {
            ...ImageFragment
          }
          id_card {
            ...ImageFragment
          }
        }
      }

      images {
        ...ImageFragment
        caption
      }

      extendedInfo {
        tourURL
      }

      myOffer {
        _id

        down_payment
        move_in

        applications {
          full_name
          email
        }
        pets {
          type
          weight
        }

        tour_date

        notes

        status
      }
      
      offers {
        _id
        status
      }

      attomExtendedInfo {
        identifier {
          obPropId
        }

        avm {
          eventDate
          amount {
            value
          }
        }

        summary {
          yearbuilt
          propclass
          proptype
          propsubtype
          legal1
        }

        utilities {
          coolingtype
          energyType
          heatingfuel
          heatingtype
          sewertype
          watertype
        }

        lot {
          pooltype
        }

        building {
          interior {
            bsmtsize
            fplccount
            fplcind
            fplctype
          }

          rooms {
            bathsfull
            bathshalf
            beds
            roomsTotal
          }

          construction {
            condition
            wallType
          }

          parking {
            prkgSize
            garagetype
            prkgSpaces
            prkgType
          }

          summary {
            archStyle
            levels
            unitsCount
            yearbuilteffective
          }
        }
      }

      under_contract
      published
      archived
      verified

      updated_at
      created_at

      favorited
    }
  }

`;

export default ((id, onCompleted, onError) => {
  const { data, loading, error } = useQuery(GET_LISTING, {
    variables: {
      listing_id: id },

    fetchPolicy: "network-only",
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err) });


  return [data && data.property, loading, error];
});