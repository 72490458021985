import React from "react";

import AppMain from "../AppMain";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import ChatsList from "../../components/chat/ChatsList";

export default (({ user }) => {

  return (
    <AppMain>
      <Row>
        <Col xs={12}>
          <h1 className="text-center my-4">Messages</h1>
        </Col>
      </Row>
      <Row>
        <ChatsList user={user} />
      </Row>
    </AppMain>);

});