import React from "react";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Row, Col, Button, Form } from "react-bootstrap";
import Chart from "react-apexcharts";

import useMlsImportLog from "../../../components/hooks/queries/useMlsImportLog";

export default ({ user }) => {
  const { t } = useTranslation();
  const [items = []] = useMlsImportLog();

  const seriesAdded = {
    name: "Added",
    data: _.map(items, ({ added, dt }) => added),
  };
  const seriesUpdated = {
    name: "Updated",
    data: _.map(items, ({ updated, dt }) => updated),
  };
  const seriesDeleted = {
    name: "Deleted",
    data: _.map(items, ({ deleted, dt }) => deleted),
  };
  const categories = _.map(items, "dt");

  return (
    <Row className="mt-4">
      <Col xs={12}>
        <h2>MLS Import Log</h2>
      </Col>

      <Col xs={12}>
        <Chart
          options={{
            chart: {
              toolbar: {
                show: false,
              },
            },

            dataLabels: {
              enabled: false,
            },
            xaxis: {
              categories,
              labels: {
                rotate: -45,
                rotateAlways: true,
              },
            },
            colors: ["#008FFB", "#00E396", "#a31515"],
          }}
          series={[seriesAdded, seriesUpdated, seriesDeleted]}
          height={280}
          type="area"
        />
      </Col>
    </Row>
  );
};
