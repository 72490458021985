import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import BackNext from "../BackNext";
import SelectCards from "../SelectCards";
import { HouseAd, HouseBulb, HouseCompass } from "../icons";
import useUpdateSellerInfo from "../../hooks/mutations/useUpdateSellerInfo";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const options = [
    {
      icon: HouseAd,
      label: "I’m actively trying to sell my home",
      value: "active",
    },
    {
      icon: HouseBulb,
      label: "I’m thinking about selling my home",
      value: "thinking",
    },
    {
      icon: HouseCompass,
      label: "I’m exploring for the future",
      value: "exploring",
    },
  ];

  const [updateSellerInfo, updating] = useUpdateSellerInfo(
    data,
    () => {
      onNext && onNext();
    },
    (error) => console.log(error)
  );

  const disableNext = !data.sellerType;

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateSellerInfo();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, updateSellerInfo]);

  return (
    <>
      <Form className="form-wide">
        <h3>What can we do to help?</h3>
        <SelectCards
          options={options}
          data={data}
          field="sellerType"
          setData={setData}
        />
      </Form>
      <BackNext
        mode="Seller"
        stepIndex={stepIndex}
        onNext={updateSellerInfo}
        onPrev={onPrev}
        updating={updating}
        disableNext={!data.sellerType}
      />
    </>
  );
};
