import React from "react";
import PropertyImage1 from "../img/property1.jpg";
import PropertyImage2 from "../img/property2.jpg";
import PropertyImage3 from "../img/property3.jpg";
import { InfoBadge, StatusBadge } from "../../../../../components/landing/Badges";

export default [
  {
    price: "140,000",
    priceMonthly: "1,200",
    term: 3,
    equityPayment: 390,
    image: PropertyImage1,
    infoBadge: <InfoBadge text="ONLY 2% DOWN" />,
    statusBadge: <StatusBadge status="sold" />
  },
  {
    price: "225,000",
    priceMonthly: "2,400",
    term: 5,
    equityPayment: 375,
    image: PropertyImage2,
    infoBadge: <InfoBadge text="ONLY 2% DOWN" />,
    statusBadge: <StatusBadge status="sold" />
  },
  {
    price: "350,000",
    priceMonthly: "3,200",
    term: 5,
    equityPayment: 583,
    image: PropertyImage3,
    infoBadge: <InfoBadge text="ONLY 2% DOWN" />,
    statusBadge: <StatusBadge status="sold" />
  }
];
