import React, { useState } from "react";
import { useRouteMatch, Link } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import moment from "moment";
import nl2br from "react-nl2br";
import { Row, Col, Button, Form, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import TagsInput from "react-tagsinput";

import AppMain from "../AppMain";
import PropertyCard from "../../components/general/PropertyCard";
import ProfilePicture from "../../components/user/ProfilePicture";
import { protectedUserName } from "../../tools";
import Loading from "../../components/general/Loading";

import "react-tagsinput/react-tagsinput.css"; // If using WebPack and style-loader.
import { Check, CheckCircle } from "../../components/icons";
import useCurrency from "../../components/hooks/helpers/useCurrency";

const GET_USER_INFO = loader("./GetUserInfo.graphql");
const SET_USER_INFO = loader("./SetUserInfo.graphql");
const SELLER_PROPERTIES = loader("./SellerProperties.graphql");

const creditScoreOptions = [
  { label: "Fair", subtitle: "550-650", value: "fair" },
  { label: "Good", subtitle: "650-750", value: "good" },
  { label: "Very Good", subtitle: "750-800", value: "veryGood" },
  { label: "Excellent", subtitle: "800+", value: "excellent" },
];

const dreamHomeOptions = [
  { label: "Pets are Welcome", value: "pets" },
  { label: "Close to City Center", value: "city" },
  { label: "Access to Good Schools", value: "schools" },
  { label: "Space to Work From Home", value: "space" },
  { label: "Room for a Growing Family", value: "room" },
  { label: "Big Backyard", value: "backyard" },
];

const showFieldValue = (options, value, field = "label") =>
  (_.find(options, { value }) || {})[field];

export default ({ user }) => {
  const { t } = useTranslation();
  const [editing, setEditing] = useState(false);
  const [profile, setProfile] = useState(user.profile || {});
  const match = useRouteMatch();
  const currency = useCurrency();

  const { data, loading } = useQuery(GET_USER_INFO, {
    variables: { user_id: match.params.user },
  });

  const { data: listingsData, loading: loadingListings } = useQuery(
    SELLER_PROPERTIES,
    {
      variables: { owner: match.params.user },
    }
  );

  const [setUserinfo] = useMutation(SET_USER_INFO, {
    variables: {
      profile: _.omit(profile, ["photo", "__typename"]),
    },

    refetchQueries: () => ["GetUserInfo", "CurrentUser"],
    onCompleted: () => {
      setEditing(false);
    },
  });

  const Documents = [
    {
      title: t("identity.driversLicense"),
      field: "drivers_license",
    },
    {
      title: t("identity.passport"),
      field: "passport",
    },
    {
      title: t("identity.idCard"),
      field: "id_card",
    },
  ];

  return (
    <AppMain>
      {loading || !data ? (
        <div>
          <Loading />
        </div>
      ) : (
        <Row className="mt-5 py-5">
          <Col xs={12} md={4}>
            <div
              className="d-flex flex-column mx-2"
              style={{
                border: "solid #CCE6FF 1px",
                borderRadius: 5,
              }}
            >
              <div className="px-1 pt-5 pb-2 text-center">
                <ProfilePicture
                  user={data.user}
                  editing={editing}
                  setProfile={(data) =>
                    setUserinfo({
                      variables: {
                        profile: data,
                      },
                    })
                  }
                />
              </div>
              <div className="mt-5 p-2" style={{ background: "#EEF7FF" }}>
                <div className="mb-3">
                  <div className="smallish">
                    <span>{t("profile.phoneNumber")}</span>
                  </div>
                  <div>
                    {data.user.validated_phone ||
                      data.user.phone ||
                      "Not specified"}{" "}
                    {data.user.validated_phone && <CheckCircle height={16} />}
                  </div>
                  <div className="smallish">
                    <span>{t("profile.emailAddress")}</span>
                  </div>
                  <div>
                    {data.user.validated_email || data.user.email}{" "}
                    {data.user.validated_email && <CheckCircle height={16} />}
                  </div>
                </div>
                <label>{data.user.firstname} Provided</label>
                {data.user.identity ? (
                  <div>
                    {Documents.map(
                      ({ title, field }) =>
                        data.user.identity[field] && (
                          <div>
                            <Check />
                            {title}
                          </div>
                        )
                    )}
                  </div>
                ) : null}
                <hr />
                {data.user.verified ? (
                  <div className="text-primary">
                    <Check />
                  </div>
                ) : (
                  <div className="text-warning">{t("profile.underReview")}</div>
                )}
              </div>
            </div>
          </Col>
          <Col xs={12} md={8} lg={{ span: 7, offset: 1 }}>
            <div className="d-flex">
              <h1 className="mr-5">{protectedUserName(data.user, user)}</h1>
              {data.user._id === user._id && !editing ? (
                <Button
                  variant="outline-primary"
                  className="my-2"
                  onClick={(e) => setEditing(true)}
                >
                  {t("profile.editProfile")}
                </Button>
              ) : null}
            </div>
            {editing ? (
              <div>
                <Form.Group>
                  <Form.Label>{t("profile.aboutMe")}</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="Tell about yourself"
                    value={profile.description}
                    onChange={(e) =>
                      setProfile({ ...profile, description: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{t("profile.languages")}</Form.Label>
                  <TagsInput
                    value={profile.languages || []}
                    onChange={(languages) =>
                      setProfile({ ...profile, languages })
                    }
                  ></TagsInput>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{t("profile.facebookLink")}</Form.Label>
                  <Form.Control
                    placeholder={t("profile.facebookLinkPlaceholder")}
                    value={profile.link_facebook}
                    onChange={(e) =>
                      setProfile({ ...profile, link_facebook: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{t("profile.twitterLink")}</Form.Label>
                  <Form.Control
                    placeholder={t("profile.twitterLinkPlaceholder")}
                    value={profile.link_twitter}
                    onChange={(e) =>
                      setProfile({ ...profile, link_twitter: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>{t("profile.instagramLink")}</Form.Label>
                  <Form.Control
                    placeholder={t("profile.instagramLinkPlaceholder")}
                    value={profile.link_instagram}
                    onChange={(e) =>
                      setProfile({ ...profile, link_instagram: e.target.value })
                    }
                  ></Form.Control>
                </Form.Group>

                <div>
                  <Button
                    variant="primary"
                    className="px-5 mr-2"
                    onClick={setUserinfo}
                  >
                    {t("profile.save")}
                  </Button>
                  <Button
                    variant="outline-primary"
                    className="px-5"
                    onClick={(e) => setEditing(false)}
                  >
                    {t("profile.cancel")}
                  </Button>
                </div>
              </div>
            ) : data.user.profile ? (
              <div>
                <p>{nl2br(data.user.profile.description)}</p>
                <h5 className="mt-5">I Speak</h5>
                <ul>
                  {(data.user.profile.languages || []).map((lang) => (
                    <li>{lang}</li>
                  ))}
                </ul>
                <h4 className="mt-5">Social Profiles</h4>
                {data.user.profile.link_facebook ? (
                  <Button
                    as="a"
                    size="sm"
                    href={data.user.profile.link_facebook}
                  >
                    Facebook
                  </Button>
                ) : null}
                {data.user.profile.link_twitter ? (
                  <Button
                    as="a"
                    size="sm"
                    href={data.user.profile.link_twitter}
                  >
                    Twitter
                  </Button>
                ) : null}
                {data.user.profile.link_instagram ? (
                  <Button
                    as="a"
                    size="sm"
                    href={data.user.profile.link_instagram}
                  >
                    Instagram
                  </Button>
                ) : null}
              </div>
            ) : null}

            <hr />

            <Card className="mb-4">
              <Card.Header>
                <h5>{t("profile.buyingPower")}</h5>
              </Card.Header>
              {data.user.buyingPower ? (
                <Card.Body>
                  <dl>
                    <dt>Submitted On</dt>
                    <dd>
                      {moment(data.user.buyingPower.submittedAt).format("LLL")}
                    </dd>

                    <dt>Buyer Type</dt>
                    <dd className="text-uppercase">
                      {data.user.buyingPower.buyerType}
                    </dd>
                    <dt>Move In</dt>
                    <dd>
                      {moment(data.user.buyingPower.moveIn).format("LLL")}
                    </dd>
                    <dt>Employment</dt>
                    <dd className="text-uppercase">
                      {data.user.buyingPower.employment}
                    </dd>
                    <dt>Dream Home</dt>
                    <dd>
                      {data.user.buyingPower.dreamHome
                        .map((value) => showFieldValue(dreamHomeOptions, value))
                        .join(", ")}
                    </dd>
                    <dt>Savings</dt>
                    <dd>{currency(data.user.buyingPower.savings)}</dd>
                    <dt>Annual Household Income</dt>
                    <dd>{currency(data.user.buyingPower.annualIncome)}</dd>
                    <dt>Credit Score</dt>
                    <dd>
                      {showFieldValue(
                        creditScoreOptions,
                        data.user.buyingPower.creditScore,
                        "subtitle"
                      )}{" "}
                      ({_.startCase(data.user.buyingPower.creditScore)})
                    </dd>
                  </dl>
                </Card.Body>
              ) : (
                <Card.Body>
                  <h5 className="text-center">{t("profile.notSubmitted")}</h5>
                </Card.Body>
              )}
            </Card>

            <Card className="mb-4">
              <Card.Header>
                <h5>{t("profile.lastVisited")}</h5>
              </Card.Header>
              <Card.Body>
                <ul>
                  {data.user.lastVisitedListings &&
                    data.user.lastVisitedListings.map(
                      ({ listing, createdAt }) => (
                        <li>
                          <Link to={`/property/${listing._id}`}>
                            {listing.address}, {listing.city} {listing.state} on{" "}
                            {moment(createdAt).format("LLL")}
                          </Link>
                        </li>
                      )
                    )}
                </ul>
              </Card.Body>
            </Card>

            {loadingListings || !listingsData ? (
              <Loading />
            ) : listingsData.search.properties.length > 0 ? (
              <React.Fragment>
                <h4 className="mt-5">Listings</h4>
                <Row>
                  {listingsData.search.properties.map((property) => (
                    <Col
                      xs={12}
                      md={6}
                      className="d-flex mb-3"
                      style={{ justifyContent: "stretch" }}
                    >
                      <PropertyCard
                        user={user}
                        property={property}
                        link={`/property/${property._id}`}
                      />
                    </Col>
                  ))}
                </Row>
              </React.Fragment>
            ) : null}
          </Col>
        </Row>
      )}
    </AppMain>
  );
};
