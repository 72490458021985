import React, { useState } from "react";
import { Link } from "react-router-dom";
import { loader } from "graphql.macro";
import { useMutation } from "@apollo/react-hooks";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import _ from "lodash";

import AppMain from "../AppMain";
import PhoneValidator from "../../components/general/PhoneValidator";
import States from "../../data/states";
import ProfilePicture from "../../components/user/ProfilePicture";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const UPDATE_PROFILE = loader("./UpdateProfile.graphql");
const SET_USER = loader("./SetUserInfo.graphql");

export default ({ user }) => {
  const { t } = useTranslation();
  const [firstname, setFirstname] = useState(user.firstname);
  const [lastname, setLastname] = useState(user.lastname);
  const [state, setState] = useState(user.state);

  const [
    updateProfile,
    { loading: settingProfile },
  ] = useMutation(UPDATE_PROFILE, { refetchQueries: () => ["CurrentUser"] });

  const [setUser, { loading: settingUser }] = useMutation(SET_USER, {
    refetchQueries: () => ["CurrentUser"],
  });

  return (
    <AppMain>
      <Row className="pb-5">
        <Col xs={12} className="my-4"></Col>
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <Link to="/profile/settings">&larr; {t("profile.back")}</Link>
        </Col>
        <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
          <h1>{t("profile.personalInfo")}</h1>
        </Col>
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <Form>
            <Form.Group>
              <Form.Label>{t("profile.email")}</Form.Label>
              <Form.Control value={user.email} readOnly />
            </Form.Group>

            <Form.Group>
              <Form.Label>{t("profile.firstName")}</Form.Label>
              <Form.Control
                value={firstname}
                onChange={(e) => setFirstname(e.target.value)}
              />
            </Form.Group>

            <Form.Group>
              <Form.Label>{t("profile.lastName")}</Form.Label>
              <Form.Control
                value={lastname}
                onChange={(e) => setLastname(e.target.value)}
              />
            </Form.Group>

            {user.mode === "seller" && (
              <Form.Group controlId="formState">
                <Form.Label>{t("profile.state")}</Form.Label>
                <Form.Control
                  as="select"
                  placeholder={t("profile.chooseState")}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                >
                  <option value={undefined}>Select state</option>
                  {_.map(States, (stateName, stateCode) => (
                    <option key={stateCode} value={stateCode}>
                      {stateName}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
            )}

            <Button
              onClick={(e) =>
                updateProfile({
                  variables: {
                    firstname,
                    lastname,
                    state,
                  },
                })
              }
              disabled={settingProfile}
              className="px-5"
              varient="primary"
            >
              Save
            </Button>
          </Form>
        </Col>
        <Col xs={12} lg={{ span: 4, offset: 2 }}>
          <Card className="mt-4">
            <Card.Header>
              <Form.Label>{t("profile.profileImage")}</Form.Label>
            </Card.Header>
            <Card.Body className="text-center">
              <ProfilePicture
                user={user}
                editing={!settingUser}
                setProfile={(profile) => setUser({ variables: { profile } })}
              />
            </Card.Body>
          </Card>
        </Col>

        <Col xs={12} className="my-4"></Col>
        <Col xs={12} lg={{ span: 6, offset: 2 }}>
          <PhoneValidator user={user} title={t("profile.phoneNumber")} />
        </Col>
      </Row>
    </AppMain>
  );
};
