import React from "react";
import {
  DownPayment,
  Puzzle,
  Scissors,
  Umbrella,
} from "../../../../../components/landing/icons";

export default [
  {
    icon: <DownPayment />,
    title: "A down payment you can afford",
    text:
      "You no longer need to save for a large down payment before moving into your home. For just a fraction of what banks require, Dojo will get you mortgage ready.",
  },
  {
    icon: <Puzzle />,
    title: "Equity-building plans that make sense",
    text:
      "From Slow & Steady to Accelerated, our path to ownership plans move you from renter to owner in a timeframe that fits your financial reality.",
  },
  {
    icon: <Scissors />,
    title: "Rates that are clear and transparent",
    text:
      "We delight in matching folks to homes, not owning houses. That’s why we do not own or hold titles to any properties listed on our platform, a fact that ensures all prices accurately reflect market rates rather than our interests.",
  },
  {
    icon: <Umbrella />,
    title: "We are with you every step of the way",
    text:
      "Just because our platform is seamless doesn’t mean you’re stuck with robots. Our team of real humans is here to help and support you through the entire process. From touring properties to ongoing financial guidance, you can count on us.",
  },
];
