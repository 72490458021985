import React from "react";
import Card from "./Card";

export default ({ comments }) => (
  <div className="landing-comments">
    {comments.map((comment, index) => (
      <Card
        text={comment.text}
        name={comment.name}
        image={comment.image}
        key={`comment ${index}`}
      />
    ))}
  </div>
);