import Users from '../../pages/Admin/Users';
import Properties from '../../pages/Admin/Properties';
import Offers from '../../pages/Admin/Offers';
import MissionControl from '../../pages/Admin/MissionControl';
import SupportChats from '../../pages/Admin/SupportChats';
import Profile from '../../pages/User/Profile';

export default [
  {
    path: "/admin/offers",
    title: "Offers",
    roles: ["admin", "success-manager"],
    fallback: "/admin/properties",
    component: Offers,
  },
  {
    path: "/admin/properties",
    title: "All Properties",
    roles: ["admin", "success-manager", "data-entry"],
    component: Properties
  },
  {
    path: "/admin/users/:role",
    title: "Users",
    roles: ["admin", "success-manager"],
    component: Users
  },

  {
    path: "/mission-control/:page",
    title: "Mission Control",
    roles: ["admin"],
    component: MissionControl
  },

  {
    path: "/mission-control",
    title: "Mission Control",
    roles: ["admin"],
    component: MissionControl
  },

  {
    path: "/admin/chats",
    title: "Support Chats",
    roles: ["admin", "success-manager"],
    component: SupportChats
  },

  {
    path: "/profile/:user",
    component: Profile,
    roles: ["admin", "success-manager"],
    private: true
  }
];