import gql from "graphql-tag";
import {
  useQuery,
  useMutation
} from "@apollo/react-hooks";

const GET_AGENTS = gql `
  query GetAgents {
    agents {
      _id
      name
      brokerage {
        _id
        title
      }
    }
  }
`;

const CREATE_AGENT = gql `
  mutation CreateAgent($name: String!, $brokerage: ID!) {
    createAgent(name: $name, brokerage: $brokerage) {
      _id
      name
      brokerage {
        _id
        title
      }
    }
  }
`;

export default (onCompleted) => {
  const { data, loading, error } = useQuery(GET_AGENTS);

  const [createAgent] = useMutation(CREATE_AGENT, {
    refetchQueries: () => ['GetAgents'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [data && data.agents, (agent) => {
    console.log(agent)
    createAgent({ variables: agent })
  }, loading, error];
};