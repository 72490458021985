import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const UPLOAD_IMAGE = gql`
  mutation UploadImage($file: Upload!) {
    uploadImage(file: $file) {
      _id
      fileid
      extension
      mimetype
      filename
    }
  }
`;

export default ((onCompleted, onError) => {
  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    onCompleted: data => onCompleted && onCompleted(data.uploadImage),
    onError: err => onError && onError(err) });


  return [uploadImage];
});