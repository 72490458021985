import { Slow, Speed, Rocket, Plan } from "../components/icons";

export default [{
  id: "slow",
  icon: Slow,
  title: "Slow & Steady",
  desc: "Gets you to 10% within 5 years",
  targetEquity: 10,
  targetMonths: 5 * 12 },

{
  id: "right",
  icon: Speed,
  title: "Cruise Control",
  desc: "Own 10% within 3 years",
  targetEquity: 10,
  targetMonths: 3 * 12 },

{
  id: "extreme",
  icon: Rocket,
  title: "Accelerated",
  desc: "Own 10% within 1.5 years",
  targetEquity: 10,
  targetMonths: 1.5 * 12 },

{
  id: "custom",
  icon: Plan,
  title: "Make your own plan",
  desc: "Purchase equity whenerver you want",
}
];