import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

import BackNext from "./BackNext";
import SelectCards from "./SelectCards";
import { HouseBuy, Compass, HouseHand } from "./icons";
import useUpdateBuyingPower from "../hooks/mutations/useUpdateBuyingPower";

export default ({ data, setData, onPrev, onNext, stepIndex }) => {
  const options = [
    {
      icon: HouseBuy,
      label: "Actively looking to buy a home",
      value: "active",
    },
    {
      icon: Compass,
      label: "Exploring my living options",
      value: "exploring",
    },
    {
      icon: HouseHand,
      label: "Dreaming for the future",
      value: "dreaming",
    },
  ];
  const disableNext = !data.buyerType;

  const [updateBuyingPower, updating] = useUpdateBuyingPower(data, () => {
    onNext && onNext();
  });

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && updateBuyingPower();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, data.buyerType, updateBuyingPower]);

  return (
    <>
      <Form className="form-wide">
        <h3>I am</h3>
        <SelectCards
          options={options}
          data={data}
          field="buyerType"
          setData={setData}
        />
      </Form>
      <BackNext
        mode="Buyer"
        stepIndex={stepIndex}
        onNext={updateBuyingPower}
        onPrev={onPrev}
        updating={updating}
        disableNext={disableNext}
      />
    </>
  );
};
