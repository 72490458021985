import React, { useState } from "react";
import { Route, Switch, Link, useRouteMatch } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery } from "@apollo/react-hooks";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";
import classNames from "classnames";
import currencyFormatter from "currency-formatter";

import AppMain from "../AppMain";
import SellerPropertyApplication from "./SellerPropertyApplication";
import SellerPropertyApplications from "./SellerPropertyApplications";
import useDeleteProperty from "../../components/hooks/mutations/useDeleteProperty";

const MY_PROPERTY = loader("./GetMyProperty.graphql");

export default ({ user }) => {
  const match = useRouteMatch();

  const [confirmationDialog, setConfirmationDialog] = useState(false);

  const { loading, data } = useQuery(MY_PROPERTY, {
    fetchPolicy: "network-only",
    variables: { listing_id: match.params.id },
  });

  const [deleteProperty] = useDeleteProperty({
    onCompleted: (result) => {
      console.log(result);
      window.location = "/seller/properties";
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const property = data ? data.myProperty : null;

  return (
    <AppMain>
      {loading || !property ? (
        <div>Loading...</div>
      ) : (
        <React.Fragment>
          <Modal
            show={confirmationDialog}
            onHide={(e) => setConfirmationDialog(false)}
            size="sm"
          >
            <Modal.Body className="text-center px-3 py-3">
              <div className="p-5">
                <svg
                  width="96"
                  height="96"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M6 19C6 20.1 6.9 21 8 21H16C17.1 21 18 20.1 18 19V7H6V19ZM19 4H15.5L14.5 3H9.5L8.5 4H5V6H19V4Z"
                    fill="#46C9F4"
                  />
                </svg>
              </div>

              <h4 className="mb-4">Delete this Listing?</h4>

              <div className="d-flex w-100 text-uppercase">
                <Button
                  className="flex-grow-1 mx-1"
                  variant="primary"
                  onClick={(e) => deleteProperty(match.params.id)}
                >
                  Yes
                </Button>
                <Button
                  className="flex-grow-1 mx-1"
                  variant="outline-primary"
                  onClick={(e) => setConfirmationDialog(false)}
                >
                  No
                </Button>
              </div>
            </Modal.Body>
          </Modal>

          <Row>
            <Col xs={12} lg={{ span: 10, offset: 1 }} className="mt-5">
              <Card style={{ overflow: "hidden" }}>
                <div className="d-flex">
                  <div className="flex-grow-1 py-4 px-5">
                    <div>Address</div>
                    <h6>{property.address}</h6>
                  </div>
                  <div
                    className="py-4 px-5"
                    style={{ borderLeft: "1px solid #CCE6FF" }}
                  >
                    <div>Price</div>
                    <h6>
                      {currencyFormatter.format(property.price, {
                        code: "USD",
                        precision: 0,
                      })}
                    </h6>
                    <div>Rent</div>
                    <h6>
                      {currencyFormatter.format(property.rent, {
                        code: "USD",
                        precision: 0,
                      })}
                      /m
                    </h6>
                  </div>
                  <div
                    className="py-4 px-5"
                    style={{ borderLeft: "1px solid #CCE6FF" }}
                  >
                    <div>Listing&nbsp;Status</div>
                    <h6>
                      {!property.verified
                        ? "Verification"
                        : property.under_contract
                        ? "Contract"
                        : "Active"}
                    </h6>
                    {!property.verified && (
                      <Button
                        as={Link}
                        block
                        size="sm"
                        variant="outline-secondary"
                        to={`/seller/property/${property._id}`}
                      >
                        Edit
                      </Button>
                    )}
                  </div>
                  <div
                    className="d-flex flex-column"
                    style={{ borderLeft: "1px solid #CCE6FF" }}
                  >
                    <Button
                      as={Link}
                      to={`/property/${property._id}`}
                      className={classNames("flex-grow-1", "d-block", {
                        disabled: !property.verified,
                      })}
                      variant="primary"
                      style={{ borderRadius: 0 }}
                    >
                      View
                    </Button>
                    <Button
                      className="flex-grow-1"
                      variant="light"
                      style={{ borderRadius: 0 }}
                      onClick={(e) => setConfirmationDialog(true)}
                    >
                      Delete
                    </Button>
                  </div>
                </div>
              </Card>
            </Col>
            <Route
              exact={true}
              path="/seller/properties/:id/offers/:aid"
              user={user}
              render={(props) => (
                <SellerPropertyApplication user={user} {...props} />
              )}
            />

            <Route
              exact={true}
              path="/seller/properties/:id"
              user={user}
              render={(props) => (
                <SellerPropertyApplications
                  user={user}
                  {...props}
                  property={property}
                />
              )}
            />
          </Row>
        </React.Fragment>
      )}
    </AppMain>
  );
};
