import React, { useContext } from "react";
import { Link, useLocation } from "react-router-dom";
import { Navbar, Nav, Button } from "react-bootstrap";
import _ from "lodash";

import { DojoLogoDark, DojoLogoWhiteNavbar, DarkHamburgerMenu } from "./icons";
import NavLinks from "./NavLinks";
import OnBoardingContext from "../context/OnBoardingContext";
import AnalyticsEvent from "../general/AnalyticsEvent";
import useCurrentUser from "../../components/hooks/queries/useCurrentUser";

export default ({ variant = "dark" }) => {
  const [ user ] = useCurrentUser();
  const location = useLocation();
  const mode =
    location.pathname.substring(1).split("/")[0] === "seller"
      ? "seller"
      : "buyer";
  const { setOpen } = useContext(OnBoardingContext);

  return (
    <Navbar
      className="landing-navbar"
      variant={variant === "gradient" ? "light" : variant}
      expand="lg"
    >
      <div className="navbar-inner">
        <Navbar.Brand>
          <Link to="/">
            {variant === "dark" || variant === "gradient" ? (
              <DojoLogoDark />
            ) : (
              <DojoLogoWhiteNavbar />
            )}
          </Link>
        </Navbar.Brand>
        <Navbar.Collapse>
          <Nav>
            <NavLinks mode={mode} />
            {!user && (
              <div className="landing-navbar-buttons">
                <AnalyticsEvent pixelEvent="Login">
                  {(onClick) => (
                    <Button
                      as={Link}
                      to="?login"
                      id="login"
                      variant={
                        variant === "light" || variant === "gradient"
                          ? "outline-light"
                          : "outline-secondary"
                      }
                      onClick={onClick}
                    >
                      Login
                    </Button>
                  )}
                </AnalyticsEvent>
                <AnalyticsEvent
                  pixelEvent={`${_.capitalize(mode)} Sign up`}
                  onClick={() => setOpen(true)}
                >
                  {(onClick) => (
                    <Button onClick={onClick} id="signup" variant="dojobrand">
                      Sign up
                    </Button>
                  )}
                </AnalyticsEvent>
              </div>
            )}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Toggle
          label="Toggle navigation"
          children={
            <DarkHamburgerMenu
              color={(variant === "light" || variant === "gradient") && "white"}
            />
          }
        />
      </div>
    </Navbar>
  );
};
