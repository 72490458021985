import React from "react";

export default () => {
  return (
    <div className="privacy-content">
      <h2>Privacy Policy</h2>

      <p>
        Last updated: September 1, 2020
        <br /><br />
        Dojohome ("us", "we", or "our") operates http://www.dojohome.com (the
        "Site"). This page informs you of our policies regarding the collection,
        use and disclosure of Personal Information we receive from users of the
        Site.
        <br /><br />
        We use your Personal Information only for providing and improving the
        Site. By using the Site, you agree to the collection and use of
        information in accordance with this policy.
      </p>

      <h3>Information Collection And Use</h3>
      <p>
        While using our Site, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you. Personally identifiable information may include, but is
        not limited to your name, email address, property address, phone number,
        and social security number ("Personal Information").
      </p>

      <h3>Log Data</h3>
      <p>
        Like many site operators, we collect information that your browser sends whenever you visit our Site ("Log Data").
        <br /><br />
        This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser type, browser version, the pages of our Site that you visit, the time and date of your visit, the time spent on those pages and other statistics.
        <br /><br />
        In addition, we may use third party services such as Mixpanel that collect, monitor and analyze this information for purposes of improving the Site. You acknowledge that Dojohome may also use third party services for purposes of providing the Site, such as payment processors and tenant verification engines.
      </p>

      <h3>Communications</h3>
      <p>
        We may use your Personal Information to contact you with newsletters, marketing or promotional materials and other information that pertains to your account – including, but not limited to, information about your properties and offers.
      </p>

      <h3>Cookies</h3>
      <p>
        Cookies are files with small amount of data, which may include an anonymous unique identifier. Cookies are sent to your browser from a web site and stored on your computer's hard drive.
        <br /><br />
        Like many sites, we use "cookies" to collect information. You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Site.
      </p>

      <h3>Changes To This Privacy Policy</h3>
      <p>
        This Privacy Policy is effective as of July 26, 2020 and will remain in effect except with respect to any changes in its provisions in the future, which will be in effect immediately after being posted on this page.
        <br /><br />
        We reserve the right to update or change our Privacy Policy at any time and you should check this Privacy Policy periodically. Your continued use of the Service after we post any modifications to the Privacy Policy on this page will constitute your acknowledgment of the modifications and your consent to abide and be bound by the modified Privacy Policy.
        <br /><br />
        If we make any material changes to this Privacy Policy, we will notify you either through the email address you have provided us, or by placing a prominent notice on our website.
      </p>

      <h3>Contact Us</h3>
      <p>
        If you have any questions about this Privacy Policy, please contact us.
      </p>
    </div>
  );
};
