import React from "react";
import { Button, Form } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";
import { useTranslation } from "react-i18next";

import InfoTooltip from "./InfoTooltip";
import useCurrency from "../hooks/helpers/useCurrency";

import "bootstrap-slider/dist/css/bootstrap-slider.css";

export default ({ listing, planCalculator }) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  return (
    <React.Fragment>
      <div className="d-flex small">
        <div className="flex-grow-1">
          {t("property.plan.monthlyRentShort")}{" "}
          {/* <InfoTooltip
            message={
              <p className="my-1">{t("property.plan.monthlyRentTooltip")}</p>
            }
          /> */}
        </div>
        <div>{currency(planCalculator.monthlyRent)}</div>
      </div>
      <div className="d-flex small">
        <div className="flex-grow-1">
          {t("property.plan.monthlyEquity")}{" "}
          <InfoTooltip
            message={
              <p className="my-1">{t("property.plan.monthlyEquityTooltip")}</p>
            }
          />
        </div>
        <div>{currency(planCalculator.monthlyEquity)}</div>
      </div>
      <div className="d-flex">
        <div className="flex-grow-1">
          {t("property.plan.monthlyTotal")}{" "}
          <InfoTooltip
            message={
              <p className="my-1">{t("property.plan.monthlyTotalTooltip")}</p>
            }
          />
        </div>
        <div>
          {currency(planCalculator.monthlyRent + planCalculator.monthlyEquity)}
        </div>
      </div>

      <hr className="light" />

      <div className="d-flex">
        <div className="flex-grow-1">
          {t("property.plan.downPayment")}{" "}
          <InfoTooltip
            message={
              <p className="my-1">{t("property.plan.downPaymentTooltip")}</p>
            }
          />
        </div>
        <div>{currency(planCalculator.down_payment)}</div>
      </div>

      <RangeSlider
        min={planCalculator.minEquity}
        max={planCalculator.maxEquity}
        size="sm"
        step={0.25}
        tooltip="off"
        value={planCalculator.equity}
        onChange={(e) => planCalculator.setEquity(e.target.value)}
      ></RangeSlider>

      <div className="d-flex small">
        <div className="flex-grow-1">{t("property.plan.startingEquity")}</div>
        <div>{planCalculator.equity.toFixed(2)}%</div>
      </div>

      <hr className="light" />

      <p>
        {t("property.plan.equityPlan")}{" "}
        <InfoTooltip message={t("property.plan.equityPlanTooltip")} />
      </p>

      {planCalculator.plans
        .filter((p) => p.targetMonths)
        .map((p) => (
          <Button
            key={p.id}
            block
            className="my-2"
            variant={planCalculator.activePlan === p ? "primary" : "dark"}
            onClick={() => planCalculator.setPlan(p.id)}
          >
            {p.title}
          </Button>
        ))}

      <div className="text-center small my-3">
        <div>
          <strong>{planCalculator.activePlan.title}</strong>
        </div>
        <div>
          {t("property.plan.summary", {
            equity: planCalculator.activePlan.targetEquity,
          })}{" "}
          {planCalculator.targetDuration.years > 0
            ? t("property.plan.years", {
                count: planCalculator.targetDuration.years,
              })
            : ""}{" "}
          {planCalculator.targetDuration.months > 0
            ? t("property.plan.months", {
                count: planCalculator.targetDuration.months,
              })
            : ""}{" "}
        </div>
      </div>
    </React.Fragment>
  );
};
