import React, { useState } from "react";
import _ from "lodash";
import { Route, Redirect } from "react-router-dom";
import useLogout from "../hooks/mutations/useLogout";

function PrivateRoute({
  user,
  loading,
  roles,
  fallback,
  component: Component,
  ...rest
}) {
  const [loggedOut, setLoggedOut] = useState(false);
  const [logout] = useLogout(() => {
    setLoggedOut(true);
  });

  if (loading) return null;
  if (!user) return <Redirect to="/" />;

  if (roles && _.intersection(roles, user.roles).length === 0) {
    return <Redirect to={fallback || "/"} />;
  }

  const childProps = { ...rest, user };
  if (user && user.banned) logout();

  return loggedOut ? (
    <Redirect to="/" />
  ) : (
    <Route
      {...childProps}
      render={(props) =>
        user ? (
          <Component {...childProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/?login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export default PrivateRoute;
