import React, { useState, useMemo } from "react";
import _ from "lodash";
import { Popover, Form, Row, Col, Button } from "react-bootstrap";
import Rheostat from "rheostat";
import { useTranslation } from "react-i18next";

import useCurrency from "../hooks/helpers/useCurrency";

import "./Rheostat.scss";

import "rheostat/initialize";

const makeSectionGetter = (valueSpan) => (val) => Math.ceil(val / valueSpan);

const makePitComponent = (pxSpan, valueSpan, pins, val) => ({
  style,
  children,
}) => {
  const getSection = makeSectionGetter(valueSpan);
  const activeSection = getSection(children);
  const height = Math.ceil(pins[activeSection]) * pxSpan + 3;
  const background =
    (getSection(val.min) || 0) <= activeSection &&
    activeSection < (getSection(val.max) || pins.length)
      ? "#87B8FF"
      : "#D5DEEC";

  return (
    <div
      style={{
        ...style,
        background,
        width: 7,
        borderRadius: "1px 1px 0 0",
        height,
        bottom: 11,
      }}
    />
  );
};

export default React.forwardRef(
  (
    {
      min,
      max,
      step,
      pins,
      changeable,
      value,
      prefix,
      onChange,
      isNumber,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation();
    const [val, setVal] = useState({ min: value.min, max: value.max });
    const currencyFormatter = useCurrency();
    const currency = isNumber ? (n) => n : currencyFormatter;

    console.log(val);

    const maxNum = _.max(pins);
    const span = Math.ceil(max / 30);
    const PitComponent = useMemo(
      () => makePitComponent(60 / maxNum, span, pins, val),
      [maxNum, pins, span, val]
    );

    return (
      <Popover id={`${changeable}-filter`} ref={ref} {...props}>
        <Popover.Content>
          <div className="p-2">
            <Row>
              <Col xs={12}>
                <div className="px-2 pb-2 pt-5 mt-2 d-none">
                  <Rheostat
                    min={0}
                    max={max}
                    values={[
                      Math.min(val.min || 0, max),
                      Math.max(val.max || max, 0),
                    ]}
                    onValuesUpdated={({ values }) =>
                      setVal({ min: values[0], max: values[1] })
                    }
                    pitPoints={_.range(0, max, span)}
                    pitComponent={PitComponent}
                  />
                </div>
              </Col>
              <Col xs={6} className="pr-1">
                <div className="Box">
                  <Form.Label>Min {changeable}</Form.Label>
                  <div className="d-flex pt-2">
                    {
                      //prefix && <div className="mr-1">{prefix}</div>
                    }
                    <Form.Control
                      className=""
                      type="number"
                      as="select"
                      placeholder={t("filters.nomin")}
                      min={min}
                      max={max}
                      step={step}
                      value={val.min || min}
                      onChange={(e) =>
                        setVal({
                          min: parseFloat(e.target.value),
                          max: val.max,
                        })
                      }
                    >
                      {_.range(
                        0,
                        (val.max ? val.max - step : max) + 1,
                        step
                      ).map((op) => (
                        <option key={op} value={op}>
                          {currency(op)}
                        </option>
                      ))}
                    </Form.Control>
                  </div>
                </div>
              </Col>
              <Col xs={6} className="pl-1">
                <div className="Box">
                  <Form.Label>Max {changeable}</Form.Label>
                  <div className="d-flex pt-2">
                    {
                      //prefix && <div className="mr-1">{prefix}</div>
                    }
                    <Form.Control
                      as="select"
                      type="number"
                      placeholder={t("filters.nomax")}
                      min={min}
                      max={max}
                      step={step}
                      value={val.max || max}
                      onChange={(e) =>
                        setVal({
                          min: val.min,
                          max: parseFloat(e.target.value),
                        })
                      }
                    >
                      {_.range(val.min ? val.min + step : 0, max + 1, step).map(
                        (op) => (
                          <option key={op} value={op}>
                            {currency(op)}
                          </option>
                        )
                      )}
                      <option value={max}>Max</option>
                    </Form.Control>
                  </div>
                </div>
              </Col>
            </Row>
            <hr />
            <div className="d-flex justify-content-end">
              <Button
                size="sm"
                variant="light"
                className="px-4 mr-2"
                onClick={() => {
                  setVal({ min: undefined, max: undefined });
                  onChange(undefined, undefined);
                }}
              >
                {t("filters.clear")}
              </Button>
              <Button
                size="sm"
                varinat="primary"
                className="px-4"
                onClick={() => onChange(val.min, val.max)}
              >
                {t("filters.save")}
              </Button>
            </div>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
);
