import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const ALL_USERS = gql`
  fragment ImageFragment on Image {
    _id
    fileid
    extension
    url
  }

  query AllUsers($type: String, $status: String, $q: String) {
    allUsers(type: $type, status: $status, q: $q) {
      _id
      status
      email
      firstname
      lastname
      roles
      mode

      properties {
        _id
      }

      profile {
        photo {
          ...ImageFragment
        }
      }

      verified
      banned
      ban_reason
    }
  }
`;

export default (({type, q, status}, onCompleted, onError) => {
  const {
    data,
    loading,
    error,
    refetch } =
  useQuery(ALL_USERS, {
    variables: {
      status,
      type,
      q },

    fetchPolicy: "network-only" });


  return [refetch, data && data.allUsers, loading, error];
});