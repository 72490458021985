import React, { useState } from "react";
import _ from "lodash";
import { Popover, Row, Col, Form, Button } from "react-bootstrap";
import moment from "moment";
import DatePicker from "react-datepicker";

import Amenities from "../../data/amenities";
import usePropertyCategories from "../../components/hooks/queries/usePropertyCategories";
import InfoTooltip from "./InfoTooltip";

/*
    A  P  C           UC   CL  FC
    X           =>    F    F
       X        =>    T    
          X     =>         T
    X  X        =>         F
    X     X     =>    F
       X  X     =>             T
    X  X  X     =>
    */

const filterMapping = [
  {
    display: [true, false, false],
    filtered: [false, false, undefined],
  },
  {
    display: [false, true, false],
    filtered: [true, undefined, undefined],
  },
  {
    display: [false, false, true],
    filtered: [undefined, true, undefined],
  },
  {
    display: [true, true, false],
    filtered: [undefined, false, undefined],
  },
  {
    display: [true, false, true],
    filtered: [false, undefined, undefined],
  },
  {
    display: [false, true, true],
    filtered: [undefined, undefined, true],
  },
  {
    display: [true, true, true],
    filtered: [undefined, undefined, undefined],
  },
];

const filterToChecks = ({ under_contract, closed, contract_or_closed }) =>
  _.get(
    _.find(filterMapping, ({ filtered }) =>
      _.isEqual(filtered, [under_contract, closed, contract_or_closed])
    ),
    "display"
  );

const checksToFilter = (checks) => {
  const result = _.find(filterMapping, ({ display }) =>
    _.isEqual(display, checks)
  );
  return _.get(result, "filtered");
};

export default React.forwardRef(
  ({ filter, searchStats, onChange, ...props }, ref) => {
    const [categories] = usePropertyCategories("active");
    const [val, setVal] = useState(filter);
    const [available, pending, sold] = filterToChecks(val);

    const switchAmenities = (value, checked) => {
      const amenities = _.without(val.amenities || [], value);
      return checked
        ? [value, ...amenities]
        : amenities.length > 0
        ? amenities
        : undefined;
    };

    const switchCategory = (value) => {
      const exists = _.includes(val.categories || [], value);
      const rest = _.without(val.categories || [], value);
      return !exists ? [...rest, value] : rest.length > 0 ? rest : undefined;
    };

    return (
      <Popover id={`extra-filter`} ref={ref} {...props}>
        <Popover.Content>
          <div style={{ width: 380, overflowY: "scroll", maxHeight: 560 }}>
            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Property Status
              </Form.Label>
              <Col sm="8">
                <Form.Check
                  checked={available}
                  onChange={(e) => {
                    const checks = checksToFilter([
                      e.target.checked,
                      pending,
                      sold,
                    ]);
                    if (checks) {
                      setVal({
                        ...val,
                        under_contract: checks[0],
                        closed: checks[1],
                        contract_or_closed: checks[2],
                      });
                    }
                  }}
                  label="Available"
                />
                <Form.Check
                  checked={pending}
                  onChange={(e) => {
                    const checks = checksToFilter([
                      available,
                      e.target.checked,
                      sold,
                    ]);
                    if (checks) {
                      setVal({
                        ...val,
                        under_contract: checks[0],
                        closed: checks[1],
                        contract_or_closed: checks[2],
                      });
                    }
                  }}
                  label={
                    <React.Fragment>
                      <span>Pending</span>{" "}
                      <div className="d-inline-block position-relative ml-2">
                        <InfoTooltip
                          id="dark-pending-info"
                          message={
                            <p className="my-1">
                              Properties in "Pending" status are under contract
                              between a member and the seller. You can still
                              make an offer on these properties.
                            </p>
                          }
                        />
                      </div>
                    </React.Fragment>
                  }
                />
                <Form.Check
                  checked={sold}
                  onChange={(e) => {
                    const checks = checksToFilter([
                      available,
                      pending,
                      e.target.checked,
                    ]);
                    if (checks) {
                      setVal({
                        ...val,
                        under_contract: checks[0],
                        closed: checks[1],
                        contract_or_closed: checks[2],
                      });
                    }
                  }}
                  label="Sold"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Square Feet
              </Form.Label>
              <Col sm="8">
                <Row>
                  <Col xs={6}>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={val.sqft_min}
                      onChange={(e) =>
                        setVal({ ...val, sqft_min: parseFloat(e.target.value) })
                      }
                    >
                      <option value={undefined}>Any</option>
                      {_.map(
                        _.range(
                          0,
                          Math.min(
                            val.sqft_max || 1000000,
                            searchStats ? searchStats.sqft.max : 1000000
                          ),
                          100
                        ),
                        (y) => (
                          <option key={y} value={y}>
                            {y}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </Col>
                  <Col xs={6}>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={val.sqft_max}
                      onChange={(e) =>
                        setVal({ ...val, sqft_max: parseFloat(e.target.value) })
                      }
                    >
                      <option value={undefined}>Any</option>
                      {_.map(
                        _.range(
                          Math.max(0, val.sqft_min + 100),
                          searchStats ? searchStats.sqft.max : 0,
                          100
                        ),
                        (y) => (
                          <option key={y} value={y}>
                            {y}
                          </option>
                        )
                      )}
                    </Form.Control>
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Ready to Move-In by
              </Form.Label>
              <Col sm="8">
                <DatePicker
                  selected={moment(val.moveInBy || new Date()).toDate()}
                  minDate={new Date()}
                  onChange={(date) => setVal({ ...val, moveInBy: date })}
                  className="form-control"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Must Haves
              </Form.Label>
              <Col sm="8">
                {Amenities.details.map((amenity) => (
                  <Form.Check
                    key={amenity.value}
                    className="dense"
                    checked={_.includes(val.amenities, amenity.value)}
                    onChange={(e) =>
                      setVal({
                        ...val,
                        amenities: switchAmenities(
                          amenity.value,
                          e.target.checked
                        ),
                      })
                    }
                    label={amenity.search}
                  />
                ))}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Days On DojoHome
              </Form.Label>

              <Col sm="8">
                <Form.Control
                  as="select"
                  size="sm"
                  value={val.daysLive}
                  onChange={(e) =>
                    setVal({ ...val, daysLive: parseFloat(e.target.value) })
                  }
                >
                  <option value={undefined}>Any</option>
                  <option value={7}>7 days or newer</option>
                  <option value={14}>14 days or newer</option>
                  <option value={21}>21 days or newer</option>
                  <option value={30}>One month or newer</option>
                </Form.Control>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Year Built
              </Form.Label>
              <Col sm="8">
                <Row>
                  <Col xs={6}>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={val.year_min}
                      onChange={(e) =>
                        setVal({ ...val, year_min: parseFloat(e.target.value) })
                      }
                    >
                      <option value={undefined}>Any</option>
                      {_.map(_.range(1950, 2020), (y) => (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                  <Col xs={6}>
                    <Form.Control
                      as="select"
                      size="sm"
                      value={val.year_max}
                      onChange={(e) =>
                        setVal({ ...val, year_max: parseFloat(e.target.value) })
                      }
                    >
                      <option value={undefined}>Any</option>
                      {_.map(_.range(1950, 2020), (y) => (
                        <option key={y} value={y}>
                          {y}
                        </option>
                      ))}
                    </Form.Control>
                  </Col>
                </Row>
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Parking Spaces
              </Form.Label>
              <Col sm="8">
                <Form.Control
                  as="select"
                  size="sm"
                  value={val.parking}
                  onChange={(e) =>
                    setVal({ ...val, parking: parseFloat(e.target.value) })
                  }
                >
                  <option value={undefined}>Any</option>
                  {_.map(_.range(1, 7), (ps) => (
                    <option key={ps} value={ps}>
                      {ps}+
                    </option>
                  ))}
                </Form.Control>

                <Form.Check
                  checked={_.includes(val.amenities, "garage")}
                  className="dense"
                  onChange={(e) =>
                    setVal({
                      ...val,
                      amenities: switchAmenities("garage", e.target.checked),
                    })
                  }
                  label="Must have garage"
                />
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Accessibility
              </Form.Label>
              <Col sm="8">
                {Amenities.accessibility.map((amenity) => (
                  <Form.Check
                    key={amenity.value}
                    className="dense"
                    checked={_.includes(val.amenities, amenity.value)}
                    onChange={(e) =>
                      setVal({
                        ...val,
                        amenities: switchAmenities(
                          amenity.value,
                          e.target.checked
                        ),
                      })
                    }
                    label={amenity.search}
                  />
                ))}
              </Col>
            </Form.Group>

            <Form.Group as={Row}>
              <Form.Label column sm="4" className="dense">
                Featured Categories
              </Form.Label>
              <Col sm="8">
                {(categories || []).map((cat) => (
                  <Button
                    key={cat._id}
                    size="sm"
                    className="mr-2 mb-2"
                    variant={
                      _.includes(val.categories || [], cat._id)
                        ? "primary"
                        : "outline-primary"
                    }
                    onClick={() =>
                      setVal({ ...val, categories: switchCategory(cat._id) })
                    }
                  >
                    {cat.header}
                  </Button>
                ))}
              </Col>
            </Form.Group>
          </div>

          <hr />
          <div className="d-flex justify-content-end">
            <Button
              size="sm"
              variant="light"
              className="px-4 mr-2"
              onClick={() => {
                setVal({});
              }}
            >
              Clear
            </Button>
            <Button
              size="sm"
              varinat="primary"
              className="px-4"
              onClick={() => onChange(val)}
            >
              Save
            </Button>
          </div>
        </Popover.Content>
      </Popover>
    );
  }
);
