import React from "react";
import { Button, Spinner } from "react-bootstrap";
import classNames from "classnames";

import AnalyticsEvent from "../general/AnalyticsEvent";

export default ({
  updating,
  subtitle,
  onPrev,
  onNext,
  nextText,
  disableNext,
  mode,
  stepIndex,
}) => {
  return (
    <>
      <div className="modal-controls">
        {onPrev ? (
          <Button
            className="px-5"
            variant="light"
            disabled={updating}
            onClick={onPrev}
          >
            Back
          </Button>
        ) : null}
        <AnalyticsEvent
          onClick={onNext}
          pixelEvent={`${mode} Sign up stage ${stepIndex + 1}`}
        >
          {(onClick) => (
            <Button
              disabled={disableNext || updating}
              className={classNames("px-5", {
                "single-control": !onPrev,
              })}
              variant="dojobrand"
              onClick={onClick}
            >
              {updating && (
                <Spinner animation="border" size="sm" variant="light" />
              )}{" "}
              {nextText ? nextText : "Next"}
            </Button>
          )}
        </AnalyticsEvent>
      </div>
      <div className="smallish text-center modal-subtitle">{subtitle}</div>
    </>
  );
};
