import React from "react";

import { matchAmenities } from "../../data/amenities";
import AmenityItem from "./AmenityItem";

export default ({ group, selectedAmenities }) => {
  return matchAmenities(group, selectedAmenities).map((amenity) => (
    <AmenityItem amenity={amenity} />
  ));
};
