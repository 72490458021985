import React from "react";
import { Row, Col, Nav, Tab } from "react-bootstrap";
import ExternalApiRaw from "./ExternalApiRaw";

/*
  attomDetail(id: ID!): JSON
  attomDetailWithchools(id: ID!): JSON
  attomAssessmentHistory(id: ID!): JSON

  zillowPropertydetails(id: ID!): JSON

*/

export default ({ user, listing }) => {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            {listing.mls && (
              <Nav.Item>
                <Nav.Link eventKey="homejunction">HomeJunction</Nav.Link>
              </Nav.Item>
            )}
            <Nav.Item>
              <Nav.Link eventKey="homejunction-public">
                HomeJunction Public Data
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="attom-1">Attom Property Detail</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="attom-2">Attom Property Schools</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="attom-3">Attom Assessment History</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="zillow">Zillow Search</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="walkscore">Walkscore</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="nearby">Nearby Search</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="greatschools">Great Schools</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="attom-1">
              <ExternalApiRaw listing_id={listing._id} endpoint="attomDetail" />
            </Tab.Pane>
            <Tab.Pane eventKey="attom-2">
              <ExternalApiRaw
                listing_id={listing._id}
                endpoint="attomDetailWithchools"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="attom-3">
              <ExternalApiRaw
                listing_id={listing._id}
                endpoint="attomAssessmentHistory"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="zillow">
              <ExternalApiRaw
                listing_id={listing._id}
                endpoint="zillowPropertydetails"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="walkscore">
              <ExternalApiRaw listing_id={listing._id} endpoint="walkscore" />
            </Tab.Pane>
            <Tab.Pane eventKey="nearby">
              <ExternalApiRaw
                listing_id={listing._id}
                endpoint="nearbyPlaces"
              />
            </Tab.Pane>
            <Tab.Pane eventKey="greatschools">
              <ExternalApiRaw listing_id={listing._id} endpoint="getSchools" />
            </Tab.Pane>
            <Tab.Pane eventKey="homejunction-public">
              <ExternalApiRaw
                listing_id={listing._id}
                endpoint="propertyDetails"
              />
            </Tab.Pane>

            {listing.mls && (
              <Tab.Pane eventKey="homejunction">
                <ExternalApiRaw
                  listing_id={listing._id}
                  endpoint="getHomejunctionListing"
                />
              </Tab.Pane>
            )}
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};
