import React from "react";
import classNames from "classnames";

export default ({ items }) => (
  <div className="landing-comparison">
    {items.map((item, index) => (
      <div
        className={classNames(
          "landing-comparison-item",
          {
            "active": item.active
          }
        )}
        key={`comparison-item ${index}`}
      >
        {<h3>{item.title}</h3>}
        <ul>
          {item.bullets.map((bullet, index) => (
            <li key={`bullet ${index}`}>
              {bullet}
            </li>
          ))}
        </ul>
      </div>
    ))}
  </div>
);
