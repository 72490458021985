import React from "react";

const StepVisual = ({ icon, hideLine }) => (
  <div className="step-icon">
    {icon}
    {!hideLine && <div className="step-line" />}
  </div>
);

const Step = ({ title, icon, text }) => (
  <div className="step">
    <h4 className="step-title">{title}</h4>
    <StepVisual icon={icon} />
    <p className="step-text">
      {text}
    </p>
  </div>
);

export default ({ steps }) => (
  <div className="steps-container">
    {steps.map((step, index) => (
      <Step
        title={step.title}
        icon={step.icon}
        text={step.text}
        hideLine={index + 1 === steps.length}
        key={`step ${index}`}
      />
    ))}
  </div>
);