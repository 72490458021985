import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Main from "../../../../components/landing/Main";
import Section from "../../../../components/landing/Section";
import { Photo1, Photo2, Photo3 } from "./img";

import "./About.scss";

export default () => {
  return (
    <Main>
      <Section navbar navbarColor="light" id="about-head" centered>
        <h2>
          The Story <span>of&nbsp;Dojohome</span>
        </h2>
        <Button variant="dojobrand" as={Link} to="/explore">
          Discover your Home
        </Button>
      </Section>
      <Section id="creation" centered>
        <p>
          Dojohome was created by a bunch of technology and data geeks who all had one thing in common: painful housing experiences.
        </p>
        <p>
          The questions that came up while sharing those stories propelled us to start Dojohome, and they’re the same questions that continue to drive us <span>every day</span>: “Why is residential real estate so broken?” and “If we could create a housing platform from scratch that brings simplicity and transparency to all who touch it, every step of the way, what would that look like?”
        </p>
        <p>
          Dojohome is that platform. It connects <span>buyers</span> and{" "}
          <span>sellers</span> who know that a better way forward is possible.
          So click around, take a look at what we’ve been working on, and see
          how we are radically changing residential real estate.
        </p>
      </Section>
      <Section id="commitment" centered>
        <h2>
          Our <span>Commitment</span> to&nbsp;You
        </h2>

        <div className="tiles">
          <div className="tile-row">
            <div className="tile tile-text">
              <h3>Dare to Dream Big</h3>
              <p>
                We believe that everyone deserves a dream home of their own -
                and that no one should feel like they have to settle for less
                because of outdated, opaque home-buying and home-selling
                experiences. Dojo is obsessed with making this experience
                enjoyable and fair for all.
              </p>
            </div>
            <div className="tile tile-img">
              <img src={Photo1} alt="Dream big" />
            </div>
          </div>

          <div className="tile-row">
            <div className="tile tile-img">
              <img src={Photo2} alt="Demand More" />
            </div>
            <div className="tile tile-text">
              <h3>Demand More</h3>
              <p>
                Our homes are where our lives are shaped - nowadays more than
                ever before - and they shouldn't be reduced to a process defined
                by unpredictable costs and unreliable outcomes. Nobody should
                feel like they have no control when it comes to their home,
                whether you're buying, maintaining, or selling. Dojo seeks to
                provide support and seamless transparency to all parties, every
                step of the way.
              </p>
            </div>
          </div>

          <div className="tile-row">
            <div className="tile tile-text">
              <h3>Discover a New Way</h3>
              <p>
                Dojo’s Gradual Home Ownership platform transforms
                home-buying/home-selling into an experience that helps everyone
                achieve and maintain the home of their dreams. From finding
                appealing, up-to-date listings to closing help, and any and all
                maintenance in between, our platform simplifies things by
                putting everything in one place. Giving all parties the
                knowledge and security they deserve, with just the click of a
                button.
              </p>
            </div>
            <div className="tile tile-img">
              <img src={Photo3} alt="Discover a New Way" />
            </div>
          </div>
        </div>
      </Section>
      <Section id="about-cta" controls="dark" centered>
        <h1>
          You <span>belong</span> here
        </h1>
        <div className="inline-buttons">
          <Button href="/" variant="dojobrand">
            I am a buyer
          </Button>
          <Button as={Link} variant="outline-secondary" to="/seller">
            I am a seller
          </Button>
        </div>
      </Section>
    </Main>
  );
};
