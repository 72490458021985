import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const QUERY = gql `
  query GetStripeLink($returnLink: String) {
    stripeConnectLink(returnLink: $returnLink)
  }
`;

export default ((returnLink) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY, {
    variables: {returnLink}
  });
  console.log("stripeConnectLink:", data)
  return [data && data.stripeConnectLink, loading, error];
});
