import React, { useState } from "react";
import { Popover, Form, Row, Col, InputGroup } from "react-bootstrap";
import _ from "lodash";
import { Search } from "../icons";

export default React.forwardRef(
  (
    { options = [], searchable, value, onChange, maxItems = 19, ...props },
    ref
  ) => {
    const [q, setQ] = useState(undefined);

    const switchValue = (val, checked) => {
      const other = _.without(value, val);
      onChange(checked ? [val, ...other] : other);
    };

    const filteredOptions = _.chain(
      q
        ? _.filter(
            options,
            ({ label, value: val }) =>
              label.toLowerCase().includes(q) || _.includes(value, val)
          )
        : options
    ).value();

    return (
      <Popover id={`$check-filter`} ref={ref} {...props}>
        <Popover.Content>
          {searchable && (
            <Row>
              <Col xs={12}>
                <Form.Group>
                  <InputGroup size="sm">
                    <InputGroup.Prepend>
                      <InputGroup.Text>
                        <Search height={16} />
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      value={q}
                      size="sm"
                      onChange={(e) =>
                        setQ(e.target.value.toLowerCase() || undefined)
                      }
                    ></Form.Control>
                  </InputGroup>
                </Form.Group>
              </Col>
            </Row>
          )}
          <Row className="p-2" style={{ maxHeight: 420, overflowY: "scroll" }}>
            {filteredOptions.map((op) => (
              <Col xs={6}>
                <Form.Check
                  className="dense"
                  checked={_.includes(value, op.value)}
                  onChange={(e) => switchValue(op.value, e.target.checked)}
                  label={op.label}
                />
              </Col>
            ))}
            {options.length > maxItems && (
              <Col xs={6}>
                <small>and {options.length - maxItems} more...</small>
              </Col>
            )}
          </Row>
        </Popover.Content>
      </Popover>
    );
  }
);
