import React, { useState, useEffect } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import _ from "lodash";
import DatePicker from "react-datepicker";
import Geosuggest from "react-geosuggest";
import currencyFormatter from "currency-formatter";
import { LightgalleryItem } from "react-lightgallery";
import moment from "moment";
import { Row, Col, Card, Form, Button, Alert } from "react-bootstrap";
import RangeSlider from "react-bootstrap-range-slider";

import AppMain from "../AppMain";
import FileUploader from "../../components/file-uploader/FileUploader";
import AmenitiesSelection from "../../components/property/AmenitiesSelection";
import InfoTooltip from "../../components/property/InfoTooltip";
import PhoneValidationPopup from "../../components/user/PhoneValidationPopup";
import UserSelector from "../../components/user/UserSelector";
import { makeImageUrl, getAddressParts } from "../../tools";
import usePropertyInfo from "../../components/hooks/queries/usePropertyInfo";
import useMinDownPayment from "../../components/hooks/mutations/useMinDownPayment";
import useListingSettings from "../../components/hooks/mutations/useListingSettings";
import usePriceEstimate from "../../components/hooks/queries/usePriceEstimate";
import useCurrency from "../../components/hooks/helpers/useCurrency";
import { useTranslation } from "react-i18next";

import "react-datepicker/dist/react-datepicker.css";
import "react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css";
import "lightgallery.js/dist/css/lightgallery.css";
import BrokeredProperty from "../../components/property/BrokeredProperty";
import ListingEditControl from "../../components/controls/ListingEditControl";
import CategoriesSelection from "../../components/property/CategoriesSelection";

export default ({ user }) => {
  const { t } = useTranslation();
  const match = useRouteMatch();
  const currency = useCurrency();
  const listingId = match.params.id;
  const [showInternalData, setShowInternalData] = useState(false);
  const [showPhoneValidator, setShowPhoneValidator] = useState(false);
  const [minDownPaymentInfo] = useMinDownPayment();
  const [listingSettings] = useListingSettings();

  const minDownPayment = (branded) =>
    branded ? minDownPaymentInfo.organic : minDownPaymentInfo.brokered;

  const isAdmin = user.mode === "admin";
  const [
    property,
    setProperty,
    addProperty,
    saving,
    propertyError,
  ] = usePropertyInfo(listingId);

  const [zestimate, getPriceEstimate] = usePriceEstimate(property);

  useEffect(() => {
    if (property.address) {
      getPriceEstimate();
    }
  }, [getPriceEstimate, property.address]);

  const tryAddProperty = () => {
    if (isAdmin || user.validated_phone) {
      addProperty();
    } else {
      setShowPhoneValidator(true);
    }
  };

  const propertyErrors =
    !saving && propertyError
      ? propertyError.graphQLErrors[0].extensions.exception.validationErrors
      : {};

  const zipCodeOk = _.includes(listingSettings.zipCodes || [], property.zip);
  const switchAmenity = (amenity, add) => {
    if (add) {
      setProperty({ amenities: [...(property.amenities || []), "garage"] });
    } else {
      setProperty({ amenities: _.without(property.amenities, "garage") });
    }
  };

  const zillowRent =
    zestimate && zestimate.rentzestimate && zestimate.rentzestimate.amount;

  return (
    <AppMain>
      <PhoneValidationPopup
        user={user}
        show={showPhoneValidator}
        onHide={() => setShowPhoneValidator(false)}
        onValidated={(r) => r && addProperty()}
      />

      <Form disabled={saving}>
        <Row>
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="mt-3">
            <h1 className="mb-5">{t("editProperty.addProperty")}</h1>

            {isAdmin && (
              <div className="mb-3">
                <Form.Label>{t("editProperty.listPropertyAs")}</Form.Label>
                <UserSelector
                  me={user}
                  user={property.owner}
                  setUser={(owner) =>
                    setProperty({ ...property, owner: { _id: owner } })
                  }
                />
              </div>
            )}

            {isAdmin && (
              <BrokeredProperty
                property={property}
                setProperty={setProperty}
                onUpdatePrice={(branded) => {
                  const equity_deposit =
                    parseFloat(property.price || 0) *
                    (minDownPayment(branded) / 100);
                  setProperty({
                    branded,
                    equity_deposit,
                  });
                }}
              />
            )}

            <Row>
              <Col lg="8">
                <ListingEditControl
                  field="propertyType"
                  label={t("editProperty.propertyType")}
                  as="select"
                  property={property}
                  transform={(val) => val}
                  onChange={(value) => setProperty({ propertyType: value })}
                  propertyErrors={propertyErrors}
                >
                  <option value="house">{t("editProperty.house")}</option>
                  <option value="condo">{t("editProperty.condo")}</option>
                  <option value="townhouse">
                    {t("editProperty.townhouse")}
                  </option>
                </ListingEditControl>
              </Col>
            </Row>

            <Form.Group>
              <Form.Label>{t("editProperty.address")}</Form.Label>

              {property._id ? (
                <Form.Control
                  value={property.address}
                  isInvalid={!!propertyErrors.address}
                  readOnly
                />
              ) : (
                <Geosuggest
                  type="text"
                  country="us"
                  types={["geocode"]}
                  value={property.address}
                  getSuggestLabel={(suggest) =>
                    suggest.description.replace(", USA", "")
                  }
                  placeholder={t("editProperty.enterAddress")}
                  onSuggestSelect={(e) => {
                    if (e) {
                      const location = getAddressParts(e.gmaps);
                      setProperty({
                        address:
                          location.street_number && location.route
                            ? `${location.street_number} ${location.route}`
                            : "",
                        city: location.locality,
                        state: location.administrative_area_level_1,
                        zip: location.postal_code,
                        lat: e.location.lat,
                        lng: e.location.lng,
                      });
                    } else {
                      setProperty({
                        address: "",
                        lat: null,
                        lng: null,
                      });
                    }
                  }}
                  className={!!propertyErrors.address ? "is-invalid" : ""}
                ></Geosuggest>
              )}

              <Form.Control
                type="hidden"
                isInvalid={!!propertyErrors.address}
              />

              {property.zip && !zipCodeOk && (
                <Alert variant="danger" className="mt-2">
                  {t("editProperty.invalidCity")}
                </Alert>
              )}

              <Form.Control.Feedback type="invalid">
                {propertyErrors.address}
              </Form.Control.Feedback>
            </Form.Group>

            <Form.Group>
              <Form.Label>{t("editProperty.neighbourhood")}</Form.Label>
              <Form.Control
                disabled
                readOnly
                value={property.neighbourhood || "N/A"}
              />
            </Form.Group>

            <Card className="my-4">
              <Card.Body className="pt-3 pb-1">
                <Row>
                  <Col xs={12} lg={4}>
                    <ListingEditControl
                      field="rent"
                      label={t("editProperty.monthlyRent")}
                      tooltip={t("editProperty.monthlyRentTooltip")}
                      type="number"
                      prepend="$"
                      min="0"
                      step="100"
                      className="currency-input"
                      property={property}
                      onChange={(value) => setProperty({ rent: value })}
                      propertyErrors={propertyErrors}
                    />
                    {zillowRent && (
                      <p className="small">
                        We recommend a base monthly rent of{" "}
                        {currency(zillowRent)} for this property.
                      </p>
                    )}
                  </Col>

                  <Col xs={12} lg={4}>
                    <ListingEditControl
                      field="price"
                      label={t("editProperty.fairPrice")}
                      tooltip={t("editProperty.fairPriceTooltip")}
                      type="number"
                      min="0"
                      step="1000"
                      className="currency-input"
                      prepend="$"
                      property={property}
                      onChange={(price) => {
                        const targetEquity = property.equity_deposit
                          ? property.equity_deposit / property.price
                          : minDownPayment(property.branded) / 100;
                        setProperty({
                          price,
                          equity_deposit:
                            Math.round(price * targetEquity * 100) / 100,
                        });
                      }}
                      propertyErrors={propertyErrors}
                    />
                  </Col>

                  <Col xs={12} lg={4}>
                    <Form.Group>
                      <Form.Label className="mb-0">
                        <p
                          className="mt-1 mb-0 title"
                          style={{ textTransform: "uppercase" }}
                        >
                          <span>{t("editProperty.equityDeposit")}</span>{" "}
                          <InfoTooltip
                            message={
                              <p className="my-1">
                                {t("editProperty.equityDepositTooltip")}
                              </p>
                            }
                          />
                        </p>
                      </Form.Label>

                      <RangeSlider
                        disabled={!property.price || property.price < 1000}
                        min={minDownPayment(property.branded)}
                        max={10}
                        size="sm"
                        step={0.25}
                        tooltip="off"
                        value={
                          Math.floor(
                            (property.equity_deposit / property.price) * 10000
                          ) / 100 || 0
                        }
                        onChange={(e) => {
                          setProperty({
                            equity_deposit: Math.floor(
                              (parseFloat(e.target.value) / 100) *
                                property.price
                            ),
                          });
                        }}
                      ></RangeSlider>
                      {property.price >= 1000 && (
                        <div className="d-flex">
                          <h6 className="flex-grow-1">
                            {currencyFormatter.format(property.equity_deposit, {
                              code: "USD",
                              precision: 0,
                            })}
                          </h6>
                          <h6>
                            {Math.floor(
                              (property.equity_deposit / property.price) * 10000
                            ) / 100}
                            %
                          </h6>
                        </div>
                      )}
                    </Form.Group>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <Row>
              <Col xs={6} md={3}>
                <ListingEditControl
                  field="beds"
                  label={t("editProperty.bedrooms")}
                  type="number"
                  as="select"
                  placeholder="Select..."
                  property={property}
                  onChange={(value) => setProperty({ beds: value })}
                  propertyErrors={propertyErrors}
                >
                  <option value={undefined} style={{ display: "none" }}>
                    {t("editProperty.enterValue")}
                  </option>
                  {_.range(1, 7).map((b) => (
                    <option key={b}>{b}</option>
                  ))}
                </ListingEditControl>
              </Col>

              <Col xs={6} md={3}>
                <ListingEditControl
                  field="baths"
                  label={t("editProperty.bathrooms")}
                  type="number"
                  as="select"
                  placeholder="Select..."
                  property={property}
                  onChange={(value) => setProperty({ baths: value })}
                  propertyErrors={propertyErrors}
                >
                  <option value={undefined} style={{ display: "none" }}>
                    {t("editProperty.enterValue")}
                  </option>
                  {_.range(1, 6.5, 0.5).map((b) => (
                    <option key={b}>{b}</option>
                  ))}
                </ListingEditControl>
              </Col>

              <Col xs={6} md={3}>
                <ListingEditControl
                  field="sqft"
                  label={t("editProperty.sqft")}
                  type="number"
                  step="50"
                  min="0"
                  property={property}
                  onChange={(value) => setProperty({ sqft: value })}
                  propertyErrors={propertyErrors}
                />
              </Col>

              <Col xs={6} md={3}>
                <ListingEditControl
                  field="year"
                  label={t("editProperty.year")}
                  type="number"
                  step="1"
                  min="1800"
                  max="2020"
                  property={property}
                  onChange={(value) => setProperty({ year: value })}
                  propertyErrors={propertyErrors}
                />
              </Col>
            </Row>
          </Col>

          {isAdmin && (
            <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
              <h4>{t("editProperty.featuredCategories")}</h4>

              <CategoriesSelection
                value={property.categories}
                onChange={(categories) => setProperty({ categories })}
              />
            </Col>
          )}

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>
              <span>{t("editProperty.photos")}</span>{" "}
              <InfoTooltip
                message={
                  <React.Fragment>
                    <p className="my-1">
                      Show Dojohome buyers what your property looks like.
                      Include photos of all the living spaces in your property
                      (e.g. bedrooms, bathrooms, kitchen, patio).
                    </p>
                    <p className="my-1">
                      The first photo will be used as the cover for your listing
                      - we recommend using an outdoor photo of the front of your
                      property.
                    </p>
                  </React.Fragment>
                }
              />
            </h4>

            <FileUploader
              setUploadedFiles={(files) => {
                setProperty({ images: files });
              }}
              uploadedFiles={property.images || []}
              multiple={true}
            />

            <Form.Control type="hidden" isInvalid={!!propertyErrors.images} />

            <Form.Control.Feedback type="invalid">
              {propertyErrors.images}
            </Form.Control.Feedback>

            {property &&
              property.images &&
              property.images.map((img) => (
                <LightgalleryItem
                  key={img._id}
                  group="property-image"
                  src={makeImageUrl(img)}
                ></LightgalleryItem>
              ))}
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>
              <span>{t("editProperty.description")}</span>{" "}
              <InfoTooltip
                message={
                  <p className="my-1">{t("editProperty.descriptionTooltip")}</p>
                }
              />
            </h4>

            <Form.Group>
              <Form.Control
                as="textarea"
                rows="3"
                value={property.description}
                placeholder={t("editProperty.shortDescription")}
                onChange={(e) => setProperty({ description: e.target.value })}
                isInvalid={!!propertyErrors.description}
              />

              <Form.Control.Feedback type="invalid">
                {propertyErrors.description}
              </Form.Control.Feedback>
            </Form.Group>
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>
              <span>Pets</span>{" "}
              <InfoTooltip
                message={<p className="my-1">Is your property pet-friendly?</p>}
              />
            </h4>

            <Row>
              <Col lg={8}>
                <ListingEditControl
                  field="pet_allowance"
                  as="select"
                  property={property}
                  onChange={(value) => setProperty({ pet_allowance: value })}
                  propertyErrors={propertyErrors}
                  transform={(val) => val}
                >
                  <option value={undefined} style={{ display: "none" }}>
                    {t("editProperty.select")}
                  </option>
                  <option value="no-pets">{t("editProperty.noPets")}</option>
                  <option value="cats">{t("editProperty.catsOnly")}</option>
                  <option value="dogs">{t("editProperty.dogsOnly")}</option>
                  <option value="any">{t("editProperty.catsAndDogs")}</option>
                </ListingEditControl>
              </Col>
            </Row>
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>{t("editProperty.parking")}</h4>
            <Row>
              <Col lg={8}>
                <ListingEditControl
                  field="parking"
                  as="select"
                  placeholder={t("editProperty.select")}
                  property={property}
                  label={t("editProperty.parkingSpots")}
                  onChange={(value) => setProperty({ parking: value })}
                  propertyErrors={propertyErrors}
                >
                  {_.range(0, 11).map((b) => (
                    <option key={b}>{b}</option>
                  ))}
                </ListingEditControl>
              </Col>

              <Col lg={8}>
                <Form.Label>{t("editProperty.hasGarage")}</Form.Label>
                <Form.Check
                  id="garage-switch"
                  checked={_.includes(property.amenities, "garage")}
                  onChange={(e) => switchAmenity("garage", e.target.checked)}
                  type="switch"
                  label={
                    _.includes(property.amenities, "garage") ? "Yes" : "No"
                  }
                />
              </Col>
            </Row>
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>{t("editProperty.accessibility")}</h4>

            <AmenitiesSelection
              group="accessibility"
              selectedAmenities={property.amenities || []}
              onChange={(amenities) => setProperty({ amenities })}
            />
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>{t("editProperty.propertyDetails")}</h4>

            <AmenitiesSelection
              group="details"
              selectedAmenities={property.amenities || []}
              onChange={(amenities) => setProperty({ amenities })}
            />
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            <h4>
              <span>{t("editProperty.moveInDate")}</span>{" "}
              <InfoTooltip
                message={
                  <p className="my-1">{t("editProperty.moveInDateTooltip")}</p>
                }
              />
            </h4>

            <Form.Group>
              <Row>
                <Col xs={8}>
                  <DatePicker
                    selected={moment(property.available_from).toDate()}
                    minDate={new Date()}
                    onChange={(date) =>
                      setProperty({
                        available_from: date,
                      })
                    }
                    isInvalid={!!propertyErrors.available_from}
                    placeholder="Choose date"
                    className="form-control"
                  />

                  <Form.Control.Feedback type="invalid">
                    {propertyErrors.available_from}
                  </Form.Control.Feedback>
                </Col>
              </Row>
            </Form.Group>
          </Col>

          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4">
            {propertyError ? (
              <Alert variant="danger">
                {propertyError.graphQLErrors[0].message}
              </Alert>
            ) : null}

            {property.zip && !zipCodeOk && (
              <Alert variant="danger" className="mt-2">
                {t("editProperty.invalidCity")}
              </Alert>
            )}
          </Col>
          <Col xs={12} lg={{ span: 8, offset: 2 }} className="my-4 d-flex">
            <div className="flex-grow-1"></div>
            {property._id && (
              <Button
                className="mx-1"
                as={Link}
                to={`/property/${property._id}`}
                variant="outline-primary"
              >
                {t("editProperty.viewProperty")}
              </Button>
            )}

            <Button
              className="mx-1 px-5"
              variant="outline-primary text-uppercase"
              disabled={!property._id && !zipCodeOk}
              onClick={tryAddProperty}
            >
              {!isAdmin && !user.validated_phone
                ? t("editProperty.verifyPhone")
                : property._id
                ? t("editProperty.updateProperty")
                : t("editProperty.addProperty")}
            </Button>
          </Col>

          {user.mode === "admin" && (
            <Col xs={12}>
              <Button
                onClick={(e) => setShowInternalData(!showInternalData)}
                size="sm"
              >
                Show Internal Data
              </Button>
              {showInternalData && (
                <pre>{JSON.stringify(property, null, 2)}</pre>
              )}
            </Col>
          )}
        </Row>
      </Form>
    </AppMain>
  );
};
