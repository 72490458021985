import React from "react";
import { Card } from "react-bootstrap";

import ExternalApiInfo from "../../../components/property/ExternalApiInfo";

export default ({ user, listing }) => {
  return (
    <Card className="mt-4">
      <Card.Header>
        <h5>External API Info (Admin Only)</h5>
      </Card.Header>
      <Card.Body>
        <ExternalApiInfo user={user} listing={listing} />
      </Card.Body>
    </Card>
  );
};
