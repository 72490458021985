import { useState, useEffect } from "react";
import gql from "graphql-tag";
import { useLazyQuery, useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const GET_LISTING = gql`
  fragment ImageFragment on Image {
    _id
    fileid
    extension
    mimetype
    filename
    url
  }

  query GetExistingListing($listing_id: String!) {
    property(listing_id: $listing_id) {
      _id
      propertyType

      categories {
        _id
        header
        thumbnail
      }

      price
      rent
      equity_deposit
      owner {_id}

      address
      city
      state
      zip
      neighbourhood

      beds
      baths
      sqft
      year
      parking

      branded
      boosts

      location {
        coordinates
      }

      description
      pet_allowance
      amenities

      available_from

      images {
        ...ImageFragment
        caption
      }

      agent {
        _id
        name
        brokerage {
          _id
          title
        }
      }
      mls

      under_contract
      published
      archived
      verified

      updated_at
      created_at

      favorited
    }
  }
`;

const SAVE_PROPERTY = gql`
  mutation AddProperty($property: PropertyInput) {
    addProperty(property: $property) {
      _id
    }
}
`;

const convertToPropertyInput = property => {
  return _.chain(property)
  .transform((obj, value, key) => {
    switch (key) {
      case "images":
      case "categories":
        obj[key] = _.map(value || [], "_id");
        break;
      case "location":
        obj.lng = value.coordinates[0];
        obj.lat = value.coordinates[1];
        break;
      case "owner":
      case "agent":
        obj[key] = value && value._id;
        break;
      default:
        return obj;}

  }, { ...property })
  .omit(["neighbourhood", "location", "under_contract", "published", "archived", "verified", "updated_at", "created_at", "favorited", "__typename"])
  .value();
};

export default ((listingId, onCompleted) => {
  const [property, setProperty] = useState({
    propertyType: "house",
    parking: 0,
    pet_allowance: "any",
    branded: true,
    available_from: new Date()
  });

  useEffect(() => {
    if (listingId === 'new' && !property.available_from) {
      console.log("resetting property");
      setProperty({
        branded: true,
        available_from: new Date() });

    }
  }, [listingId, property.available_from]);

  const { data, loading, error } = useQuery(GET_LISTING, {
    variables: {
      listing_id: listingId },

    fetchPolicy: "network-only",
    onCompleted: data => {
      setProperty(data.property);
    },
    onError: () => {
      setProperty({
        branded: true,
        available_from: new Date() });


    } });


  const propertyInput = convertToPropertyInput(property);
  const [addProperty, { data: propertyData, loading: saving, error: propertyError }] = useMutation(SAVE_PROPERTY, {
    variables: {
      property: propertyInput },

    refetchQueries: () => ["GetExistingListing", "MyProperties", "AllProperties"],
    onCompleted: result => {
      window.location = `/property/${result.addProperty._id}`;
    },
    onError: err => {
      console.log(err);
    } });


  return [property, data => {
    console.log(property);
    setProperty({ ...property, ...data });
  }, addProperty, loading || saving, propertyError];
});