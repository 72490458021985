import React from "react";
import { useTranslation } from "react-i18next";

import PriceHistoryChart from "../../../components/general/PriceHistoryChart";

export default ({ listing }) => {
  const { t } = useTranslation();
  return (
    <div>
      <h6>{t("property.propertyValue")}</h6>
      {listing.attomExtendedInfo && <PriceHistoryChart listing={listing} />}
    </div>
  );
};
