import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';
import { MixpanelProvider } from 'react-mixpanel-browser';
import { ApolloClient } from 'apollo-client';
import { split } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { BrowserRouter } from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';

import './index.scss';
import './i18n/';
import App from './app/App';

import './axios';

import * as serviceWorker from './serviceWorker';

import introspectionQueryResultData from './fragmentTypes.json';
import ScrollToTop from './components/general/ScrollToTop';

const fbPixelOptions = {
  autoConfig: true, // set pixel's autoConfig
  debug: false, // enable logs
};

const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
const WS_BASE_URL = process.env.REACT_APP_WS_BASE_URL;

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData });

const cache = new InMemoryCache({
  fragmentMatcher, typePolicies: {
    Property: {
      fields: {
        owner: {
          merge(existing, incoming) {
            console.log("====>", existing, incoming)
            return {
              ...existing,
              ...incoming
            };
          },
        },
      },
    },
  }
});


const httpLink = createUploadLink({
  uri: `${API_BASE_URL}/graphql`,
  credentials: 'include',
  options: {
    reconnect: true } });



const wsLink = new WebSocketLink({
  uri: `${WS_BASE_URL}/pubsub`,
  options: {
    reconnect: true } });



const link = split(
// split based on operation type
({
  query }) =>
{
  const definition = getMainDefinition(query);
  return (
    definition.kind === 'OperationDefinition' &&
    definition.operation === 'subscription');

},
wsLink,
httpLink);


const client = new ApolloClient({
  link: link,
  cache });

ReactPixel.init(process.env.REACT_APP_FB_PIXEL, {}, fbPixelOptions);

ReactDOM.render(
  <ApolloProvider client={client}>
    <MixpanelProvider>
      <BrowserRouter>
        <ScrollToTop />
        <App />
      </BrowserRouter>
    </MixpanelProvider>
  </ApolloProvider>,
document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();