import _ from "lodash";

const PlaceTypes = {
  emergency: [
    'police', 'fire_station', 'hospital'

  ],
  pharmacy: [
    'drugstore',
    'pharmacy',

  ],
  store: [
    'supermarket',
    'convenience_store',
    'grocery_or_supermarket',
    'supermarket',
  ],
  food: [
    'restaurant', 'bar', 'cafe'
  ],
  // commuting: [
  //   'bus_station',
  //   'subway_station',
  //   'train_station',
  //   'transit_station',

  // ],
};

export function getCategory (types) {
  return _.findKey(PlaceTypes, (group) => _.intersection(group, types).length > 0);
}

export default PlaceTypes;
