import _ from 'lodash';

const resetQueries = (toClear) =>
store => {
  const cacheData = store.data.data;
  console.log(cacheData);
  Object.keys(cacheData).
  filter(query => {
    console.log(query);
    return _.some(toClear, name => query.indexOf(`ROOT_QUERY.${name}`) === 0);
  }).
  forEach(query => {
    console.log("deleting: ", query);
    store.data.delete(query);
  });
  console.log(cacheData);
};


export {
resetQueries };