import {
  useState
} from "react";
import gql from "graphql-tag";
import {
  useQuery,
  useMutation
} from "@apollo/react-hooks";
import _ from "lodash";

const GET_SETTINGS = gql `
  query GetAnonymousSettings {
    settings {
      anonymousData {
        enabled
        openAfter
        canCloseAfter
      }
    }
  }
`;

const SET_SETTINGS = gql `
  mutation SetAnonymousSettings($anonymousData: AnonymousDataInput!) {
    updateSettings(settings: {
      anonymousData: $anonymousData
    }) {
      message
    }
  }
`;

export default (onCompleted) => {
  const [anonymousData, setAnonymousData] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const {
    loading,
    error,
    refetch
  } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setIsChanged(false);
      setAnonymousData(_.omit(data.settings.anonymousData, ['__typename']));
    }
  });

  const [saveAnonymousData] = useMutation(SET_SETTINGS, {
    variables: {
      anonymousData
    },
    refetchQueries: () => ['GetAnonymousSettings'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [anonymousData, (update) => {
    setAnonymousData({
      ...anonymousData,
      ...update
    });
    setIsChanged(true)
  }, refetch, saveAnonymousData, isChanged, loading, error];
};