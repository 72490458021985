import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";
import { useMixpanel } from "react-mixpanel-browser";

const CURRENT_USER_QUERY = gql`
  fragment ImageFragment on Image {
    _id
    fileid
    extension
    mimetype
    filename
    url
  }

  query CurrentUser($id: ID) {
    me {
      _id
      email
      validated_email
      firstname
      lastname
      roles
      banned
      mode
      modes
      verified
      state

      phone
      validated_phone
      paymentMethod {
        id
        name
        mask
        type
      }

      profile {
        photo {
          ...ImageFragment
        }
        description

        languages

        link_facebook
        link_twitter
        link_instagram
      }

      identity {
        drivers_license {
          ...ImageFragment
        }
        passport {
          ...ImageFragment
        }
        id_card {
          ...ImageFragment
        }
      }

      buyingPower {
        buyerType
        moveIn
        employment
        dreamHome
        savings
        annualIncome
        creditScore

        completed
        submittedAt
        submittedFrom
      }
    }

    anonymous(id: $id) {
      _id
      email
    }
  }

`;

export default (() => {
  const mixpanel = useMixpanel();
  const { loading, data, error } =
    useQuery(CURRENT_USER_QUERY, {
      variables: {
        id: localStorage.getItem("dojo_id")
      },
      onCompleted: (data) => {
        if (data && !data.me && data.anonymous._id) {
          localStorage.setItem("dojo_id", data.anonymous._id)
          mixpanel.identify(data.anonymous._id);
        }
        if (data && data.me) {
          mixpanel.alias(data.me._id);
          mixpanel.people.set({
            "$email": data.me.email,
            "role": data.me.role,
          })
        }

    }
  });


  return [data && data.me, loading, error];
});