import React, { useState } from "react";
import classNames from "classnames";
import ReactYoutube from "react-youtube";

import { ArrowDown } from "./icons";
import Aside from "./Aside";
import Navbar from "./Navbar";

const Scroll = ({ index, sectionsIDs }) => (
  <a className="landing-scroll" href={`#${sectionsIDs[index + 1]}`}>
    {index === 0 && <span>Scroll</span>}
    <ArrowDown />
  </a>
);

const Section = ({
  total,
  index,
  aside,
  centered,
  controls = "light",
  navbar,
  navbarColor,
  backgroundVideo,
  hideScroll = true,
  id,
  className,
  sectionsIDs,
  children,
  growable,
}) => {
  const videoParams = {
    playerVars: {
      start: 0,
      controls: 0,
      showinfo: 0,
      modestbranding: 1,
      rel: 0,
      autoplay: 1,
      autohide: 1,
      disablekb: 1,
      loop: 1,
      mute: 1,
      playlist: backgroundVideo,
      hd: 1,
    },
  };
  const [videoReady, setVideoReady] = useState(false);

  return (
    <section
      className={classNames(
        "landing-section",
        {
          "dark-controls": controls === "dark",
          growable: growable,
        },
        className
      )}
      id={id}
    >
      {navbar && <Navbar variant={navbarColor} />}
      {aside && <Aside>{aside}</Aside>}
      {backgroundVideo && (
        <>
          <div
            className={classNames("video-overlay", {
              "hide": videoReady,
            })}
          />
          <ReactYoutube
            containerClassName="video-background"
            videoId={backgroundVideo}
            opts={videoParams}
            onPlay={() => setVideoReady(true)}
          />
        </>
      )}
      <div className={classNames("landing-container", { aside })}>
        <div
          className={classNames("landing-section-content", {
            aside: aside,
            centered: centered,
          })}
        >
          {children}
        </div>
      </div>
      {!hideScroll && index !== total - 1 && (
        <Scroll index={index} sectionsIDs={sectionsIDs} />
      )}
    </section>
  );
};

export default Section;
