import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Nav } from "react-bootstrap";
import _ from "lodash";

export default ({ simpleLinks }) => {
  const links = [
    {
      label: "Buyers",
      slug: "",
      alternateSlug: "buyer",
    },
    {
      label: "Sellers",
      slug: "seller",
    },
    {
      label: "FAQ",
      slug: "faq",
    },
    {
      label: "Our story",
      slug: "about-us",
    },
  ];

  const location = useLocation();
  const path = location.pathname.substring(1).split("/")[0];

  const linksFiltered = ["", "buyer", "seller"].includes(path)
    ? _.filter(
        links,
        (link) => link.slug !== path && link.alternateSlug !== path
      )
    : links;

  return linksFiltered.map((link, index) =>
    simpleLinks ? (
      <Link to={`/${link.slug}`} key={`simple-link ${index}`}>
        {link.label}
      </Link>
    ) : (
      <Nav.Link as={Link} to={`/${link.slug}`} key={`link ${index}`}>
        {link.label}
      </Nav.Link>
    )
  );
};
