import React from "react";
import { Card, Row } from "react-bootstrap";
import nl2br from "react-nl2br";
import moment from "moment";

import AmenitiesList from "../../../components/property/AmenitiesList";
import AmenityItem from "../../../components/property/AmenityItem";
import InfoTooltip from "../../../components/property/InfoTooltip";
import { Parking, NoPets, Time } from "../../../components/general/Icons";

export default ({ listing }) => {
  return (
    <Card className="ListingSummaryFeature no-shadow text-left">
      <Card.Body className="">
        <div className="d-flex">
          <h5 className="flex-grow-1 mt-1 mb-3">Description</h5>
          <div>
            <InfoTooltip
              dark
              placement="left"
              message={`${moment
                .duration(moment().diff(moment(listing.created_at)))
                .asDays()
                .toFixed()} days on DojoHome`}
            >
              <Time height={16} />
            </InfoTooltip>
          </div>
        </div>

        <div className="normal">{nl2br(listing.description)}</div>

        <Row className="mt-4">
          <AmenityItem
            amenity={{
              value: "parking",
              label: `${listing.parking || 0} Parking Spaces`,
              icon: Parking,
            }}
          />

          <AmenitiesList
            group="accessibility"
            selectedAmenities={listing.amenities || []}
          />

          <AmenitiesList
            group="details"
            selectedAmenities={listing.amenities || []}
          />

          {listing.pet_allowance === "no-pets" && (
            <AmenityItem
              amenity={{
                value: "pets",
                label: "No Pets",
                icon: NoPets,
              }}
            />
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};
