import React, { useState, useEffect } from "react";
import { Form, Button } from "react-bootstrap";
import ReactCodeInput from "react-code-input";
import _ from "lodash";
import classNames from "classnames";
import phoneFormatter from "phone-number-formats";

import BackNext from "./BackNext";
import useValidatePhone from "../hooks/mutations/useValidatePhone";

export default ({ data, onNext, onPrev, stepIndex }) => {
  const [code, setCode] = useState("");

  const phone = data && data.phone;

  const {
    resendCode,
    validatePhone,
    validateError,
    resendData,
    resendError,
  } = useValidatePhone(
    `${phone}`,
    code,
    data.user_id,
    (validated) => validated && onNext()
  );

  const disableNext = !code;

  const [codeResent, setCodeResent] = useState(false);

  useEffect(() => {
    if (code.length === 6) {
      _.debounce(validatePhone, 1000)();
    }
  }, [code, validatePhone]);

  const phoneFormatted = phone
    ? new phoneFormatter(phone).format({ type: "international" }).toString()
    : "";

  useEffect(() => {
    const handleKeyDown = (event) =>
      !disableNext && event.keyCode === 13 && validatePhone();

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [disableNext, validatePhone]);

  return (
    <>
      <Form className="form-phone">
        <h2>
          Confirm your <br />
          phone number
        </h2>
        <p>
          We sent a one-time verification <br />
          code to you at
        </p>
        <h4 className="muted-text">{phoneFormatted}</h4>
        <ReactCodeInput
          type="number"
          fields={6}
          onChange={(value) => setCode(value)}
          className={classNames("code-input", { "is-invalid": validateError })}
        />
        {codeResent
          ? resendData && (
              <div className="text-success text-center mt-4">
                {resendData.resendCode.message}
              </div>
            )
          : validateError && (
              <Form.Control.Feedback type="invalid">
                {validateError.graphQLErrors[0].message}
              </Form.Control.Feedback>
            )}
      </Form>
      {validateError && !codeResent && (
        <Button
          className="single-control"
          variant="light"
          onClick={() => {
            setCodeResent(true);
            resendCode();
          }}
        >
          Resend code
        </Button>
      )}
      <BackNext
        stepIndex={stepIndex}
        onNext={validatePhone}
        nextText="Confirm"
        disableNext={!code}
      />
      <button className="simple-link" onClick={onNext}>
        Not Now
      </button>
    </>
  );
};
