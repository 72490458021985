import React from "react";
import _ from "lodash";
import moment from "moment";
import classNames from "classnames";
import useCurrency from "../../../components/hooks/helpers/useCurrency";
import { useTranslation } from "react-i18next";

const MAX_YEARS = 5;

const PlanCard = ({ plan, planCalculator }) => {
  const { t } = useTranslation();
  const currency = useCurrency();

  if (!plan) return null;

  const selectedPlan = planCalculator.activePlan;

  return (
    <div
      className={classNames("PlanCard", {
        active: plan.id === selectedPlan.id,
      })}
      onClick={() => planCalculator.setPlan(plan.id)}
    >
      <div className="text-center">
        {selectedPlan === plan && (
          <React.Fragment>
            <h6>
              {moment(planCalculator.forecastDate(plan.targetMonths)).format(
                "LL"
              )}
            </h6>
            <p className="text-nowrap">
              {t("property.plan.perMonth", {
                amount: currency(
                  planCalculator.monthlyRent + planCalculator.monthlyEquity
                ),
              })}
            </p>
          </React.Fragment>
        )}
        <div className="py-2">
          <plan.icon
            height={selectedPlan === plan ? 32 : 24}
            color={selectedPlan === plan ? "#fff" : undefined}
          />
        </div>
        {selectedPlan === plan ? (
          <p className="small">
            {t("property.plan.summaryBadge", {
              equity: planCalculator.targetEquity,
            })}{" "}
            {planCalculator.targetDuration.years > 0
              ? t("property.plan.years", {
                  count: planCalculator.targetDuration.years,
                })
              : ""}{" "}
            {planCalculator.targetDuration.months > 0
              ? t("property.plan.months", {
                  count: planCalculator.targetDuration.months,
                })
              : ""}{" "}
            (
            {planCalculator.targetDate
              ? planCalculator.targetDate.format("LL")
              : "Unknown"}
            )
          </p>
        ) : (
          <p className="small text-nowrap">
            {t("property.plan.perMonth", {
              amount: currency(
                planCalculator.forecastPayment(plan.targetMonths)
              ),
            })}
          </p>
        )}
      </div>{" "}
    </div>
  );
};

export default ({ planCalculator }) => {
  return (
    <div className="PlanSelectionTimeline">
      <div className="ruler">
        {_.range(0, MAX_YEARS + 1).map((year) => (
          <div className="cm" key={`year-${year}`}>
            {_.range(0, year !== MAX_YEARS ? 12 : 1).map((month) => (
              <div key={`month-${month}`} className="mm">
                <PlanCard
                  plan={planCalculator.getPlan(year, month)}
                  planCalculator={planCalculator}
                />
              </div>
            ))}

            <div className="label">
              {year === 0
                ? "Move In"
                : moment(planCalculator.moveIn).add(year, "years").format("LL")}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
