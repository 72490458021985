import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const QUERY = gql`
  mutation UserExists($email: String, $anonymousId: String) {
    userExists(email: $email, anonymousId: $anonymousId)
  }
`;

export default ((onCompleted, onError) => {
  const [validateEmail, {
    loading,
    error }] =
    useMutation(QUERY, {
    variables: {
      anonymousId: localStorage.getItem("dojo_id"),
    },
    refetchQueries: () => ["AnonymousUser"],
    onCompleted: data => onCompleted && onCompleted(data && data.userExists),
    onError: err => onError && onError(err) });

  return [email => validateEmail({
    variables: { email } }),
  loading, error];
});
