import React, { useState, useEffect } from 'react';


function imageToDataUrl(file) {
  return new Promise((accept, deny) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = e => accept(e.target.result);
      reader.readAsDataURL(file);
    } else {
      deny({
        message: "" });

    }
  });
}

export default (({ file }) => {
  const [preview, setPreview] = useState();

  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(file);
  }, [file]);

  return (
    <div className="PreviewContainer">
      <div style={{ backgroundImage: `url(${preview})` }}></div>
    </div>);

});