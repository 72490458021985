import gql from "graphql-tag";
import { useQuery } from "@apollo/react-hooks";

const QUERY = gql`
  query GetMyOffer($id: String!) {
    application(id: $id) {
      _id
      down_payment

      status
      move_in

      plan {
        id
        title
        targetEquity
        targetMonths
      }

      paymentMethod {
        id
        name
        mask
        type
      }

      payoutMethod {
        id
        type
      }

      listing {
        _id
        address
        price
        rent
        equity_deposit
        available_from

        offers {
          _id
        }

        owner {
          _id
          firstname
          lastname
          email
          phone

          profile {
            photo {
              _id
              fileid
              extension
            }
          }
        }
      }
    }
  }
`;

export default ((id, onCompleted, onError) => {
  const { data, loading, error } = useQuery(QUERY, { variables: { id },  fetchPolicy: "network-only", onCompleted, onError });


  return [data && data.application, loading, error];
});
