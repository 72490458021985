import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import nl2br from "react-nl2br";

import Avatar from "../general/Avatar";

import "./ChatMessage.scss";

export default (({ user, message, showAvatar }) => {
  const isOwnMessage = user._id === message.from._id;

  const messageSpan = isOwnMessage ? { span: 8 } : { span: 8, offset: 4 };
  const messageClass = isOwnMessage ? "" : "message-other";

  return (
    <Row>
      <Col xs={12} lg={messageSpan} className="my-3">
        <Card className={messageClass}>
          <Card.Body className="pb-2 d-flex">
            {showAvatar && <div className="flex-grow-0 pr-3 mt-n2">
                <Avatar user={message.from} size={48}></Avatar>
            </div>}
            <div className="flex-grow-1">
              <div>{nl2br(message.text)}</div>
              <div className="small text-right text-uppercase">
                {moment(message.timestamp).format("DD MMM, LT")}
              </div>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>);

});