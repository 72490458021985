import React from "react";
import ReactPixel from "react-facebook-pixel";
import { useMixpanel } from "react-mixpanel-browser";

export default ({
  children,
  onClick,
  pixelEvent,
  mixpanelEvent,
  hubspotEvent,
}) => {
  const mixpanel = useMixpanel();

  const customClick = () => {
    console.log("Click detected");
    if (pixelEvent) {
      ReactPixel.trackCustom(pixelEvent);
    }
    if (mixpanelEvent) {
      mixpanel.track(mixpanelEvent);
    }
    if (hubspotEvent && window._hsq) {
      window._hsq.push([hubspotEvent, {}]);
    }
    onClick && onClick();
  };

  return children(customClick);
};
