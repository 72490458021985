import React, { useRef } from "react";

import { makeImageUrl } from "../../tools";
import useSetProfileImage from "../hooks/mutations/useSetProfileImage";
import { Button } from "react-bootstrap";

export default ({ user, editing, setProfile }) => {
  const fileInputRef = useRef(null);

  console.log(user.profile);

  const [uploadImage] = useSetProfileImage(
    (result) => {
      console.log(result);
      setProfile({ photo: result._id });
    },
    (err) => {
      console.log(err);
    }
  );

  return (
    <React.Fragment>
      <div
        style={{
          position: "relative",
          disaply: "inline-block",
          width: "120px",
          height: "120px",
          margin: "0 auto",
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            background: "#EEF7FF",
            backgroundSize: "cover",
            backgroundPosition: "center",
            backgroundImage:
              user.profile && user.profile.photo
                ? `url(${makeImageUrl(user.profile.photo)})`
                : null,
            borderRadius: "50%",
          }}
        ></div>
      </div>
      {editing ? (
        <div>
          <Button
            className="mt-3"
            variant="light"
            size="sm"
            onClick={(e) => fileInputRef.current.click()}
          >
            Upload Photo
          </Button>
          <input
            style={{ display: "none" }}
            ref={fileInputRef}
            className="FileInput"
            type="file"
            onChange={(e) =>
              uploadImage({
                variables: { file: e.target.files.item(0) },
              })
            }
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};
