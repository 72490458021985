import React from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import _ from "lodash";

import useGreatSchools from "../../../components/hooks/queries/useGreatSchools";
import Loading from "../../../components/general/Loading";
import { Star } from "../../../components/icons";
import InfoSlider from "../../../components/carousel/InfoSlider";

const slickSettings = {
  slidesToShow: 2.3,
  slidesToScroll: 2,
  infinite: false,
  cssEase: "linear",
  padding: 48,

  responsive: [
    {
      breakpoint: 550,
      settings: {
        infinite: false,
        slidesToShow: 1.11,
        slidesToScroll: 1,
      },
    },
  ],
};

const Rating = ({ amount }) => (
  <div>
    {_.range(0, 5).map((i) => (
      <span key={i} style={{ paddingRight: 4 }}>
        <Star height={18} color={i >= (amount || 0) ? "#979EA6" : "#F2C94C"} />
      </span>
    ))}
  </div>
);

export default React.forwardRef(({ listing }, ref) => {
  const { t } = useTranslation();
  const [schools, loading] = useGreatSchools(listing._id);

  return (
    <Card ref={ref} id="schools" className="ListingNeighbourhood">
      <Card.Body>
        <h5 className="mb-3">{t("property.schools")}</h5>
        {loading ? (
          <Loading />
        ) : (
          <InfoSlider {...slickSettings}>
            {_.take(schools, 20)
              .filter((school) => school.gsRating)
              .map((school) => (
                <div className="p-2" key={school.gsId}>
                  <Card className="d-flex flex-grow-1 no-shadow">
                    <Card.Body>
                      <div className="d-flex">
                        <div className="SchoolScore">
                          <div>
                            <h4>{school.gsRating}</h4>/10
                          </div>
                        </div>
                        <div className="pl-2">
                          <Rating amount={Math.round(school.gsRating / 2)} />
                          <div className="my-2 small">
                            <a
                              href={school.reviewsLink}
                              rel="nofollow noopener noreferrer"
                              target="_blank"
                            >
                              {t("property.schoolReviews")}
                            </a>
                          </div>
                        </div>
                      </div>
                      <div className="mt-5 mb-4" style={{ minHeight: 72 }}>
                        <h6 className="em">{school.name}</h6>
                        <div className="smallish">
                          {school.type} ·{" "}
                          {(school.gradeRange || "")
                            .toString()
                            .replace("-", " to ")
                            .replace("PK", "Pre-K")}
                        </div>
                      </div>
                      <div className="d-flex">
                        <div className="flex-grow-0 mr-3">
                          <h5>{school.enrollment}</h5>
                          <div className="small">Students</div>
                        </div>
                        <div className="flex-grow-0">
                          <h5>{school.distance} mi</h5>
                          <div className="small">Distance</div>
                        </div>
                      </div>
                    </Card.Body>
                  </Card>
                </div>
              ))}
          </InfoSlider>
        )}

        <p className="mt-4">
          Data provided by{" "}
          <a href="https://www.greatschools.org" rel="nofollow">
            GreatSchools
          </a>{" "}
          . To verify enrollment eligibility, contact the school directly.
        </p>
      </Card.Body>
    </Card>
  );
});
