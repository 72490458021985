import gql from "graphql-tag";
import {
  useMutation
} from "@apollo/react-hooks";

const MUTATION = gql `
  mutation UpdateUser($params: UserParamsInput) {
    updateMe(params: $params) {
      _id
    }
  }
`;

export default ((refetchQueries = []) => {
  const [updateUser, {
    loading,
    error
  }] = useMutation(MUTATION, {
    refetchQueries: () => ["AllUsers", ...refetchQueries],
  });


  return [(params) => updateUser({
    variables: {
      params
    }
  }), loading, error];
});
