import React, { useContext } from "react";
import {
  Accordion,
  useAccordionToggle,
  Card,
} from "react-bootstrap";
import AccordionContext from 'react-bootstrap/AccordionContext';
import { Expand, ExpandThin } from "./icons";
import classNames from "classnames";

const HeaderToggle = ({ eventKey, children }) => {
  const currentEventKey = useContext(AccordionContext);
  const isActive = currentEventKey === eventKey;

  const decoratedOnClick = useAccordionToggle(eventKey);

  return (
    <Accordion.Toggle
      as={Card.Header}
      eventKey={eventKey}
      className={classNames({
        expanded: isActive,
      })}
      onClick={decoratedOnClick}
    >
      {children}
    </Accordion.Toggle>
  );
};

const AccordionItem = ({
  item,
  thinArrows,
  eventKey,
}) => (
  <Card>
    <HeaderToggle
      eventKey={eventKey}
    >
      {item.question}
      {thinArrows ? <ExpandThin /> : <Expand />}
    </HeaderToggle>
    <Accordion.Collapse eventKey={eventKey}>
      <Card.Body>{item.answer}</Card.Body>
    </Accordion.Collapse>
  </Card>
);

export default ({ items = [], thinArrows }) => {
  return (
    <Accordion>
      {items.map((item, index) => (
        <AccordionItem
          key={item.question}
          eventKey={index}
          item={item}
          thinArrows={thinArrows}
        />
      ))}
    </Accordion>
  );
};
