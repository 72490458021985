import {useState} from "react";
import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";

const QUERY = gql`
  query GetMlsSettings {
    settings {
      mls {
        owner
        minPrice
        maxPrice
      }
    }
  }
`;

const MUTATION = gql`
  mutation SetMlsSettings($mls: MlsSettingsInput!) {
    updateSettings(settings: {
      mls: $mls
    }) {
      message
    }
  }
`;

export default (onCompleted) => {
  const [mlsSettings, setMlsSettings] = useState({})
  const {
    loading,
    error,
    refetch
  } = useQuery(QUERY, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setMlsSettings(_.omit(data.settings.mls || {}, ['__typename']));
    }
  });

  const [saveMlsSettings] = useMutation(MUTATION, {
    variables: {mls: mlsSettings},
    refetchQueries: () => ['GetMlsSettings'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [mlsSettings, (update) => setMlsSettings({...mlsSettings, ...update}), refetch, saveMlsSettings, loading, error];
};