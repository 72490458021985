import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const makeQuery = (endpoint, id) => {
  const query = `query External${endpoint}($id: ID!) {
    ${endpoint}(id: $id)
  }`
  return gql(query);
}


export default ((endpoint, id) => {
  const QUERY = makeQuery(endpoint);
  const {
    data,
    loading,
    error
  } = useQuery(QUERY, {
    variables: {
      id
    }
  });

  return [data && data[endpoint], loading, error];
});
