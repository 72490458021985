import {
  useState
} from "react";
import gql from "graphql-tag";
import {
  useQuery,
  useMutation
} from "@apollo/react-hooks";
import _ from "lodash";

const GET_SETTINGS = gql `
  query GetListingSettings {
    settings {
      listing {
        zipCodes
      }
    }
  }
`;

const SET_SETTINGS = gql `
  mutation SetListingSettings($listing: ListingSettingInput!) {
    updateSettings(settings: {
      listing: $listing
    }) {
      message
    }
  }
`;

export default (onCompleted) => {
  const [listingSettings, setListingSettings] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const {
    loading,
    error,
    refetch
  } = useQuery(GET_SETTINGS, {
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setIsChanged(false);
      setListingSettings(_.omit(data.settings.listing, ['__typename']));
    }
  });

  const [saveSettings] = useMutation(SET_SETTINGS, {
    variables: {
      listing: listingSettings
    },
    refetchQueries: () => ['GetCategorySettings'],
    onCompleted: (data) => onCompleted && onCompleted(data)
  });


  return [listingSettings, (update = {}) => {
    setListingSettings({
      ...listingSettings,
      ...update
    });
    setIsChanged(true)
  }, refetch, saveSettings, isChanged, loading, error];
};