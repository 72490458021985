import React from "react";
import _ from "lodash";
import Select from "react-select";

import usePropertyCategories from "..//hooks/queries/usePropertyCategories";

const categoryToSelection = ({ _id, header, thumbnail }) => ({
  value: _id,
  label: `${header} [${thumbnail}]`,
});

const customStyles = {
  control: (provided, state) => {
    const border = "1px solid #CCE6FF";
    return { ...provided, border };
  },
  multiValue: (provided, state) => {
    const background = "rgba(204, 230, 255, 0.75)";
    const border = "1px solid #CCE6FF";
    const borderRadius = 5;
    const color = "#2D596D";
    const fontSize = "1.1rem";
    return {
      ...provided,
      border,
      borderRadius,
      background,
      color,
      fontSize,
    };
  },
  option: (provided, state) => {
    return {
      ...provided,
    };
  },
};

export default ({ value, onChange }) => {
  const [categories, loading] = usePropertyCategories();

  const valueToCategory = ({ value }) => _.find(categories, { _id: value });

  return (
    <Select
      isMulti
      styles={customStyles}
      //components={{ MultiValueLabel, Option }}
      options={_.map(categories, categoryToSelection)}
      value={_.map(value, categoryToSelection)}
      onChange={(items) => {
        onChange(_.map(items, valueToCategory));
      }}
    />
  );
};
