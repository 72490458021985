import { useQuery } from "@apollo/react-hooks";
import gql from 'graphql-tag';

const QUERY = gql`query Neighbourhoods { neighbourhoods }`

export default (() => {
  const { loading, data, error } = useQuery(QUERY);

  return [data && data.neighbourhoods, loading, error];
});
