import gql from "graphql-tag";
import {
  useQuery
} from "@apollo/react-hooks";

const QUERY = gql`
  fragment StatInfoFragment on StatInfo {
    min
    max
    pins
  }

  query GetSearchStats {
    listingStats(bars: 30) {
      price {...StatInfoFragment}
      rent {...StatInfoFragment}
      downPayment {...StatInfoFragment}
      sqft {...StatInfoFragment}
    }
  }
`;

export default ((onCompleted, onError) => {
  const {
    data,
    loading,
    error
  } = useQuery(QUERY, {
    onCompleted: data => onCompleted && onCompleted(data),
    onError: err => onError && onError(err)
  });


  return [data && data.listingStats, loading, error];
});
