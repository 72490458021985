import React from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { useTranslation } from "react-i18next";

export default ({ user }) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Nav.Link className="px-3" as={Link} to="/buyer/favorites">
        {t("member.menu.favorites")}
      </Nav.Link>
      <Nav.Link className="px-3" as={Link} to="/buyer/offers">
        {t("member.menu.offers")}
      </Nav.Link>
      <Nav.Link className="px-3" as={Link} to="/buyer/my-home">
        {t("member.menu.home")}
      </Nav.Link>
    </React.Fragment>
  );
};
