import gql from "graphql-tag";
import { useMutation } from "@apollo/react-hooks";

const MUTATION = gql `
  mutation AddCityRequest($request: CityRequestInput) {
    addCityRequest(request: $request) {
      _id
    }
  }
`;

export default ((request, onCompleted, onError) => {
  const [addCityRequest, {
    loading,
    error = {}
  }] = useMutation(MUTATION, {
    variables: {
      request
    },
    onCompleted: data => onCompleted && onCompleted(data)
  });


  return [addCityRequest, loading, error];
});