import React from "react";
import { Row, Col, Form, Button, ListGroupItem } from "react-bootstrap";

import useUpdateCategory from "../../../components/hooks/mutations/useUpdateCategory";
import useDeleteCategory from "../../../components/hooks/mutations/useDeleteCategory";

export default ({ key, category, onEdit }) => {
  const [updateCategory] = useUpdateCategory(category);
  const [deleteCategory] = useDeleteCategory(category._id);

  return (
    <ListGroupItem key={key}>
      <Row>
        <Col md={4}>{category.header}</Col>
        <Col md={3}>{category.thumbnail}</Col>
        <Col md={2}>{category.labelled || 0}</Col>
        <Col md={3} className="d-flex">
          <Form.Check
            id={`category-switch-${category._id}`}
            type="switch"
            checked={category.status === "active"}
            onChange={(e) =>
              updateCategory({
                variables: {
                  status: category.status === "active" ? "hidden" : "active",
                },
              })
            }
            label=""
          />
          <Button
            size="sm"
            variant="outline-danger"
            className="mr-1"
            onClick={() => deleteCategory()}
          >
            Delete
          </Button>
          <Button
            size="sm"
            variant="outline-primary"
            className="mr-1"
            onClick={() => onEdit(category)}
          >
            Edit
          </Button>
        </Col>
      </Row>
    </ListGroupItem>
  );
};
