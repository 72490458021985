import React, { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { loader } from "graphql.macro";
import { useQuery, useMutation } from "@apollo/react-hooks";
import _ from "lodash";
import classNames from "classnames";
import moment from "moment";
import DatePicker from "react-datepicker";
import { Row, Col, Card, Button, Modal } from "react-bootstrap";

import ApplicantValuation from "./ApplicantValuation";
import { protectedUserName } from "../../tools";
import StripeConnect from "../../components/payment/StripeConnect";

const GET_APPLICATION = loader("./GetApplication.graphql");
const UPDATE_APPLICATION = loader("./UpdateApplication.graphql");

export default ({ user }) => {
  const match = useRouteMatch();

  console.log(user);
  const [moveIn, setMoveIn] = useState(null);
  const [payoutMethod, setPayoutMethod] = useState(user.payoutMethod || {});
  const [confirmApplication, setConfirmApplication] = useState(null);
  const [currentStep, setCurrentStep] = useState(1);

  const { loading, data } = useQuery(GET_APPLICATION, {
    variables: { id: match.params.aid },
    onCompleted: (data) => {
      setMoveIn(moment(data.application.move_in).toDate());
    },
  });

  const [sendContract] = useMutation(UPDATE_APPLICATION, {
    variables: {
      id: match.params.aid,
      status: "approved",
      params: {
        move_in: moveIn,
        payoutMethod,
      },
    },

    onCompleted: (result) => {
      console.log(result);
      window.location = "/seller/properties";
    },
    onError: (err) => {
      console.log(err);
    },
  });

  const [rejectApplication] = useMutation(UPDATE_APPLICATION, {
    variables: {
      id: match.params.aid,
      status: "rejected",
    },

    refetchQueries: () => ["GetApplication"],
    onError: (err) => {
      console.log(err);
    },
  });

  if (loading || !data) return null;

  const application = data.application;

  return (
    <Col xs={12} lg={{ span: 10, offset: 1 }} className="my-4">
      <Modal
        show={confirmApplication}
        size="lg"
        center="true"
        onHide={(e) => setConfirmApplication(false)}
      >
        <Modal.Header className="d-flex p-0">
          <h4
            className={classNames(
              "flex-grow-1",
              "text-center",
              "my-0",
              "py-4",
              { "header-shadow": currentStep === 2 }
            )}
          >
            Offer
          </h4>
          <h4
            className={classNames(
              "flex-grow-1",
              "text-center",
              "my-0",
              "py-4",
              { "header-shadow": currentStep === 1 }
            )}
          >
            Payout
          </h4>
        </Modal.Header>
        {currentStep === 1 ? (
          <Modal.Body>
            <Card>
              <Card.Body style={{ height: "320px", overflowY: "scroll" }}>
                TODO
              </Card.Body>
            </Card>

            <Row className="mt-4">
              <Col lg={{ span: 4, offset: 4 }}>
                <h5 className="text-center">Move in Date</h5>
                <DatePicker
                  selected={moveIn}
                  minDate={new Date()}
                  onChange={(date) => {
                    setMoveIn(date);
                  }}
                  className="form-control"
                />
              </Col>
            </Row>
          </Modal.Body>
        ) : (
          <Modal.Body>
            <p>
              You need to connect one of the payment methods to collect rent and
              receive payments.
            </p>

            <h5 className="my-4">Select a Method</h5>

            <StripeConnect
              user={user}
              defaultPayout={user.payoutMethod && user.payoutMethod.id}
              setDefaultPayout={({ id, name, mask }) =>
                setPayoutMethod({
                  id,
                  name,
                  mask,
                  type: "stripe",
                })
              }
            />
          </Modal.Body>
        )}

        <Modal.Footer className="d-flex">
          <div className="flex-grow-1"></div>
          {currentStep === 1 ? (
            <Button
              disabled={!moveIn}
              variant="success"
              className="px-5"
              onClick={(e) => setCurrentStep(2)}
            >
              AGREED
            </Button>
          ) : (
            <Button
              variant="success"
              className="px-5"
              onClick={(e) => sendContract()}
            >
              SEND OFFER
            </Button>
          )}

          <div className="flex-grow-1"></div>
        </Modal.Footer>
      </Modal>

      <Card>
        <Card.Body>
          <Link
            to={`/seller/properties/${application.listing._id}`}
            className="small"
          >
            &lt; BACK
          </Link>

          <div className="d-flex mt-1">
            <h4 className="flex-grow-0 mr-4">
              {protectedUserName(application.applicant, user)}
            </h4>
            <div className="flex-grow-1">
              <Button
                as={Link}
                to={`/property/${application.listing._id}/chat/${application.applicant._id}`}
                size="sm"
                variant="outline-secondary"
              >
                SEND MESSAGE
              </Button>
            </div>
          </div>

          <p>{application.notes}</p>

          {application.status === "new" ? (
            <div className="mt-4">
              <Button
                variant="success"
                className="mr-3 px-4 py-1"
                onClick={(e) => setConfirmApplication(true)}
              >
                Approve Application
              </Button>
              <Button
                variant="outline-secondary"
                className="px-4 py-1"
                onClick={(e) => rejectApplication()}
              >
                Decline Application
              </Button>
            </div>
          ) : application.status === "approved" ? (
            <div className="mt-4">
              <Button
                variant="outline-primary"
                className="mr-3 px-4 py-1"
                disabled={true}
              >
                Awaiting documents from applicant
              </Button>
            </div>
          ) : application.status === "rejected" ? (
            <div className="mt-4">
              <Button
                variant="outline-warning"
                className="px-4 py-1"
                disabled={true}
              >
                Application Rejected
              </Button>
            </div>
          ) : null}
          <hr />
          <div className="my-4">
            <h6>Expected Term</h6>
            <div>{(application.plan.targetMonths / 12).toFixed(1)} years</div>
          </div>
          <hr />

          {_.includes(user.roles, "admin") && (
            <ApplicantValuation application={application} />
          )}

          <div className="my-4">
            <h6>Expected Move In</h6>
            <div>{moment(application.move_in).format("LL")}</div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};
