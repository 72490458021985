import React, { useState } from "react";
import currencyFormatter from "currency-formatter";
import { Row, Col, Button, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import MoreEquityModal from "./MoreEquityModal";
import { protectedLastName } from "../../tools";
import PlanData from "../../pages/Member/PlanData";

export default ({ user, activeOffer, setActiveOffer }) => {
  const { t } = useTranslation();
  const [moreEquity, setMoreEquity] = useState(false);
  const isSeller = activeOffer.owner._id === user._id;

  return (
    <React.Fragment>
      {!isSeller ? (
        <MoreEquityModal
          user={user}
          offer={activeOffer}
          show={moreEquity}
          onHide={(e) => setMoreEquity(false)}
        />
      ) : null}
      <Row>
        <Col xs={12}>
          <Card className="mt-1 mb-4">
            <Card.Body>
              <h4>{t("contract.info.plan")}</h4>

              <Row className="my-4">
                <Col md={3}>
                  <div>
                    <small>{t("contract.info.total_price")}</small>
                  </div>
                  <h6>
                    {currencyFormatter.format(activeOffer.listing.price, {
                      code: "USD",
                      precision: 0,
                    })}
                  </h6>
                </Col>
                <Col md={2}>
                  <div>
                    <small>{t("contract.info.monthly_rent")}</small>
                  </div>
                  <h6>
                    {currencyFormatter.format(activeOffer.listing.rent || 0, {
                      code: "USD",
                      precision: 0,
                    })}
                  </h6>
                </Col>
                <Col md={2}>
                  <div>
                    <small>{t("contract.info.down_payment")}</small>
                  </div>
                  <h6>
                    {currencyFormatter.format(activeOffer.down_payment || 0, {
                      code: "USD",
                      precision: 0,
                    })}
                  </h6>
                </Col>
                <Col md={2}>
                  <div>
                    <small>{t("contract.info.current_equity")}</small>
                  </div>
                  <h6>
                    {(
                      (activeOffer.payments / activeOffer.listing.price) *
                      100
                    ).toFixed(2)}
                    %{" "}
                    {activeOffer.status === "closed"
                      ? `(${currencyFormatter.format(activeOffer.payments, {
                          code: "USD",
                          precision: 0,
                        })})`
                      : null}
                  </h6>
                </Col>
                <Col md={3}>
                  {!isSeller ? (
                    <Button
                      variant="success"
                      block
                      className="text-uppercase mt-2"
                      onClick={(e) => setMoreEquity(true)}
                    >
                      {t("contract.info.buy_more_equity")}
                    </Button>
                  ) : null}
                </Col>
              </Row>

              {isSeller ? (
                <div></div>
              ) : (
                <React.Fragment>
                  <h5 className="mt-3 mb-2">
                    {t("contract.info.current_plan")}
                  </h5>
                  <PlanData offer={activeOffer} />
                </React.Fragment>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col xs={12}>
          <Card className="mt-1 mb-4">
            <Card.Body>
              <h5>{t("contract.info.member_info")}</h5>

              <Row className="mt-3">
                <Col xs={6} md={4}>
                  <div>
                    <small>{t("contract.info.firstname")}</small>
                  </div>
                  <h6>{activeOffer.applicant.firstname}</h6>
                </Col>
                <Col xs={6} md={4}>
                  <div>
                    <small>{t("contract.info.lastname")}</small>
                  </div>
                  <h6>{protectedLastName(activeOffer.applicant, user)}</h6>
                </Col>
              </Row>

              {/* <Row className="mt-3">
                <Col md={4}>
                  <div>
                    <small>{t("contract.info.phone")}</small>
                  </div>
                  <h6>
                    {!activeOffer.applicant.validated_phone ? "⚠️" : ""}{" "}
                    {activeOffer.applicant.validated_phone ||
                      activeOffer.applicant.phone}
                  </h6>
                </Col>
                <Col md={6}>
                  <div>
                    <small>{t("contract.info.email")}</small>
                  </div>
                  <h6>{activeOffer.applicant.email}</h6>
                </Col>
              </Row> */}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};
