import React from "react";
import classNames from "classnames";
import _ from "lodash";
import { Card } from "react-bootstrap";

export default ({ options, data, field, setData, multiple, small }) => {
  const isActive = (value) =>
    multiple ? _.includes(data[field], value) : data[field] === value;

  const multipleFields = multiple && (data[field] || []);

  const switchValue = (value) => {
    if (_.includes(multipleFields, value)) {
      setData({ [field]: _.without(multipleFields, value) });
    } else {
      setData({ [field]: [...multipleFields, value] });
    }
  };

  return (
    <div
      className={classNames("select-cards", `cards-${options.length}`, {
        "cards-small": small,
      })}
    >
      {options.map((option, i) => (
        <Card
          className={classNames({
            active: isActive(option.value),
          })}
          onClick={() =>
            multiple
              ? switchValue(option.value)
              : setData({ [field]: option.value })
          }
          key={`${option.value} card ${i}`}
        >
          <Card.Body>
            {option.icon && (
              <div className="card-icon">
                <option.icon color={isActive(option.value) && "white"} />
              </div>
            )}
            <div className="card-label">{option.label}</div>
            {option.subtitle && (
              <div className="card-subtitle">{option.subtitle}</div>
            )}
          </Card.Body>
        </Card>
      ))}
    </div>
  );
};
